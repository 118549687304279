import {
  SMARTER_SEARCH_CLUSTERS,
  type SmarterSearchCluster,
} from 'src/config/types';

import type { ChaliceStore } from '../types';

import type { TFeatureFlagNames } from './types';

// defaults to 'default' cluster if no cookie
export const selectSmarterSearchCluster = (
  state: ChaliceStore,
): SmarterSearchCluster =>
  state.featureFlags?.smarterSearchLaunchCluster ?? SMARTER_SEARCH_CLUSTERS[0];

export const selectFeatureFlag =
  (flagName: TFeatureFlagNames) => (state: ChaliceStore) =>
    Boolean(state.featureFlags[flagName]);
