import type { SearchResultJob, SearchResultJobV5 } from '@seek/chalice-types';
import { JOB_DISPLAY_TYPE_QUERY_KEY } from '@seek/job-details-view';

import { SELECTED_JOB_ID_SEARCH_QUERY_KEY } from 'src/store/results';
import { pushParams, removeParams } from 'src/utils/urlParams';

export const persistJobUrl = ({
  job: { id, displayType },
}: {
  job: Pick<SearchResultJob | SearchResultJobV5, 'id' | 'displayType'>;
}) =>
  pushParams({
    [SELECTED_JOB_ID_SEARCH_QUERY_KEY]: String(id),
    [JOB_DISPLAY_TYPE_QUERY_KEY]: displayType,
  });

export const removePersistedJobUrl = () =>
  removeParams([SELECTED_JOB_ID_SEARCH_QUERY_KEY, JOB_DISPLAY_TYPE_QUERY_KEY]);

// TODO While we support both V4 and V5 search responses, the selected jobId can either be a string (V5) or number (V4)
// This should be a temporary workaround until we fully cutover and we can consolidate types
export const compareJobIds = (
  currentJobId?: number | string,
  selectedJobId?: number | string,
) => {
  if (!currentJobId || !selectedJobId) {
    return false;
  }

  const convertedCurrentJobId =
    typeof currentJobId === 'string'
      ? parseInt(currentJobId, 10)
      : currentJobId;
  const convertedSelectedJobId =
    typeof selectedJobId === 'string'
      ? parseInt(selectedJobId, 10)
      : selectedJobId;

  return convertedCurrentJobId === convertedSelectedJobId;
};
