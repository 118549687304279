import type { UrlLocation } from '@seek/chalice-types';
import type { CountrySiteName } from '@seek/melways-sites';
import {
  hrefFromLocation,
  isSameHost,
  isSeekLocation,
} from '@seek/seek-jobs-seo';
import uniq from 'lodash/uniq';
import { useMemo } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import type { LocationState } from 'src/store/location/types';
import { useSelector } from 'src/store/react';
import { selectLocation } from 'src/store/selectors';
import type { Country } from 'src/types/globals';

import { isQualifiedLocationNoFollow, qualifyLocation } from './seo';

const isCurrentLocation = (
  location: ReturnType<typeof getQualifiedLocation>,
  currentLocation: LocationState,
) => {
  const homePage = `${currentLocation.hostname}/`;

  const [newURL, currentURL] = [location, currentLocation].map((loc) => {
    const path = loc.pathname || '/';
    const host = loc.hostname || currentLocation.hostname;
    return host + path;
  });

  if (newURL === homePage) {
    return false;
  }

  return newURL === currentURL;
};

const calculateRel = (
  qualifiedLocation: ReturnType<typeof getQualifiedLocation>,
  currentLocation: LocationState,
  providedRel: string,
  site: CountrySiteName,
) => {
  const noFollowRequired =
    isQualifiedLocationNoFollow(qualifiedLocation, currentLocation, site) ||
    isCurrentLocation(qualifiedLocation, currentLocation);

  const noFollowNotPresent =
    typeof providedRel === 'string' && providedRel.indexOf('nofollow') === -1;

  const shouldAddNoFollow = noFollowRequired && noFollowNotPresent;

  const shouldNoOpen = !isSeekLocation(qualifiedLocation);

  return (
    uniq([
      ...(shouldNoOpen ? ['noopener'] : []),
      ...(shouldAddNoFollow ? ['nofollow'] : []),
      ...(providedRel ? providedRel.split(' ') : []),
    ]).join(' ') || undefined
  );
};

export const isJobExternal = (
  candidateCountryCode: Country,
  jobCountryCode?: string,
) =>
  typeof jobCountryCode !== 'undefined' &&
  jobCountryCode.toLowerCase() !== candidateCountryCode.toLowerCase();

export const useIsJobExternal = (countryCode?: string) => {
  const { country } = useAppConfig();

  return isJobExternal(country, countryCode);
};

// baseLocation must be enriched
export const isRelativeLocation = (
  baseLocation: LocationState,
  targetLocation: UrlLocation,
) => isSameHost(baseLocation, targetLocation) || !targetLocation.hostname;

export function getQualifiedLocation(
  currentLocation: LocationState,
  newLocation: UrlLocation,
  site: CountrySiteName,
) {
  if (
    isRelativeLocation(currentLocation, newLocation) &&
    !newLocation.pathname
  ) {
    return { ...currentLocation, hash: '#' };
  }
  return qualifyLocation(newLocation, currentLocation, site);
}

export function useQualifiedLinkParams({
  rel: relFromProps = '',
  shouldUseUrlResolver = true,
}) {
  const currentLocation = useSelector(selectLocation);
  const { site } = useAppConfig();

  // turn a location into {href, rel}
  const getQualifiedLinkParams = useMemo(
    () => (newLocation: UrlLocation) => {
      const qualifiedLocation = shouldUseUrlResolver
        ? getQualifiedLocation(currentLocation, newLocation, site)
        : newLocation;

      const href = hrefFromLocation(
        qualifiedLocation,
        undefined,
        isRelativeLocation(currentLocation, qualifiedLocation),
      );

      const rel = calculateRel(
        qualifiedLocation,
        currentLocation,
        relFromProps,
        site,
      );

      return { href, rel };
    },
    [currentLocation, relFromProps, shouldUseUrlResolver, site],
  );

  return getQualifiedLinkParams;
}
