
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IHNhbGFyeSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IlNhbGFyeSAoe2N1cnJlbmN5Q29kZX0pIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJHYWppIHtsYWJlbH0iLCJTYWxhcnkgKGN1cnJlbmN5Q29kZSkiOiJHYWppICh7Y3VycmVuY3lDb2RlfSkifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJHYWppIHtsYWJlbH0iLCJTYWxhcnkgKGN1cnJlbmN5Q29kZSkiOiJHYWppICh7Y3VycmVuY3lDb2RlfSkifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IOC5gOC4h+C4tOC4meC5gOC4lOC4t+C4reC4mSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IuC5gOC4h+C4tOC4meC5gOC4lOC4t+C4reC4mSAoe2N1cnJlbmN5Q29kZX0pIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJ7bGFiZWx9IOC5gOC4h+C4tOC4meC5gOC4lOC4t+C4reC4mSIsIlNhbGFyeSAoY3VycmVuY3lDb2RlKSI6IuC5gOC4h+C4tOC4meC5gOC4lOC4t+C4reC4mSAoe2N1cnJlbmN5Q29kZX0pIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJbe2xhYmVsfSDFocSDxIPGmsSDxIPFmcO9w71dIiwiU2FsYXJ5IChjdXJyZW5jeUNvZGUpIjoiW8WgxIPEg8SDxprEg8SDxIPFmcO9w73DvSAoe2N1cnJlbmN5Q29kZX0pXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkiOiJbe2xhYmVsfSDFocSDxIPGmsSDxIPFmcO9w71dIiwiU2FsYXJ5IChjdXJyZW5jeUNvZGUpIjoiW8WgxIPEg8SDxprEg8SDxIPFmcO9w73DvSAoe2N1cnJlbmN5Q29kZX0pXSJ9!"
        )
      )
      });
  
      export { translations as default };
    