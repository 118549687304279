import createTrackingClient from '@seek/seek-impression-tracking';
import { parse as parseCookies } from 'cookie';

import { jobTrackingEnabled, jobTrackingUrl } from 'src/config';
import createLocalEventStore from 'src/modules/createLocalEventStore';

const LOCAL_STORAGE_KEY = 'impression-tracking-logger';

const sessionId = ENV.CLIENT
  ? parseCookies(document.cookie).JobseekerSessionId
  : null;
const loggingClient =
  ENV.CLIENT && jobTrackingEnabled
    ? createTrackingClient(jobTrackingUrl, {
        store: createLocalEventStore(LOCAL_STORAGE_KEY),
        params: sessionId ? { sessionId } : null,
        errorLogger: () => {},
      })
    : null;

export const parseErrArguments = (firstArg, secondArg = '') => {
  const { err = '', req = '', url = '' } = firstArg;

  let errParsed;
  if (typeof err === 'object' && !(err instanceof Error)) {
    errParsed = JSON.stringify(err);
  }

  let combinedMessage;
  if (typeof firstArg === 'string') {
    combinedMessage = `${firstArg}, ${secondArg}`;
  }

  return {
    error: errParsed || `${err.toString()}`,
    message: combinedMessage || secondArg,
    req,
    url,
  };
};

const identity = (args) => args;

const createLogger = (type, argumentParser) => {
  if (loggingClient) {
    return (args) =>
      loggingClient.trackGeneral({ type, data: argumentParser(args) });
  }
  return (_) => {}; // eslint-disable-line no-unused-vars
};

export default {
  error: createLogger('error', parseErrArguments),
  info: createLogger('info', identity),
  warn: createLogger('warn', parseErrArguments),
  log: createLogger('log', identity),
};
