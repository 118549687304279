import { useTranslations, VocabProvider } from '@vocab/react';
import {
  Box,
  BraidProvider,
  Text,
  TextLinkButton,
  ToastProvider,
} from 'braid-design-system';
import seekJobsTheme from 'braid-design-system/themes/seekJobs';
import { renderRoutes } from 'react-router-config';
// @ts-expect-error: non-ts file
import { provideHooks } from 'redial';

import Footer from 'src/components/Footer/Footer';
import { GlobalNotificationBanner } from 'src/components/GlobalNotificationBanner';
import { GoogleOneTapWrapper } from 'src/components/GoogleOneTapWrapper/GoogleOneTapWrapper';
import Header from 'src/components/Header/Header';
import { PreviewBranchName } from 'src/components/PreviewBranchName/PreviewBranchName';
import { ScreenReaderAnnouncer } from 'src/components/ScreenReaderAnnouncer/ScreenReaderAnnouncer';
import SharedHead from 'src/components/SharedHead/SharedHead';
import { useAppConfig } from 'src/config/appConfig';
import BranchBanner from 'src/modules/BranchBanner/BranchBanner';
import { isDatadogSynthetics } from 'src/modules/chalice-user-agent/datadog-synthetics';
import { isGooglebot } from 'src/modules/chalice-user-agent/googlebot';
import { getUserAgent } from 'src/modules/chalice-user-agent/isomorphic-useragent';
import { scrollTo } from 'src/modules/scroll-with-callback';
import {
  isAuthenticated,
  updateUserTestData,
} from 'src/modules/seek-jobs-api-client/apis/candidate';
import {
  experimentsAfterAuthIntialised,
  experimentsIntialised,
  FEATURE_EXPERIMENTS,
  FEATURE_EXPERIMENTS_AFTER_AUTH,
} from 'src/store/experiments';
import { mapToSeekExperiments } from 'src/store/experiments/experimentHelpers';
import {
  updateFeatureFlags,
  updateFeatureFlagsAfterAuth,
} from 'src/store/featureFlags';
import { fetchSalaryNudge } from 'src/store/nudges';
import { selectHostname } from 'src/store/selectors';
import { setAlternateLinks, setCanonicalUrl } from 'src/store/seo';
import { getCandidateAccount, updateSession } from 'src/store/user';
import type { RedialLocals } from 'src/types/RedialLocals';

import { CustomLinkForBraid } from '../components/NavLink/NavLink';

import translations from './.vocab';

import * as styles from './App.css';

const hooks = {
  async first({
    analyticsFacade,
    cookies,
    dispatch,
    getState,
    routeEnter,
    store,
  }: RedialLocals) {
    const { JobseekerVisitorId = '' } = cookies;
    const state = getState();
    const userAgent = getUserAgent();
    const hostname = selectHostname(state);

    const seekExperiments = mapToSeekExperiments({
      userId: JobseekerVisitorId,
      featureExperiments: FEATURE_EXPERIMENTS,
      shouldExcludeFromExperiment:
        isGooglebot(userAgent) || isDatadogSynthetics(userAgent),
      zone: state.appConfig.zone,
    });

    if (routeEnter) {
      const resolvedAuthState = await isAuthenticated();
      await updateUserTestData(store, resolvedAuthState, hostname);

      return Promise.all([
        dispatch(experimentsIntialised(seekExperiments)),
        dispatch(
          updateFeatureFlags(
            cookies,
            seekExperiments,
            state.appConfig.zoneFeatures,
          ),
        ),
      ]).then(() => {
        analyticsFacade.registerExperiments(seekExperiments);
      });
    }
  },

  seo({ dispatch, country, path, query }: RedialLocals) {
    return Promise.all([
      dispatch(setCanonicalUrl({ country, path: path ?? '', query })),
      dispatch(setAlternateLinks({ country, path: path ?? '', query })),
    ]);
  },

  defer({ routeEnter, dispatch, cookies }: RedialLocals) {
    if (routeEnter) {
      return dispatch(
        updateSession({
          userClientId: cookies.JobseekerVisitorId || '',
          sessionId: cookies.JobseekerSessionId || '',
        }),
      );
    }
    return null;
  },

  authenticated: ({
    analyticsFacade,
    dispatch,
    getState,
    routeEnter,
    apolloClient,
    zone,
    cookies,
  }: RedialLocals) => {
    if (routeEnter) {
      return dispatch(getCandidateAccount(apolloClient, analyticsFacade))
        .then(() => {
          const state = getState();

          // The following will push the new user group to `experiments` state.
          const seekExperimentsAfterAuth = mapToSeekExperiments({
            userId: String(state.user.seekerId),
            featureExperiments: FEATURE_EXPERIMENTS_AFTER_AUTH,
            shouldExcludeFromExperiment: false,
            zone,
          });
          dispatch(experimentsAfterAuthIntialised(seekExperimentsAfterAuth));

          // Update feature flags after user details are fetched and updated the `experiments` state.
          dispatch(
            updateFeatureFlagsAfterAuth(
              cookies,
              seekExperimentsAfterAuth,
              state.appConfig.zoneFeatures,
            ),
          );
        })
        .then(() => {
          const { experiments } = getState();
          analyticsFacade.registerExperiments(experiments);
        })
        .then(() => {
          const state = getState();

          const { NUDGE_ENABLED = false } = state.appConfig.zoneFeatures ?? {};

          if (NUDGE_ENABLED) {
            dispatch(
              fetchSalaryNudge({ apolloClient, zone: state.appConfig.zone }),
            );
          }

          analyticsFacade.userDetailsUpdated({
            authenticated: true,
            trackingId: state.user.trackingId,
            loginId: String(state.user.seekerId),
          });
        });
    }

    return null;
  },
};

interface Props {
  route: any;
}

const UseScreenReaderSkipLink = () => {
  const { t } = useTranslations(translations);
  const noop = () => {};

  const scrollToStartOfContent = ENV.CLIENT
    ? () => {
        const startOfContent = document.getElementById('start-of-content');
        scrollTo({
          top: startOfContent?.getBoundingClientRect().top ?? 0,
          behavior: 'smooth',
        });
        startOfContent?.focus();
      }
    : noop;

  return (
    <Text>
      <Box className={styles.screenReaderSkipLink}>
        <TextLinkButton
          onClick={(e) => {
            e.preventDefault();
            scrollToStartOfContent();
          }}
        >
          {t('Skip to content')}
        </TextLinkButton>
      </Box>
    </Text>
  );
};

const App = ({ route }: Props) => {
  const { locale } = useAppConfig();

  return (
    <VocabProvider language={locale} locale={locale}>
      <BraidProvider theme={seekJobsTheme} linkComponent={CustomLinkForBraid}>
        <GoogleOneTapWrapper />
        <PreviewBranchName />
        <GlobalNotificationBanner />
        <ToastProvider>
          <BranchBanner />
          <SharedHead />
          <UseScreenReaderSkipLink />
          <ScreenReaderAnnouncer />
          <Header />
          <div role="main">{renderRoutes(route.routes)}</div>
          <Box paddingTop="xxlarge">
            <Footer />
          </Box>
        </ToastProvider>
      </BraidProvider>
    </VocabProvider>
  );
};

export default provideHooks(hooks)(App);

export const hooksForTests = hooks;
