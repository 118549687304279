
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBpbiIsIkpvYnMgaW4iOiJKb2JzIGluIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IkFsbCBDbGFzc2lmaWNhdGlvbnMiLCJTdWJtaXQgc2VhcmNoIjoiU3VibWl0IHNlYXJjaCIsIlBlcmZvcm0gYSBqb2Igc2VhcmNoIjoiUGVyZm9ybSBhIGpvYiBzZWFyY2giLCJTRUVLIjoiU0VFSyJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBkaSIsIkpvYnMgaW4iOiJMb3dvbmdhbiBkaSIsIkFsbCBDbGFzc2lmaWNhdGlvbnMiOiJTZW11YSBrbGFzaWZpa2FzaSIsIlN1Ym1pdCBzZWFyY2giOiJLaXJpbSBwZW5jYXJpYW4iLCJQZXJmb3JtIGEgam9iIHNlYXJjaCI6Ikxha3VrYW4gcGVuY2FyaWFuIGxvd29uZ2FuIiwiU0VFSyI6IkNhcmkifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSBkaSIsIkpvYnMgaW4iOiJMb3dvbmdhbiBkaSIsIkFsbCBDbGFzc2lmaWNhdGlvbnMiOiJTZW11YSBrbGFzaWZpa2FzaSIsIlN1Ym1pdCBzZWFyY2giOiJLaXJpbSBwZW5jYXJpYW4iLCJQZXJmb3JtIGEgam9iIHNlYXJjaCI6Ikxha3VrYW4gcGVuY2FyaWFuIGxvd29uZ2FuIiwiU0VFSyI6IkNhcmkifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSDguYPguJkiLCJKb2JzIGluIjoi4LiH4Liy4LiZ4LmD4LiZIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IuC4m+C4o+C4sOC5gOC4oOC4l+C4h+C4suC4meC4l+C4seC5ieC4h+C4q+C4oeC4lCIsIlN1Ym1pdCBzZWFyY2giOiLguITguYnguJnguKvguLIiLCJQZXJmb3JtIGEgam9iIHNlYXJjaCI6IuC4hOC5ieC4meC4q+C4suC4h+C4suC4mSIsIlNFRUsiOiLguKvguLLguIfguLLguJkifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoie2tleXdvcmRzfSDguYPguJkiLCJKb2JzIGluIjoi4LiH4Liy4LiZ4LmD4LiZIiwiQWxsIENsYXNzaWZpY2F0aW9ucyI6IuC4m+C4o+C4sOC5gOC4oOC4l+C4h+C4suC4meC4l+C4seC5ieC4h+C4q+C4oeC4lCIsIlN1Ym1pdCBzZWFyY2giOiLguITguYnguJnguKvguLIiLCJQZXJmb3JtIGEgam9iIHNlYXJjaCI6IuC4hOC5ieC4meC4q+C4suC4h+C4suC4mSIsIlNFRUsiOiLguKvguLLguIfguLLguJkifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoiW3trZXl3b3Jkc30gw6zDrMOs4LiB4Li14LmJXSIsIkpvYnMgaW4iOiJbxLTDtsO2w7bDn8WhIMOsw6zDrOC4geC4teC5iV0iLCJBbGwgQ2xhc3NpZmljYXRpb25zIjoiW+G6rMaaxpogw4fGmsSDxIPEg8WhxaHDrMOsw6zGksOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaFdIiwiU3VibWl0IHNlYXJjaCI6IlvFoMeax5rHmsOfbcyCw6zDrMOs4bmvIMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qV0iLCJQZXJmb3JtIGEgam9iIHNlYXJjaCI6IlvGpOG6veG6veG6vcWZxpLDtsO2w7bFmW3MgiDEg8SDxIMgxLXDtsO2w7bDnyDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKldIiwiU0VFSyI6IlvFoMOLw4vhuLBdIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7a2V5d29yZHN9IGluIjoiW3trZXl3b3Jkc30gw6zDrMOs4LiB4Li14LmJXSIsIkpvYnMgaW4iOiJbxLTDtsO2w7bDn8WhIMOsw6zDrOC4geC4teC5iV0iLCJBbGwgQ2xhc3NpZmljYXRpb25zIjoiW+G6rMaaxpogw4fGmsSDxIPEg8WhxaHDrMOsw6zGksOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaFdIiwiU3VibWl0IHNlYXJjaCI6IlvFoMeax5rHmsOfbcyCw6zDrMOs4bmvIMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qV0iLCJQZXJmb3JtIGEgam9iIHNlYXJjaCI6IlvGpOG6veG6veG6vcWZxpLDtsO2w7bFmW3MgiDEg8SDxIMgxLXDtsO2w7bDnyDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKldIiwiU0VFSyI6IlvFoMOLw4vhuLBdIn0=!"
        )
      )
      });
  
      export { translations as default };
    