import type { Logger } from '@seek/logger';

type AnyArg = (...arg: any[]) => void;

const noop: AnyArg = () => {};

type PartialLogger = Pick<
  Logger,
  'trace' | 'debug' | 'info' | 'warn' | 'error'
>;

export let logger: PartialLogger = {
  trace: noop,
  debug: noop,
  info: noop,
  warn: noop,
  error: noop,
};

export const setupLogger = (logProvider: PartialLogger) => {
  logger = logProvider;
};
