import {
  ApacCandidateFooter,
  ApacCandidateHeaderFooterLinkProvider,
} from '@seek/apac-candidate-header-footer';

import { useHeaderFooterLinkProvider } from 'src/hooks';
import { useFooterProps } from 'src/hooks/useHeaderFooterProps';

const Footer = () => {
  const footerLink = useHeaderFooterLinkProvider();
  const {
    brand,
    country,
    hostname,
    isUnified,
    language,
    authenticationStatus,
    widthMode,
  } = useFooterProps();

  return (
    <ApacCandidateHeaderFooterLinkProvider value={footerLink}>
      <ApacCandidateFooter
        authenticationStatus={authenticationStatus}
        brand={brand}
        country={country}
        hostname={hostname}
        isExpandedVersion={true}
        isUnified={isUnified}
        language={language}
        widthMode={widthMode}
      />
    </ApacCandidateHeaderFooterLinkProvider>
  );
};

export default Footer;
