
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJKb2Igc2F2ZWQiLCJKb2IgdW5zYXZlZCI6IkpvYiB1bnNhdmVkIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJMb3dvbmdhbiBrZXJqYSBkaXNpbXBhbiIsIkpvYiB1bnNhdmVkIjoiTG93b25nYW4ga2VyamEgYmF0YWwgZGlzaW1wYW4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJMb3dvbmdhbiBrZXJqYSBkaXNpbXBhbiIsIkpvYiB1bnNhdmVkIjoiTG93b25nYW4ga2VyamEgYmF0YWwgZGlzaW1wYW4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiLguJrguLHguJnguJfguLbguIHguIfguLLguJnguYHguKXguYnguKciLCJKb2IgdW5zYXZlZCI6IuC4ouC4seC4h+C5hOC4oeC5iOC5hOC4lOC5ieC4muC4seC4meC4l+C4tuC4geC4h+C4suC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiLguJrguLHguJnguJfguLbguIHguIfguLLguJnguYHguKXguYnguKciLCJKb2IgdW5zYXZlZCI6IuC4ouC4seC4h+C5hOC4oeC5iOC5hOC4lOC5ieC4muC4seC4meC4l+C4tuC4geC4h+C4suC4mSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJbxLTDtsO2w7bDnyDFocSDxIPEg+G5veG6veG6veG6vcaMXSIsIkpvYiB1bnNhdmVkIjoiW8S0w7bDtsO2w58gx5rHmsea4LiB4Li14LmJxaHEg8SDxIPhub3hur3hur3hur3GjF0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2Igc2F2ZWQiOiJbxLTDtsO2w7bDnyDFocSDxIPEg+G5veG6veG6veG6vcaMXSIsIkpvYiB1bnNhdmVkIjoiW8S0w7bDtsO2w58gx5rHmsea4LiB4Li14LmJxaHEg8SDxIPhub3hur3hur3hur3GjF0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    