import type { ApolloError } from '@apollo/client';
import { datadogLogs, StatusType } from '@datadog/browser-logs';
import { brandAndCountryFromZone } from '@seek/audience-zones';
import { parse } from 'cookie';

import {
  appName,
  datadogBrowserLogsMetadata,
  datadogBrowserLogsIsEnabled,
  environment,
  version,
} from 'src/config';

import { beforeSend } from './beforeSend';
import { scrubSensitiveData } from './utils';

export interface InputProps extends Record<string, any> {}

export interface BrowserLogExtras {
  kind?: 'clientError' | 'serverError';
  name?: string;
  input?: InputProps;
}

export const initBrowserLog = (): void => {
  if (datadogBrowserLogsIsEnabled) {
    // for more info about the configuration:
    // https://docs.datadoghq.com/logs/log_collection/javascript/#configuration
    datadogLogs.init({
      service: appName,
      clientToken: JSON.parse(datadogBrowserLogsMetadata)?.clientToken,
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sessionSampleRate: 20,
      env: environment,
      version,
      beforeSend,
    });
  }
};

const getExtrasDetails = (
  extras: BrowserLogExtras,
  error?: Error | ApolloError,
) => {
  const { zone, site } = window.SEEK_APP_CONFIG;
  const { name, kind, input } = extras;
  const [brand, country] = (zone && brandAndCountryFromZone(zone)) || [];
  return {
    site,
    name,
    kind,
    brand,
    country,
    zone,
    input: input && scrubSensitiveData(input),
    seek_test: Boolean(window.SEEK_TEST),
    seek_sessionID: parse(document.cookie).JobseekerSessionId,
    raw: { ...error },
  };
};

const log = (
  level: StatusType,
  error: Error | ApolloError,
  extras: BrowserLogExtras = {},
): void => {
  const extrasDetail = getExtrasDetails(extras, error);
  if (datadogBrowserLogsIsEnabled) {
    datadogLogs.logger[level](error.message, {
      'error.kind': error.name,
      'error.stack': error.stack,
      extras: extrasDetail,
    });
  } else {
    switch (level) {
      case 'error': {
        // eslint-disable-next-line no-console
        console.error(extrasDetail, error);
        break;
      }
      case 'warn': {
        // eslint-disable-next-line no-console
        console.warn(extrasDetail, error);
        break;
      }
      default: {
        break;
      }
    }
  }
};

export const browserLogError = (
  error: Error | ApolloError,
  extras: BrowserLogExtras = {},
): void => {
  log(StatusType.error, error, extras);
};

export const browserLogWarn = (
  error: Error | ApolloError,
  extras: BrowserLogExtras = {},
): void => {
  log(StatusType.warn, error, extras);
};

export const browserLogInfo = (
  message: string,
  extras: BrowserLogExtras = {},
): void => {
  const extrasDetail = getExtrasDetails(extras);
  if (datadogBrowserLogsIsEnabled) {
    datadogLogs.logger.log(message, {
      extras: extrasDetail,
    });
  } else {
    // eslint-disable-next-line no-console
    console.log({ message, extrasDetail });
  }
};
