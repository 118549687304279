import type { UrlLocation } from '@seek/chalice-types';

import type { Country } from 'src/types/globals';

import type { Meta } from '../meta';

export const LOCATION_CHANGED = 'LOCATION_CHANGED';
export const CAMPAIGN_LINK_CLICKED = 'CAMPAIGN_LINK_CLICKED';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_REQUEST_ID = 'SET_REQUEST_ID';
export const COUNTRY_CHANGED = 'COUNTRY_CHANGED';

export interface LocationState {
  url: string | null;
  pathname: string;
  prevPathname: string | null;
  query: any;
  pageNumber?: number;
  isHomepage: boolean;
  pageTitle?: string;
  hostname?: string;
  href?: string;
  hash?: string;
  port?: string;
  protocol?: string;
  requestId?: string;
}

interface CountryChangedAction {
  type: typeof COUNTRY_CHANGED;
  payload: {
    country: Country;
  };
}

interface LocationChangedAction {
  type: typeof LOCATION_CHANGED;
  payload: {
    url: string;
    location: UrlLocation;
  };
  meta: Meta;
}

export interface SetRequestIdAction {
  type: typeof SET_REQUEST_ID;
  payload: {
    requestId?: string;
  };
}

export type LocationAction =
  | LocationChangedAction
  | CountryChangedAction
  | SetRequestIdAction
  | {
      type: typeof SET_PAGE_TITLE;
      payload: {
        pageTitle: string;
      };
    }
  | {
      type: typeof CAMPAIGN_LINK_CLICKED;
      payload: {
        linkName: string;
        trackingCode: string;
      };
      meta: Meta;
    };
