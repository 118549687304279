import { set, get } from 'local-storage';

const createLocalEventStore = (key: string) => {
  const store = {
    swap: (f: (arg0: ReturnType<typeof get>) => string) => {
      store.set(f(store.get()));
    },
    set: (value: string) => {
      set(key, JSON.stringify(value));
    },
    get: () => JSON.parse(get(key)),
  };

  return store;
};

export default createLocalEventStore;
