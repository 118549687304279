
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgdHlwZXN9IG90aGVyIHt7Y291bnR9IHR5cGVzfX0ifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiU2VtdWEgamVuaXMgcGVrZXJqYWFuIiwiQWxsIHR5cGVzICh0b3RhbCkiOiJTZW11YSBqZW5pcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtTZW11YSBqZW5pcyBwZWtlcmphYW59IG90aGVyIHt7Y291bnR9IGplbmlzIHBla2VyamFhbn19IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtKZW5pcyBwZWtlcmphYW59IG90aGVyIHt7Y291bnR9IGplbmlzfX0ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiU2VtdWEgamVuaXMgcGVrZXJqYWFuIiwiQWxsIHR5cGVzICh0b3RhbCkiOiJTZW11YSBqZW5pcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtTZW11YSBqZW5pcyBwZWtlcmphYW59IG90aGVyIHt7Y291bnR9IGplbmlzIHBla2VyamFhbn19IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtKZW5pcyBwZWtlcmphYW59IG90aGVyIHt7Y291bnR9IGplbmlzfX0ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQWxsIHR5cGVzICh0b3RhbCkiOiLguJvguKPguLDguYDguKDguJfguIHguLLguKPguIjguYnguLLguIfguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJBbGwgd29yayB0eXBlcyI6Intjb3VudCwgcGx1cmFsLCA9MCB74Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUfSBvdGhlciB7e2NvdW50fSDguJvguKPguLDguYDguKDguJfguIfguLLguJl9fSIsIkFsbCB0eXBlcyI6Intjb3VudCwgcGx1cmFsLCA9MCB74LiX4Li44LiB4Lib4Lij4Liw4LmA4Lig4LiXfSBvdGhlciB7e2NvdW50fSDguJvguKPguLDguYDguKDguJd9fSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQWxsIHR5cGVzICh0b3RhbCkiOiLguJvguKPguLDguYDguKDguJfguIHguLLguKPguIjguYnguLLguIfguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJBbGwgd29yayB0eXBlcyI6Intjb3VudCwgcGx1cmFsLCA9MCB74Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUfSBvdGhlciB7e2NvdW50fSDguJvguKPguLDguYDguKDguJfguIfguLLguJl9fSIsIkFsbCB0eXBlcyI6Intjb3VudCwgcGx1cmFsLCA9MCB74LiX4Li44LiB4Lib4Lij4Liw4LmA4Lig4LiXfSBvdGhlciB7e2NvdW50fSDguJvguKPguLDguYDguKDguJd9fSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiW+G6rMaaxpogxbXDtsO2w7bFmcS3IOG5r8O9w73Dvcal4bq94bq94bq9xaFdIiwiQWxsIHR5cGVzICh0b3RhbCkiOiJb4bqsxprGmiDhua/DvcO9w73GpeG6veG6veG6vcWhXSIsIkFsbCB3b3JrIHR5cGVzIjoiW3tjb3VudCxwbHVyYWwsPTB74bqsxprGmiDFtcO2w7bDtsWZxLcg4bmvw73DvcO9xqXhur3hur3hur3FoX0gb3RoZXJ7e2NvdW50fSDFtcO2w7bDtsWZxLcg4bmvw73DvcO9xqXhur3hur3hur3FoX19XSIsIkFsbCB0eXBlcyI6Ilt7Y291bnQscGx1cmFsLD0we+G6rMaaxpog4bmvw73DvcO9xqXhur3hur3hur3FoX0gb3RoZXJ7e2NvdW50fSDhua/DvcO9w73GpeG6veG6veG6vcWhfX1dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiW+G6rMaaxpogxbXDtsO2w7bFmcS3IOG5r8O9w73Dvcal4bq94bq94bq9xaFdIiwiQWxsIHR5cGVzICh0b3RhbCkiOiJb4bqsxprGmiDhua/DvcO9w73GpeG6veG6veG6vcWhXSIsIkFsbCB3b3JrIHR5cGVzIjoiW3tjb3VudCxwbHVyYWwsPTB74bqsxprGmiDFtcO2w7bDtsWZxLcg4bmvw73DvcO9xqXhur3hur3hur3FoX0gb3RoZXJ7e2NvdW50fSDFtcO2w7bDtsWZxLcg4bmvw73DvcO9xqXhur3hur3hur3FoX19XSIsIkFsbCB0eXBlcyI6Ilt7Y291bnQscGx1cmFsLD0we+G6rMaaxpog4bmvw73DvcO9xqXhur3hur3hur3FoX0gb3RoZXJ7e2NvdW50fSDhua/DvcO9w73GpeG6veG6veG6vcWhfX1dIn0=!"
        )
      )
      });
  
      export { translations as default };
    