import type { CountrySiteName } from '@seek/melways-sites';
import { unifiedRoutes } from '@seek/seek-jobs-seo';
import type { FunctionComponent, ComponentClass } from 'react';
import type { RouteConfig } from 'react-router-config';

import PageNotFound from 'src/components/PageNotFound/PageNotFound';
import Search from 'src/components/Search/Search';
import {
  expiredJobPageRegex,
  homePageRegex,
  jobDetailsPageRegex,
  searchResultsPageRegex,
} from 'src/modules/routes-regexp';

import App from './handlers/App';
import Home from './handlers/Home';
import JobDetails from './handlers/JobDetails';
import SearchResults from './handlers/SearchResults';

const route = (
  regex: RegExp,
  component: FunctionComponent | ComponentClass,
  routes: string[],
) =>
  routes
    .filter((path: string) => regex.test(path))
    .map((path: string) => ({
      path,
      exact: true,
      component,
    }));

const withSearchBar = (
  regex: RegExp,
  component: FunctionComponent | ComponentClass,
  routes: string[],
  options = {},
) =>
  routes
    .filter((path: string) => regex.test(path))
    .map((path: string) => ({
      path,
      component: Search,
      // use key prop to prevent remounting component when
      // transition was made from route with the same component and same key prop
      key: 'SEARCH',
      routes: [{ component }],
      ...options,
    }));

type RouteConfigWithStatusCode = RouteConfig & { statusCode?: number };

const reactRoutes = (site: CountrySiteName): RouteConfigWithStatusCode[] => {
  const routes = unifiedRoutes(site);

  return [
    {
      path: '/',
      component: App,
      routes: [
        ...route(jobDetailsPageRegex, JobDetails, routes),
        ...route(expiredJobPageRegex, JobDetails, routes),

        ...withSearchBar(homePageRegex, Home, routes, { exact: true }),
        ...withSearchBar(searchResultsPageRegex, SearchResults, routes),

        { path: '/*', component: PageNotFound, statusCode: 404 },
      ],
    },
  ];
};

export default reactRoutes;
