// @ts-expect-error
import config from './source-APP_TARGET';

const getPrivatePublicEndpoint = (
  privateConfigKey: string,
  publicConfigKey: string | undefined,
) => {
  if (!ENV.SERVER && publicConfigKey && config[publicConfigKey]) {
    return config[publicConfigKey];
  }

  return config[privateConfigKey];
};

const getConfigOptionally = (key: string, optional?: boolean) => {
  if (!ENV.SERVER && `${key}_PUBLIC` in config) {
    return config[`${key}_PUBLIC`];
  }

  if (key in config) {
    return config[key];
  }

  if (!optional) {
    throw new Error(
      `Configuration Error: required environment variable "${key}" not found.`,
    );
  }
  return '';
};

const getConfigAsBool = (key: string) =>
  getConfigOptionally(key, true) === 'true';

export const appName = 'chalice';
export const siteSection = 'discover';

export const awsAccount = getConfigOptionally('AWS_ACCOUNT', true);

export const metricsIsEnabled = getConfigAsBool('SEEK_METRICS_ENABLED');
export const metricsPrefix = getConfigOptionally(
  'SEEK_METRICS_PREFIX',
  !metricsIsEnabled,
);
export const metricsHost = getConfigOptionally(
  'SEEK_METRICS_HOST',
  !metricsIsEnabled,
);
export const statsdHost = getConfigOptionally(
  'SEEK_STATSD_HOST',
  !metricsIsEnabled,
);
export const statsdPort = getConfigOptionally(
  'SEEK_STATSD_PORT',
  !metricsIsEnabled,
);
export const analyticsIsEnabled = getConfigAsBool('SEEK_ANALYTICS_ENABLED');

export const v4CountsUrl = getConfigOptionally('SEEK_API_V4_COUNTS_ENDPOINT');
export const v5CountsUrl = getConfigOptionally('SEEK_API_V5_COUNTS_ENDPOINT');
export const v5PersonalisedCountsUrl = getConfigOptionally(
  'SEEK_API_V5_COUNTS_ENDPOINT_AUTHED',
);

export const v4CountUrl = getConfigOptionally('SEEK_API_V4_COUNT_ENDPOINT');

export const searchUrlV4 = getPrivatePublicEndpoint(
  'SEEK_API_V4_SEARCH_ENDPOINT',
  'SEEK_API_V4_SEARCH_ENDPOINT_PUBLIC',
);

export const searchUrlV5 = getPrivatePublicEndpoint(
  'SEEK_API_V5_SEARCH_ENDPOINT',
  'SEEK_API_V5_SEARCH_ENDPOINT_PUBLIC',
);

export const personalisedSearchUrlV5 = getPrivatePublicEndpoint(
  'SEEK_API_V5_SEARCH_ENDPOINT_AUTHED',
  'SEEK_API_V5_SEARCH_ENDPOINT_PUBLIC_AUTHED',
);

export const v4RelatedSearchesUrl = getPrivatePublicEndpoint(
  'SEEK_API_V4_RELATED_SEARCHES_ENDPOINT',
  'SEEK_API_V4_RELATED_SEARCHES_ENDPOINT_PUBLIC',
);

export const auditLoggerLevel = getConfigOptionally('SEEK_LOG_LEVEL');

export const stubHost = getConfigOptionally('SEEK_STUB_HOST', true);
export const stubPort = getConfigOptionally('SEEK_STUB_PORT', true);
export const environment = getConfigOptionally('SEEK_ENVIRONMENT');
export const isProduction = environment === 'production';
export const version = getConfigOptionally('SEEK_VERSION');
export const isGoogleOneTapEnabled = getConfigAsBool(
  'SEEK_GOOGLE_ONE_TAP_ENABLED',
);
export const advertsIsEnabled = getConfigAsBool('SEEK_ADVERTS_ENABLED');
export const signInRegUrlPrefix =
  getConfigOptionally('SEEK_SIGN_IN_REG_URL_PREFIX', true) || '';
export const signInPath = getConfigOptionally('SEEK_SIGN_IN_PATH');
export const registerPath = getConfigOptionally('SEEK_REGISTER_PATH');
export const jobTrackingEnabled = getConfigAsBool('SEEK_JOB_TRACKING_ENABLED');
export const jobTrackingUrl = getPrivatePublicEndpoint(
  'SEEK_JOB_TRACKING_URL',
  'SEEK_JOB_TRACKING_URL_PUBLIC',
);
export const solTrackingEndpoint = getPrivatePublicEndpoint(
  'SEEK_SOL_TRACKING_ENDPOINT',
  'SEEK_SOL_TRACKING_ENDPOINT_PUBLIC',
);
export const savedSearchesPath = getConfigOptionally(
  'SEEK_SAVED_SEARCHES_PATH',
);
export const graphqlEndpoint = getPrivatePublicEndpoint(
  'SEEK_GRAPHQL_ENDPOINT',
  'SEEK_GRAPHQL_ENDPOINT_PUBLIC',
);
export const graphqlDarkProdEndpoint = getConfigOptionally(
  'SEEK_GRAPHQL_DARK_PROD_ENDPOINT',
  true,
);
export const isGraphDarkProdEnabled = getConfigAsBool(
  'SEEK_GRAPHQL_DARK_PROD_ENABLED',
);
export const datadogRumIsEnabled = getConfigAsBool('SEEK_DATADOG_RUM_ENABLED');
export const datadogRumApplicationMetadata = getConfigOptionally(
  'SEEK_DATADOG_RUM_APPLICATION_METADATA',
  !datadogRumIsEnabled,
);

export const datadogBrowserLogsIsEnabled = getConfigAsBool(
  'SEEK_DATADOG_BROWSER_LOGS_ENABLED',
);
export const datadogBrowserLogsMetadata = getConfigOptionally(
  'SEEK_DATADOG_BROWSER_LOG_METADATA',
  !datadogBrowserLogsIsEnabled,
);
