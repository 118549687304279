import type { Zone } from '@seek/audience-zones';

import type { TFeatureFlagNames } from '../featureFlags/types';

/**
 * See https://myseek.atlassian.net/wiki/spaces/TDA/pages/2056519973/Experimentation
 * for experiment specs.
 */

interface TSeekExperimentLink {
  href: string;
}

export type TExperimentTrafficName = 'variant' | 'control' | 'outsider';
export type TExperimentTrafficIndex = '0' | '1' | '2';

export type TExperimentKeys = TFeatureFlagNames | 'remoteSearchFilter';

export interface VariationType {
  name: TExperimentTrafficName;
  index: TExperimentTrafficIndex;
}

export type TSeekExperiments = Partial<
  Record<TExperimentKeys, TSeekExperiment>
>;

export interface TSeekExperiment {
  name: TExperimentNames;
  variation: VariationType;
  num: number;
  _links?: {
    conversion: TSeekExperimentLink;
    impression: TSeekExperimentLink;
  };
}

export type TExperimentNames =
  | 'split_view_anz_6'
  | 'remote_search_filter'
  | 'behavioural_cues_filters'
  | 'dynamic_pills'
  | 'secondary_filters'
  | 'homepage_layout_refresh'
  | 'serp_google_one_tap'
  | 'serp_job_card_info_density_1'
  | 'matched_qualities'
  | 'loggedInGroupSegmentTest';

export const EXPERIMENTS_INITIALISED = '__EXPERIMENTS_INITIALISED';

export const UPDATE_AFTER_AUTH = '__UPDATE_AFTER_AUTH';

export interface TExperiment {
  /**
   * Experiment name for tracking purposes.
   */
  name: TExperimentNames;
  /**
   * Percentage of `variant` of the experiment.
   *
   * percentage | variant | control | outsider
   * -----------|---------|---------|---------
   * 0          | 0       | 0       | 100
   * 20         | 20      | 20      | 60
   * 40         | 40      | 40      | 20
   * 50         | 50      | 50      | 0
   * 80         | 80      | 20      | 0
   * 100        | 100     | 0       | 0
   */
  percentage: number;
  /**
   * Number of experiment groups, for tracking purposes.
   */
  num: number;
  targetZones: Set<Zone>;
  /**
   * Salt for hashing the experiment group with user ID. If not provided, the experiment name is used. This is useful for grouping users across **multiple experiments**.
   */
  groupSalt?: string;
  /**
   * Whether to enable tracking for the 0%. Default is `false` and Chalice will not track experiments with 0%.
   */
  enableTrackingForZeroPercent?: boolean;
}

export type TFeatureExperiments = Partial<Record<TExperimentKeys, TExperiment>>;

export interface UpdateExperimentsAction {
  type: typeof EXPERIMENTS_INITIALISED | typeof UPDATE_AFTER_AUTH;
  payload: TSeekExperiments;
}

export type ExperimentsState = TSeekExperiments;
