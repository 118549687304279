import type { Zone } from '@seek/audience-zones';
import type { SearchParams } from '@seek/chalice-types';
import type { Brand, Locale } from '@seek/melways-sites';
import checksum from '@seek/request-checksum';
import axios from 'axios';
import get from 'lodash/get';
import has from 'lodash/has';

import {
  environment,
  searchUrlV4 as jobSearchUrlV4,
  searchUrlV5 as jobSearchUrlV5,
  personalisedSearchUrlV5 as personalisedJobSearchUrlV5,
  v4CountUrl,
  v4CountsUrl,
  v4RelatedSearchesUrl,
  v5CountsUrl,
  v5PersonalisedCountsUrl,
} from 'src/config';
import type { SmarterSearchCluster } from 'src/config/types';
import getCountry from 'src/config/utils/getCountryFromZone';
import clean from 'src/modules/clean-object';
import {
  createAuthenticatedHttpClient,
  createUnauthenticatedHttpClient,
  withDevAuth,
  withLastKnownSolUserId,
  withRequestId,
  withUserAgent,
  withXRealIp,
} from 'src/modules/seek-api-request';
import { convertToApiQuery } from 'src/modules/seek-jobs-search-query';
import { shouldRequestRelatedSearches } from 'src/modules/seek-jobs-search-query/convertToApiQueryHelper';
import type { Country } from 'src/types/globals';
import type { TestHeaders } from 'src/utils/productionTesting/productionTesting';

// Matches the jobsearch api timeout. Speak to the #jobsearch
// team when changing this value.
export const SEARCH_API_TIMEOUT = 3000;

interface BaseAPIProps {
  searchParams: SearchParams;
  brand?: Brand;
  country: Country;
  zone: Zone;
  cookies: Record<string, any>;
  requestId?: string;
  timeout?: number;
  testHeaders: TestHeaders;
  locale: Locale;
  isV5Search?: boolean;
  isAuthenticated?: boolean;
  solId: string;
}

let onlySearchPromise: any,
  onlyCountsPromise: Promise<any> | undefined,
  onlyRelatedSearchPromise: Promise<any> | undefined,
  // Use AxiosType from ca-http-client once moved to ts
  onlyRelatedSearchPromiseSource: any,
  onlySearchPromiseSource: any,
  onlyCountsPromiseSource: any;

function buildApiQuery({
  searchQuery,
  country,
  zone,
  cookies,
  userQueryId,
  seekerId,
  solId,
  locale,
  isV5Search,
  relatedSearchesCount,
  isDynamicPillsEnabled,
  baseKeywords,
  source,
}: {
  searchQuery: SearchParams;
  country: Country;
  zone: Zone;
  cookies?: Record<string, any>;
  userQueryId?: string;
  seekerId?: number;
  solId: string;
  locale: Locale;
  isV5Search?: boolean;
  relatedSearchesCount?: number;
  isDynamicPillsEnabled?: boolean;
  baseKeywords?: string;
  source?: string;
}) {
  const analyticsParams = !cookies
    ? {}
    : {
        userqueryid: userQueryId || null,
        userid: cookies.JobseekerVisitorId || null,
        usersessionid: cookies.JobseekerVisitorId || null,
        eventCaptureSessionId: cookies.JobseekerSessionId || null,
      };

  // TODO-ZONES: See if we can update the library so we can pass in Zone
  // instead of country value
  const searchParams = convertToApiQuery({
    country,
    searchQuery,
    isV5Search,
  });

  const includePillsAndbaseKeywordsQuery = isDynamicPillsEnabled
    ? {
        ...searchParams,
        baseKeywords: baseKeywords ? baseKeywords : searchParams?.keywords,
        include: searchParams?.include
          ? `${searchParams?.include},pills`
          : `pills`,
      }
    : searchParams;

  const isLocationOnlySerp = Boolean(
    !searchQuery.keywords && !searchQuery.classification && searchQuery.where,
  );

  const isLocationAndWorkTypeSerp = Boolean(
    isLocationOnlySerp && searchQuery.worktype,
  );

  const requestFacets = isLocationOnlySerp || isLocationAndWorkTypeSerp;

  const cleanApiQuery = clean({
    siteKey: `${getCountry(zone)}-Main`,
    sourcesystem: 'houston',
    facets: requestFacets ? 'title' : '',
    ...analyticsParams,
    ...includePillsAndbaseKeywordsQuery,
    locale,
    seekerId,
    solId,
    ...(isV5Search &&
      shouldRequestRelatedSearches(searchParams) && { relatedSearchesCount }),
    ...(source && { source }),
  });

  return {
    ...cleanApiQuery,
    ...(isDynamicPillsEnabled
      ? {
          baseKeywords: cleanApiQuery?.baseKeywords
            ? cleanApiQuery?.baseKeywords
            : '',
        }
      : {}),
  };
}

const buildApiHeaders = (requestId?: string) => ({
  ...withRequestId(requestId),
});

const getAuthClientDetails = ({
  isV5Search,
  isAuthenticated,
}: {
  isV5Search?: boolean;
  isAuthenticated?: boolean;
}) => {
  // For dev environments, we use an unauthenticated request and set the Authorization in the header manually (see withDevAuth)
  const useDevAuth =
    environment === 'development' ||
    environment === 'dev' ||
    environment === 'dark-prod';
  return {
    isAuthenticatedClient:
      isV5Search && isAuthenticated && ENV.CLIENT && !useDevAuth,
    useDevAuth,
  };
};

const getCountsUrl = ({
  isV5Search,
  isAuthenticated,
}: {
  isV5Search?: boolean;
  isAuthenticated?: boolean;
}) => {
  if (isV5Search) {
    if (isAuthenticated) {
      return v5PersonalisedCountsUrl;
    }
    return v5CountsUrl;
  }

  return v4CountsUrl;
};

function getSearchUrl({
  isV5Search,
  isAuthenticated,
}: {
  isV5Search?: boolean;
  isAuthenticated?: boolean;
}) {
  if (isV5Search) {
    if (isAuthenticated) {
      return personalisedJobSearchUrlV5;
    }
    return jobSearchUrlV5;
  }

  return jobSearchUrlV4;
}

function search({
  searchParams,
  brand,
  country,
  zone,
  cookies,
  userQueryId,
  requestId,
  timeout = SEARCH_API_TIMEOUT,
  userAgent,
  seekerId,
  solId,
  testHeaders,
  locale,
  xRealIp,
  isAuthenticated,
  isV5Search,
  relatedSearchesCount,
  isDynamicPillsEnabled,
  baseKeywords,
  source,
}: BaseAPIProps & {
  userQueryId: string;
  userAgent: string;
  seekerId?: number;
  xRealIp?: string;
  isAuthenticated: boolean;
  isV5Search?: boolean;
  relatedSearchesCount?: number;
  isDynamicPillsEnabled?: boolean;
  baseKeywords?: string;
  source?: string;
}) {
  if (onlySearchPromise) {
    onlySearchPromiseSource.cancel();
  }
  const apiQuery = buildApiQuery({
    isDynamicPillsEnabled,
    baseKeywords,
    searchQuery: searchParams,
    country,
    zone,
    cookies,
    userQueryId,
    seekerId,
    solId,
    locale,
    isV5Search,
    relatedSearchesCount,
    source,
  });

  const { isAuthenticatedClient, useDevAuth } = getAuthClientDetails({
    isV5Search,
    isAuthenticated,
  });

  const config = {
    retryPolicy: { retries: 0 },
    defaultRequestConfig: {
      headers: {
        ...testHeaders,
        ...(isV5Search
          ? withLastKnownSolUserId(cookies['last-known-sol-user-id'])
          : {}),
        ...(isV5Search && useDevAuth ? withDevAuth(cookies.AUTH_TOKEN) : {}),
      },
    },
  };

  const httpClient = isAuthenticatedClient
    ? createAuthenticatedHttpClient(config)
    : createUnauthenticatedHttpClient(config);

  const searchPromise = httpClient
    .send({
      url: getSearchUrl({ isV5Search, isAuthenticated }),
      timeout,
      params: apiQuery,
      label: 'search-experience-api',
      cancelToken: get(onlySearchPromiseSource, 'token'),
      headers: {
        ...buildApiHeaders(requestId),
        ...withUserAgent(userAgent),
        ...withXRealIp(xRealIp),
        'x-seek-checksum': checksum(apiQuery),
        'seek-request-brand': brand,
        'seek-request-country': country,
      },
    })
    .then((response: Record<string, any>) => {
      const result = get(response, 'data');

      if (has(result, 'data')) {
        return result;
      }

      throw new Error(`invalid api response: ${JSON.stringify(result)}`);
    });

  if (ENV.CLIENT) {
    onlySearchPromise = searchPromise;
    onlySearchPromiseSource = axios.CancelToken.source();
  }

  return searchPromise;
}

function count({
  searchParams,
  country,
  zone,
  cookies,
  requestId,
  timeout = SEARCH_API_TIMEOUT,
  testHeaders,
  locale,
  solId,
}: BaseAPIProps) {
  if (onlyCountsPromise) {
    onlyCountsPromiseSource.cancel();
  }

  const countsPromise = Promise.resolve(
    createUnauthenticatedHttpClient({
      omitXSeekSiteHeader: true,
      defaultRequestConfig: {
        headers: testHeaders,
      },
    }).send({
      url: v4CountUrl,
      timeout,
      cancelToken: get(onlySearchPromiseSource, 'token'),
      label: 'search-counts',
      params: buildApiQuery({
        searchQuery: searchParams,
        zone,
        cookies,
        country,
        locale,
        solId,
      }),
      headers: buildApiHeaders(requestId),
    }),
  ).then((response) => response.data);

  if (ENV.CLIENT) {
    onlyCountsPromise = countsPromise;
    onlyCountsPromiseSource = axios.CancelToken.source();
  }

  return countsPromise;
}

function counts({
  searchParams,
  country,
  zone,
  cookies,
  requestId,
  timeout = SEARCH_API_TIMEOUT,
  testHeaders,
  locale,
  isV5Search,
  isAuthenticated,
  solId,
}: BaseAPIProps) {
  if (onlyCountsPromise) {
    onlyCountsPromiseSource.cancel();
  }

  const { isAuthenticatedClient, useDevAuth } = getAuthClientDetails({
    isV5Search,
    isAuthenticated,
  });

  const config = {
    omitXSeekSiteHeader: true,
    defaultRequestConfig: {
      headers: {
        ...testHeaders,
        ...(isV5Search && useDevAuth ? withDevAuth(cookies.AUTH_TOKEN) : {}),
      },
    },
  };

  const httpClient = isAuthenticatedClient
    ? createAuthenticatedHttpClient(config)
    : createUnauthenticatedHttpClient(config);

  const countsPromise = Promise.resolve(
    httpClient.send({
      url: getCountsUrl({ isV5Search, isAuthenticated }),
      timeout,
      cancelToken: get(onlySearchPromiseSource, 'token'),
      label: 'search-counts',
      params: buildApiQuery({
        searchQuery: searchParams,
        zone,
        cookies,
        country,
        locale,
        solId,
      }),
      headers: buildApiHeaders(requestId),
    }),
  ).then((response) => response.data);

  if (ENV.CLIENT) {
    onlyCountsPromise = countsPromise;
    onlyCountsPromiseSource = axios.CancelToken.source();
  }

  return countsPromise;
}

function relatedSearches({
  zone,
  keywords,
  where,
  requestId,
  timeout,
  testHeaders,
  countryCode,
  cluster,
  visitorId,
}: {
  countryCode: string;
  zone: string;
  keywords: string;
  where?: string;
  requestId?: string;
  timeout: number;
  testHeaders: TestHeaders;
  cluster: SmarterSearchCluster;
  visitorId: string;
}): Promise<any> {
  if (onlyRelatedSearchPromise) {
    onlyRelatedSearchPromiseSource.cancel();
  }

  const relatedSearchPromise = Promise.resolve(
    createUnauthenticatedHttpClient({
      omitXSeekSiteHeader: true,
      defaultRequestConfig: {
        headers: testHeaders,
      },
    }).send({
      url: v4RelatedSearchesUrl,
      timeout,
      cancelToken: get(onlySearchPromiseSource, 'token'),
      label: 'related-search',
      params: {
        zone,
        keywords,
        siteKey: countryCode,
        cluster,
        where,
        solId: visitorId,
      },
      headers: withRequestId(requestId),
    }),
  ).then(({ data }) => data);

  if (ENV.CLIENT) {
    onlyRelatedSearchPromise = relatedSearchPromise;
    onlyRelatedSearchPromiseSource = axios.CancelToken.source();
  }

  return relatedSearchPromise;
}

export default {
  search,
  counts,
  count,
  relatedSearches,
};
