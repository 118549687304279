import { Box, Text } from 'braid-design-system';
import { type FC, useMemo } from 'react';

import { Link } from 'src/components/NavLink/NavLink';
import { workTypeList } from 'src/components/Search/StaticList';
import { useAppConfig } from 'src/config/appConfig';
import { useSERPHasExternalJobs } from 'src/modules/hooks/useSERPHasExternalJobs';
import type { Option } from 'src/types/globals';

import * as styles from './RefineRadioItem.css';

interface RefineRadioItemProps extends Option {
  id: string;
  multiSelect?: boolean;
  path: string;
  count?: number;
  labelledBy: string;
  handleSelection: (arg0: any) => any;
}

const shouldFollowWorkType = (country: string, value: string): boolean => {
  const countryWorkTypes = workTypeList[country && country.toLowerCase()];
  return (
    Boolean(countryWorkTypes) &&
    countryWorkTypes.some((item) => item.value === value)
  );
};

const RefineRadioItem = (props: RefineRadioItemProps) => {
  const {
    id,
    multiSelect = false,
    value,
    label,
    count,
    labelledBy,
    isActive,
    refineParams,
    path,
    handleSelection,
  } = props;

  const handleClick = (event: any) => {
    event.preventDefault();
    handleSelection(value);
  };

  const hasExternalJobs = useSERPHasExternalJobs();
  const isDisabled = !refineParams && !isActive;

  const { country } = useAppConfig();

  // Determine if the option should be followed based on its presence in the list for the selected country
  // It's a temporary solution to make it work for SSR as moving getCounts to SSR increase the latency by 200ms

  const shouldFollow = useMemo(
    () => shouldFollowWorkType(country, value),
    [country, value],
  );

  const shouldNoFollowWorkType =
    labelledBy === 'RefineWorkType__radiogroup' && (!shouldFollow || !count);

  const RefineButton: FC<{ children: string }> = ({ children }) => (
    <Box
      component="span"
      position="relative"
      display="flex"
      alignItems="center"
      cursor="pointer"
      userSelect="none"
      transition="fast"
      aria-hidden={isDisabled ? 'true' : undefined}
      className={{
        [styles.fakeField]: true,
        [styles.radio]: !multiSelect,
        [styles.disabled]: isDisabled,
        [styles.isActive]: isActive && !isDisabled,
      }}
    >
      <Text
        weight={isActive && !isDisabled ? 'medium' : undefined}
        tone={isDisabled ? 'secondary' : undefined}
      >
        {children}
      </Text>
    </Box>
  );

  return (
    <Box
      id={id}
      component="li"
      display="block"
      position="relative"
      role="presentation"
    >
      {isDisabled ? (
        <RefineButton>{label}</RefineButton>
      ) : (
        // We provide a link, even for the selected item
        // so screen readers maintain checkbox/radio selection context
        <Link
          onClick={handleClick}
          aria-label={label}
          data-automation={value}
          role={multiSelect ? 'checkbox' : 'radio'}
          aria-checked={isActive}
          location={{
            pathname: refineParams ? path : undefined,
            query: refineParams,
          }}
          className={styles.link}
          rel={
            shouldNoFollowWorkType || hasExternalJobs ? 'nofollow' : undefined
          }
        >
          <RefineButton>{label}</RefineButton>
        </Link>
      )}
    </Box>
  );
};

export default RefineRadioItem;
