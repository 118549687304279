import {
  type ApolloLink,
  InMemoryCache,
  isApolloError,
  type NormalizedCacheObject,
  type ServerError,
} from '@apollo/client';
import { createClient, createMockClient } from '@seek/ca-graphql-client';
import { browserHttpLink } from '@seek/ca-graphql-client/browser';
import { nodeHttpLink } from '@seek/ca-graphql-client/node';
import type { Brand } from '@seek/melways-sites';

import {
  appName,
  environment,
  graphqlDarkProdEndpoint,
  graphqlEndpoint,
  isGraphDarkProdEnabled,
} from 'src/config';
import type { RequestHeaders } from 'src/server/server-render';
import type { CookieType } from 'src/types/cookie';
import type { Country } from 'src/types/globals';

import customMetricLink from './links/customMetricLink';
import { makeLoggedInEvictCacheLink } from './links/evictCacheLink';
import mockOverrides from './mock-overrides';
import resolverOverrides from './resolver-overrides';

const RATE_LIMITED = 'RATE_LIMITED';

const useAuth =
  environment !== 'dev' ||
  environment !== 'development' ||
  environment !== 'dark-prod';
const mock = environment === 'development';

export const getGraphqlEndpoint = (cookies?: CookieType) => {
  const shouldUseDarkProdEndpoint =
    Boolean(cookies?.SEEK_GRAPHQL_DARK_PROD_ENABLED) || isGraphDarkProdEnabled;

  if (shouldUseDarkProdEndpoint && graphqlDarkProdEndpoint) {
    if (cookies?.SEEK_GRAPHQL_DARK_PROD_BRANCH) {
      return `${graphqlDarkProdEndpoint}?branch=${cookies?.SEEK_GRAPHQL_DARK_PROD_BRANCH}`;
    }

    return graphqlDarkProdEndpoint;
  }

  return graphqlEndpoint;
};

export const getHeaders = ({
  ssrMode,
  reqHeaders,
  cookies,
  environment: _environment,
  queryParams,
}: {
  ssrMode: boolean;
  reqHeaders?: RequestHeaders;
  cookies?: CookieType;
  environment: string;
  queryParams?: URLSearchParams;
}) => {
  const sourceExperienceReference =
    queryParams?.get('sourceExperience') ||
    queryParams?.get('ref') ||
    queryParams?.get('cid');

  let headers: Record<string, string> = {
    ...(sourceExperienceReference && {
      'seek-traffic-source': sourceExperienceReference,
    }),
  };

  if (_environment === 'dev' || _environment === 'dark-prod') {
    headers = {
      ...headers,
      ...(cookies?.AUTH_TOKEN ? { authorization: cookies.AUTH_TOKEN } : {}),
    };
  }

  if (ssrMode) {
    const xRealIp = reqHeaders!['x-real-ip'];
    headers = {
      ...headers,
      ...(typeof xRealIp === 'string' ? { 'x-real-ip': xRealIp } : {}),
      'user-agent': 'chalice',
    };
  }
  return headers;
};

export const getClient = ({
  brand,
  country,
  links = [],
  reqHeaders,
  cacheData = {},
  cookies,
  queryParams,
}: {
  brand: Brand;
  country: Country;
  links?: ApolloLink[];
  reqHeaders?: RequestHeaders;
  cacheData?: NormalizedCacheObject;
  cookies?: CookieType;
  queryParams?: URLSearchParams;
}) => {
  const httpLink = ENV.SERVER ? nodeHttpLink : browserHttpLink;
  const ssrMode = Boolean(ENV.SERVER);

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          jobDetails: {
            keyArgs: ['id', 'locale', 'zone'],
            merge: true,
          },
          viewer: {
            merge: true,
          },
        },
      },
      Job: {
        keyFields: false,
      },
      PersonalisedJobDetails: {
        merge: true,
      },
    },
  });

  if (ENV.CLIENT) {
    cache.restore(cacheData);
  }

  const evictCacheLink = makeLoggedInEvictCacheLink();

  if (mock) {
    return createMockClient({
      mocks: mockOverrides(),
      resolvers: resolverOverrides,
      links: [customMetricLink, evictCacheLink],
      cache,
    });
  }

  return createClient({
    headers: getHeaders({
      ssrMode,
      reqHeaders,
      cookies,
      environment,
      queryParams,
    }),
    appName,
    brand,
    country,
    graphqlEndpointOverride: getGraphqlEndpoint(cookies),
    authOptions:
      environment === 'dark-prod'
        ? { useAuth: false }
        : {
            useAuth,
            forceStrategy: 'AUTH0',
          },
    links: [...links, customMetricLink, evictCacheLink],
    httpLink,
    cache,
  });
};

export const isWAFForbiddenError = (error: any) =>
  isApolloError(error) &&
  (error?.networkError as ServerError)?.statusCode === 403;

export const isGraphQLRateLimitedError = (error: any) =>
  (isApolloError(error) &&
    error?.graphQLErrors?.length > 0 &&
    error?.graphQLErrors[0]?.extensions?.code === RATE_LIMITED) ||
  error?.customData?.errors[0]?.extensions?.code === RATE_LIMITED;
