import { type MutationHookOptions, useMutation } from '@apollo/client';

import { useUpdateRecommendedGlobalJobCache } from 'src/components/HomePage/Dashboard/SignedInDashboard/DashboardRecommendations/useHomeRecommendations';
import {
  CreateSavedJobDocument,
  DeleteSavedJobDocument,
  type CreateSavedJobMutation,
  type CreateSavedJobMutationVariables,
  type DeleteSavedJobMutation,
  type DeleteSavedJobMutationVariables,
} from 'src/graphql/graphql';

import {
  useUpdateJobDetailsPersonalisedCache,
  useUpdateSearchSavedAndAppliedJobsCache,
} from './useSavedJobsData';

interface UseSaveJobMutationProps {
  create?: MutationHookOptions<
    CreateSavedJobMutation,
    CreateSavedJobMutationVariables
  >;
  delete?: MutationHookOptions<
    DeleteSavedJobMutation,
    DeleteSavedJobMutationVariables
  >;
}

const useSaveJobMutation = (
  jobId: string,
  options?: UseSaveJobMutationProps,
) => {
  const updateSearchSavedAndAppliedJobsCache =
    useUpdateSearchSavedAndAppliedJobsCache(jobId);
  const updateJobDetailsPersonalisedCache =
    useUpdateJobDetailsPersonalisedCache(jobId);
  const updateRecommendedGlobalJobCache =
    useUpdateRecommendedGlobalJobCache(jobId);

  const [createSavedJob] = useMutation(CreateSavedJobDocument, {
    ...options?.create,
    update: (cache, ...restArg) => {
      updateSearchSavedAndAppliedJobsCache.setSaved(cache);
      updateJobDetailsPersonalisedCache.setSaved(cache);
      updateRecommendedGlobalJobCache.setSaved(cache);
      options?.create?.update?.(cache, ...restArg);
    },
  });

  const [deleteSavedJob] = useMutation(DeleteSavedJobDocument, {
    ...options?.delete,
    update: (cache, ...restArg) => {
      updateSearchSavedAndAppliedJobsCache.setUnsave(cache);
      updateJobDetailsPersonalisedCache.setUnsave(cache);
      updateRecommendedGlobalJobCache.setUnsave(cache);
      options?.delete?.update?.(cache, ...restArg);
    },
  });

  return { create: createSavedJob, delete: deleteSavedJob };
};

export default useSaveJobMutation;
