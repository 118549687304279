/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A string that corresponds to one of the company brands like seek, jora etc */
  Brand: { input: any; output: any };
  /** A string that corresponds to a country code from the [alpha-2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) set of country codes defined in the [ISO 3166 standard](https://en.wikipedia.org/wiki/ISO_3166). Example: `AU`. */
  CountryCodeIso2: { input: any; output: any };
  /** A string that corresponds to an alphabetic currency code defined in the [ISO 4217 standard](https://en.wikipedia.org/wiki/ISO_4217). Example: `AUD`. */
  CurrencyCodeIso: { input: any; output: any };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: any; output: any };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any };
  /** A string representing an email address. Emails are validated against this [regex](https://github.com/SEEK-Jobs/validators-js/blob/master/src/validators/emailFormat/emailFormat.ts#L1). Example: `test@example.com`. */
  Email: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** A string that corresponds to a job search query. */
  JobSearchQueryString: { input: any; output: any };
  /** A string that corresponds to a language code defined in the [ISO 639-1 standard](https://en.wikipedia.org/wiki/ISO_639-1). Example: `en`. */
  LanguageCodeIso: { input: any; output: any };
  /** ISO-639-1 language codes and ISO-3166-1-alpha-2 country codes, separated by '-' */
  Locale: { input: any; output: any };
  /** The `PositiveInt` scalar type represents non-fractional unsigned whole numeric values with a value greater than 0. */
  PositiveInt: { input: any; output: any };
  /** The `SafeInt` scalar type represents non-fractional signed whole numeric values that are considered safe as defined by the ECMAScript specification. */
  SafeInt: { input: any; output: any };
  /** Used to determine which smarter search cluster (URL) to use, valid values: default, ph-launch, end-state-launch */
  SmarterSearchCluster: { input: any; output: any };
  /** A string that corresponds to an IANA timezone ID defined in the [IANA Time Zone Database](https://www.iana.org/time-zones). Example: `Australia/Melbourne`. */
  Timezone: { input: any; output: any };
  /** A string representing a UUID. UUIDs are validated against this [regex](https://github.com/SEEK-Jobs/validators-js/blob/master/src/validators/uuid/isUuid.ts#L9). *Note:* The validation regex used does *not* conform to the UUID specification defined in [RFC 4122](https://tools.ietf.org/html/rfc4122), it is intentionally broader. Example: `f81d4fae-7dec-11d0-a765-00a0c91e6bf6`. */
  UUID: { input: any; output: any };
  /** A string that corresponds to a SEEK zone defined in [RFC034](https://github.com/SEEK-Jobs/rfc/blob/master/RFC034-Audience-Zone-Identifiers.md). */
  Zone: { input: any; output: any };
};

export type AccountBlockedError = I18nAccountError & {
  __typename?: 'AccountBlockedError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  reason: Scalars['String']['output'];
};

export type AccountBlockedErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type AccountStatus = {
  __typename?: 'AccountStatus';
  candidate: AccountStatusType;
  hirer: AccountStatusType;
  realmHint: Scalars['String']['output'];
};

export enum AccountStatusType {
  Found = 'found',
  NotFound = 'notFound',
}

export type AccountValidationError = I18nAccountError & {
  __typename?: 'AccountValidationError';
  code: Scalars['String']['output'];
  details: Array<I18nAccountFieldError>;
  message: Scalars['String']['output'];
  reason: Scalars['String']['output'];
};

export type AccountValidationErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type Actor = {
  __typename?: 'Actor';
  /** Email address of the actor. */
  email: Scalars['String']['output'];
  /** The Auth0 user id of the actor, eg 'samlp|seek-okta-global|tkilvington@seek.com.au'. */
  id: Scalars['String']['output'];
};

export type Advertiser = {
  __typename?: 'Advertiser';
  id: Scalars['ID']['output'];
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  registrationDate?: Maybe<SeekDateTime>;
};

export type AdvertiserNameArgs = {
  locale?: Scalars['Locale']['input'];
};

export enum AlertType {
  Info = 'Info',
  Promote = 'Promote',
}

export type AnswerForQuestionInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type ApacSavedSearch = {
  __typename?: 'ApacSavedSearch';
  /** Display count for saved search (max 99+) */
  countLabel: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  createdDate: SeekDateTime;
  id: Scalars['ID']['output'];
  lastExecutionDate?: Maybe<SeekDateTime>;
  modifiedDate?: Maybe<SeekDateTime>;
  name: Scalars['String']['output'];
  query: SavedSearchApacQuery;
  subscribeToNewJobs: Scalars['Boolean']['output'];
};

export type ApacSavedSearchCountLabelArgs = {
  cluster?: InputMaybe<Scalars['SmarterSearchCluster']['input']>;
};

export type ApacSavedSearchNameArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type ApacSavedSearchQueryArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type ApacSavedSearchConnection = {
  __typename?: 'ApacSavedSearchConnection';
  edges: Array<ApacSavedSearchEdge>;
  pageInfo: PageInfo;
};

export type ApacSavedSearchEdge = {
  __typename?: 'ApacSavedSearchEdge';
  cursor: Scalars['String']['output'];
  node: ApacSavedSearch;
};

export type ApplicantCount = JobInsight & {
  __typename?: 'ApplicantCount';
  count: Scalars['Int']['output'];
  message: Scalars['String']['output'];
};

export type ApplicantCountMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type ApplicantsWithCoverLetterPercentage = JobInsight & {
  __typename?: 'ApplicantsWithCoverLetterPercentage';
  message: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
};

export type ApplicantsWithCoverLetterPercentageMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type ApplicantsWithResumePercentage = JobInsight & {
  __typename?: 'ApplicantsWithResumePercentage';
  message: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
};

export type ApplicantsWithResumePercentageMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type ApplicationContextInput = {
  build?: InputMaybe<Scalars['String']['input']>;
  /** client generated */
  key: Scalars['UUID']['input'];
  /** application bundleId */
  namespace?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicationDocuments = {
  __typename?: 'ApplicationDocuments';
  lastAppliedResumeIdPrefill?: Maybe<Scalars['String']['output']>;
  lastWrittenCoverLetter?: Maybe<LastWrittenCoverLetter>;
  selectionCriteriaRequired: Scalars['Boolean']['output'];
};

export type ApplicationInsightsStatistic = {
  message: Scalars['String']['output'];
};

export type ApplicationInsightsStatisticMessageArgs = {
  locale?: Scalars['Locale']['input'];
};

export type ApplicationProfile = {
  __typename?: 'ApplicationProfile';
  name?: Maybe<Scalars['String']['output']>;
};

export type AppliedForRoleCount = ApplicationInsightsStatistic & {
  __typename?: 'AppliedForRoleCount';
  count: Scalars['Int']['output'];
  message: Scalars['String']['output'];
};

export type AppliedForRoleCountMessageArgs = {
  locale?: Scalars['Locale']['input'];
};

export type AppliedJob = {
  __typename?: 'AppliedJob';
  applicationInsights: Array<ApplicationInsightsStatistic>;
  appliedAt: SeekDateTime;
  createdAt: SeekDateTime;
  documents?: Maybe<AppliedJobDocuments>;
  downloadLinks?: Maybe<AppliedJobDownloadLinks>;
  events: Array<AppliedJobEvent>;
  hasAppliedWithCoverLetter: Scalars['Boolean']['output'];
  hasAppliedWithResume: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  isExternal: Scalars['Boolean']['output'];
  job: Job;
  notes?: Maybe<Scalars['String']['output']>;
};

export type AppliedJobConnection = {
  __typename?: 'AppliedJobConnection';
  edges: Array<AppliedJobEdge>;
  pageInfo: PageInfo;
};

export type AppliedJobDocuments = {
  __typename?: 'AppliedJobDocuments';
  coverLetter?: Maybe<CoverLetterAppliedJobDocument>;
  resume?: Maybe<ResumeAppliedJobDocument>;
};

export type AppliedJobDownloadLinks = {
  __typename?: 'AppliedJobDownloadLinks';
  coverLetter?: Maybe<Scalars['String']['output']>;
  resume?: Maybe<Scalars['String']['output']>;
};

export type AppliedJobEdge = {
  __typename?: 'AppliedJobEdge';
  cursor: Scalars['String']['output'];
  node: AppliedJob;
};

export type AppliedJobEvent = {
  __typename?: 'AppliedJobEvent';
  status: AppliedJobStatus;
  timestamp: SeekDateTime;
};

export type AppliedJobSortByInput = {
  applicationDate?: InputMaybe<Sort>;
  companyName?: InputMaybe<Sort>;
  jobTitle?: InputMaybe<Sort>;
};

export enum AppliedJobStatus {
  Applied = 'Applied',
  NotSuitable = 'NotSuitable',
  Viewed = 'Viewed',
}

export type AppliedWithCoverLetterPercentage = ApplicationInsightsStatistic & {
  __typename?: 'AppliedWithCoverLetterPercentage';
  message: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
};

export type AppliedWithCoverLetterPercentageMessageArgs = {
  locale?: Scalars['Locale']['input'];
};

export type AppliedWithResumePercentage = ApplicationInsightsStatistic & {
  __typename?: 'AppliedWithResumePercentage';
  message: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
};

export type AppliedWithResumePercentageMessageArgs = {
  locale?: Scalars['Locale']['input'];
};

export type Approachability = {
  __typename?: 'Approachability';
  approachable: Scalars['Boolean']['output'];
};

export type AttachmentMetadata = {
  __typename?: 'AttachmentMetadata';
  contentType?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  virusScanStatus: VirusScanStatus;
};

export enum AttachmentType {
  CoverLetter = 'CoverLetter',
  SelectionCriteria = 'SelectionCriteria',
}

export enum AttributionSource {
  AppliedJobs = 'APPLIED_JOBS',
  ApplySuccess = 'APPLY_SUCCESS',
  Home = 'HOME',
  JobDetails = 'JOB_DETAILS',
  LoggedOutHome = 'LOGGED_OUT_HOME',
  Recommendations = 'RECOMMENDATIONS',
}

export type Availability = {
  __typename?: 'Availability';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type AvailabilityOption = {
  __typename?: 'AvailabilityOption';
  /** Human readable string used when the user is selecting an availability option. */
  description: Scalars['String']['output'];
  /** Unique ID used to identify a specific availability option */
  id: Scalars['Int']['output'];
};

export type Award = {
  __typename?: 'Award';
  description: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export type Banner = {
  __typename?: 'Banner';
  analytics?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  template?: Maybe<BannerTemplate>;
};

export type BannerHeading = {
  __typename?: 'BannerHeading';
  style?: Maybe<BannerStyle>;
  text?: Maybe<Scalars['String']['output']>;
};

export type BannerImage = {
  __typename?: 'BannerImage';
  url?: Maybe<Scalars['String']['output']>;
  urlDark?: Maybe<Scalars['String']['output']>;
};

export type BannerItems = {
  __typename?: 'BannerItems';
  href?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type BannerLink = {
  __typename?: 'BannerLink';
  style?: Maybe<BannerStyle>;
  text?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum BannerPlacement {
  MobileHome = 'MOBILE_HOME',
  SerpFooterLinks = 'SERP_FOOTER_LINKS',
}

export type BannerStyle = {
  __typename?: 'BannerStyle';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  backgroundColorDark?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  colorDark?: Maybe<Scalars['String']['output']>;
};

export type BannerTemplate = {
  __typename?: 'BannerTemplate';
  heading?: Maybe<BannerHeading>;
  id?: Maybe<Scalars['String']['output']>;
  image?: Maybe<BannerImage>;
  items: Array<BannerItems>;
  link?: Maybe<BannerLink>;
  style?: Maybe<BannerStyle>;
};

export type BaseCredential = {
  __typename?: 'BaseCredential';
  credentialInfo: Array<CredentialInfo>;
  deleteVerificationUrl: Scalars['String']['output'];
  manageVerificationUrl: Scalars['String']['output'];
  verification: CredentialVerification;
};

export type BaseVerifiableCredential = {
  label: Scalars['String']['output'];
};

export type Branding = {
  __typename?: 'Branding';
  cover?: Maybe<Cover>;
  logo?: Maybe<Scalars['String']['output']>;
};

export enum Company_Group {
  Industry = 'INDUSTRY',
}

export enum Company_Sort_By {
  ReviewsCount = 'REVIEWS_COUNT',
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type Candidate = {
  __typename?: 'Candidate';
  /**
   * Unique candidate ID.
   * Note: This is a temporary field. We cannot change `id` to string format
   * right now because of dependency on long tail apps.
   */
  _id: Scalars['ID']['output'];
  apacSavedSearches?: Maybe<Array<ApacSavedSearch>>;
  appliedJob?: Maybe<AppliedJob>;
  appliedJobs: AppliedJobConnection;
  /** @deprecated Candidates have received sufficeint notice of migration since Unification completed */
  appliedJobsMigrationMessage?: Maybe<Scalars['String']['output']>;
  /** Candidate's approachability */
  approachability: Approachability;
  attachmentMetadata?: Maybe<AttachmentMetadata>;
  availability?: Maybe<Availability>;
  /** Career Objectives for candidate */
  careerObjectives?: Maybe<CareerObjectives>;
  /** Current location for this candidate */
  currentLocation2?: Maybe<Location2>;
  /** Temporary document download link for a given resource id (Type could be a Resume, Cover Letter, or Selection Criteria) */
  documentDownloadLink?: Maybe<DocumentDownloadLink>;
  /** Form data for clients to upload documents(documents type could be a Resume, Cover Letter, or Selection Criteria) */
  documentUploadFormData?: Maybe<DocumentUploadFormData>;
  emailAddress: Scalars['Email']['output'];
  /** @deprecated Use viewer.trackingId */
  emailCorrelationId: Scalars['String']['output'];
  /**
   * External Id (candidate)
   * Note: This is a temporary field used to identify the candidate within APAC (Asia) Domain
   */
  externalId?: Maybe<Scalars['ID']['output']>;
  /** Unique candidate ID in numeric format */
  id: Scalars['Int']['output'];
  /** Identity of the currently authenticated user. */
  identity: Identity;
  /** Determines whether an authenticated user is associated with a candidate record. */
  isAccountProvisioned?: Maybe<Scalars['Boolean']['output']>;
  /** Candidate's language proficiencies */
  languageProficiencies: Array<LanguageProficiency>;
  /** Licences */
  licences: Array<Licence>;
  /**
   * Candidate's work notice period length
   * @deprecated Use availability instead
   */
  noticePeriod?: Maybe<NoticePeriod>;
  notificationPreferenceGroups?: Maybe<Array<NotificationPreferenceGroup>>;
  onboardingComplete?: Maybe<Scalars['Boolean']['output']>;
  /**
   * *Note*: The default values for the parameters to this query are `"AU"`
   * and `"en"` respectively, *not* `false` as they in the playground.
   */
  personalDetails: PersonalDetails;
  /** Preferred classification for this candidate */
  preferredClassification?: Maybe<Classification>;
  /** All preferred locations for this candidate */
  preferredLocations2: Array<Location2>;
  /** The insights of profile interactions for this candidate */
  profileInsights?: Maybe<ProfileInsights>;
  profileSuggestions?: Maybe<Array<ProfileSuggestion>>;
  /** The verified and verifiable data for this candidate */
  profileVerifications?: Maybe<ProfileVerifications>;
  /** Profile Visibility for candidate */
  profileVisibility?: Maybe<ProfileVisibility>;
  /** A specific qualification for this candidate */
  qualification?: Maybe<Qualification>;
  /** All qualifications for this candidate */
  qualifications: Array<Qualification>;
  /** Group candidate recommendations under one type */
  recommendations?: Maybe<Recommendations>;
  /** A specific resume for this candidate */
  resume?: Maybe<Resume>;
  /** All resumes for this candidate */
  resumes: Array<Resume>;
  /** Rights to work for candidate */
  rightsToWork: Array<RightToWork>;
  /**
   * Rights to work alert type for candidate
   * @deprecated No longer required
   */
  rightsToWorkAlert: RightsToWorkAlert;
  /** Specific role for candidate */
  role?: Maybe<Role>;
  /** All roles for this candidate */
  roles: Array<Role>;
  salaryNudge?: Maybe<CandidateSalaryNudge>;
  /**
   * Salary preferences for candidate. Description fields will be returned in the language
   * specified via the `languageCode` parameter.
   */
  salaryPreferences2: Array<SalaryPreference2>;
  savedJobs: SavedJobConnection;
  /**
   * Profile completeness score. The `zone` parameter restricts the tips returned so they are only relevant to the
   * brand associated with the zone provided.
   */
  score: ProfileScore;
  searchAppliedJobs: Array<SearchAppliedJobPartial>;
  searchSavedJobs: Array<SearchSavedJobPartial>;
  /** Displays data that a candidate has chosen to share via a public url */
  shareableProfile: ShareableProfile;
  /** Shareable Profile Settings */
  shareableProfileSettings: ShareableProfileSettings;
  /**
   * All skills for this candidate
   * @deprecated Use `viewer.skills2` instead
   */
  skills: Array<Skill>;
  /** All skills for this candidate */
  skills2?: Maybe<Array<Skill>>;
  /** All suggested skills for this candidate */
  suggestedSkills: Array<Skill>;
  /** Unique tracking (candidate) ID in UUID format */
  trackingId: Scalars['ID']['output'];
  /** Unconfirmed data for given context */
  unconfirmedDataForContext: UnconfirmedData;
  /** Candidate context that can be persisted in cookies */
  userCookie?: Maybe<UserCookie>;
  verificationNudges: Array<VerificationNudge>;
  /** @deprecated Please use viewer.profileVerifications instead */
  verifications?: Maybe<Verifications>;
  /** The verified identity data for this candidate */
  verifiedIdentity?: Maybe<VerifiedIdentity>;
  /** Candidate's work types */
  workTypes: Array<WorkType>;
  /** Candidate's years of experience */
  yearsOfExperience?: Maybe<YearsOfExperience>;
};

export type CandidateAppliedJobArgs = {
  jobId: Scalars['ID']['input'];
};

export type CandidateAppliedJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['Locale']['input']>;
  sortBy?: InputMaybe<AppliedJobSortByInput>;
};

export type CandidateAppliedJobsMigrationMessageArgs = {
  locale: Scalars['Locale']['input'];
  zone: Scalars['Zone']['input'];
};

export type CandidateAttachmentMetadataArgs = {
  blobUrl: Scalars['String']['input'];
};

export type CandidateAvailabilityArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
};

export type CandidateCurrentLocation2Args = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  zone: Scalars['Zone']['input'];
};

export type CandidateDocumentDownloadLinkArgs = {
  resourceId: Scalars['ID']['input'];
  resourceType: DocumentResourceType;
};

export type CandidateDocumentUploadFormDataArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type CandidateLicencesArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  platform?: InputMaybe<Platform>;
  source?: InputMaybe<SourceExperience>;
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type CandidateNotificationPreferenceGroupsArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type CandidatePersonalDetailsArgs = {
  countryCode?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type CandidatePreferredClassificationArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type CandidatePreferredLocations2Args = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  zone: Scalars['Zone']['input'];
};

export type CandidateProfileInsightsArgs = {
  zone: Scalars['Zone']['input'];
};

export type CandidateProfileSuggestionsArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
  zone: Scalars['Zone']['input'];
};

export type CandidateProfileVerificationsArgs = {
  locale: Scalars['Locale']['input'];
  zone: Scalars['Zone']['input'];
};

export type CandidateProfileVisibilityArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type CandidateQualificationArgs = {
  id: Scalars['UUID']['input'];
  isUnifiedSite?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  platform?: InputMaybe<Platform>;
  source?: InputMaybe<SourceExperience>;
  zone?: Scalars['Zone']['input'];
};

export type CandidateQualificationsArgs = {
  includeVerified?: InputMaybe<Scalars['Boolean']['input']>;
  isUnifiedSite?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  platform?: InputMaybe<Platform>;
  source?: InputMaybe<SourceExperience>;
  status?: InputMaybe<ConfirmedStatus>;
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type CandidateResumeArgs = {
  id: Scalars['UUID']['input'];
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type CandidateResumesArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type CandidateRightsToWorkArgs = {
  countryCode?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  isUnifiedSite?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  platform?: InputMaybe<Platform>;
  source?: InputMaybe<SourceExperience>;
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type CandidateRightsToWorkAlertArgs = {
  countryCode: Scalars['CountryCodeIso2']['input'];
};

export type CandidateRoleArgs = {
  id: Scalars['UUID']['input'];
};

export type CandidateRolesArgs = {
  status?: InputMaybe<ConfirmedStatus>;
};

export type CandidateSalaryNudgeArgs = {
  zone: Scalars['String']['input'];
};

export type CandidateSalaryPreferences2Args = {
  countryCode?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type CandidateSavedJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CandidateScoreArgs = {
  isUnifiedSite?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type CandidateSearchAppliedJobsArgs = {
  jobIds: Array<Scalars['String']['input']>;
};

export type CandidateSearchSavedJobsArgs = {
  jobIds: Array<Scalars['String']['input']>;
};

export type CandidateShareableProfileArgs = {
  profileSlug: Scalars['String']['input'];
};

export type CandidateShareableProfileSettingsArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type CandidateSuggestedSkillsArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type CandidateUnconfirmedDataForContextArgs = {
  contextId: Scalars['String']['input'];
  contextType?: InputMaybe<UnconfirmedContextType>;
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type CandidateVerificationNudgesArgs = {
  jobId: Scalars['ID']['input'];
  platform: Platform;
  selectedAnswerUris: Array<Scalars['String']['input']>;
  source: SourceExperience;
};

export type CandidateVerificationsArgs = {
  testGroup?: InputMaybe<VerificationsTestVariant>;
};

export type CandidateVerifiedIdentityArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
  platform: Platform;
  source: SourceExperience;
  zone: Scalars['Zone']['input'];
};

export type CandidateWorkTypesArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type CandidateYearsOfExperienceArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type CandidateSalaryNudge = {
  __typename?: 'CandidateSalaryNudge';
  callToActions: Array<CandidateSalaryNudgeCallToAction>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  prompt: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type CandidateSalaryNudgeCallToAction = {
  __typename?: 'CandidateSalaryNudgeCallToAction';
  displayText: Scalars['String']['output'];
  /** The call to action submission operation */
  operation: Scalars['String']['output'];
};

export type CandidateSalaryNudgeFeedbackPayload = {
  __typename?: 'CandidateSalaryNudgeFeedbackPayload';
  nudgeId: Scalars['ID']['output'];
};

export type CareerObjectives = {
  __typename?: 'CareerObjectives';
  personalStatement: Scalars['String']['output'];
};

export type CategoryInfo = {
  __typename?: 'CategoryInfo';
  label: Scalars['String']['output'];
};

export type CategoryInfoLabelArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type ChangeEmailAddressFailure = {
  __typename?: 'ChangeEmailAddressFailure';
  error: I18nAccountError;
};

export type ChangeEmailAddressInput = {
  isBGroup?: InputMaybe<Scalars['Boolean']['input']>;
  newEmailAddress: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
};

export type ChangeEmailAddressPayload =
  | ChangeEmailAddressFailure
  | ChangeEmailAddressSuccess;

export type ChangeEmailAddressSuccess = {
  __typename?: 'ChangeEmailAddressSuccess';
  ref: Scalars['UUID']['output'];
};

export type ChangePasswordFailure = {
  __typename?: 'ChangePasswordFailure';
  error?: Maybe<I18nAccountError>;
};

export type ChangePasswordInput = {
  newPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type ChangePasswordPayload =
  | ChangePasswordFailure
  | ChangePasswordSuccess;

export type ChangePasswordSuccess = {
  __typename?: 'ChangePasswordSuccess';
  ref: Scalars['UUID']['output'];
};

export type ChildClassification = {
  __typename?: 'ChildClassification';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type ChildClassificationDescriptionArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type Classification = {
  __typename?: 'Classification';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  subClassification: SubClassification;
};

export type ClassificationInfo = {
  __typename?: 'ClassificationInfo';
  label: Scalars['String']['output'];
};

export type ClassificationInfoLabelArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
};

export type ClassificationInput = {
  id: Scalars['Int']['input'];
  subClassification: SubClassificationInput;
};

export type ClassificationOptions = {
  __typename?: 'ClassificationOptions';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  subClassifications: Array<SubClassification>;
};

export type CommunityGuideline = {
  __typename?: 'CommunityGuideline';
  url: Scalars['String']['output'];
};

export type CommunityGuidelineUrlArgs = {
  locale?: Scalars['Locale']['input'];
  zone?: Scalars['Zone']['input'];
};

export type CompanyGroupByInput = {
  group: Company_Group;
  value: Array<InputMaybe<Scalars['String']['input']>>;
};

export type CompanyNameSlug = {
  __typename?: 'CompanyNameSlug';
  companySlug?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type CompanyProfile = {
  __typename?: 'CompanyProfile';
  /** @deprecated All company pages should display reviews */
  areReviewsEnabled: Scalars['Boolean']['output'];
  awards: Array<Award>;
  branding?: Maybe<Branding>;
  companyNameSlug: Scalars['String']['output'];
  cultureAndValues?: Maybe<CultureAndValues>;
  customStatement?: Maybe<CustomStatement>;
  featured?: Maybe<Featured>;
  id: Scalars['ID']['output'];
  /** @deprecated Beta badge is not needed anymore */
  isBeta?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated All company pages are company profiles now */
  isCompanyProfile: Scalars['Boolean']['output'];
  missionStatement?: Maybe<MissionStatement>;
  name: Scalars['String']['output'];
  overview?: Maybe<Overview>;
  perksAndBenefits: Array<PerkAndBenefit>;
  profileUrl?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use CompanyReviews query instead */
  reviews: Reviews;
  reviewsSummary?: Maybe<ReviewsSummary>;
  reviewsUrl?: Maybe<Scalars['String']['output']>;
  /** @deprecated All company pages should display reviews */
  shouldDisplayReviews: Scalars['Boolean']['output'];
  tracking: CompanyProfileTracking;
};

export type CompanyProfileReviewsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyProfileReviewsSummary = {
  __typename?: 'CompanyProfileReviewsSummary';
  count?: Maybe<Scalars['Int']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
};

export type CompanyProfileTracking = {
  __typename?: 'CompanyProfileTracking';
  isCompanyProfile: Scalars['Boolean']['output'];
};

export type CompanyReviewReply = {
  __typename?: 'CompanyReviewReply';
  createdAt: SeekDateTime;
  from: Scalars['String']['output'];
  text: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type CompanyReviews = {
  __typename?: 'CompanyReviews';
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  rating: Scalars['Float']['output'];
  reviewCount: Scalars['Int']['output'];
  reviewsUrl: Scalars['String']['output'];
};

export type CompanySize = {
  __typename?: 'CompanySize';
  description: Scalars['String']['output'];
};

export type CompanySizeDescriptionArgs = {
  locale?: Scalars['Locale']['input'];
};

export type CompanyTag = {
  __typename?: 'CompanyTag';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CompanyTagKeyArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type CompletionDate = MonthYear | Year;

export type ConfirmQualificationInput = {
  completed: Scalars['Boolean']['input'];
  completionYear?: InputMaybe<Scalars['Int']['input']>;
  expectedCompletionDate?: InputMaybe<MonthYearInput>;
  highlights?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  institute: OntologyStructuredDataInput;
  /** Required for JobsDB only */
  level2?: InputMaybe<Scalars['String']['input']>;
  name: OntologyStructuredDataInput;
  tracking?: InputMaybe<TrackingInfoInput>;
};

export type ConfirmQualificationPayload = {
  __typename?: 'ConfirmQualificationPayload';
  /** @deprecated Use viewer.qualifications */
  ref?: Maybe<Scalars['UUID']['output']>;
  viewer?: Maybe<Candidate>;
};

export type ConfirmRoleInput = {
  achievements?: InputMaybe<Scalars['String']['input']>;
  company: OntologyStructuredDataInput;
  from: MonthYearInput;
  /** Required for JobsDB only */
  function?: InputMaybe<RoleFunctionInput>;
  id: Scalars['UUID']['input'];
  /** Required for JobsDB only */
  industry?: InputMaybe<RoleIndustryInput>;
  seniority?: InputMaybe<OntologyStructuredDataInput>;
  title: OntologyStructuredDataInput;
  to?: InputMaybe<MonthYearInput>;
  tracking?: InputMaybe<TrackingInfoInput>;
};

export type ConfirmRolePayload = {
  __typename?: 'ConfirmRolePayload';
  /** @deprecated Use viewer.roles */
  role: Role;
  viewer?: Maybe<Candidate>;
};

export enum ConfirmedStatus {
  Confirmed = 'confirmed',
  Unconfirmed = 'unconfirmed',
}

export type ConsentTransactionDetails = {
  __typename?: 'ConsentTransactionDetails';
  /** The name of the hirer requesting consent */
  hirerName: Scalars['String']['output'];
  /** The name of the partner requesting consent */
  partnerName: Scalars['String']['output'];
  /** The partner redirectUri in the event of a consent rejection or error */
  redirectUri: Scalars['String']['output'];
};

export type ConsentTransactionDetailsInput = {
  transactionId: Scalars['UUID']['input'];
};

export type ContactMatches = {
  __typename?: 'ContactMatches';
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Country = {
  __typename?: 'Country';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type CountryCallingCode = {
  __typename?: 'CountryCallingCode';
  /** Country calling code prefixed with a `+`, e.g. `+61` */
  countryCallingCode: Scalars['String']['output'];
  /**
   * Human-readable description of the country calling code; contains the country
   * associated with the code as well as the code itself, e.g. `Australia (+61)`.
   */
  description: Scalars['String']['output'];
  /** Unique ID used to identify a specific country calling code */
  id: Scalars['ID']['output'];
};

export type CountryCallingCodeInput = {
  /** Unique ID corresponding to a `CountryCallingCode` */
  id: Scalars['ID']['input'];
};

export enum CountryCode {
  Au = 'au',
  Nz = 'nz',
  Sg = 'sg',
}

export type CountryInformation = {
  __typename?: 'CountryInformation';
  /** The country's ISO 3166-1 alpha-2 country code */
  countryCode: Scalars['CountryCodeIso2']['output'];
  /** The country's name */
  name: Scalars['String']['output'];
};

export type Cover = {
  __typename?: 'Cover';
  aspectRatio?: Maybe<Scalars['Float']['output']>;
  url: Scalars['String']['output'];
};

export type CoverLetterAppliedJobDocument = {
  __typename?: 'CoverLetterAppliedJobDocument';
  attachmentMetadata: AttachmentMetadata;
  blobUrl: Scalars['String']['output'];
  downloadLink?: Maybe<Scalars['String']['output']>;
};

export type CoverLetterInput = {
  uri: Scalars['String']['input'];
  writtenText?: InputMaybe<Scalars['String']['input']>;
};

export type CreateApacSavedSearchDuplicateError = CreateApacSavedSearchError & {
  __typename?: 'CreateApacSavedSearchDuplicateError';
  message: Scalars['String']['output'];
};

export type CreateApacSavedSearchDuplicateErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type CreateApacSavedSearchError = {
  message: Scalars['String']['output'];
};

export type CreateApacSavedSearchErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type CreateApacSavedSearchErrorMaxExceeded =
  CreateApacSavedSearchError & {
    __typename?: 'CreateApacSavedSearchErrorMaxExceeded';
    message: Scalars['String']['output'];
    savedSearchLimit: Scalars['Int']['output'];
  };

export type CreateApacSavedSearchErrorMaxExceededMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type CreateApacSavedSearchFailure = {
  __typename?: 'CreateApacSavedSearchFailure';
  error: CreateApacSavedSearchError;
};

export type CreateApacSavedSearchInput = {
  isUnified?: InputMaybe<Scalars['Boolean']['input']>;
  searchQueryString: Scalars['JobSearchQueryString']['input'];
  zone: Scalars['Zone']['input'];
};

export type CreateApacSavedSearchInvalidLocationError =
  CreateApacSavedSearchError & {
    __typename?: 'CreateApacSavedSearchInvalidLocationError';
    message: Scalars['String']['output'];
  };

export type CreateApacSavedSearchInvalidLocationErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type CreateApacSavedSearchPayload =
  | CreateApacSavedSearchFailure
  | CreateApacSavedSearchSuccess;

export type CreateApacSavedSearchSuccess = {
  __typename?: 'CreateApacSavedSearchSuccess';
  savedSearch: ApacSavedSearch;
};

export type CreateConsentError = {
  message: Scalars['String']['output'];
};

export type CreateConsentErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type CreateConsentFailure = {
  __typename?: 'CreateConsentFailure';
  errors: Array<CreateConsentError>;
};

export type CreateConsentInput = {
  transactionId: Scalars['UUID']['input'];
};

export type CreateConsentPayload = CreateConsentFailure | CreateConsentSuccess;

export type CreateConsentSuccess = {
  __typename?: 'CreateConsentSuccess';
  redirectUri: Scalars['String']['output'];
};

export type CreateConsentTransactionInvalidError = CreateConsentError & {
  __typename?: 'CreateConsentTransactionInvalidError';
  message: Scalars['String']['output'];
};

export type CreateConsentTransactionInvalidErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type CreateSavedJobError = {
  message: Scalars['String']['output'];
};

export type CreateSavedJobErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type CreateSavedJobFailure = {
  __typename?: 'CreateSavedJobFailure';
  errors: Array<CreateSavedJobError>;
};

export type CreateSavedJobInput = {
  eventCaptureData?: InputMaybe<SavedJobEventCaptureDataInput>;
  id: Scalars['ID']['input'];
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type CreateSavedJobPayload =
  | CreateSavedJobFailure
  | CreateSavedJobSuccess;

export type CreateSavedJobSuccess = {
  __typename?: 'CreateSavedJobSuccess';
  id: Scalars['ID']['output'];
  zone?: Maybe<Scalars['Zone']['output']>;
};

export type CreateSavedJobValidationError = CreateSavedJobError & {
  __typename?: 'CreateSavedJobValidationError';
  message: Scalars['String']['output'];
};

export type CreateSavedJobValidationErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type CreateSeekMaxCommentBlockedWordsError =
  CreateSeekMaxCommentError & {
    __typename?: 'CreateSeekMaxCommentBlockedWordsError';
    message: Scalars['String']['output'];
    title: Scalars['String']['output'];
  };

export type CreateSeekMaxCommentBlockedWordsErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxCommentBlockedWordsErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxCommentError = {
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CreateSeekMaxCommentErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxCommentErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxCommentExceedRateLimitError =
  CreateSeekMaxCommentError & {
    __typename?: 'CreateSeekMaxCommentExceedRateLimitError';
    message: Scalars['String']['output'];
    title: Scalars['String']['output'];
  };

export type CreateSeekMaxCommentExceedRateLimitErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxCommentExceedRateLimitErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxCommentFailure = {
  __typename?: 'CreateSeekMaxCommentFailure';
  errors: Array<CreateSeekMaxCommentError>;
};

export type CreateSeekMaxCommentInput = {
  content: Scalars['String']['input'];
  locale: Scalars['Locale']['input'];
  threadId: Scalars['ID']['input'];
};

export type CreateSeekMaxCommentPayload =
  | CreateSeekMaxCommentFailure
  | CreateSeekMaxCommentSuccess;

export type CreateSeekMaxCommentSuccess = {
  __typename?: 'CreateSeekMaxCommentSuccess';
  id: Scalars['ID']['output'];
};

export type CreateSeekMaxCommentUserIsBannedError =
  CreateSeekMaxCommentError & {
    __typename?: 'CreateSeekMaxCommentUserIsBannedError';
    message: Scalars['String']['output'];
    title: Scalars['String']['output'];
  };

export type CreateSeekMaxCommentUserIsBannedErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxCommentUserIsBannedErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxModuleBookmarkError = {
  message: Scalars['String']['output'];
};

export type CreateSeekMaxModuleBookmarkErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxModuleBookmarkFailure = {
  __typename?: 'CreateSeekMaxModuleBookmarkFailure';
  errors: Array<CreateSeekMaxModuleBookmarkError>;
};

export type CreateSeekMaxModuleBookmarkPayload =
  | CreateSeekMaxModuleBookmarkFailure
  | CreateSeekMaxModuleBookmarkSuccess;

export type CreateSeekMaxModuleBookmarkSuccess = {
  __typename?: 'CreateSeekMaxModuleBookmarkSuccess';
  id: Scalars['ID']['output'];
};

export type CreateSeekMaxReplyBlockedWordsError = CreateSeekMaxReplyError & {
  __typename?: 'CreateSeekMaxReplyBlockedWordsError';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CreateSeekMaxReplyBlockedWordsErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxReplyBlockedWordsErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxReplyError = {
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CreateSeekMaxReplyErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxReplyErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxReplyExceedRateLimitError = CreateSeekMaxReplyError & {
  __typename?: 'CreateSeekMaxReplyExceedRateLimitError';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CreateSeekMaxReplyExceedRateLimitErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxReplyExceedRateLimitErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxReplyFailure = {
  __typename?: 'CreateSeekMaxReplyFailure';
  errors: Array<CreateSeekMaxReplyError>;
};

export type CreateSeekMaxReplyInput = {
  commentId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  countryCode: Scalars['CountryCodeIso2']['input'];
  threadId: Scalars['ID']['input'];
};

export type CreateSeekMaxReplyPayload =
  | CreateSeekMaxReplyFailure
  | CreateSeekMaxReplySuccess;

export type CreateSeekMaxReplySuccess = {
  __typename?: 'CreateSeekMaxReplySuccess';
  id: Scalars['ID']['output'];
};

export type CreateSeekMaxReplyUserIsBannedError = CreateSeekMaxReplyError & {
  __typename?: 'CreateSeekMaxReplyUserIsBannedError';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CreateSeekMaxReplyUserIsBannedErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxReplyUserIsBannedErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxThreadAttachmentInput = {
  filename: Scalars['String']['input'];
  type: SeekMaxAttachmentType;
};

export type CreateSeekMaxThreadBlockedWordsError = CreateSeekMaxThreadError & {
  __typename?: 'CreateSeekMaxThreadBlockedWordsError';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CreateSeekMaxThreadBlockedWordsErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxThreadBlockedWordsErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxThreadError = {
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CreateSeekMaxThreadErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxThreadErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxThreadExceedRateLimitError =
  CreateSeekMaxThreadError & {
    __typename?: 'CreateSeekMaxThreadExceedRateLimitError';
    message: Scalars['String']['output'];
    title: Scalars['String']['output'];
  };

export type CreateSeekMaxThreadExceedRateLimitErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxThreadExceedRateLimitErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxThreadFailure = {
  __typename?: 'CreateSeekMaxThreadFailure';
  errors: Array<CreateSeekMaxThreadError>;
};

export type CreateSeekMaxThreadInput = {
  attachments?: InputMaybe<Array<CreateSeekMaxThreadAttachmentInput>>;
  category: SeekMaxCategory;
  description: Scalars['String']['input'];
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxThreadPayload =
  | CreateSeekMaxThreadFailure
  | CreateSeekMaxThreadSuccess;

export type CreateSeekMaxThreadSuccess = {
  __typename?: 'CreateSeekMaxThreadSuccess';
  attachmentsToUpload?: Maybe<Array<SeekMaxAttachmentUploadReady>>;
  thread: SeekMaxThread;
};

export type CreateSeekMaxThreadUserIsBannedError = CreateSeekMaxThreadError & {
  __typename?: 'CreateSeekMaxThreadUserIsBannedError';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CreateSeekMaxThreadUserIsBannedErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxThreadUserIsBannedErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type CreateSeekMaxThreadV2Input = {
  attachments?: InputMaybe<Array<CreateSeekMaxThreadAttachmentInput>>;
  categoryId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  zone: Scalars['Zone']['input'];
};

export type CreateShareableProfileSettingsError = {
  message: Scalars['String']['output'];
};

export type CreateShareableProfileSettingsErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type CreateShareableProfileSettingsFailure = {
  __typename?: 'CreateShareableProfileSettingsFailure';
  error: CreateShareableProfileSettingsError;
};

export type CreateShareableProfileSettingsPayload =
  | CreateShareableProfileSettingsFailure
  | CreateShareableProfileSettingsSuccess;

export type CreateShareableProfileSettingsSuccess = {
  __typename?: 'CreateShareableProfileSettingsSuccess';
  viewer?: Maybe<Candidate>;
};

export type CreateUnregisteredApacSavedSearchDuplicateError =
  CreateUnregisteredApacSavedSearchError & {
    __typename?: 'CreateUnregisteredApacSavedSearchDuplicateError';
    message: Scalars['String']['output'];
  };

export type CreateUnregisteredApacSavedSearchDuplicateErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type CreateUnregisteredApacSavedSearchError = {
  message: Scalars['String']['output'];
};

export type CreateUnregisteredApacSavedSearchErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type CreateUnregisteredApacSavedSearchErrorMaxExceeded =
  CreateUnregisteredApacSavedSearchError & {
    __typename?: 'CreateUnregisteredApacSavedSearchErrorMaxExceeded';
    message: Scalars['String']['output'];
    savedSearchLimit: Scalars['Int']['output'];
  };

export type CreateUnregisteredApacSavedSearchErrorMaxExceededMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type CreateUnregisteredApacSavedSearchInvalidChecksumError =
  CreateUnregisteredApacSavedSearchError & {
    __typename?: 'CreateUnregisteredApacSavedSearchInvalidChecksumError';
    message: Scalars['String']['output'];
  };

export type CreateUnregisteredApacSavedSearchInvalidChecksumErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type CreateUnregisteredApacSavedSearchInvalidEmailError =
  CreateUnregisteredApacSavedSearchError & {
    __typename?: 'CreateUnregisteredApacSavedSearchInvalidEmailError';
    message: Scalars['String']['output'];
  };

export type CreateUnregisteredApacSavedSearchInvalidEmailErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type CreateUnregisteredApacSavedSearchInvalidLocationError =
  CreateUnregisteredApacSavedSearchError & {
    __typename?: 'CreateUnregisteredApacSavedSearchInvalidLocationError';
    message: Scalars['String']['output'];
  };

export type CreateUnregisteredApacSavedSearchInvalidLocationErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type CreateUnregisteredApacSavedSearchWithChecksumFailure = {
  __typename?: 'CreateUnregisteredApacSavedSearchWithChecksumFailure';
  error: CreateUnregisteredApacSavedSearchError;
};

export type CreateUnregisteredApacSavedSearchWithChecksumInput = {
  checksum: Scalars['String']['input'];
  emailAddress: Scalars['Email']['input'];
  isUnified?: InputMaybe<Scalars['Boolean']['input']>;
  searchQueryString: Scalars['JobSearchQueryString']['input'];
  zone: Scalars['Zone']['input'];
};

export type CreateUnregisteredApacSavedSearchWithChecksumPayload =
  | CreateUnregisteredApacSavedSearchWithChecksumFailure
  | CreateUnregisteredApacSavedSearchWithChecksumSuccess;

export type CreateUnregisteredApacSavedSearchWithChecksumSuccess = {
  __typename?: 'CreateUnregisteredApacSavedSearchWithChecksumSuccess';
  id: Scalars['ID']['output'];
};

export type CredentialInfo = {
  __typename?: 'CredentialInfo';
  name: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export enum CredentialStatus {
  Expired = 'Expired',
  /** @deprecated No longer shown in UI */
  ExpiresSoon = 'ExpiresSoon',
  New = 'New',
}

export type CredentialVerification = {
  __typename?: 'CredentialVerification';
  result: Scalars['String']['output'];
};

export type CultureAndValues = {
  __typename?: 'CultureAndValues';
  description: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
  values: Array<CultureValue>;
};

export type CultureValue = {
  __typename?: 'CultureValue';
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CurrentLocation2Input = {
  id: Scalars['Int']['input'];
};

export type CustomStatement = {
  __typename?: 'CustomStatement';
  description: Description;
  title: Scalars['String']['output'];
};

export enum DateTimeContext {
  JobPosted = 'JOB_POSTED',
  SeekmaxPollDurationLeft = 'SEEKMAX_POLL_DURATION_LEFT',
}

export enum DateTimeLength {
  Long = 'LONG',
  Short = 'SHORT',
}

export type DefaultCurrency = {
  __typename?: 'DefaultCurrency';
  code: Scalars['String']['output'];
};

export type DefaultGenerateCoverLetterError = GenerateCoverLetterError & {
  __typename?: 'DefaultGenerateCoverLetterError';
  message: Scalars['String']['output'];
};

export type DefaultGenerateCoverLetterErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type DefaultGenerateSelectionCriteriaError =
  GenerateSelectionCriteriaError & {
    __typename?: 'DefaultGenerateSelectionCriteriaError';
    message: Scalars['String']['output'];
  };

export type DefaultGenerateSelectionCriteriaErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type DeleteAccountFailure = {
  __typename?: 'DeleteAccountFailure';
  error: I18nAccountError;
};

export type DeleteAccountInput = {
  isBGroup?: InputMaybe<Scalars['Boolean']['input']>;
  password: Scalars['String']['input'];
};

export type DeleteAccountPayload = DeleteAccountFailure | DeleteAccountSuccess;

export type DeleteAccountSuccess = {
  __typename?: 'DeleteAccountSuccess';
  ref: Scalars['UUID']['output'];
};

export type DeleteApacSavedSearchAbsentError = DeleteApacSavedSearchError & {
  __typename?: 'DeleteApacSavedSearchAbsentError';
  message: Scalars['String']['output'];
};

export type DeleteApacSavedSearchAbsentErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type DeleteApacSavedSearchError = {
  message: Scalars['String']['output'];
};

export type DeleteApacSavedSearchErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type DeleteApacSavedSearchFailure = {
  __typename?: 'DeleteApacSavedSearchFailure';
  errors: Array<DeleteApacSavedSearchError>;
};

export type DeleteApacSavedSearchInput = {
  id: Scalars['ID']['input'];
};

export type DeleteApacSavedSearchPayload =
  | DeleteApacSavedSearchFailure
  | DeleteApacSavedSearchSuccess;

export type DeleteApacSavedSearchSuccess = {
  __typename?: 'DeleteApacSavedSearchSuccess';
  id: Scalars['ID']['output'];
};

export type DeleteAppliedJobError = {
  message: Scalars['String']['output'];
};

export type DeleteAppliedJobErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type DeleteAppliedJobFailure = {
  __typename?: 'DeleteAppliedJobFailure';
  errors: Array<DeleteAppliedJobError>;
};

export type DeleteAppliedJobInput = {
  id: Scalars['String']['input'];
};

export type DeleteAppliedJobPayload =
  | DeleteAppliedJobFailure
  | DeleteAppliedJobSuccess;

export type DeleteAppliedJobSuccess = {
  __typename?: 'DeleteAppliedJobSuccess';
  id: Scalars['String']['output'];
};

export type DeleteAppliedJobValidationError = DeleteAppliedJobError & {
  __typename?: 'DeleteAppliedJobValidationError';
  message: Scalars['String']['output'];
};

export type DeleteAppliedJobValidationErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type DeleteLanguageProficiencyInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteLanguageProficiencyPayload = {
  __typename?: 'DeleteLanguageProficiencyPayload';
  viewer?: Maybe<Candidate>;
};

export type DeleteLicenceInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteLicencePayload = {
  __typename?: 'DeleteLicencePayload';
  /** @deprecated Use viewer.licences */
  ref?: Maybe<Scalars['String']['output']>;
  viewer?: Maybe<Candidate>;
};

export type DeleteQualificationInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteQualificationPayload = {
  __typename?: 'DeleteQualificationPayload';
  /** @deprecated Use viewer.qualifications */
  id?: Maybe<Scalars['UUID']['output']>;
  viewer?: Maybe<Candidate>;
};

export type DeleteResumeInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteResumePayload = {
  __typename?: 'DeleteResumePayload';
  ref?: Maybe<Scalars['UUID']['output']>;
  viewer?: Maybe<Candidate>;
};

export type DeleteRoleInput = {
  id: Scalars['UUID']['input'];
};

export type DeleteRolePayload = {
  __typename?: 'DeleteRolePayload';
  /** @deprecated Use viewer.roles */
  id: Scalars['UUID']['output'];
  viewer?: Maybe<Candidate>;
};

export type DeleteSavedJobError = {
  message: Scalars['String']['output'];
};

export type DeleteSavedJobErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type DeleteSavedJobFailure = {
  __typename?: 'DeleteSavedJobFailure';
  errors: Array<DeleteSavedJobError>;
};

export type DeleteSavedJobInput = {
  eventCaptureData?: InputMaybe<SavedJobEventCaptureDataInput>;
  id: Scalars['ID']['input'];
};

export type DeleteSavedJobPayload =
  | DeleteSavedJobFailure
  | DeleteSavedJobSuccess;

export type DeleteSavedJobSuccess = {
  __typename?: 'DeleteSavedJobSuccess';
  id: Scalars['ID']['output'];
};

export type DeleteSavedJobValidationError = DeleteSavedJobError & {
  __typename?: 'DeleteSavedJobValidationError';
  message: Scalars['String']['output'];
};

export type DeleteSavedJobValidationErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type DeleteSeekMaxCommentAlreadyDeletedError =
  DeleteSeekMaxCommentError & {
    __typename?: 'DeleteSeekMaxCommentAlreadyDeletedError';
    message: Scalars['String']['output'];
    title: Scalars['String']['output'];
  };

export type DeleteSeekMaxCommentAlreadyDeletedErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxCommentAlreadyDeletedErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxCommentError = {
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DeleteSeekMaxCommentErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxCommentErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxCommentFailure = {
  __typename?: 'DeleteSeekMaxCommentFailure';
  errors: Array<DeleteSeekMaxCommentError>;
};

export type DeleteSeekMaxCommentInput = {
  id: Scalars['ID']['input'];
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxCommentNotExistError = DeleteSeekMaxCommentError & {
  __typename?: 'DeleteSeekMaxCommentNotExistError';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DeleteSeekMaxCommentNotExistErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxCommentNotExistErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxCommentPayload =
  | DeleteSeekMaxCommentFailure
  | DeleteSeekMaxCommentSuccess;

export type DeleteSeekMaxCommentSuccess = {
  __typename?: 'DeleteSeekMaxCommentSuccess';
  id: Scalars['ID']['output'];
};

export type DeleteSeekMaxCommentUserIsBannedError =
  DeleteSeekMaxCommentError & {
    __typename?: 'DeleteSeekMaxCommentUserIsBannedError';
    message: Scalars['String']['output'];
    title: Scalars['String']['output'];
  };

export type DeleteSeekMaxCommentUserIsBannedErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxCommentUserIsBannedErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxModuleBookmarkError = {
  message: Scalars['String']['output'];
};

export type DeleteSeekMaxModuleBookmarkErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxModuleBookmarkFailure = {
  __typename?: 'DeleteSeekMaxModuleBookmarkFailure';
  errors: Array<DeleteSeekMaxModuleBookmarkError>;
};

export type DeleteSeekMaxModuleBookmarkPayload =
  | DeleteSeekMaxModuleBookmarkFailure
  | DeleteSeekMaxModuleBookmarkSuccess;

export type DeleteSeekMaxModuleBookmarkSuccess = {
  __typename?: 'DeleteSeekMaxModuleBookmarkSuccess';
  id: Scalars['ID']['output'];
};

export type DeleteSeekMaxReplyAlreadyDeletedError = DeleteSeekMaxReplyError & {
  __typename?: 'DeleteSeekMaxReplyAlreadyDeletedError';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DeleteSeekMaxReplyAlreadyDeletedErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxReplyAlreadyDeletedErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxReplyError = {
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DeleteSeekMaxReplyErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxReplyErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxReplyFailure = {
  __typename?: 'DeleteSeekMaxReplyFailure';
  errors: Array<DeleteSeekMaxReplyError>;
};

export type DeleteSeekMaxReplyInput = {
  countryCode: Scalars['CountryCodeIso2']['input'];
  id: Scalars['ID']['input'];
};

export type DeleteSeekMaxReplyNotExistError = DeleteSeekMaxReplyError & {
  __typename?: 'DeleteSeekMaxReplyNotExistError';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DeleteSeekMaxReplyNotExistErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxReplyNotExistErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxReplyPayload =
  | DeleteSeekMaxReplyFailure
  | DeleteSeekMaxReplySuccess;

export type DeleteSeekMaxReplySuccess = {
  __typename?: 'DeleteSeekMaxReplySuccess';
  id: Scalars['ID']['output'];
};

export type DeleteSeekMaxReplyUserIsBannedError = DeleteSeekMaxReplyError & {
  __typename?: 'DeleteSeekMaxReplyUserIsBannedError';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DeleteSeekMaxReplyUserIsBannedErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxReplyUserIsBannedErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxThreadAlreadyDeletedError =
  DeleteSeekMaxThreadError & {
    __typename?: 'DeleteSeekMaxThreadAlreadyDeletedError';
    message: Scalars['String']['output'];
    title: Scalars['String']['output'];
  };

export type DeleteSeekMaxThreadAlreadyDeletedErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxThreadAlreadyDeletedErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxThreadError = {
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DeleteSeekMaxThreadErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxThreadErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxThreadFailure = {
  __typename?: 'DeleteSeekMaxThreadFailure';
  errors: Array<DeleteSeekMaxThreadError>;
};

export type DeleteSeekMaxThreadInput = {
  id: Scalars['ID']['input'];
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxThreadNotExistError = DeleteSeekMaxThreadError & {
  __typename?: 'DeleteSeekMaxThreadNotExistError';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DeleteSeekMaxThreadNotExistErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxThreadNotExistErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxThreadPayload =
  | DeleteSeekMaxThreadFailure
  | DeleteSeekMaxThreadSuccess;

export type DeleteSeekMaxThreadSuccess = {
  __typename?: 'DeleteSeekMaxThreadSuccess';
  id: Scalars['ID']['output'];
};

export type DeleteSeekMaxThreadUserIsBannedError = DeleteSeekMaxThreadError & {
  __typename?: 'DeleteSeekMaxThreadUserIsBannedError';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type DeleteSeekMaxThreadUserIsBannedErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type DeleteSeekMaxThreadUserIsBannedErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type Description = {
  __typename?: 'Description';
  full: Scalars['String']['output'];
  paragraphs: Array<Scalars['String']['output']>;
};

export type DeviceContextInput = {
  /** device country is different from brand country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** client generated */
  key: Scalars['UUID']['input'];
  /** IETF BCP 47 Language Tag */
  locale?: InputMaybe<Scalars['String']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  os?: InputMaybe<ExperimentDeviceOsInput>;
  type?: InputMaybe<ExperimentDeviceType>;
};

export type DocumentDownloadLink = {
  __typename?: 'DocumentDownloadLink';
  link: Scalars['String']['output'];
};

export enum DocumentResourceType {
  Resume = 'Resume',
}

export type DocumentUploadFormData = {
  __typename?: 'DocumentUploadFormData';
  formFields: Array<FormField>;
  key: Scalars['String']['output'];
  link: Scalars['String']['output'];
};

export type Duration = {
  __typename?: 'Duration';
  label: Scalars['String']['output'];
  lengthInSeconds: Scalars['Int']['output'];
};

export type DurationLeft = {
  __typename?: 'DurationLeft';
  label: Scalars['String']['output'];
};

export type DurationLeftLabelArgs = {
  locale: Scalars['Locale']['input'];
};

export type EmployeeRecommendation = {
  __typename?: 'EmployeeRecommendation';
  description: Scalars['String']['output'];
  rating: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type EmployeeRecommendationDescriptionArgs = {
  locale?: Scalars['Locale']['input'];
};

export type Episodes = {
  __typename?: 'Episodes';
  /** @deprecated Client side will generate this localised text on their own */
  label: Scalars['String']['output'];
};

export type ExactCommentSocialData = {
  __typename?: 'ExactCommentSocialData';
  likeCount: SocialMetric;
  replyCount: SocialMetric;
};

export type ExactModuleSocialData = {
  __typename?: 'ExactModuleSocialData';
  commentCount: SocialMetric;
  likeCount: SocialMetric;
  viewCount?: Maybe<SocialMetric>;
};

export type ExactThreadSocialData = {
  __typename?: 'ExactThreadSocialData';
  commentAndReplyCount: SocialMetric;
  commentCount: SocialMetric;
  likeCount: SocialMetric;
};

export type ExperienceContextInput = {
  brand?: InputMaybe<Scalars['Brand']['input']>;
  country?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  /** client generated */
  key: Scalars['UUID']['input'];
  /** IETF BCP 47 Language Tag */
  language?: InputMaybe<Scalars['String']['input']>;
};

export type ExperimentDeviceOsInput = {
  name: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export enum ExperimentDeviceType {
  Phone = 'PHONE',
  Tablet = 'TABLET',
}

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['JSON']['output']>;
};

export type FeatureFlagEvaluation = {
  __typename?: 'FeatureFlagEvaluation';
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  value?: Maybe<Scalars['JSON']['output']>;
  variation?: Maybe<Scalars['String']['output']>;
};

export type FeatureFlagInput = {
  advertiserId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
};

export type Featured = {
  __typename?: 'Featured';
  description?: Maybe<Description>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type FeedbackError = {
  message: Scalars['String']['output'];
};

export type FeedbackErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type FeedbackFailure = {
  __typename?: 'FeedbackFailure';
  errors: Array<FeedbackError>;
};

export type FeedbackInput = {
  device?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Email']['input'];
  message: Scalars['String']['input'];
  zone: Scalars['Zone']['input'];
};

export type FeedbackLoggedInInput = {
  device?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  zone: Scalars['Zone']['input'];
};

export type FeedbackMessageMaxLengthError = FeedbackError & {
  __typename?: 'FeedbackMessageMaxLengthError';
  message: Scalars['String']['output'];
};

export type FeedbackMessageMaxLengthErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type FeedbackPayload = FeedbackFailure | FeedbackSuccess;

export type FeedbackSuccess = {
  __typename?: 'FeedbackSuccess';
  ref: Scalars['UUID']['output'];
};

export type FileMetadata = {
  __typename?: 'FileMetadata';
  name: Scalars['String']['output'];
  size: Scalars['String']['output'];
  /** @deprecated Use virusScanStatus. */
  status: FileMetadataStatus;
  uri: Scalars['String']['output'];
  virusScanStatus: VirusScanStatus;
};

export enum FileMetadataStatus {
  HasVirus = 'HasVirus',
  Ok = 'OK',
  Pending = 'Pending',
  Unknown = 'Unknown',
}

export type FormField = {
  __typename?: 'FormField';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type FreeTextQuestion = Question & {
  __typename?: 'FreeTextQuestion';
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type GfjInfo = {
  __typename?: 'GFJInfo';
  location?: Maybe<GfjLocation>;
  workTypes?: Maybe<GfjWorkTypes>;
};

export type GfjLocation = {
  __typename?: 'GFJLocation';
  country?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  suburb?: Maybe<Scalars['String']['output']>;
};

export type GfjLocationCountryArgs = {
  locale?: Scalars['Locale']['input'];
};

export type GfjLocationRegionArgs = {
  locale?: Scalars['Locale']['input'];
};

export type GfjLocationStateArgs = {
  locale?: Scalars['Locale']['input'];
};

export type GfjLocationSuburbArgs = {
  locale?: Scalars['Locale']['input'];
};

export type GfjWorkTypes = {
  __typename?: 'GFJWorkTypes';
  label?: Maybe<Array<Scalars['String']['output']>>;
};

export type GenerateCoverLetterError = {
  message: Scalars['String']['output'];
};

export type GenerateCoverLetterErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type GenerateCoverLetterFailure = {
  __typename?: 'GenerateCoverLetterFailure';
  errors: Array<GenerateCoverLetterError>;
};

export type GenerateCoverLetterInput = {
  writtenText: Scalars['String']['input'];
};

export type GenerateCoverLetterPayload =
  | GenerateCoverLetterFailure
  | GenerateCoverLetterSuccess;

export type GenerateCoverLetterSuccess = {
  __typename?: 'GenerateCoverLetterSuccess';
  uri: Scalars['String']['output'];
};

export type GenerateResumeFromProfileInput = {
  languageCode: Scalars['LanguageCodeIso']['input'];
  zone: Scalars['Zone']['input'];
};

export type GenerateResumeFromProfilePayload = {
  __typename?: 'GenerateResumeFromProfilePayload';
  resume?: Maybe<Resume>;
  viewer?: Maybe<Candidate>;
};

export type GenerateSelectionCriteriaError = {
  message: Scalars['String']['output'];
};

export type GenerateSelectionCriteriaErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type GenerateSelectionCriteriaFailure = {
  __typename?: 'GenerateSelectionCriteriaFailure';
  errors: Array<GenerateSelectionCriteriaError>;
};

export type GenerateSelectionCriteriaInput = {
  writtenText: Scalars['String']['input'];
};

export type GenerateSelectionCriteriaPayload =
  | GenerateSelectionCriteriaFailure
  | GenerateSelectionCriteriaSuccess;

export type GenerateSelectionCriteriaSuccess = {
  __typename?: 'GenerateSelectionCriteriaSuccess';
  uri: Scalars['String']['output'];
};

export type GroupedCompanies = {
  __typename?: 'GroupedCompanies';
  companies: Array<GroupedCompany>;
  groupName: Scalars['String']['output'];
};

export type GroupedCompany = {
  __typename?: 'GroupedCompany';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type I18nAccountError = {
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  reason: Scalars['String']['output'];
};

export type I18nAccountErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type I18nAccountFieldError = I18nAccountError & {
  __typename?: 'I18nAccountFieldError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  path?: Maybe<I18nErrorPath>;
  reason: Scalars['String']['output'];
};

export type I18nAccountFieldErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type I18nErrorPath = {
  __typename?: 'I18nErrorPath';
  field?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

export type IJobProfileSalaryMatch = {
  label: Scalars['String']['output'];
};

export type IJobProfileSalaryMatchLabelArgs = {
  locale: Scalars['Locale']['input'];
};

export type IOntologyCountry = {
  __typename?: 'IOntologyCountry';
  id: Scalars['String']['output'];
  isoCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type IOntologyEducationProvider = {
  __typename?: 'IOntologyEducationProvider';
  countries: Array<IOntologyCountry>;
  highlights: Array<SuggestionHighlight>;
  id: Scalars['String']['output'];
  matchedVariant: Scalars['String']['output'];
  normalisedText: Scalars['String']['output'];
};

export type IOntologyOrganisation = {
  __typename?: 'IOntologyOrganisation';
  countries: Array<IOntologyCountry>;
  highlights: Array<SuggestionHighlight>;
  id: Scalars['String']['output'];
  matchedVariant: Scalars['String']['output'];
  normalisedText: Scalars['String']['output'];
};

export type IOntologyQualification = {
  __typename?: 'IOntologyQualification';
  highlights: Array<SuggestionHighlight>;
  id: Scalars['String']['output'];
  matchedVariant: Scalars['String']['output'];
  normalisedText: Scalars['String']['output'];
  /** @deprecated Phasing out of UI */
  type: Array<QualificationLevel>;
};

export type IOntologyRoleTitleSeniority = {
  __typename?: 'IOntologyRoleTitleSeniority';
  highlights: Array<SuggestionHighlight>;
  id: Scalars['String']['output'];
  matchedRoleTitleVariant: Scalars['String']['output'];
  matchedSeniorityVariant?: Maybe<Scalars['String']['output']>;
  matchedVariant: Scalars['String']['output'];
  normalisedText: Scalars['String']['output'];
  seniorityId?: Maybe<Scalars['String']['output']>;
};

export type ISeekMaxAttachmentStatus = {
  id: Scalars['ID']['output'];
};

export type ISeekMaxCategoryGroup = {
  groupId: Scalars['ID']['output'];
};

export type ISeekMaxKolGroup = {
  description: Scalars['String']['output'];
};

export type ISeekMaxSearchContent = {
  id: Scalars['ID']['output'];
};

export type ITrackSelected = {
  __typename?: 'ITrackSelected';
  id: Scalars['String']['output'];
  index: Scalars['Int']['output'];
  session: Scalars['String']['output'];
};

export type Identity = {
  __typename?: 'Identity';
  /** Operator making requests on behalf of user. */
  actor?: Maybe<Actor>;
  /**
   * Indicates that the user is CS operator, regardless of whether they're actively impersonating.
   * Either true or undefined.
   */
  allowImpersonation?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates that the CS operator has enabled administrator mode. Either true or undefined. */
  showCustomerServiceView?: Maybe<Scalars['Boolean']['output']>;
  /** User requesting access to a resource. */
  subject: Subject;
};

export type IdentityVerifiableCredential = BaseVerifiableCredential & {
  __typename?: 'IdentityVerifiableCredential';
  label: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Job = {
  __typename?: 'Job';
  abstract: Scalars['String']['output'];
  advertiser: Advertiser;
  /** @deprecated Use classifications instead */
  categories?: Maybe<CategoryInfo>;
  classifications: Array<ClassificationInfo>;
  contactMatches?: Maybe<Array<ContactMatches>>;
  content: Scalars['String']['output'];
  createdAt: SeekDateTime;
  expiredAt?: Maybe<SeekDateTime>;
  expiresAt: SeekDateTime;
  id: Scalars['ID']['output'];
  /** @deprecated Use job-details.isChatEligible instead. Will always return null. */
  isChatEligible: Scalars['Boolean']['output'];
  isExpired: Scalars['Boolean']['output'];
  /**
   * Used together with the Link-out system (Close the Loop system)
   * https://indie-jobapply-linkout-service.cloud.seek.com.au/job/<JOBID>
   * to retrieve the external link and record Candidate's action
   */
  isLinkOut: Scalars['Boolean']['output'];
  isProfileApply: Scalars['Boolean']['output'];
  isVerified: Scalars['Boolean']['output'];
  listedAt?: Maybe<SeekDateTime>;
  location: LocationInfo;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  products?: Maybe<JobProducts>;
  salary?: Maybe<JobSalary>;
  shareLink?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  sourceZone?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  title: Scalars['String']['output'];
  tracking?: Maybe<JobTracking>;
  updatedAt: SeekDateTime;
  workTypes: JobWorkTypes;
};

export type JobContentArgs = {
  platform?: InputMaybe<Platform>;
};

export type JobShareLinkArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
  platform: Platform;
  zone: Scalars['Zone']['input'];
};

export type JobApplicantsBadge = {
  __typename?: 'JobApplicantsBadge';
  label?: Maybe<Scalars['String']['output']>;
};

export type JobApplicationArea = {
  __typename?: 'JobApplicationArea';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type JobApplicationClassification = {
  __typename?: 'JobApplicationClassification';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subClassification?: Maybe<JobApplicationSubClassification>;
};

export type JobApplicationInsights = {
  __typename?: 'JobApplicationInsights';
  applicationTipsType: JobApplicationInsightsEnum;
  date: SeekDateTime;
  description: Scalars['String']['output'];
  stats: Array<JobApplicationInsightsStat>;
  title: Scalars['String']['output'];
};

export type JobApplicationInsightsDescriptionArgs = {
  locale?: Scalars['Locale']['input'];
};

export type JobApplicationInsightsTitleArgs = {
  locale?: Scalars['Locale']['input'];
};

export enum JobApplicationInsightsEnum {
  ActivityStatusNotSuitable = 'ACTIVITY_STATUS_NOT_SUITABLE',
  ActivityStatusViewed = 'ACTIVITY_STATUS_VIEWED',
}

export type JobApplicationInsightsStat = {
  __typename?: 'JobApplicationInsightsStat';
  stat: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type JobApplicationInsightsStatTextArgs = {
  locale?: Scalars['Locale']['input'];
};

export type JobApplicationLocation = {
  __typename?: 'JobApplicationLocation';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type JobApplicationProcess = {
  __typename?: 'JobApplicationProcess';
  area?: Maybe<JobApplicationArea>;
  classification?: Maybe<JobApplicationClassification>;
  documents: ApplicationDocuments;
  extractedRoleTitles?: Maybe<Array<Scalars['String']['output']>>;
  /** Web apply url used by app legacy clients that may not be able to render the application requirements */
  fallbackUrl?: Maybe<Scalars['String']['output']>;
  job?: Maybe<Job>;
  linkOut?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<JobApplicationLocation>;
  profile?: Maybe<ApplicationProfile>;
  questionnaire?: Maybe<Questionnaire>;
  state?: Maybe<JobApplicationState>;
};

export type JobApplicationProcessFallbackUrlArgs = {
  zone: Scalars['Zone']['input'];
};

export type JobApplicationState = {
  __typename?: 'JobApplicationState';
  id?: Maybe<Scalars['ID']['output']>;
};

export type JobApplicationSubClassification = {
  __typename?: 'JobApplicationSubClassification';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type JobDetails = {
  __typename?: 'JobDetails';
  companyProfile?: Maybe<CompanyProfile>;
  companyReviews?: Maybe<CompanyReviews>;
  companySearchUrl?: Maybe<Scalars['String']['output']>;
  companyTags: Array<CompanyTag>;
  /**
   * GFJInfo is used to provide information needed to show job postings on Google for Jobs.
   * This is strictly used for the above and should not be used for any other purpose.
   */
  gfjInfo?: Maybe<GfjInfo>;
  /** The insights of job details */
  insights?: Maybe<Array<Maybe<JobInsight>>>;
  /** isChatEligible is experimental and will be deprecated after the APEX chat POC is completed */
  isChatEligible?: Maybe<Scalars['Boolean']['output']>;
  job: Job;
  learningInsights?: Maybe<LearningInsights>;
  personalised?: Maybe<PersonalisedJobDetails>;
  restrictedApplication: JobDetailsRestrictedApplication;
  /**
   * Sourcr is a Product used by Seek for matching recruiters email addresses and phone numbers
   * Note: it is not a misspelling
   * @deprecated Use sourcrRecruiter
   */
  sourcr?: Maybe<JobDetailsSourcr>;
  sourcrRecruiter?: Maybe<SourcrRecruiter>;
};

export type JobDetailsCompanyProfileArgs = {
  zone: Scalars['Zone']['input'];
};

export type JobDetailsCompanyReviewsArgs = {
  zone: Scalars['Zone']['input'];
};

export type JobDetailsCompanySearchUrlArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type JobDetailsLearningInsightsArgs = {
  locale?: Scalars['Locale']['input'];
  platform: Platform;
  zone: Scalars['Zone']['input'];
};

export type JobDetailsRestrictedApplicationArgs = {
  countryCode: Scalars['CountryCodeIso2']['input'];
};

export type JobDetailsRestrictedApplication = {
  __typename?: 'JobDetailsRestrictedApplication';
  label?: Maybe<Scalars['String']['output']>;
};

export type JobDetailsRestrictedApplicationLabelArgs = {
  locale: Scalars['Locale']['input'];
};

/**
 * Sourcr is a Product used by Seek for matching recruiters email addresses and phone numbers
 * Note: it is not a misspelling
 */
export type JobDetailsSourcr = {
  __typename?: 'JobDetailsSourcr';
  image: Scalars['String']['output'];
  imageMobile: Scalars['String']['output'];
  link: Scalars['String']['output'];
};

export type JobDetailsTrackingInput = {
  channel: Scalars['String']['input'];
  jobDetailsViewedCorrelationId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};

export type JobInsight = {
  message: Scalars['String']['output'];
};

export type JobInsightMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type JobProductBranding = {
  __typename?: 'JobProductBranding';
  cover?: Maybe<JobProductBrandingImage>;
  id: Scalars['ID']['output'];
  logo: JobProductBrandingImage;
};

export type JobProductBrandingCoverArgs = {
  isThumbnail?: InputMaybe<Scalars['Boolean']['input']>;
};

export type JobProductBrandingImage = {
  __typename?: 'JobProductBrandingImage';
  aspectRatio?: Maybe<Scalars['Float']['output']>;
  url: Scalars['String']['output'];
};

export type JobProducts = {
  __typename?: 'JobProducts';
  branding?: Maybe<JobProductBranding>;
  bullets?: Maybe<Array<Scalars['String']['output']>>;
  questionnaire?: Maybe<JobQuestionnaire>;
  video?: Maybe<VideoProduct>;
};

export type JobProfileMissingSalaryPreference = IJobProfileSalaryMatch & {
  __typename?: 'JobProfileMissingSalaryPreference';
  label: Scalars['String']['output'];
};

export type JobProfileMissingSalaryPreferenceLabelArgs = {
  locale: Scalars['Locale']['input'];
};

export type JobProfileSalaryMatch = IJobProfileSalaryMatch & {
  __typename?: 'JobProfileSalaryMatch';
  label: Scalars['String']['output'];
  salaryPreference: SalaryPreference2;
};

export type JobProfileSalaryMatchLabelArgs = {
  locale: Scalars['Locale']['input'];
};

export type JobProfileSalaryMatchSalaryPreferenceArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
  locale: Scalars['Locale']['input'];
};

export type JobProfileSalaryNoMatch = IJobProfileSalaryMatch & {
  __typename?: 'JobProfileSalaryNoMatch';
  label: Scalars['String']['output'];
};

export type JobProfileSalaryNoMatchLabelArgs = {
  locale: Scalars['Locale']['input'];
};

/**
 * This is a simplified read-only questionnaire
 * which informs Candidates the questions they will
 * likely need to answer as part of the Application process
 */
export type JobQuestionnaire = {
  __typename?: 'JobQuestionnaire';
  id: Scalars['ID']['output'];
  questions: Array<Scalars['String']['output']>;
};

export type JobSalary = {
  __typename?: 'JobSalary';
  currencyLabel?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
};

export type JobSalaryCurrencyLabelArgs = {
  zone: Scalars['Zone']['input'];
};

export type JobTag = {
  __typename?: 'JobTag';
  label?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type JobTagLabelArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type JobTracking = {
  __typename?: 'JobTracking';
  adProductType?: Maybe<Scalars['String']['output']>;
  classificationInfo?: Maybe<JobTrackingClassificationInfo>;
  hasRoleRequirements?: Maybe<Scalars['Boolean']['output']>;
  isPrivateAdvertiser?: Maybe<Scalars['Boolean']['output']>;
  isRightToWorkRequired?: Maybe<Scalars['Boolean']['output']>;
  isScreenAssigned?: Maybe<Scalars['Boolean']['output']>;
  locationInfo?: Maybe<JobTrackingLocationInfo>;
  postedTime?: Maybe<Scalars['String']['output']>;
  workTypeIds?: Maybe<Scalars['String']['output']>;
};

export type JobTrackingClassificationInfo = {
  __typename?: 'JobTrackingClassificationInfo';
  classification?: Maybe<Scalars['String']['output']>;
  classificationId?: Maybe<Scalars['String']['output']>;
  subClassification?: Maybe<Scalars['String']['output']>;
  subClassificationId?: Maybe<Scalars['String']['output']>;
};

export type JobTrackingLocationInfo = {
  __typename?: 'JobTrackingLocationInfo';
  area?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  locationIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type JobWorkTypes = {
  __typename?: 'JobWorkTypes';
  label: Scalars['String']['output'];
};

export type JobWorkTypesLabelArgs = {
  locale?: Scalars['Locale']['input'];
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String']['output'];
  value: Scalars['JSON']['output'];
};

export type KeyValueInput = {
  key: Scalars['String']['input'];
  value: Scalars['JSON']['input'];
};

export type KeywordSuggestion = {
  __typename?: 'KeywordSuggestion';
  /** A array of SuggestionHighlight which tells you the text index 'start' and 'end' to highlight. This supports multi highlight on a single string of text */
  highlights: Array<SuggestionHighlight>;
  text: Scalars['String']['output'];
};

/** KeywordSuggestionGroup is a collection of pre-defined suggestions e.g. Recent */
export type KeywordSuggestionGroup = {
  __typename?: 'KeywordSuggestionGroup';
  label: Scalars['String']['output'];
  suggestions: Array<KeywordSuggestion>;
  type: SuggestionGroupType;
};

export type KeywordSuggestionType = KeywordSuggestion | KeywordSuggestionGroup;

export enum LanguageCode {
  En = 'en',
  Id = 'id',
  Ms = 'ms',
  Th = 'th',
  Tl = 'tl',
  Vi = 'vi',
}

export type LanguageProficiency = {
  __typename?: 'LanguageProficiency';
  id: Scalars['UUID']['output'];
  name: OntologyStructuredData;
};

export type LastAnswer = {
  __typename?: 'LastAnswer';
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  uri?: Maybe<Scalars['String']['output']>;
};

export type LastWrittenCoverLetter = {
  __typename?: 'LastWrittenCoverLetter';
  content: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type LearningInsights = {
  __typename?: 'LearningInsights';
  action?: Maybe<LearningInsightsAction>;
  analytics?: Maybe<Scalars['JSON']['output']>;
  content: Scalars['String']['output'];
};

export type LearningInsightsAction = {
  __typename?: 'LearningInsightsAction';
  label: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Licence = {
  __typename?: 'Licence';
  credential?: Maybe<BaseCredential>;
  credentialType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<MonthYear>;
  formattedDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isVerified: Scalars['Boolean']['output'];
  issueDate?: Maybe<MonthYear>;
  issuingOrganisation?: Maybe<Scalars['String']['output']>;
  name: OntologyStructuredData;
  noExpiryDate: Scalars['Boolean']['output'];
  status?: Maybe<LicenceStatus>;
  verificationMessage?: Maybe<Scalars['String']['output']>;
  verificationUrl?: Maybe<Scalars['String']['output']>;
};

export enum LicenceStatus {
  Expired = 'Expired',
  /** @deprecated No longer shown in UI */
  ExpiresSoon = 'ExpiresSoon',
  New = 'New',
}

export type LiteProfileUpdateInput = {
  aboutYouEmail: Scalars['String']['input'];
  aboutYouName: Scalars['String']['input'];
  accountPrimaryContact: Scalars['String']['input'];
  brandId?: InputMaybe<Scalars['String']['input']>;
  organisationName: Scalars['String']['input'];
};

export type LiteProfileUpdateNotificationPayload = {
  __typename?: 'LiteProfileUpdateNotificationPayload';
  success: Scalars['Boolean']['output'];
};

export type Location2 = {
  __typename?: 'Location2';
  countryCode: Scalars['CountryCodeIso2']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isDeprecated?: Maybe<Scalars['Boolean']['output']>;
  kind: Scalars['String']['output'];
  shortDescription: Scalars['String']['output'];
};

export type LocationInfo = {
  __typename?: 'LocationInfo';
  label: Scalars['String']['output'];
};

export type LocationInfoLabelArgs = {
  language?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  locale?: InputMaybe<Scalars['Locale']['input']>;
  type?: InputMaybe<LocationLabelType>;
};

export enum LocationLabelType {
  Long = 'LONG',
  Short = 'SHORT',
}

export type LocationSuggestion = {
  __typename?: 'LocationSuggestion';
  /** A array of SuggestionHighlight which tells you the text index 'start' and 'end' to highlight. This supports multi highlight on a single string of text */
  highlights: Array<SuggestionHighlight>;
  id: Scalars['String']['output'];
  isGranular: Scalars['Boolean']['output'];
  partnerId?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

/** LocationSuggestionGroup is a collection of pre-defined suggestions e.g. Recent or Suggested Area */
export type LocationSuggestionGroup = {
  __typename?: 'LocationSuggestionGroup';
  label: Scalars['String']['output'];
  suggestions: Array<LocationSuggestion>;
  type: SuggestionGroupType;
};

export type LocationSuggestionType =
  | LocationSuggestion
  | LocationSuggestionGroup;

export type LoggedOutRecommendedGlobalJob = {
  __typename?: 'LoggedOutRecommendedGlobalJob';
  job?: Maybe<Job>;
  solMetadata: Scalars['JSON']['output'];
};

export type LoggedOutRecommendedGlobalJobs = {
  __typename?: 'LoggedOutRecommendedGlobalJobs';
  globalJobs?: Maybe<Array<Maybe<LoggedOutRecommendedGlobalJob>>>;
  solMetadata?: Maybe<Scalars['JSON']['output']>;
};

export type MkLocationSuggestion = {
  __typename?: 'MKLocationSuggestion';
  countryCode: Scalars['CountryCodeIso2']['output'];
  id: Scalars['Int']['output'];
  text: Scalars['String']['output'];
};

export type MkLocationTrackSelectedResponse = {
  __typename?: 'MKLocationTrackSelectedResponse';
  id: Scalars['Int']['output'];
};

export type MarqueeCompanyProfile = {
  __typename?: 'MarqueeCompanyProfile';
  companyNameSlug?: Maybe<Scalars['String']['output']>;
  /** primary key: Company ID */
  id: Scalars['ID']['output'];
  jobCount?: Maybe<Scalars['Int']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  /** foreign key: Ontology Organisation ID */
  organizationId?: Maybe<Scalars['ID']['output']>;
  reviews?: Maybe<CompanyProfileReviewsSummary>;
  title: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type MatchedQualities = {
  __typename?: 'MatchedQualities';
  inferred?: Maybe<Array<Maybe<Skill>>>;
  matched?: Maybe<Array<Maybe<Skill>>>;
  unmatched?: Maybe<Array<Maybe<Skill>>>;
};

export type MatchedSkills = {
  __typename?: 'MatchedSkills';
  inferred?: Maybe<Array<Maybe<Quality>>>;
  matched?: Maybe<Array<Maybe<Quality>>>;
  unmatched?: Maybe<Array<Maybe<Quality>>>;
};

export type MissionStatement = {
  __typename?: 'MissionStatement';
  description: Description;
};

export type MobileNotiError = {
  message: Scalars['String']['output'];
};

export type MobileNotiErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type MobileNotiFailure = {
  __typename?: 'MobileNotiFailure';
  errors: Array<MobileNotiError>;
};

export type MobileNotiServiceUnavailableError = MobileNotiError & {
  __typename?: 'MobileNotiServiceUnavailableError';
  message: Scalars['String']['output'];
};

export type MobileNotiServiceUnavailableErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type MobileNotiUnknownError = MobileNotiError & {
  __typename?: 'MobileNotiUnknownError';
  message: Scalars['String']['output'];
};

export type MobileNotiUnknownErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type MobilePlatformNoti = {
  client: Scalars['String']['input'];
  name: Scalars['String']['input'];
  service: Scalars['String']['input'];
};

export type MonthYear = {
  __typename?: 'MonthYear';
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type MonthYearInput = {
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type MostRecentRoleInput = {
  company: Scalars['String']['input'];
  finished?: InputMaybe<MonthYearInput>;
  started: MonthYearInput;
  title: Scalars['String']['input'];
};

export type MultipleChoiceQuestion = Question & {
  __typename?: 'MultipleChoiceQuestion';
  id: Scalars['String']['output'];
  lastAnswers?: Maybe<Array<LastAnswer>>;
  options: Array<QuestionOption>;
  text: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']['output']>;
  /** Add feedback for a salary nudge */
  addSalaryNudgeFeedback: CandidateSalaryNudgeFeedbackPayload;
  changeEmailAddress?: Maybe<ChangeEmailAddressPayload>;
  changePassword?: Maybe<ChangePasswordPayload>;
  confirmQualification: ConfirmQualificationPayload;
  confirmRole?: Maybe<ConfirmRolePayload>;
  createApacSavedSearch: CreateApacSavedSearchPayload;
  /** Create a consent for a given transactionId */
  createConsent: CreateConsentPayload;
  createSavedJob2: CreateSavedJobPayload;
  createSeekMaxComment: CreateSeekMaxCommentPayload;
  createSeekMaxModuleBookmark: CreateSeekMaxModuleBookmarkPayload;
  createSeekMaxReply: CreateSeekMaxReplyPayload;
  /** @deprecated Client side will use createSeekMaxThreadV2 instead - https://myseek.atlassian.net/browse/CNC-4488 */
  createSeekMaxThread: CreateSeekMaxThreadPayload;
  createSeekMaxThreadV2: CreateSeekMaxThreadPayload;
  createShareableProfileSettings: CreateShareableProfileSettingsPayload;
  createUnregisteredApacSavedSearchWithChecksum: CreateUnregisteredApacSavedSearchWithChecksumPayload;
  deleteAccount?: Maybe<DeleteAccountPayload>;
  deleteApacSavedSearch: DeleteApacSavedSearchPayload;
  deleteAppliedJob: DeleteAppliedJobPayload;
  deleteLanguageProficiency: DeleteLanguageProficiencyPayload;
  deleteLicence: DeleteLicencePayload;
  deleteQualification?: Maybe<DeleteQualificationPayload>;
  /** Delete the specified from a candidates profile */
  deleteResume?: Maybe<DeleteResumePayload>;
  deleteRole?: Maybe<DeleteRolePayload>;
  deleteSavedJob2: DeleteSavedJobPayload;
  deleteSeekMaxComment: DeleteSeekMaxCommentPayload;
  deleteSeekMaxModuleBookmark: DeleteSeekMaxModuleBookmarkPayload;
  deleteSeekMaxReply: DeleteSeekMaxReplyPayload;
  deleteSeekMaxThread: DeleteSeekMaxThreadPayload;
  feedback?: Maybe<FeedbackPayload>;
  feedbackLoggedIn?: Maybe<FeedbackPayload>;
  generateCoverLetter: GenerateCoverLetterPayload;
  /**
   * This mutation will generate a resume from the candidates profile. The generated
   * resume will be stored in the profile.
   */
  generateResumeFromProfile?: Maybe<GenerateResumeFromProfilePayload>;
  generateSelectionCriteria: GenerateSelectionCriteriaPayload;
  liteProfileUpdateNotification?: Maybe<LiteProfileUpdateNotificationPayload>;
  mkTrackLocationSuggestionSelection: MkLocationTrackSelectedResponse;
  postCompanyProfileReviewFlag?: Maybe<PostCompanyProfileReviewFlagPayload>;
  postCompanyProfileReviewUpvotes?: Maybe<PostCompanyProfileReviewUpvotesPayload>;
  /** This mutation is utilised to complete the uploading process. */
  processUploadedAttachment?: Maybe<ProcessUploadedAttachmentPayload>;
  /**
   * This mutation is utilised to complete the uploading process. Plus, it also triggers the parsing for this resume
   * via the contextId passed by clients, and it is only used for uploading parsing.
   */
  processUploadedResume?: Maybe<ProcessUploadedResumeResult>;
  provisionAccount?: Maybe<ProvisionAccountPayload>;
  /** Reject consent for a given transactionId */
  rejectConsent: RejectConsentPayload;
  /** @deprecated Use rejectUnconfirmedData instead */
  rejectRole?: Maybe<RejectRolePayload>;
  rejectUnconfirmedData: RejectUnconfirmedDataPayload;
  reportJob: ReportJobPayload;
  reportProfile: ReportProfilePayload;
  reportSeekMaxComment: ReportSeekMaxCommentPayload;
  reportSeekMaxReply: ReportSeekMaxReplyPayload;
  reportSeekMaxThread: ReportSeekMaxThreadPayload;
  /**
   * This mutation is only used for selected|scheduled resume parsing (selecting one of the previously uploaded resumes during
   * job apply process) and default resume parsing (making an uploaded resume the primary|default one on profile page),
   * the contextId set by clients is the key to this parsing.
   */
  requestResumeParsing?: Maybe<MutationResult>;
  resubscribeApacSavedSearchByCode: ResubscribeApacSavedSearchByCodePayload;
  saveSeekMaxKeywordSearch: SaveSeekMaxKeywordSearchPayload;
  sendLoginCallbackEvent?: Maybe<SendLoginCallbackEventPayload>;
  sendSeekMaxModuleClickedEvent: SendSeekMaxModuleClickedEventPayload;
  sendSeekMaxModuleClickedPersonalisationEvent: SeekMaxModuleClickedPersonalisationEventPayload;
  /** @deprecated Using deprecated resource API endpoint - https://myseek.atlassian.net/browse/CNC-3205 */
  sendSeekMaxModulePersonalisationEvent: SeekMaxModulePersonalisationEventPayload;
  sendSeekMaxModuleProgress: SeekMaxModuleProgressPayload;
  sendSeekMaxVideoViewedEvent: SeekMaxVideoViewedEventPayload;
  sendSeekMaxVideoViewedPersonalisationEvent: SeekMaxVideoViewedPersonalisationEventPayload;
  setAppliedJobNotes: SetAppliedJobNotesPayload;
  /** Set the default resume for a candidate. */
  setDefaultResume?: Maybe<SetDefaultResumePayload>;
  setSavedJobNotes: SetSavedJobNotesPayload;
  setSeekMaxCategoryGroupFollowedStatus: SetSeekMaxCategoryGroupFollowedStatusPayload;
  setSeekMaxCommentLikedStatus: SetSeekMaxCommentLikedStatusPayload;
  setSeekMaxModuleLikedStatus: SetSeekMaxModuleLikedStatusPayload;
  setSeekMaxPreferredCategories: SetSeekMaxPreferredCategoriesPayload;
  setSeekMaxThreadLikedStatus: SetSeekMaxThreadLikedStatusPayload;
  setSeekMaxThreadPollStatus: SetSeekMaxThreadPollStatusPayload;
  submitApplication: SubmitApplicationPayload;
  subscribeApacSavedEmailAlert: SubscribeApacSavedEmailAlertPayload;
  subscribeMobileNotifications: SubscribeMobileNotiPayload;
  trackJobApplicationStarted: TrackJobApplicationStartedPayload;
  trackJobDetailsViewed: TrackJobDetailsViewedPayload;
  trackSearchResultSelected: TrackSearchResultSelectedPayload;
  unsubscribeApacSavedSearchByCode: UnsubscribeApacSavedSearchByCodePayload;
  unsubscribeMobileNotifications: UnsubscribeMobileNotiPayload;
  updateApproachability: UpdateApproachabilityPayload;
  updateAvailability?: Maybe<UpdateNoticePeriodPayload>;
  updateCareerObjectives?: Maybe<UpdateCareerObjectivesPayload>;
  updateCurrentLocation2: UpdateCurrentLocation2Payload;
  updateLanguageProficiency: UpdateLanguageProficiencyPayload;
  updateLicence: UpdateLicencePayload;
  /** @deprecated Use updateAvailability instead */
  updateNoticePeriod?: Maybe<UpdateNoticePeriodPayload>;
  updateNotificationSubscription?: Maybe<NotificationPreference>;
  updateNotificationSubscriptionWithToken?: Maybe<NotificationPreference>;
  updatePersonalDetails?: Maybe<UpdatePersonalDetailsPayload>;
  updatePreferredClassification: UpdatePreferredClassificationPayload;
  updatePreferredLocations2: UpdatePreferredLocations2Payload;
  updatePreferredWorkTypes?: Maybe<UpdateWorkTypesPayload>;
  updateProfileVisibility: UpdateProfileVisibilityPayload;
  updateQualification?: Maybe<UpdateQualificationPayload>;
  updateRightsToWork: UpdateRightsToWorkPayload;
  updateRole?: Maybe<UpdateRolePayload>;
  updateSalaryPreferences: UpdateSalaryPreferencesPayload;
  updateSeekMaxMyThreadsLastViewed?: Maybe<UpdateSeekMaxMyThreadsLastViewedPayload>;
  updateShareableProfileSettings: UpdateShareableProfileSettingsPayload;
  updateShareableProfileViews: UpdateShareableProfileViewsPayload;
  updateSkills?: Maybe<UpdateSkillsPayload>;
  updateVerifiedLicence: UpdateVerifiedLicencePayload;
  updateVerifiedQualification: UpdateVerifiedQualificationPayload;
  updateYearsOfExperience: UpdateYearsOfExperiencePayload;
};

export type MutationAddSalaryNudgeFeedbackArgs = {
  nudgeId: Scalars['ID']['input'];
  nudgeOption: Scalars['String']['input'];
  zone: Scalars['String']['input'];
};

export type MutationChangeEmailAddressArgs = {
  input: ChangeEmailAddressInput;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationConfirmQualificationArgs = {
  input: ConfirmQualificationInput;
};

export type MutationConfirmRoleArgs = {
  input: ConfirmRoleInput;
};

export type MutationCreateApacSavedSearchArgs = {
  input: CreateApacSavedSearchInput;
};

export type MutationCreateConsentArgs = {
  input: CreateConsentInput;
};

export type MutationCreateSavedJob2Args = {
  input: CreateSavedJobInput;
};

export type MutationCreateSeekMaxCommentArgs = {
  input: CreateSeekMaxCommentInput;
};

export type MutationCreateSeekMaxModuleBookmarkArgs = {
  input: SeekMaxModuleBookmarkInput;
};

export type MutationCreateSeekMaxReplyArgs = {
  input: CreateSeekMaxReplyInput;
};

export type MutationCreateSeekMaxThreadArgs = {
  input: CreateSeekMaxThreadInput;
};

export type MutationCreateSeekMaxThreadV2Args = {
  input: CreateSeekMaxThreadV2Input;
};

export type MutationCreateUnregisteredApacSavedSearchWithChecksumArgs = {
  input: CreateUnregisteredApacSavedSearchWithChecksumInput;
};

export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};

export type MutationDeleteApacSavedSearchArgs = {
  input: DeleteApacSavedSearchInput;
};

export type MutationDeleteAppliedJobArgs = {
  input: DeleteAppliedJobInput;
};

export type MutationDeleteLanguageProficiencyArgs = {
  input: DeleteLanguageProficiencyInput;
};

export type MutationDeleteLicenceArgs = {
  input: DeleteLicenceInput;
};

export type MutationDeleteQualificationArgs = {
  input: DeleteQualificationInput;
};

export type MutationDeleteResumeArgs = {
  input: DeleteResumeInput;
};

export type MutationDeleteRoleArgs = {
  input: DeleteRoleInput;
};

export type MutationDeleteSavedJob2Args = {
  input: DeleteSavedJobInput;
};

export type MutationDeleteSeekMaxCommentArgs = {
  input: DeleteSeekMaxCommentInput;
};

export type MutationDeleteSeekMaxModuleBookmarkArgs = {
  input: SeekMaxModuleBookmarkInput;
};

export type MutationDeleteSeekMaxReplyArgs = {
  input: DeleteSeekMaxReplyInput;
};

export type MutationDeleteSeekMaxThreadArgs = {
  input: DeleteSeekMaxThreadInput;
};

export type MutationFeedbackArgs = {
  input: FeedbackInput;
};

export type MutationFeedbackLoggedInArgs = {
  input: FeedbackLoggedInInput;
};

export type MutationGenerateCoverLetterArgs = {
  input: GenerateCoverLetterInput;
};

export type MutationGenerateResumeFromProfileArgs = {
  input?: InputMaybe<GenerateResumeFromProfileInput>;
};

export type MutationGenerateSelectionCriteriaArgs = {
  input: GenerateSelectionCriteriaInput;
};

export type MutationLiteProfileUpdateNotificationArgs = {
  input: LiteProfileUpdateInput;
};

export type MutationMkTrackLocationSuggestionSelectionArgs = {
  id: Scalars['Int']['input'];
  session: Scalars['String']['input'];
};

export type MutationPostCompanyProfileReviewFlagArgs = {
  input: PostCompanyProfileReviewFlagInput;
};

export type MutationPostCompanyProfileReviewUpvotesArgs = {
  input: PostCompanyProfileReviewUpvotesInput;
};

export type MutationProcessUploadedAttachmentArgs = {
  input: ProcessUploadedAttachmentInput;
};

export type MutationProcessUploadedResumeArgs = {
  input: ProcessUploadedResumeInput;
};

export type MutationProvisionAccountArgs = {
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type MutationRejectConsentArgs = {
  input: RejectConsentInput;
};

export type MutationRejectRoleArgs = {
  input: RejectRoleInput;
};

export type MutationRejectUnconfirmedDataArgs = {
  input: RejectUnconfirmedDataInput;
};

export type MutationReportJobArgs = {
  input: ReportJobInput;
};

export type MutationReportProfileArgs = {
  input: ReportProfileInput;
};

export type MutationReportSeekMaxCommentArgs = {
  input: ReportSeekMaxCommentInput;
};

export type MutationReportSeekMaxReplyArgs = {
  input: ReportSeekMaxReplyInput;
};

export type MutationReportSeekMaxThreadArgs = {
  input: ReportSeekMaxThreadInput;
};

export type MutationRequestResumeParsingArgs = {
  input: RequestResumeParsingInput;
};

export type MutationResubscribeApacSavedSearchByCodeArgs = {
  input: ResubscribeApacSavedSearchByCodeInput;
};

export type MutationSaveSeekMaxKeywordSearchArgs = {
  input: SaveSeekMaxKeywordSearchInput;
};

export type MutationSendSeekMaxModuleClickedEventArgs = {
  input: SendSeekMaxModuleClickedEventInput;
};

export type MutationSendSeekMaxModuleClickedPersonalisationEventArgs = {
  input: SeekMaxModuleClickedPersonalisationEventInput;
};

export type MutationSendSeekMaxModulePersonalisationEventArgs = {
  input: SeekMaxModulePersonalisationEventInput;
};

export type MutationSendSeekMaxModuleProgressArgs = {
  input: SeekMaxModuleProgressInput;
};

export type MutationSendSeekMaxVideoViewedEventArgs = {
  input: SeekMaxVideoViewedEventInput;
};

export type MutationSendSeekMaxVideoViewedPersonalisationEventArgs = {
  input: SeekMaxVideoViewedPersonalisationEventInput;
};

export type MutationSetAppliedJobNotesArgs = {
  input: SetAppliedJobNotesInput;
};

export type MutationSetDefaultResumeArgs = {
  input: SetDefaultResumeInput;
};

export type MutationSetSavedJobNotesArgs = {
  input: SetSavedJobNotesInput;
};

export type MutationSetSeekMaxCategoryGroupFollowedStatusArgs = {
  input: SeekMaxCategoryGroupFollowInput;
};

export type MutationSetSeekMaxCommentLikedStatusArgs = {
  input: SeekMaxLikeInput;
};

export type MutationSetSeekMaxModuleLikedStatusArgs = {
  input: SeekMaxModuleLikeInput;
};

export type MutationSetSeekMaxPreferredCategoriesArgs = {
  input: SetSeekMaxPreferredCategoriesInput;
};

export type MutationSetSeekMaxThreadLikedStatusArgs = {
  input: SeekMaxLikeInput;
};

export type MutationSetSeekMaxThreadPollStatusArgs = {
  input: SeekMaxThreadPollInput;
};

export type MutationSubmitApplicationArgs = {
  input: SubmitApplicationInput;
};

export type MutationSubscribeApacSavedEmailAlertArgs = {
  input: SubscribeApacSavedEmailAlertInput;
};

export type MutationSubscribeMobileNotificationsArgs = {
  input: SubscribeMobileNoti;
};

export type MutationTrackJobApplicationStartedArgs = {
  input: TrackJobApplicationStartedInput;
};

export type MutationTrackJobDetailsViewedArgs = {
  input: TrackJobDetailsViewedInput;
};

export type MutationTrackSearchResultSelectedArgs = {
  input: TrackSearchResultSelectedInput;
};

export type MutationUnsubscribeApacSavedSearchByCodeArgs = {
  input: UnsubscribeApacSavedSearchByCodeInput;
};

export type MutationUnsubscribeMobileNotificationsArgs = {
  input: UnsubscribeMobileNoti;
};

export type MutationUpdateApproachabilityArgs = {
  input: UpdateApproachabilityInput;
};

export type MutationUpdateAvailabilityArgs = {
  input: UpdateAvailabilityInput;
};

export type MutationUpdateCareerObjectivesArgs = {
  input: UpdateCareerObjectivesInput;
};

export type MutationUpdateCurrentLocation2Args = {
  input: UpdateCurrentLocation2Input;
};

export type MutationUpdateLanguageProficiencyArgs = {
  input: UpdateLanguageProficiencyInput;
};

export type MutationUpdateLicenceArgs = {
  input: UpdateLicenceInput;
};

export type MutationUpdateNoticePeriodArgs = {
  input: UpdateNoticePeriodInput;
};

export type MutationUpdateNotificationSubscriptionArgs = {
  input: NotificationPreferenceInput;
};

export type MutationUpdateNotificationSubscriptionWithTokenArgs = {
  input: NotificationPreferenceWithTokenInput;
};

export type MutationUpdatePersonalDetailsArgs = {
  input: UpdatePersonalDetailsInput;
};

export type MutationUpdatePreferredClassificationArgs = {
  input: UpdateClassificationInput;
};

export type MutationUpdatePreferredLocations2Args = {
  input: UpdatePreferredLocations2Input;
};

export type MutationUpdatePreferredWorkTypesArgs = {
  input: UpdatePreferredWorkTypesInput;
};

export type MutationUpdateProfileVisibilityArgs = {
  input: UpdateProfileVisibilityInput;
};

export type MutationUpdateQualificationArgs = {
  input: UpdateQualificationInput;
};

export type MutationUpdateRightsToWorkArgs = {
  input: UpdateRightsToWorkInput;
};

export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};

export type MutationUpdateSalaryPreferencesArgs = {
  input: UpdateSalaryPreferencesInput;
};

export type MutationUpdateSeekMaxMyThreadsLastViewedArgs = {
  input: UpdateSeekMaxMyThreadsLastViewedInput;
};

export type MutationUpdateShareableProfileSettingsArgs = {
  input: UpdateShareableProfileSettingsInput;
};

export type MutationUpdateShareableProfileViewsArgs = {
  input: UpdateShareableProfileViewsInput;
};

export type MutationUpdateSkillsArgs = {
  input: UpdateSkillsInput;
};

export type MutationUpdateVerifiedLicenceArgs = {
  input: UpdateVerifiedLicenceInput;
};

export type MutationUpdateVerifiedQualificationArgs = {
  input: UpdateVerifiedQualificationInput;
};

export type MutationUpdateYearsOfExperienceArgs = {
  input: UpdateYearsOfExperienceInput;
};

export type MutationResult = {
  __typename?: 'MutationResult';
  ref?: Maybe<Scalars['UUID']['output']>;
};

export type Nationality = {
  __typename?: 'Nationality';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type NationalityInput = {
  id: Scalars['ID']['input'];
};

export type NextRolePreferredLocation = {
  __typename?: 'NextRolePreferredLocation';
  country?: Maybe<Country>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  parent?: Maybe<NextRolePreferredLocation>;
  type: PreferredLocationType;
};

export type NoticePeriod = {
  __typename?: 'NoticePeriod';
  weeks: Scalars['Int']['output'];
};

export type NotificationCtpCategory = {
  __typename?: 'NotificationCTPCategory';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type NotificationCtpCategoryDescriptionArgs = {
  locale: Scalars['Locale']['input'];
};

export type NotificationCtpCategoryLabelArgs = {
  locale: Scalars['Locale']['input'];
  zone: Scalars['Zone']['input'];
};

export type NotificationCtpChannel = {
  __typename?: 'NotificationCTPChannel';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type NotificationCtpChannelLabelArgs = {
  locale: Scalars['Locale']['input'];
};

export type NotificationCtpFrequency = {
  __typename?: 'NotificationCTPFrequency';
  options: Array<NotificationCtpFrequencyOptions>;
  value: Scalars['String']['output'];
};

export type NotificationCtpFrequencyOptions = {
  __typename?: 'NotificationCTPFrequencyOptions';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type NotificationCtpFrequencyOptionsDescriptionArgs = {
  locale: Scalars['Locale']['input'];
};

export type NotificationCtpFrequencyOptionsLabelArgs = {
  locale: Scalars['Locale']['input'];
};

export type NotificationCtpPreference = {
  __typename?: 'NotificationCTPPreference';
  product: NotificationCtpProduct;
  subscriptions: Array<NotificationCtpSubscription>;
};

export type NotificationCtpPreferenceGroup = {
  __typename?: 'NotificationCTPPreferenceGroup';
  category: NotificationCtpCategory;
  preferences: Array<NotificationCtpPreference>;
};

export type NotificationCtpProduct = {
  __typename?: 'NotificationCTPProduct';
  action?: Maybe<NotificationCtpProductAction>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type NotificationCtpProductDescriptionArgs = {
  locale: Scalars['Locale']['input'];
};

export type NotificationCtpProductLabelArgs = {
  locale: Scalars['Locale']['input'];
};

export type NotificationCtpProductAction = {
  __typename?: 'NotificationCTPProductAction';
  label: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export type NotificationCtpProductActionLabelArgs = {
  locale: Scalars['Locale']['input'];
  zone: Scalars['Zone']['input'];
};

export type NotificationCtpSubscription = {
  __typename?: 'NotificationCTPSubscription';
  channel: NotificationCtpChannel;
  frequency?: Maybe<NotificationCtpFrequency>;
  id: Scalars['ID']['output'];
  subscribed: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type NotificationCategory = {
  __typename?: 'NotificationCategory';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type NotificationChannel = {
  __typename?: 'NotificationChannel';
  code: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type NotificationCtpPreferenceError = {
  errorCode?: Maybe<Scalars['Int']['output']>;
  message: Scalars['String']['output'];
};

export type NotificationCtpPreferenceErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type NotificationCtpSubscriptionWithTokenInput = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
  subscribe: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
};

export type NotificationFrequency = {
  __typename?: 'NotificationFrequency';
  options: Array<NotificationFrequencyOptions>;
  value: Scalars['String']['output'];
};

export type NotificationFrequencyOptions = {
  __typename?: 'NotificationFrequencyOptions';
  code: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  category: NotificationCategory;
  product: NotificationProduct;
  subscriptions: Array<NotificationSubscription>;
};

export type NotificationPreferenceGroup = {
  __typename?: 'NotificationPreferenceGroup';
  category: NotificationCategory;
  notificationPreferences: Array<NotificationPreference>;
};

export type NotificationPreferenceInput = {
  channelCode: Scalars['String']['input'];
  frequency?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['Locale']['input']>;
  productCode: Scalars['String']['input'];
  subscribe: Scalars['Boolean']['input'];
};

export type NotificationPreferenceWithTokenInput = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
  subscribe: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
};

export type NotificationProduct = {
  __typename?: 'NotificationProduct';
  code: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
};

export type NotificationSubscription = {
  __typename?: 'NotificationSubscription';
  channel: NotificationChannel;
  frequency?: Maybe<NotificationFrequency>;
  id: Scalars['ID']['output'];
  subscribed: Scalars['Boolean']['output'];
};

export type NumberOfReviews = {
  __typename?: 'NumberOfReviews';
  description: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type NumberOfReviewsDescriptionArgs = {
  locale: Scalars['Locale']['input'];
};

export type OntologySpecReq = {
  __typename?: 'OntologySpecReq';
  collectExpiry: Scalars['Boolean']['output'];
  highlights: Array<SuggestionHighlight>;
  id: Scalars['String']['output'];
  matchedVariant: Scalars['String']['output'];
  normalisedText: Scalars['String']['output'];
  verificationUrl?: Maybe<Scalars['String']['output']>;
};

export type OntologySpecReqVerificationUrlArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  platform?: InputMaybe<Platform>;
  source?: InputMaybe<SourceExperience>;
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type OntologyStructuredData = {
  __typename?: 'OntologyStructuredData';
  ontologyId?: Maybe<Scalars['ID']['output']>;
  text: Scalars['String']['output'];
};

export type OntologyStructuredDataInput = {
  ontologyId?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
};

export enum OntologyType {
  EducationProviders = 'EDUCATION_PROVIDERS',
  Organisations = 'ORGANISATIONS',
  Qualifications = 'QUALIFICATIONS',
  RoletitlesSeniority = 'ROLETITLES_SENIORITY',
  Specreqs = 'SPECREQS',
}

export enum Option {
  All = 'all',
  Common = 'common',
}

export type OverallRating = {
  __typename?: 'OverallRating';
  numberOfReviews: NumberOfReviews;
  value: Scalars['Float']['output'];
};

export type Overview = {
  __typename?: 'Overview';
  description?: Maybe<Description>;
  industry?: Maybe<Scalars['String']['output']>;
  primaryLocation?: Maybe<Scalars['String']['output']>;
  size?: Maybe<CompanySize>;
  specialities?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Website>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPrevPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ParentClassification = {
  __typename?: 'ParentClassification';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  subClassifications: Array<ChildClassification>;
};

export type ParentClassificationDescriptionArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type PendingCredentialGroup = {
  __typename?: 'PendingCredentialGroup';
  count: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

export type PerkAndBenefit = {
  __typename?: 'PerkAndBenefit';
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type PersonalDetails = {
  __typename?: 'PersonalDetails';
  /**
   * The candidate's country calling code. The `description` field will be returned in the
   * language specified by the `languageCode` parameter passed to the `personalDetails`
   * field. If this field is `null` then the `defaultCountryCallingCode` value must be
   * used instead. Otherwise, this field must always be used.
   */
  countryCallingCode?: Maybe<CountryCallingCode>;
  /**
   * A default country calling code based on the `countryCode` parameter passed to the
   * `personalDetails` field. The `description` field will be returned in the language
   * specified by the `languageCode` parameter passed to the `personalDetails` field.
   * If the `countryCallingCode` field is `null`, then this  field must always be used.
   * Otherwise, the `countryCallingCode` must be used instead.
   */
  defaultCountryCallingCode: CountryCallingCode;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  /** @deprecated Being phased out as part of unification */
  nationality?: Maybe<Nationality>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type PersonalisedJobDetails = {
  __typename?: 'PersonalisedJobDetails';
  applicationInsights?: Maybe<JobApplicationInsights>;
  appliedDateTime?: Maybe<SeekDateTime>;
  isSaved?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated No longer shown in UI */
  jobApplicantsBadge?: Maybe<JobApplicantsBadge>;
  /**
   * CAUTION!! This field uses a resolver that does not meet the SPREAD requirements
   * for a Seek Resource API.  Please query separately from the other fields in the
   * PersonaliseJobDetails object.
   */
  matchedSkills?: Maybe<MatchedSkills>;
  qualities?: Maybe<MatchedQualities>;
  salaryMatch?: Maybe<IJobProfileSalaryMatch>;
  /** Top Applicant Badge uses Brand, Locale/translatable text */
  topApplicantBadge?: Maybe<TopApplicantBadge>;
};

export type PersonalisedSeekMaxCategoryGroup = {
  __typename?: 'PersonalisedSeekMaxCategoryGroup';
  followed?: Maybe<Scalars['Boolean']['output']>;
};

export type PersonalisedSeekMaxComment = {
  __typename?: 'PersonalisedSeekMaxComment';
  liked: Scalars['Boolean']['output'];
};

export type PersonalisedSeekMaxModule = {
  __typename?: 'PersonalisedSeekMaxModule';
  bookmark?: Maybe<SeekMaxModuleBookmark>;
  liked: Scalars['Boolean']['output'];
};

export type PersonalisedSeekMaxModuleBookmarkArgs = {
  countryCode: Scalars['CountryCodeIso2']['input'];
};

export type PersonalisedSeekMaxModuleProgress = {
  __typename?: 'PersonalisedSeekMaxModuleProgress';
  completed: Scalars['Boolean']['output'];
  nextVideoProgress?: Maybe<SeekMaxNextVideoProgress>;
};

export type PersonalisedSeekMaxPollOption = {
  __typename?: 'PersonalisedSeekMaxPollOption';
  voted: Scalars['Boolean']['output'];
};

export type PersonalisedSeekMaxThread = {
  __typename?: 'PersonalisedSeekMaxThread';
  liked: Scalars['Boolean']['output'];
};

export type PersonalisedSeekMaxVideo = {
  __typename?: 'PersonalisedSeekMaxVideo';
  progress: SeekMaxVideoProgress;
};

export type PersonalisedSeekMaxVideoProgressArgs = {
  locale: Scalars['Locale']['input'];
};

export enum Platform {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB',
}

export type PostCompanyProfileReviewFlagInput = {
  companyId: Scalars['String']['input'];
  details: Scalars['String']['input'];
  reason: Scalars['String']['input'];
  reviewId: Scalars['String']['input'];
};

export type PostCompanyProfileReviewFlagPayload = {
  __typename?: 'PostCompanyProfileReviewFlagPayload';
  success: Scalars['Boolean']['output'];
};

export type PostCompanyProfileReviewUpvotesInput = {
  companyId: Scalars['String']['input'];
  reviewId: Scalars['String']['input'];
};

export type PostCompanyProfileReviewUpvotesPayload = {
  __typename?: 'PostCompanyProfileReviewUpvotesPayload';
  success: Scalars['Boolean']['output'];
};

export type PreferredLocation2Input = {
  id: Scalars['Int']['input'];
};

export enum PreferredLocationType {
  Area = 'Area',
  Location = 'Location',
}

export type Privacy = {
  __typename?: 'Privacy';
  education: Scalars['Boolean']['output'];
  languages: Scalars['Boolean']['output'];
  licences: Scalars['Boolean']['output'];
  personalDetails: Scalars['Boolean']['output'];
  skills: Scalars['Boolean']['output'];
  summary: Scalars['Boolean']['output'];
  workHistory: Scalars['Boolean']['output'];
};

export type PrivacyInput = {
  education: Scalars['Boolean']['input'];
  languages: Scalars['Boolean']['input'];
  licences: Scalars['Boolean']['input'];
  personalDetails: Scalars['Boolean']['input'];
  skills: Scalars['Boolean']['input'];
  summary: Scalars['Boolean']['input'];
  workHistory: Scalars['Boolean']['input'];
};

export type PrivacyPolicyQuestion = Question & {
  __typename?: 'PrivacyPolicyQuestion';
  id: Scalars['String']['output'];
  options: Array<QuestionOption>;
  text: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ProcessUploadedAttachmentInput = {
  attachmentType: AttachmentType;
  id: Scalars['UUID']['input'];
};

export type ProcessUploadedAttachmentPayload = {
  __typename?: 'ProcessUploadedAttachmentPayload';
  uri: Scalars['String']['output'];
};

export type ProcessUploadedResumeInput = {
  /** @deprecated Only the zone parameter should be passed after all unification releases */
  countryCode?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  id: Scalars['UUID']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  origin?: InputMaybe<ResumeOriginType>;
  parsingContext?: InputMaybe<RequestResumeParsingContext>;
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type ProcessUploadedResumeResult = {
  __typename?: 'ProcessUploadedResumeResult';
  /** @deprecated Please use the resume type with more resume metadata */
  id: Scalars['UUID']['output'];
  resume: Resume;
  viewer?: Maybe<Candidate>;
};

export type ProfileInsights = {
  __typename?: 'ProfileInsights';
  activities: Array<ProfileInsightsActivity>;
  searchImpressionCount: Scalars['Int']['output'];
  viewCount: Scalars['Int']['output'];
};

export type ProfileInsightsActivity = {
  /** The relative day(s) since the activity, or date of the activity if more than 29 days ago */
  date: Scalars['String']['output'];
  /** Display label for the source of the activity e.g. Found from an employer search */
  sourceLabel: Scalars['String']['output'];
};

export type ProfileInsightsActivityDateArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
  timezone?: InputMaybe<Scalars['Timezone']['input']>;
  zone: Scalars['Zone']['input'];
};

export type ProfileInsightsActivitySourceLabelArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
};

export type ProfileInsightsAdvertiser = {
  __typename?: 'ProfileInsightsAdvertiser';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ProfileInsightsJob = {
  __typename?: 'ProfileInsightsJob';
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  /** Job URL built with brand based on candidate's zone and selected language */
  url: Scalars['String']['output'];
};

export type ProfileInsightsJobUrlArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
  zone: Scalars['Zone']['input'];
};

export type ProfileInsightsJobSentActivity = ProfileInsightsActivity & {
  __typename?: 'ProfileInsightsJobSentActivity';
  /** The advertiser who interacted with the candidate's profile */
  advertiser: ProfileInsightsAdvertiser;
  date: Scalars['String']['output'];
  /** Job sent from the advertiser to the candidate */
  job: ProfileInsightsJob;
  sourceLabel: Scalars['String']['output'];
};

export type ProfileInsightsJobSentActivityDateArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
  timezone?: InputMaybe<Scalars['Timezone']['input']>;
  zone: Scalars['Zone']['input'];
};

export type ProfileInsightsJobSentActivitySourceLabelArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
};

export type ProfileInsightsMessageSentActivity = ProfileInsightsActivity & {
  __typename?: 'ProfileInsightsMessageSentActivity';
  /** The advertiser who interacted with the candidate's profile */
  advertiser: ProfileInsightsAdvertiser;
  date: Scalars['String']['output'];
  sourceLabel: Scalars['String']['output'];
};

export type ProfileInsightsMessageSentActivityDateArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
  timezone?: InputMaybe<Scalars['Timezone']['input']>;
  zone: Scalars['Zone']['input'];
};

export type ProfileInsightsMessageSentActivitySourceLabelArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
};

export type ProfileInsightsResumeDownloadedActivity =
  ProfileInsightsActivity & {
    __typename?: 'ProfileInsightsResumeDownloadedActivity';
    /** The advertiser who interacted with the candidate's profile */
    advertiser: ProfileInsightsAdvertiser;
    date: Scalars['String']['output'];
    sourceLabel: Scalars['String']['output'];
  };

export type ProfileInsightsResumeDownloadedActivityDateArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
  timezone?: InputMaybe<Scalars['Timezone']['input']>;
  zone: Scalars['Zone']['input'];
};

export type ProfileInsightsResumeDownloadedActivitySourceLabelArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
};

export type ProfileLocationTrackSelectedResponse = {
  __typename?: 'ProfileLocationTrackSelectedResponse';
  id: Scalars['Int']['output'];
};

export enum ProfileLocationType {
  Home = 'Home',
  Preferred = 'Preferred',
}

export type ProfileScore = {
  __typename?: 'ProfileScore';
  /** Overall progress/completeness, value from 0 to 1. */
  progress: Scalars['Float']['output'];
  /** The sum of all score values from each individual scorer. */
  score: Scalars['Float']['output'];
  /** A list of all tips the candidate can action on their profile. */
  tips: Array<ProfileScoreTip>;
};

export type ProfileScoreTip = {
  __typename?: 'ProfileScoreTip';
  /** Parameters used by the profile app to customise the tip action. */
  action: ProfileScoreTipAction;
  /** A description of the value the candidate could get by actioning this tip. */
  description: Scalars['String']['output'];
  /** The action button label for this tip. */
  label: Scalars['String']['output'];
  /** The score amount that can be gained from actioning this tip. */
  score: Scalars['Float']['output'];
};

export type ProfileScoreTipAction = {
  __typename?: 'ProfileScoreTipAction';
  /**
   * The specific card to scroll to when the tip is actioned. Valid values are
   * strings from [this array](https://github.com/SEEK-Jobs/ca-myprofile-ui/blob/master/src/components/Router/index.ts#L29).
   */
  card: Scalars['String']['output'];
  /**
   * A specific field to focus on within the `card` specified. The field must
   * have a focus ref attached it. Reference the `field-name` associated with
   * the ref. [Example](https://github.com/SEEK-Jobs/ca-myprofile-ui/blob/494f567314685156cc3c40bfa212746d14845efa/src/components/Profile/PersonalDetails/EditPersonalDetails/EditPersonalDetails.tsx#L144).
   */
  field?: Maybe<Scalars['String']['output']>;
  /**
   * The id of a specific profile item. Can be used if you want an action to
   * target a specific item, e.g. a candidate's most recent career history.
   */
  item?: Maybe<Scalars['String']['output']>;
  /**
   * The mode to set the card to when the tip is actioned. Valid values are
   * `read` or `edit`.
   */
  mode?: Maybe<Scalars['String']['output']>;
};

export type ProfileSuggestLocation = {
  __typename?: 'ProfileSuggestLocation';
  contextualName: Scalars['String']['output'];
  countryCode: Scalars['CountryCodeIso2']['output'];
  highlights: Array<SuggestionHighlight>;
  id: Scalars['Int']['output'];
  kind: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
};

export type ProfileSuggestLocationGroup = {
  __typename?: 'ProfileSuggestLocationGroup';
  label: Scalars['String']['output'];
  suggestions: Array<ProfileSuggestLocation>;
};

export type ProfileSuggestLocationType =
  | ProfileSuggestLocation
  | ProfileSuggestLocationGroup;

export type ProfileSuggestion = {
  __typename?: 'ProfileSuggestion';
  /** Action that candidate could take on for this particular suggestion. */
  action?: Maybe<ProfileSuggestionAction>;
  /** A suggestion message the candidate can improve or action on their profile. */
  message: Scalars['String']['output'];
  /** Event name passed to the tracking system when the suggestion is displayed. */
  trackingNudgeType: Scalars['String']['output'];
};

export type ProfileSuggestionAction = {
  __typename?: 'ProfileSuggestionAction';
  /** The context of the action on what is the next step to take after button click. */
  context?: Maybe<ProfileSuggestionActionContext>;
  /** The action button label for this suggestion action. */
  label: Scalars['String']['output'];
  /** Event name passed to the tracking system when the action is clicked. */
  trackingCallToAction: Scalars['String']['output'];
};

export type ProfileSuggestionActionContext = {
  /**
   * The specific card to scroll to when the tip is actioned. Valid values are
   * strings from [this array](https://github.com/SEEK-Jobs/ca-myprofile-ui/blob/master/src/components/Router/index.ts#L29).
   */
  card?: Maybe<Scalars['String']['output']>;
  /**
   * A specific field to focus on within the `section` specified. The field must have a focus ref attached it. Reference the `field-name` associated with
   * the ref. [Example](https://github.com/SEEK-Jobs/ca-myprofile-ui/blob/494f567314685156cc3c40bfa212746d14845efa/src/components/Profile/PersonalDetails/EditPersonalDetails/EditPersonalDetails.tsx#L144).
   * (Mainly used by web)
   */
  field?: Maybe<Scalars['String']['output']>;
  /** The mode to set the section/card to when the suggestion is actioned. Valid values are `read` or `edit`. (Mainly used by web) */
  mode?: Maybe<Scalars['String']['output']>;
};

export type ProfileSuggestionAvailabilityActionContext =
  ProfileSuggestionActionContext & {
    __typename?: 'ProfileSuggestionAvailabilityActionContext';
    card?: Maybe<Scalars['String']['output']>;
    field?: Maybe<Scalars['String']['output']>;
    mode?: Maybe<Scalars['String']['output']>;
  };

export type ProfileSuggestionCareerHistoryActionContext =
  ProfileSuggestionActionContext & {
    __typename?: 'ProfileSuggestionCareerHistoryActionContext';
    card?: Maybe<Scalars['String']['output']>;
    field?: Maybe<Scalars['String']['output']>;
    /**
     * The id of a specific profile item. Can be used if you want an action to
     * target a specific item, e.g. a candidate's most recent career history.
     */
    itemId?: Maybe<Scalars['String']['output']>;
    mode?: Maybe<Scalars['String']['output']>;
  };

export type ProfileSuggestionClassificationActionContext =
  ProfileSuggestionActionContext & {
    __typename?: 'ProfileSuggestionClassificationActionContext';
    card?: Maybe<Scalars['String']['output']>;
    field?: Maybe<Scalars['String']['output']>;
    mode?: Maybe<Scalars['String']['output']>;
  };

export type ProfileSuggestionEducationActionContext =
  ProfileSuggestionActionContext & {
    __typename?: 'ProfileSuggestionEducationActionContext';
    card?: Maybe<Scalars['String']['output']>;
    field?: Maybe<Scalars['String']['output']>;
    mode?: Maybe<Scalars['String']['output']>;
  };

export type ProfileSuggestionLanguagesActionContext =
  ProfileSuggestionActionContext & {
    __typename?: 'ProfileSuggestionLanguagesActionContext';
    card?: Maybe<Scalars['String']['output']>;
    field?: Maybe<Scalars['String']['output']>;
    mode?: Maybe<Scalars['String']['output']>;
  };

export type ProfileSuggestionLicencesActionContext =
  ProfileSuggestionActionContext & {
    __typename?: 'ProfileSuggestionLicencesActionContext';
    card?: Maybe<Scalars['String']['output']>;
    field?: Maybe<Scalars['String']['output']>;
    mode?: Maybe<Scalars['String']['output']>;
  };

export type ProfileSuggestionPersonalDetailsActionContext =
  ProfileSuggestionActionContext & {
    __typename?: 'ProfileSuggestionPersonalDetailsActionContext';
    card?: Maybe<Scalars['String']['output']>;
    field?: Maybe<Scalars['String']['output']>;
    mode?: Maybe<Scalars['String']['output']>;
  };

export type ProfileSuggestionPersonalSummaryActionContext =
  ProfileSuggestionActionContext & {
    __typename?: 'ProfileSuggestionPersonalSummaryActionContext';
    card?: Maybe<Scalars['String']['output']>;
    field?: Maybe<Scalars['String']['output']>;
    mode?: Maybe<Scalars['String']['output']>;
  };

export type ProfileSuggestionProfileVisibilityActionContext =
  ProfileSuggestionActionContext & {
    __typename?: 'ProfileSuggestionProfileVisibilityActionContext';
    card?: Maybe<Scalars['String']['output']>;
    field?: Maybe<Scalars['String']['output']>;
    mode?: Maybe<Scalars['String']['output']>;
  };

export type ProfileSuggestionResumeActionContext =
  ProfileSuggestionActionContext & {
    __typename?: 'ProfileSuggestionResumeActionContext';
    card?: Maybe<Scalars['String']['output']>;
    field?: Maybe<Scalars['String']['output']>;
    mode?: Maybe<Scalars['String']['output']>;
  };

export type ProfileSuggestionRightToWorkActionContext =
  ProfileSuggestionActionContext & {
    __typename?: 'ProfileSuggestionRightToWorkActionContext';
    card?: Maybe<Scalars['String']['output']>;
    field?: Maybe<Scalars['String']['output']>;
    mode?: Maybe<Scalars['String']['output']>;
  };

export type ProfileSuggestionSalaryActionContext =
  ProfileSuggestionActionContext & {
    __typename?: 'ProfileSuggestionSalaryActionContext';
    card?: Maybe<Scalars['String']['output']>;
    field?: Maybe<Scalars['String']['output']>;
    mode?: Maybe<Scalars['String']['output']>;
  };

export type ProfileSuggestionSkillsActionContext =
  ProfileSuggestionActionContext & {
    __typename?: 'ProfileSuggestionSkillsActionContext';
    card?: Maybe<Scalars['String']['output']>;
    field?: Maybe<Scalars['String']['output']>;
    mode?: Maybe<Scalars['String']['output']>;
  };

export type ProfileSuggestionWorkTypeActionContext =
  ProfileSuggestionActionContext & {
    __typename?: 'ProfileSuggestionWorkTypeActionContext';
    card?: Maybe<Scalars['String']['output']>;
    field?: Maybe<Scalars['String']['output']>;
    mode?: Maybe<Scalars['String']['output']>;
  };

export type ProfileVerifications = {
  __typename?: 'ProfileVerifications';
  nudge?: Maybe<VerificationsNudge2>;
  verifiable?: Maybe<Array<VerifiableCredentialCategory>>;
  verified?: Maybe<Array<VerifiedCredentialCategory>>;
};

export type ProfileVerificationsNudgeArgs = {
  platform: Platform;
  source: SourceExperience;
  testGroup?: InputMaybe<VerificationsTestVariant>;
};

export type ProfileVerificationsVerifiableArgs = {
  platform: Platform;
  source: SourceExperience;
};

export type ProfileVisibility = {
  __typename?: 'ProfileVisibility';
  /** Human readable string used when showing the candidate's profile visibility in a read-only context */
  description: Scalars['String']['output'];
  /** Visibility level of the candidate's profile */
  level: VisibilityLevel;
};

export type ProvisionAccountError = {
  message: Scalars['String']['output'];
};

export type ProvisionAccountErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type ProvisionAccountErrorAlreadyProvisioned = ProvisionAccountError & {
  __typename?: 'ProvisionAccountErrorAlreadyProvisioned';
  message: Scalars['String']['output'];
};

export type ProvisionAccountErrorAlreadyProvisionedMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type ProvisionAccountErrorAlreadyProvisionedDuplicateGuid =
  ProvisionAccountError & {
    __typename?: 'ProvisionAccountErrorAlreadyProvisionedDuplicateGuid';
    message: Scalars['String']['output'];
  };

export type ProvisionAccountErrorAlreadyProvisionedDuplicateGuidMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type ProvisionAccountFailure = {
  __typename?: 'ProvisionAccountFailure';
  error: ProvisionAccountError;
};

export type ProvisionAccountPayload =
  | ProvisionAccountFailure
  | ProvisionAccountSuccess;

export type ProvisionAccountSuccess = {
  __typename?: 'ProvisionAccountSuccess';
  id: Scalars['ID']['output'];
  isTokenRefreshRequired: Scalars['Boolean']['output'];
};

export type Qualification = {
  __typename?: 'Qualification';
  completed: Scalars['Boolean']['output'];
  completionDate?: Maybe<CompletionDate>;
  credential?: Maybe<BaseCredential>;
  /** This field returns a description of the completion for the qualification eg. "In progress", "Finished 2018" */
  formattedCompletion: Scalars['String']['output'];
  highlights?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  institute: OntologyStructuredData;
  isVerified: Scalars['Boolean']['output'];
  /** Required for JobsDB only */
  level2?: Maybe<Scalars['String']['output']>;
  name: OntologyStructuredData;
  status?: Maybe<QualificationStatus>;
  tracking?: Maybe<TrackingInfo>;
  verificationUrl?: Maybe<Scalars['String']['output']>;
};

export enum QualificationLevel {
  AdvancedDiploma = 'AdvancedDiploma',
  AssociateDegree = 'AssociateDegree',
  BachelorDegree = 'BachelorDegree',
  BachelorDegreeHonours = 'BachelorDegreeHonours',
  CertificateI = 'CertificateI',
  CertificateIi = 'CertificateII',
  CertificateIii = 'CertificateIII',
  CertificateIv = 'CertificateIV',
  CompletedHighSchool = 'CompletedHighSchool',
  CompletedYear9To11 = 'CompletedYear9To11',
  Diploma = 'Diploma',
  DoctoralDegree = 'DoctoralDegree',
  GraduateCertificate = 'GraduateCertificate',
  GraduateDiploma = 'GraduateDiploma',
  IosLongtailFallback = 'IosLongtailFallback',
  MastersDegree = 'MastersDegree',
}

export enum QualificationStatus {
  New = 'New',
}

export type Quality = {
  __typename?: 'Quality';
  displayLabel: Scalars['String']['output'];
  ontologyId: Scalars['ID']['output'];
};

export type QualityDisplayLabelArgs = {
  locale: Scalars['Locale']['input'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']['output']>;
  accountExists: AccountStatus;
  availabilityOptions: Array<AvailabilityOption>;
  banner?: Maybe<Banner>;
  bookmarkedSeekMaxModules: SeekMaxModuleBookmarkResults;
  categorySeekMaxModules?: Maybe<SeekMaxModuleConnectionResults>;
  categorySeekMaxThreads?: Maybe<SeekMaxThreadConnectionResults>;
  classifications: Array<ParentClassification>;
  companyNameSlug: CompanyNameSlug;
  companyProfile: CompanyProfile;
  companyProfileReviewUpvotes?: Maybe<ReviewUpvotes>;
  companyProfilesFeatureFlags: Array<FeatureFlag>;
  companyReviews?: Maybe<Reviews>;
  /** The details of a consent transaction for a given transactionId */
  consentTransactionDetails?: Maybe<ConsentTransactionDetails>;
  /**
   * A list of country codes filtered based on the `option` parameter. The `description`
   * of each list item will be returned in the language specified via the `languageCode`
   * parameter. *Note*: The default value for the `languageCode` parameter is `en`, *not*
   * `false` as it appears in the playground.
   */
  countryCallingCodeOptions: Array<CountryCallingCode>;
  defaultCurrency: DefaultCurrency;
  dummy?: Maybe<Scalars['String']['output']>;
  featureFlags?: Maybe<Array<FeatureFlagEvaluation>>;
  featuredSeekMaxCollection?: Maybe<SeekMaxCollection>;
  /** @deprecated Use featuredSeekMaxThreadsV2 instead */
  featuredSeekMaxThreads: Array<SeekMaxThread>;
  featuredSeekMaxThreadsV2?: Maybe<Array<Maybe<SeekMaxThread>>>;
  groupedCompanies?: Maybe<Array<Maybe<GroupedCompanies>>>;
  jobApplicationProcess?: Maybe<JobApplicationProcess>;
  jobDetails?: Maybe<JobDetails>;
  loggedOutRecommendedJobs?: Maybe<LoggedOutRecommendedGlobalJobs>;
  marqueeCompanies: Array<MarqueeCompanyProfile>;
  mkLocationSuggestions: Array<MkLocationSuggestion>;
  /** @deprecated Use moreSeekMaxThreadsV2 instead */
  moreSeekMaxThreads?: Maybe<SeekMaxThreadConnectionResults>;
  moreSeekMaxThreadsV2?: Maybe<SeekMaxThreadConnectionResults>;
  ontologyEducationProviders: Array<IOntologyEducationProvider>;
  ontologyOrganisations: Array<IOntologyOrganisation>;
  ontologyQualifications: Array<IOntologyQualification>;
  ontologyRoleTitlesSeniority: Array<IOntologyRoleTitleSeniority>;
  ontologySpecReqs: Array<OntologySpecReq>;
  ontologyTrackSelected?: Maybe<ITrackSelected>;
  /** @deprecated Use pinnedSeekMaxThreadsV2 instead */
  pinnedSeekMaxThreads: Array<SeekMaxThread>;
  pinnedSeekMaxThreadsV2?: Maybe<Array<Maybe<SeekMaxThread>>>;
  preferredClassificationOptions: Array<ClassificationOptions>;
  profileLocationSuggest: Array<ProfileSuggestLocationType>;
  profileLocationTrackSelected: ProfileLocationTrackSelectedResponse;
  recentlyAddedSeekMaxModules: Array<SeekMaxModule>;
  recommendedSeekMaxModules: Array<SeekMaxModule>;
  relatedSeekMaxModules: Array<SeekMaxModule>;
  resumePrivacyDisclaimers: ResumePrivacyDisclaimers;
  review?: Maybe<Review>;
  /** A list of right to work options for all countries we collect right to work information in */
  rightToWorkOptions: Array<RightToWorkCountryOptions>;
  roleFunctionOptions: Array<RoleFunctionOption>;
  salaryOptions: Array<SalaryOption>;
  searchFilterData: SearchFilterData;
  searchKeywordsSuggest: SearchKeywordSuggestions;
  searchLocationsSuggest: SearchLocationSuggestions;
  searchSalaryRanges?: Maybe<Array<SearchSalaryRange>>;
  /** @deprecated Use searchSeekMaxContentsV2 instead */
  searchSeekMaxContents: SeekMaxContentSearchResults;
  searchSeekMaxContentsV2?: Maybe<SeekMaxContentSearchResults>;
  /** @deprecated Use searchSeekMaxContentsV2 instead - https://myseek.atlassian.net/browse/CNC-3509 */
  searchSeekMaxModules: SeekMaxModuleSearchResults;
  /** @deprecated Use searchSeekMaxContentsV2 instead - https://myseek.atlassian.net/browse/CNC-3509 */
  searchSeekMaxThreads: SeekMaxThreadSearchResults;
  seekMaxCategoriesHome: Array<Array<SeekMaxCategory>>;
  seekMaxCategoriesThreadCreate: Array<SeekMaxCategory>;
  seekMaxCategoryGroup?: Maybe<ISeekMaxCategoryGroup>;
  seekMaxCategoryGroups?: Maybe<SeekMaxCategoryGroupConnectionResults>;
  seekMaxCollections: Array<SeekMaxCollection>;
  seekMaxComments: SeekMaxCommentResults;
  seekMaxLandingInfo: SeekMaxLandingInfo;
  seekMaxModule?: Maybe<SeekMaxModule>;
  /** @deprecated Use categorySeekMaxModules instead - https://myseek.atlassian.net/browse/CNC-4488 */
  seekMaxModules: SeekMaxModuleResults;
  seekMaxModulesInProgress: SeekMaxModulesInProgressResults;
  /** @deprecated Use seekMaxMyThreadsV2 instead */
  seekMaxMyThreads: SeekMaxThreadResults;
  seekMaxMyThreadsV2?: Maybe<SeekMaxThreadConnectionResults>;
  seekMaxMyUnreadThreadsCount?: Maybe<SeekMaxUnreadThreadsCountResponse>;
  seekMaxProfile: SeekMaxProfile;
  seekMaxReplies: SeekMaxReplyResults;
  seekMaxThread?: Maybe<SeekMaxThread>;
  seekMaxThreadAttachmentStatus?: Maybe<ISeekMaxAttachmentStatus>;
  /** @deprecated Use categorySeekMaxThreads instead - https://myseek.atlassian.net/browse/CNC-4488 */
  seekMaxThreads: SeekMaxThreadResults;
  /** Displays data that a candidate has chosen to share via a public url */
  shareableProfile: ShareableProfile;
  supportedCountries: Array<CountryInformation>;
  trendingSeekMaxModules: Array<SeekMaxModule>;
  /** The version of the schema */
  version: Scalars['String']['output'];
  viewer?: Maybe<Candidate>;
  workTypeOptions: Array<WorkTypeTaxonomyOption>;
};

export type QueryAccountExistsArgs = {
  email: Scalars['String']['input'];
};

export type QueryAvailabilityOptionsArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
};

export type QueryBannerArgs = {
  candidateId?: InputMaybe<Scalars['String']['input']>;
  classification?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  keywords?: InputMaybe<Scalars['String']['input']>;
  locale?: Scalars['Locale']['input'];
  loggedIn?: InputMaybe<Scalars['String']['input']>;
  placement: BannerPlacement;
  roleId?: InputMaybe<Scalars['String']['input']>;
  salaryRange?: InputMaybe<Scalars['String']['input']>;
  salaryType?: InputMaybe<Scalars['String']['input']>;
  subclassification?: InputMaybe<Scalars['String']['input']>;
  visitorId?: InputMaybe<Scalars['UUID']['input']>;
  where?: InputMaybe<Scalars['String']['input']>;
  workType?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type QueryBookmarkedSeekMaxModulesArgs = {
  countryCode: Scalars['CountryCodeIso2']['input'];
  pageSize: Scalars['Int']['input'];
  pageToken?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCategorySeekMaxModulesArgs = {
  categoryCode: Scalars['String']['input'];
  cursor: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  zone: Scalars['Zone']['input'];
};

export type QueryCategorySeekMaxThreadsArgs = {
  categoryId: Scalars['ID']['input'];
  cursor: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  zone: Scalars['Zone']['input'];
};

export type QueryClassificationsArgs = {
  zone: Scalars['Zone']['input'];
};

export type QueryCompanyNameSlugArgs = {
  companySlug: Scalars['String']['input'];
  isLegacyUrl?: Scalars['Boolean']['input'];
  zone?: Scalars['Zone']['input'];
};

export type QueryCompanyProfileArgs = {
  id: Scalars['ID']['input'];
  useCachedProfile?: Scalars['Boolean']['input'];
  zone: Scalars['Zone']['input'];
};

export type QueryCompanyProfileReviewUpvotesArgs = {
  id: Scalars['ID']['input'];
  zone: Scalars['Zone']['input'];
};

export type QueryCompanyProfilesFeatureFlagsArgs = {
  input?: InputMaybe<FeatureFlagInput>;
};

export type QueryCompanyReviewsArgs = {
  companyId: Scalars['ID']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  zone: Scalars['Zone']['input'];
};

export type QueryConsentTransactionDetailsArgs = {
  input: ConsentTransactionDetailsInput;
};

export type QueryCountryCallingCodeOptionsArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  option?: InputMaybe<Option>;
};

export type QueryDefaultCurrencyArgs = {
  zone: Scalars['Zone']['input'];
};

export type QueryFeatureFlagsArgs = {
  applicationContext?: InputMaybe<ApplicationContextInput>;
  deviceContext?: InputMaybe<DeviceContextInput>;
  experienceContext?: InputMaybe<ExperienceContextInput>;
  flags: Array<Scalars['String']['input']>;
  visitorContext?: InputMaybe<VisitorContextInput>;
};

export type QueryFeaturedSeekMaxCollectionArgs = {
  locale: Scalars['Locale']['input'];
  maxCollectionSize: Scalars['Int']['input'];
};

export type QueryFeaturedSeekMaxThreadsArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
  maxSize?: Scalars['Int']['input'];
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type QueryFeaturedSeekMaxThreadsV2Args = {
  maxSize: Scalars['PositiveInt']['input'];
  zone: Scalars['Zone']['input'];
};

export type QueryGroupedCompaniesArgs = {
  excludedCompanyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  groupBy: CompanyGroupByInput;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  randomSeed?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Company_Sort_By>;
  zone: Scalars['Zone']['input'];
};

export type QueryJobApplicationProcessArgs = {
  jobId: Scalars['ID']['input'];
};

export type QueryJobDetailsArgs = {
  id: Scalars['ID']['input'];
  tracking?: InputMaybe<JobDetailsTrackingInput>;
};

export type QueryLoggedOutRecommendedJobsArgs = {
  cluster?: InputMaybe<Scalars['SmarterSearchCluster']['input']>;
  jobId: Scalars['ID']['input'];
  limit?: Scalars['Int']['input'];
  platform: Platform;
  source: AttributionSource;
  visitorId: Scalars['UUID']['input'];
  zone: Scalars['Zone']['input'];
};

export type QueryMarqueeCompaniesArgs = {
  hideJobs?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  zone?: Scalars['Zone']['input'];
};

export type QueryMkLocationSuggestionsArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
  kind: Array<Scalars['String']['input']>;
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  searchText: Scalars['String']['input'];
  session: Scalars['String']['input'];
  zone: Scalars['Zone']['input'];
};

export type QueryMoreSeekMaxThreadsArgs = {
  countryCode?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  cursor: Scalars['Int']['input'];
  pageSize: Scalars['PositiveInt']['input'];
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type QueryMoreSeekMaxThreadsV2Args = {
  cursor: Scalars['Int']['input'];
  pageSize: Scalars['PositiveInt']['input'];
  zone: Scalars['Zone']['input'];
};

export type QueryOntologyEducationProvidersArgs = {
  count: Scalars['Int']['input'];
  countryCode?: InputMaybe<CountryCode>;
  countryCode2?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  query: Scalars['String']['input'];
  session: Scalars['String']['input'];
};

export type QueryOntologyOrganisationsArgs = {
  count: Scalars['Int']['input'];
  countryCode?: InputMaybe<CountryCode>;
  countryCode2?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  query: Scalars['String']['input'];
  session: Scalars['String']['input'];
};

export type QueryOntologyQualificationsArgs = {
  count: Scalars['Int']['input'];
  countryCode?: InputMaybe<CountryCode>;
  countryCode2?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  query: Scalars['String']['input'];
  session: Scalars['String']['input'];
};

export type QueryOntologyRoleTitlesSeniorityArgs = {
  count: Scalars['Int']['input'];
  countryCode?: InputMaybe<CountryCode>;
  countryCode2?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  query: Scalars['String']['input'];
  session: Scalars['String']['input'];
};

export type QueryOntologySpecReqsArgs = {
  count: Scalars['Int']['input'];
  countryCode?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  profileField?: InputMaybe<SpecReqProfileField>;
  query: Scalars['String']['input'];
  session: Scalars['String']['input'];
};

export type QueryOntologyTrackSelectedArgs = {
  id: Scalars['String']['input'];
  index: Scalars['Int']['input'];
  ontologyType: OntologyType;
  session: Scalars['String']['input'];
};

export type QueryPinnedSeekMaxThreadsArgs = {
  maxSize: Scalars['Int']['input'];
  zone: Scalars['Zone']['input'];
};

export type QueryPinnedSeekMaxThreadsV2Args = {
  maxSize: Scalars['PositiveInt']['input'];
  zone: Scalars['Zone']['input'];
};

export type QueryPreferredClassificationOptionsArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
  zone: Scalars['Zone']['input'];
};

export type QueryProfileLocationSuggestArgs = {
  count: Scalars['Int']['input'];
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  locationType: ProfileLocationType;
  query: Scalars['String']['input'];
  session: Scalars['String']['input'];
  zone: Scalars['Zone']['input'];
};

export type QueryProfileLocationTrackSelectedArgs = {
  id: Scalars['Int']['input'];
  session: Scalars['String']['input'];
};

export type QueryRecentlyAddedSeekMaxModulesArgs = {
  locale: Scalars['Locale']['input'];
};

export type QueryRecommendedSeekMaxModulesArgs = {
  countryCode: Scalars['CountryCodeIso2']['input'];
  model?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRelatedSeekMaxModulesArgs = {
  countryCode: Scalars['CountryCodeIso2']['input'];
  moduleId: Scalars['ID']['input'];
};

export type QueryResumePrivacyDisclaimersArgs = {
  isUnifiedSite?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode: Scalars['LanguageCodeIso']['input'];
  zone: Scalars['Zone']['input'];
};

export type QueryReviewArgs = {
  reviewId: Scalars['ID']['input'];
  zone: Scalars['Zone']['input'];
};

export type QueryRightToWorkOptionsArgs = {
  filter?: InputMaybe<RightToWorkFilterInput>;
  isUnified?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  locale?: InputMaybe<Scalars['Locale']['input']>;
  platform?: InputMaybe<Platform>;
  source?: InputMaybe<SourceExperience>;
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type QueryRoleFunctionOptionsArgs = {
  countryCode: Scalars['CountryCodeIso2']['input'];
  languageCode: LanguageCode;
};

export type QuerySearchKeywordsSuggestArgs = {
  count: Scalars['Int']['input'];
  countryCode2?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  query: Scalars['String']['input'];
  visitorId?: InputMaybe<Scalars['UUID']['input']>;
};

export type QuerySearchLocationsSuggestArgs = {
  count: Scalars['Int']['input'];
  country?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  countryCode?: InputMaybe<CountryCode>;
  isRemoteEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUnified?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['Locale']['input']>;
  query: Scalars['String']['input'];
  recentLocation?: InputMaybe<Scalars['String']['input']>;
  visitorId?: InputMaybe<Scalars['UUID']['input']>;
};

export type QuerySearchSalaryRangesArgs = {
  zone: Scalars['Zone']['input'];
};

export type QuerySearchSeekMaxContentsArgs = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['CountryCodeIso2']['input'];
  cursor: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySearchSeekMaxContentsV2Args = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  cursor: Scalars['Int']['input'];
  pageSize: Scalars['PositiveInt']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  zone: Scalars['Zone']['input'];
};

export type QuerySearchSeekMaxModulesArgs = {
  cursor?: Scalars['Int']['input'];
  locale: Scalars['Locale']['input'];
  pageSize: Scalars['Int']['input'];
  query: Scalars['String']['input'];
};

export type QuerySearchSeekMaxThreadsArgs = {
  cursor: Scalars['Int']['input'];
  locale: Scalars['Locale']['input'];
  pageSize: Scalars['Int']['input'];
  query: Scalars['String']['input'];
};

export type QuerySeekMaxCategoryGroupArgs = {
  groupId: Scalars['UUID']['input'];
  zone: Scalars['Zone']['input'];
};

export type QuerySeekMaxCategoryGroupsArgs = {
  cursor: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  zone: Scalars['Zone']['input'];
};

export type QuerySeekMaxCollectionsArgs = {
  locale: Scalars['Locale']['input'];
  maxCollectionSize: Scalars['Int']['input'];
};

export type QuerySeekMaxCommentsArgs = {
  cursor?: Scalars['Int']['input'];
  locale?: InputMaybe<Scalars['Locale']['input']>;
  pageSize: Scalars['Int']['input'];
  threadId: Scalars['ID']['input'];
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type QuerySeekMaxLandingInfoArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type QuerySeekMaxModuleArgs = {
  countryCode: Scalars['CountryCodeIso2']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySeekMaxModulesArgs = {
  category: SeekMaxCategory;
  cursor?: Scalars['Int']['input'];
  locale: Scalars['Locale']['input'];
  pageSize: Scalars['Int']['input'];
};

export type QuerySeekMaxModulesInProgressArgs = {
  locale: Scalars['Locale']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  pageToken?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySeekMaxMyThreadsArgs = {
  countryCode?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  cursor?: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type QuerySeekMaxMyThreadsV2Args = {
  cursor: Scalars['Int']['input'];
  pageSize: Scalars['PositiveInt']['input'];
  zone: Scalars['Zone']['input'];
};

export type QuerySeekMaxMyUnreadThreadsCountArgs = {
  zone: Scalars['Zone']['input'];
};

export type QuerySeekMaxRepliesArgs = {
  commentId: Scalars['ID']['input'];
  countryCode?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  cursor?: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type QuerySeekMaxThreadArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['Locale']['input']>;
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type QuerySeekMaxThreadAttachmentStatusArgs = {
  countryCode?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  id: Scalars['ID']['input'];
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type QuerySeekMaxThreadsArgs = {
  category: SeekMaxCategory;
  cursor?: Scalars['Int']['input'];
  locale?: InputMaybe<Scalars['Locale']['input']>;
  pageSize: Scalars['Int']['input'];
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type QueryShareableProfileArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
  profileSlug: Scalars['String']['input'];
  zone: Scalars['Zone']['input'];
};

export type QuerySupportedCountriesArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
};

export type QueryTrendingSeekMaxModulesArgs = {
  locale: Scalars['Locale']['input'];
  maxSize: Scalars['Int']['input'];
};

export type QueryWorkTypeOptionsArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
};

export type Question = {
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type QuestionOption = {
  __typename?: 'QuestionOption';
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  uri?: Maybe<Scalars['String']['output']>;
};

export type Questionnaire = {
  __typename?: 'Questionnaire';
  questions: Array<Question>;
};

export type QuestionnaireAnswerInput = {
  answers: Array<AnswerForQuestionInput>;
  questionId: Scalars['String']['input'];
};

export type RatingItem = {
  __typename?: 'RatingItem';
  description: Scalars['String']['output'];
  /** @deprecated Use value instead */
  rating: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type RatingItemDescriptionArgs = {
  locale?: Scalars['Locale']['input'];
};

export type Recommendations = {
  __typename?: 'Recommendations';
  recommendedGlobalJobs?: Maybe<RecommendedGlobalJobs>;
  similarJobsGlobal?: Maybe<RecommendedGlobalJobs>;
};

export type RecommendationsRecommendedGlobalJobsArgs = {
  cluster?: InputMaybe<Scalars['SmarterSearchCluster']['input']>;
  includeSavedJobs?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  platform: Platform;
  source: AttributionSource;
  visitorId: Scalars['UUID']['input'];
  zone: Scalars['Zone']['input'];
};

export type RecommendationsSimilarJobsGlobalArgs = {
  cluster?: InputMaybe<Scalars['SmarterSearchCluster']['input']>;
  includeSavedJobs?: InputMaybe<Scalars['Boolean']['input']>;
  jobId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  platform: Platform;
  source: AttributionSource;
  visitorId: Scalars['UUID']['input'];
  zone: Scalars['Zone']['input'];
};

export type RecommendedGlobalJob = {
  __typename?: 'RecommendedGlobalJob';
  id: Scalars['ID']['output'];
  isNew: Scalars['Boolean']['output'];
  isSaved: Scalars['Boolean']['output'];
  job: Job;
  solMetadata: Scalars['JSON']['output'];
  tags?: Maybe<Array<JobTag>>;
};

export type RecommendedGlobalJobs = {
  __typename?: 'RecommendedGlobalJobs';
  globalJobs: Array<RecommendedGlobalJob>;
  solMetadata?: Maybe<Scalars['JSON']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type RejectConsentError = {
  message: Scalars['String']['output'];
};

export type RejectConsentErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type RejectConsentFailure = {
  __typename?: 'RejectConsentFailure';
  errors: Array<RejectConsentError>;
};

export type RejectConsentInput = {
  transactionId: Scalars['UUID']['input'];
};

export type RejectConsentPayload = RejectConsentFailure | RejectConsentSuccess;

export type RejectConsentSuccess = {
  __typename?: 'RejectConsentSuccess';
  redirectUri: Scalars['String']['output'];
};

export type RejectConsentTransactionInvalidError = RejectConsentError & {
  __typename?: 'RejectConsentTransactionInvalidError';
  message: Scalars['String']['output'];
};

export type RejectConsentTransactionInvalidErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type RejectRoleInput = {
  achievements?: InputMaybe<Scalars['String']['input']>;
  company: OntologyStructuredDataInput;
  from: MonthYearInput;
  id: Scalars['UUID']['input'];
  seniority?: InputMaybe<OntologyStructuredDataInput>;
  title: OntologyStructuredDataInput;
  to?: InputMaybe<MonthYearInput>;
  tracking?: InputMaybe<TrackingInfoInput>;
};

export type RejectRolePayload = {
  __typename?: 'RejectRolePayload';
  /** @deprecated Use viewer.roles */
  id: Scalars['UUID']['output'];
  viewer?: Maybe<Candidate>;
};

export type RejectUnconfirmedDataInput = {
  dataType: UnconfirmedDataType;
  id: Scalars['UUID']['input'];
  tracking?: InputMaybe<TrackingInfoInput>;
};

export type RejectUnconfirmedDataPayload = {
  __typename?: 'RejectUnconfirmedDataPayload';
  /** @deprecated Use viewer.qualifications or viewer.roles */
  ref?: Maybe<Scalars['UUID']['output']>;
  viewer?: Maybe<Candidate>;
};

export enum ReportJobCountryCode {
  /** @deprecated Use CountryCodeIso2 */
  Au = 'AU',
  /** @deprecated Use CountryCodeIso2 */
  Nz = 'NZ',
}

export type ReportJobInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  /** @deprecated Use country */
  countryCode?: InputMaybe<ReportJobCountryCode>;
  email: Scalars['Email']['input'];
  jobId: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
};

export type ReportJobPayload = {
  __typename?: 'ReportJobPayload';
  success: Scalars['Boolean']['output'];
};

export type ReportProfileError = {
  message: Scalars['String']['output'];
};

export type ReportProfileErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type ReportProfileFailure = {
  __typename?: 'ReportProfileFailure';
  error: ReportProfileError;
};

export type ReportProfileInput = {
  comment: Scalars['String']['input'];
  email: Scalars['Email']['input'];
  profileSlug: Scalars['String']['input'];
  reason: Scalars['String']['input'];
  zone: Scalars['Zone']['input'];
};

export type ReportProfilePayload = ReportProfileFailure | ReportProfileSuccess;

export type ReportProfileSuccess = {
  __typename?: 'ReportProfileSuccess';
  ref: Scalars['UUID']['output'];
};

export type ReportSeekMaxCommentAlreadyReportedError =
  ReportSeekMaxCommentError & {
    __typename?: 'ReportSeekMaxCommentAlreadyReportedError';
    message: Scalars['String']['output'];
    title: Scalars['String']['output'];
  };

export type ReportSeekMaxCommentAlreadyReportedErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxCommentAlreadyReportedErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxCommentError = {
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ReportSeekMaxCommentErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxCommentErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxCommentFailure = {
  __typename?: 'ReportSeekMaxCommentFailure';
  errors: Array<ReportSeekMaxCommentError>;
};

export type ReportSeekMaxCommentInput = {
  id: Scalars['ID']['input'];
  locale: Scalars['Locale']['input'];
  reason: Scalars['String']['input'];
};

export type ReportSeekMaxCommentPayload =
  | ReportSeekMaxCommentFailure
  | ReportSeekMaxCommentSuccess;

export type ReportSeekMaxCommentPrivilegeError = ReportSeekMaxCommentError & {
  __typename?: 'ReportSeekMaxCommentPrivilegeError';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ReportSeekMaxCommentPrivilegeErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxCommentPrivilegeErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxCommentSuccess = {
  __typename?: 'ReportSeekMaxCommentSuccess';
  id: Scalars['ID']['output'];
};

export type ReportSeekMaxCommentUserIsBannedError =
  ReportSeekMaxCommentError & {
    __typename?: 'ReportSeekMaxCommentUserIsBannedError';
    message: Scalars['String']['output'];
    title: Scalars['String']['output'];
  };

export type ReportSeekMaxCommentUserIsBannedErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxCommentUserIsBannedErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxReplyAlreadyReportedError = ReportSeekMaxReplyError & {
  __typename?: 'ReportSeekMaxReplyAlreadyReportedError';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ReportSeekMaxReplyAlreadyReportedErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxReplyAlreadyReportedErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxReplyError = {
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ReportSeekMaxReplyErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxReplyErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxReplyFailure = {
  __typename?: 'ReportSeekMaxReplyFailure';
  errors: Array<ReportSeekMaxReplyError>;
};

export type ReportSeekMaxReplyInput = {
  countryCode: Scalars['CountryCodeIso2']['input'];
  id: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export type ReportSeekMaxReplyPayload =
  | ReportSeekMaxReplyFailure
  | ReportSeekMaxReplySuccess;

export type ReportSeekMaxReplyPrivilegeError = ReportSeekMaxReplyError & {
  __typename?: 'ReportSeekMaxReplyPrivilegeError';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ReportSeekMaxReplyPrivilegeErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxReplyPrivilegeErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxReplySuccess = {
  __typename?: 'ReportSeekMaxReplySuccess';
  id: Scalars['ID']['output'];
};

export type ReportSeekMaxReplyUserIsBannedError = ReportSeekMaxReplyError & {
  __typename?: 'ReportSeekMaxReplyUserIsBannedError';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ReportSeekMaxReplyUserIsBannedErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxReplyUserIsBannedErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxThreadAlreadyReportedError =
  ReportSeekMaxThreadError & {
    __typename?: 'ReportSeekMaxThreadAlreadyReportedError';
    message: Scalars['String']['output'];
    title: Scalars['String']['output'];
  };

export type ReportSeekMaxThreadAlreadyReportedErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxThreadAlreadyReportedErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxThreadError = {
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ReportSeekMaxThreadErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxThreadErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxThreadFailure = {
  __typename?: 'ReportSeekMaxThreadFailure';
  errors: Array<ReportSeekMaxThreadError>;
};

export type ReportSeekMaxThreadInput = {
  id: Scalars['ID']['input'];
  locale: Scalars['Locale']['input'];
  reason: Scalars['String']['input'];
};

export type ReportSeekMaxThreadPayload =
  | ReportSeekMaxThreadFailure
  | ReportSeekMaxThreadSuccess;

export type ReportSeekMaxThreadPrivilegeError = ReportSeekMaxThreadError & {
  __typename?: 'ReportSeekMaxThreadPrivilegeError';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ReportSeekMaxThreadPrivilegeErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxThreadPrivilegeErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxThreadSuccess = {
  __typename?: 'ReportSeekMaxThreadSuccess';
  id: Scalars['ID']['output'];
};

export type ReportSeekMaxThreadUserIsBannedError = ReportSeekMaxThreadError & {
  __typename?: 'ReportSeekMaxThreadUserIsBannedError';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ReportSeekMaxThreadUserIsBannedErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type ReportSeekMaxThreadUserIsBannedErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type RequestResumeParsingContext = {
  id: Scalars['UUID']['input'];
};

export type RequestResumeParsingInput = {
  id: Scalars['UUID']['input'];
  parsingContext: RequestResumeParsingContext;
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type ResubscribeApacSavedSearchByCodeError = {
  __typename?: 'ResubscribeApacSavedSearchByCodeError';
  message: Scalars['String']['output'];
};

export type ResubscribeApacSavedSearchByCodeErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type ResubscribeApacSavedSearchByCodeFailure = {
  __typename?: 'ResubscribeApacSavedSearchByCodeFailure';
  error: ResubscribeApacSavedSearchByCodeError;
};

export type ResubscribeApacSavedSearchByCodeInput = {
  code: Scalars['String']['input'];
};

export type ResubscribeApacSavedSearchByCodePayload =
  | ResubscribeApacSavedSearchByCodeFailure
  | ResubscribeApacSavedSearchByCodeSuccess;

export type ResubscribeApacSavedSearchByCodeSuccess = {
  __typename?: 'ResubscribeApacSavedSearchByCodeSuccess';
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type Resume = {
  __typename?: 'Resume';
  createdDateRelative: Scalars['String']['output'];
  createdDateUtc: Scalars['DateTime']['output'];
  fileMetadata: FileMetadata;
  id: Scalars['UUID']['output'];
  isDefault: Scalars['Boolean']['output'];
  origin: ResumeOrigin;
};

export type ResumeAppliedJobDocument = {
  __typename?: 'ResumeAppliedJobDocument';
  attachmentMetadata: AttachmentMetadata;
  blobUrl: Scalars['String']['output'];
  downloadLink?: Maybe<Scalars['String']['output']>;
};

export type ResumeInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  idFromResumeResource?: InputMaybe<Scalars['Int']['input']>;
  uri: Scalars['String']['input'];
};

export type ResumeOrigin = {
  __typename?: 'ResumeOrigin';
  type?: Maybe<ResumeOriginType>;
};

export enum ResumeOriginType {
  Generated = 'Generated',
  Uploaded = 'Uploaded',
}

export type ResumePrivacyDisclaimer = {
  __typename?: 'ResumePrivacyDisclaimer';
  isEmphasized: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export type ResumePrivacyDisclaimers = {
  __typename?: 'ResumePrivacyDisclaimers';
  longParagraphs: Array<ResumePrivacyDisclaimer>;
  shortParagraphs: Array<ResumePrivacyDisclaimer>;
};

export type Review = {
  __typename?: 'Review';
  companyId: Scalars['String']['output'];
  cons: Scalars['String']['output'];
  createdAt: SeekDateTime;
  employmentStatus?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  overallRating: Scalars['Float']['output'];
  pros: Scalars['String']['output'];
  ratings: Array<RatingItem>;
  recommended: ReviewRecommended;
  reply?: Maybe<CompanyReviewReply>;
  salarySummary: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  upvotes?: Maybe<Upvotes>;
  workLocation?: Maybe<Scalars['String']['output']>;
};

export type ReviewEmploymentStatusArgs = {
  locale?: Scalars['Locale']['input'];
};

export type ReviewSalarySummaryArgs = {
  locale?: Scalars['Locale']['input'];
};

export type ReviewRecommended = {
  __typename?: 'ReviewRecommended';
  description: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
};

export type ReviewRecommendedDescriptionArgs = {
  locale?: Scalars['Locale']['input'];
};

export type ReviewUpvotes = {
  __typename?: 'ReviewUpvotes';
  upvotes: Array<Scalars['String']['output']>;
};

export type Reviews = {
  __typename?: 'Reviews';
  data: Array<Review>;
  paging: ReviewsPaging;
};

export type ReviewsPaging = {
  __typename?: 'ReviewsPaging';
  page: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type ReviewsSummary = {
  __typename?: 'ReviewsSummary';
  communityGuideline: CommunityGuideline;
  employeeRecommendation: EmployeeRecommendation;
  overallRating: OverallRating;
  ratings: Array<RatingItem>;
  salaryRating: SalaryRating;
};

export type RightToWork = {
  __typename?: 'RightToWork';
  /** Information about the country associated with the right to work */
  country: CountryInformation;
  credential?: Maybe<RightToWorkCredential>;
  /** Unique ID that identifies a specific right to work */
  id: Scalars['UUID']['output'];
  /** Will be true if it doesn't have ontology data */
  isDeprecated?: Maybe<Scalars['Boolean']['output']>;
  /** Will be `null` if the user has not selected a right to work option. */
  type2?: Maybe<RightToWorkType2>;
  /** Will only hold a value if the verification URL should be shown, will be `null` otherwise. */
  verificationUrl?: Maybe<Scalars['String']['output']>;
};

export type RightToWorkCountryOptions = {
  __typename?: 'RightToWorkCountryOptions';
  /** Information about the country associated with the right to work options */
  country: CountryInformation;
  /** A array of SuggestionHighlight which tells you the text index 'start' and 'end' to highlight. This supports multi highlight on a single string of text */
  highlights?: Maybe<Array<SuggestionHighlight>>;
  /** A list of right to work options for the associated country code */
  options: Array<RightToWorkOption>;
};

export type RightToWorkCredential = {
  __typename?: 'RightToWorkCredential';
  expiryFormatted: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  manageVerificationUrl: Scalars['String']['output'];
  status?: Maybe<CredentialStatus>;
  verification: CredentialVerification;
  visa?: Maybe<RightToWorkVisa>;
};

export type RightToWorkFilterInput = {
  /** A partial country name to be searched */
  countryNameQuery?: InputMaybe<Scalars['String']['input']>;
};

export type RightToWorkOption = {
  __typename?: 'RightToWorkOption';
  /** Human readable string used when the user is selecting a right to work option. E.g. `Yes`. */
  description: Scalars['String']['output'];
  /** Human readable string used when showing a right to work option in a read-only context. E.g. `Yes, I have the right to work in Australia`. */
  formattedDescription: Scalars['String']['output'];
  /** Unique ID used to identify a specific right to work option */
  id: Scalars['ID']['output'];
  /** Certsy verification URL for this right to work option. Will be `null` if selecting this option indicates that you do not have the right to work */
  verificationUrl?: Maybe<Scalars['String']['output']>;
};

export type RightToWorkType2 = {
  __typename?: 'RightToWorkType2';
  /** Human readable string used when the user is selecting a right to work option. E.g. `Yes`. */
  description: Scalars['String']['output'];
  /** Human readable string used when showing a right to work option in a read-only context. E.g. `Yes, I have the right to work in Australia`. */
  formattedDescription: Scalars['String']['output'];
  /** Unique ID associated with a  `RightToWorkOption` */
  id: Scalars['String']['output'];
};

export type RightToWorkType2Input = {
  /** ID corresponding to a `RightToWorkOption` */
  id: Scalars['ID']['input'];
};

export type RightToWorkVisa = {
  __typename?: 'RightToWorkVisa';
  detailUrl?: Maybe<Scalars['String']['output']>;
  holderType?: Maybe<Scalars['String']['output']>;
  subclass?: Maybe<Scalars['String']['output']>;
};

export type RightsToWorkAlert = {
  __typename?: 'RightsToWorkAlert';
  alert?: Maybe<AlertType>;
};

export type Role = {
  __typename?: 'Role';
  achievements?: Maybe<Scalars['String']['output']>;
  company: OntologyStructuredData;
  from: MonthYear;
  /** Required for JobsDB only */
  function?: Maybe<RoleFunction>;
  id: Scalars['UUID']['output'];
  /** Required for JobsDB only */
  industry?: Maybe<RoleIndustry>;
  seniority?: Maybe<OntologyStructuredData>;
  title: OntologyStructuredData;
  to?: Maybe<MonthYear>;
  tracking?: Maybe<TrackingInfo>;
};

export type RoleFunction = {
  __typename?: 'RoleFunction';
  id: Scalars['ID']['output'];
  subFunction: RoleSubFunction;
};

export type RoleFunctionInput = {
  id: Scalars['ID']['input'];
  subFunction: RoleSubFunctionInput;
};

export type RoleFunctionOption = {
  __typename?: 'RoleFunctionOption';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  roleSubFunctions: Array<RoleSubFunctionOption>;
};

export type RoleIndustry = {
  __typename?: 'RoleIndustry';
  id: Scalars['ID']['output'];
};

export type RoleIndustryInput = {
  id: Scalars['ID']['input'];
};

export type RoleSubFunction = {
  __typename?: 'RoleSubFunction';
  id: Scalars['ID']['output'];
};

export type RoleSubFunctionInput = {
  id: Scalars['ID']['input'];
};

export type RoleSubFunctionOption = {
  __typename?: 'RoleSubFunctionOption';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type SalaryFrequency = {
  __typename?: 'SalaryFrequency';
  isDefault: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type SalaryFrequencyLabelArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
};

export type SalaryOption = {
  __typename?: 'SalaryOption';
  annually: Array<SalaryRangeOption>;
  country: Scalars['String']['output'];
  hourly: Array<SalaryRangeOption>;
};

export type SalaryPreference2 = {
  __typename?: 'SalaryPreference2';
  /** Candidate's preferred minimum salary amount for a specific country and salary type */
  amount?: Maybe<Scalars['SafeInt']['output']>;
  /** Information about the country associated with the salary preference */
  country: CountryInformation;
  /** Currency associated with the minimum salary amount */
  currencyCode: Scalars['CurrencyCodeIso']['output'];
  /**
   * Formatted string to be shown to the user in read-mode. Formatted as:
   * {currency code} {amount} {salary type}. Will be `null` if the
   * preference has no `amount` value.
   */
  description?: Maybe<Scalars['String']['output']>;
  /** Unique ID that identifies a specific salary preference */
  id: Scalars['UUID']['output'];
  /** Salary type corresponding to the minimum salary amount */
  salaryType: SalaryType2;
};

export type SalaryPreference2Input = {
  /** Candidate's preferred minimum salary amount for a specific country and salary type */
  amount?: InputMaybe<Scalars['SafeInt']['input']>;
  /** Country associated with the minimum salary amount */
  countryCode: Scalars['CountryCodeIso2']['input'];
  /** Unique ID that identifies a specific salary preference */
  id: Scalars['UUID']['input'];
  /** Salary type corresponding to the minimum salary amount */
  salaryType: SalaryType2;
};

export type SalaryRangeOption = {
  __typename?: 'SalaryRangeOption';
  label: Scalars['String']['output'];
  value: Scalars['SafeInt']['output'];
};

export type SalaryRating = {
  __typename?: 'SalaryRating';
  description: Scalars['String']['output'];
  rating: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type SalaryRatingDescriptionArgs = {
  locale?: Scalars['Locale']['input'];
};

export enum SalaryType2 {
  Annual = 'Annual',
  Hourly = 'Hourly',
  Monthly = 'Monthly',
}

export type SaveSeekMaxKeywordSearchError = {
  message: Scalars['String']['output'];
};

export type SaveSeekMaxKeywordSearchErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type SaveSeekMaxKeywordSearchFailure = {
  __typename?: 'SaveSeekMaxKeywordSearchFailure';
  errors: Array<SaveSeekMaxKeywordSearchError>;
};

export type SaveSeekMaxKeywordSearchInput = {
  searchedKeyword: Scalars['String']['input'];
};

export type SaveSeekMaxKeywordSearchPayload =
  | SaveSeekMaxKeywordSearchFailure
  | SaveSeekMaxKeywordSearchSuccess;

export type SaveSeekMaxKeywordSearchSuccess = {
  __typename?: 'SaveSeekMaxKeywordSearchSuccess';
  searchedKeyword: Scalars['String']['output'];
};

export type SavedJob = {
  __typename?: 'SavedJob';
  createdAt: SeekDateTime;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isExternal: Scalars['Boolean']['output'];
  job: Job;
  notes?: Maybe<Scalars['String']['output']>;
  zone?: Maybe<Scalars['Zone']['output']>;
};

export type SavedJobConnection = {
  __typename?: 'SavedJobConnection';
  edges: Array<SavedJobEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type SavedJobEdge = {
  __typename?: 'SavedJobEdge';
  cursor: Scalars['String']['output'];
  node: SavedJob;
};

export type SavedJobEventCaptureDataInput = {
  channel: Scalars['String']['input'];
  eventCaptureSessionId: Scalars['String']['input'];
  serpTracking?: InputMaybe<Scalars['String']['input']>;
  view: Scalars['String']['input'];
};

export type SavedSearchApacQuery = {
  __typename?: 'SavedSearchApacQuery';
  parameters: Array<SavedSearchParameters>;
  searchQueryString?: Maybe<Scalars['JobSearchQueryString']['output']>;
};

export type SavedSearchParameters = {
  __typename?: 'SavedSearchParameters';
  type: Scalars['String']['output'];
  value: Array<Scalars['String']['output']>;
};

export type SearchAppliedJobPartial = {
  __typename?: 'SearchAppliedJobPartial';
  jobId: Scalars['String']['output'];
};

export type SearchFilterData = {
  __typename?: 'SearchFilterData';
  workArrangementOptions: Array<WorkArrangementOption>;
};

export type SearchKeywordSuggestions = {
  __typename?: 'SearchKeywordSuggestions';
  metadata?: Maybe<SearchSuggestionsMetadata>;
  suggestions: Array<KeywordSuggestionType>;
};

export type SearchLocationSuggestions = {
  __typename?: 'SearchLocationSuggestions';
  suggestions: Array<LocationSuggestionType>;
};

export type SearchPageInfo = {
  __typename?: 'SearchPageInfo';
  endCursor?: Maybe<Scalars['Int']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPrevPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['Int']['output']>;
};

export type SearchSalaryRange = {
  __typename?: 'SearchSalaryRange';
  frequency: SalaryFrequency;
  range: Array<SearchSalaryRangeItem>;
};

export type SearchSalaryRangeItem = {
  __typename?: 'SearchSalaryRangeItem';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SearchSalaryRangeItemLabelArgs = {
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  type?: InputMaybe<SearchSalaryRangeLabelType>;
};

export enum SearchSalaryRangeLabelType {
  Long = 'LONG',
  Short = 'SHORT',
}

export type SearchSavedJobPartial = {
  __typename?: 'SearchSavedJobPartial';
  jobId: Scalars['String']['output'];
  zone?: Maybe<Scalars['Zone']['output']>;
};

export type SearchSuggestionsMetadata = {
  __typename?: 'SearchSuggestionsMetadata';
  flightId?: Maybe<Scalars['String']['output']>;
  requestToken?: Maybe<Scalars['UUID']['output']>;
};

export type SeekDateTime = {
  __typename?: 'SeekDateTime';
  dateTime: Scalars['String']['output'];
  dateTimeUtc: Scalars['DateTime']['output'];
  /** Contextual label for the date. E.g. for job posted, 15 mins ago, but for seekmax poll, 15 mins left */
  label: Scalars['String']['output'];
  longAbsoluteLabel: Scalars['String']['output'];
  longLabel: Scalars['String']['output'];
  /** @deprecated Use label with context SEEKMAX_POLL_DURATION_LEFT */
  seekMaxPollLabel: Scalars['String']['output'];
  /** Generic string representation of the absolute date. E.g. 9 Nov 2021 */
  shortAbsoluteLabel: Scalars['String']['output'];
  /** Generic string representation of the date relative to now. E.g. 6 hours ago */
  shortLabel: Scalars['String']['output'];
};

export type SeekDateTimeDateTimeArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
  timezone?: InputMaybe<Scalars['Timezone']['input']>;
};

export type SeekDateTimeLabelArgs = {
  context: DateTimeContext;
  length: DateTimeLength;
  locale: Scalars['Locale']['input'];
  timezone: Scalars['Timezone']['input'];
};

export type SeekDateTimeLongAbsoluteLabelArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
  timezone?: InputMaybe<Scalars['Timezone']['input']>;
};

export type SeekDateTimeLongLabelArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
  timezone?: InputMaybe<Scalars['Timezone']['input']>;
};

export type SeekDateTimeSeekMaxPollLabelArgs = {
  locale: Scalars['Locale']['input'];
  timezone: Scalars['Timezone']['input'];
};

export type SeekDateTimeShortAbsoluteLabelArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
  timezone?: InputMaybe<Scalars['Timezone']['input']>;
};

export type SeekDateTimeShortLabelArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
  timezone?: InputMaybe<Scalars['Timezone']['input']>;
};

export type SeekMaxAttachment = {
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type SeekMaxAttachmentFailed = ISeekMaxAttachmentStatus & {
  __typename?: 'SeekMaxAttachmentFailed';
  failureReason: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type SeekMaxAttachmentPending = ISeekMaxAttachmentStatus & {
  __typename?: 'SeekMaxAttachmentPending';
  id: Scalars['ID']['output'];
};

export type SeekMaxAttachmentPublished = ISeekMaxAttachmentStatus & {
  __typename?: 'SeekMaxAttachmentPublished';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export enum SeekMaxAttachmentType {
  Image = 'IMAGE',
}

export type SeekMaxAttachmentUploadReady = ISeekMaxAttachmentStatus & {
  __typename?: 'SeekMaxAttachmentUploadReady';
  id: Scalars['ID']['output'];
  uploadUrl: Scalars['String']['output'];
};

export enum SeekMaxCategory {
  BusinessAndFinance = 'BUSINESS_AND_FINANCE',
  CareerAdvice = 'CAREER_ADVICE',
  IndustryTrends = 'INDUSTRY_TRENDS',
  JobSearchAdvice = 'JOB_SEARCH_ADVICE',
  LanguageLearning = 'LANGUAGE_LEARNING',
  LeadershipAndManagement = 'LEADERSHIP_AND_MANAGEMENT',
  SalaryAdvice = 'SALARY_ADVICE',
  SalesAndMarketing = 'SALES_AND_MARKETING',
  SelfDevelopment = 'SELF_DEVELOPMENT',
  TechnologyAndDesign = 'TECHNOLOGY_AND_DESIGN',
}

export type SeekMaxCategoryGroupConnectionResults = {
  __typename?: 'SeekMaxCategoryGroupConnectionResults';
  edges?: Maybe<Array<Maybe<SeekMaxCategoryGroupEdge>>>;
  pageInfo: SeekMaxPageInfo;
};

export type SeekMaxCategoryGroupEdge = {
  __typename?: 'SeekMaxCategoryGroupEdge';
  cursor: Scalars['Int']['output'];
  node: ISeekMaxCategoryGroup;
};

export type SeekMaxCategoryGroupFollowInput = {
  followed: Scalars['Boolean']['input'];
  groupId: Scalars['ID']['input'];
};

export type SeekMaxCategoryGroupLocal = ISeekMaxCategoryGroup & {
  __typename?: 'SeekMaxCategoryGroupLocal';
  categoryId: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  groupId: Scalars['ID']['output'];
  memberCount: SocialMetric;
  name: Scalars['String']['output'];
  personalisedDetails?: Maybe<PersonalisedSeekMaxCategoryGroup>;
};

export type SeekMaxCategoryGroupTopic = ISeekMaxCategoryGroup & {
  __typename?: 'SeekMaxCategoryGroupTopic';
  categoryCode: Scalars['String']['output'];
  categoryId: Scalars['ID']['output'];
  groupId: Scalars['ID']['output'];
  memberCount: SocialMetric;
  name: Scalars['String']['output'];
  personalisedDetails?: Maybe<PersonalisedSeekMaxCategoryGroup>;
};

export type SeekMaxCollection = {
  __typename?: 'SeekMaxCollection';
  modules: Array<SeekMaxModule>;
  title: Scalars['String']['output'];
};

export type SeekMaxComment = {
  __typename?: 'SeekMaxComment';
  author: SeekMaxPublicUser;
  content: Scalars['String']['output'];
  creationDate: SeekDateTime;
  exactSocialData: ExactCommentSocialData;
  id: Scalars['ID']['output'];
  personalisedDetails?: Maybe<PersonalisedSeekMaxComment>;
};

export type SeekMaxCommentLikedStatusFailure = {
  __typename?: 'SeekMaxCommentLikedStatusFailure';
  errors: Array<SeekMaxLikedStatusError>;
};

export type SeekMaxCommentLikedStatusSuccess = {
  __typename?: 'SeekMaxCommentLikedStatusSuccess';
  id: Scalars['ID']['output'];
  likeCount: SocialMetric;
  liked: Scalars['Boolean']['output'];
};

export type SeekMaxCommentResults = {
  __typename?: 'SeekMaxCommentResults';
  comments: Array<SeekMaxComment>;
  lastCursor: Scalars['Int']['output'];
  resultCount: Scalars['Int']['output'];
};

export type SeekMaxContentSearchResults = {
  __typename?: 'SeekMaxContentSearchResults';
  edges: Array<SeekMaxSearchContentEdge>;
  pageInfo: SearchPageInfo;
};

export type SeekMaxImageAttachment = SeekMaxAttachment & {
  __typename?: 'SeekMaxImageAttachment';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type SeekMaxKolExpert = ISeekMaxKolGroup & {
  __typename?: 'SeekMaxKOLExpert';
  description: Scalars['String']['output'];
};

export type SeekMaxLandingInfo = {
  __typename?: 'SeekMaxLandingInfo';
  learningProfileId: Scalars['String']['output'];
};

export type SeekMaxLikeInput = {
  id: Scalars['ID']['input'];
  liked: Scalars['Boolean']['input'];
  locale: Scalars['Locale']['input'];
};

export type SeekMaxLikedStatusError = {
  message: Scalars['String']['output'];
};

export type SeekMaxLikedStatusErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type SeekMaxModule = {
  __typename?: 'SeekMaxModule';
  author: Scalars['String']['output'];
  brightcoveId: Scalars['ID']['output'];
  categories?: Maybe<Array<SeekMaxCategory>>;
  description?: Maybe<Scalars['String']['output']>;
  exactSocialData: ExactModuleSocialData;
  id: Scalars['ID']['output'];
  keywordTags?: Maybe<Array<Scalars['String']['output']>>;
  personalisedDetails?: Maybe<PersonalisedSeekMaxModule>;
  personalisedProgress?: Maybe<PersonalisedSeekMaxModuleProgress>;
  previewImageSrc?: Maybe<Scalars['String']['output']>;
  shareLink: Scalars['String']['output'];
  skillLevel: SeekMaxSkillLevel;
  tags?: Maybe<Array<SeekMaxTags>>;
  threadId?: Maybe<Scalars['ID']['output']>;
  thumbnailImageSrc?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  totalDuration: Duration;
  /** @deprecated Client side will generate this localised text by using '${videos.length} Episodes' - https://myseek.atlassian.net/browse/CNC-2307 */
  totalEpisodes?: Maybe<Episodes>;
  videos?: Maybe<Array<SeekMaxVideo>>;
};

export type SeekMaxModulePersonalisedProgressArgs = {
  countryCode: Scalars['CountryCodeIso2']['input'];
};

export type SeekMaxModuleShareLinkArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
  platform: Platform;
  zone: Scalars['Zone']['input'];
};

export type SeekMaxModuleTotalEpisodesArgs = {
  locale: Scalars['Locale']['input'];
};

export type SeekMaxModuleBookmark = {
  __typename?: 'SeekMaxModuleBookmark';
  bookmarkDate: SeekDateTime;
};

export type SeekMaxModuleBookmarkInput = {
  countryCode: Scalars['CountryCodeIso2']['input'];
  id: Scalars['ID']['input'];
};

export type SeekMaxModuleBookmarkResults = {
  __typename?: 'SeekMaxModuleBookmarkResults';
  modules: Array<SeekMaxModule>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
  resultCount: Scalars['Int']['output'];
};

export type SeekMaxModuleClickedPersonalisationEventError = {
  message: Scalars['String']['output'];
};

export type SeekMaxModuleClickedPersonalisationEventErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type SeekMaxModuleClickedPersonalisationEventFailure = {
  __typename?: 'SeekMaxModuleClickedPersonalisationEventFailure';
  errors: Array<SeekMaxModuleClickedPersonalisationEventError>;
};

export type SeekMaxModuleClickedPersonalisationEventInput = {
  moduleId: Scalars['ID']['input'];
  session: Scalars['UUID']['input'];
  zone: Scalars['Zone']['input'];
};

export type SeekMaxModuleClickedPersonalisationEventPayload =
  | SeekMaxModuleClickedPersonalisationEventFailure
  | SeekMaxModuleClickedPersonalisationEventSuccess;

export type SeekMaxModuleClickedPersonalisationEventSuccess = {
  __typename?: 'SeekMaxModuleClickedPersonalisationEventSuccess';
  moduleId: Scalars['ID']['output'];
};

export type SeekMaxModuleConnectionResults = {
  __typename?: 'SeekMaxModuleConnectionResults';
  edges: Array<SeekMaxModuleEdge>;
  pageInfo: SeekMaxPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SeekMaxModuleEdge = {
  __typename?: 'SeekMaxModuleEdge';
  cursor: Scalars['Int']['output'];
  node: SeekMaxModule;
};

export type SeekMaxModuleLikeInput = {
  countryCode: Scalars['CountryCodeIso2']['input'];
  liked: Scalars['Boolean']['input'];
  moduleThreadId: Scalars['ID']['input'];
};

export type SeekMaxModuleLikedStatusFailure = {
  __typename?: 'SeekMaxModuleLikedStatusFailure';
  errors: Array<SeekMaxLikedStatusError>;
};

export type SeekMaxModuleLikedStatusSuccess = {
  __typename?: 'SeekMaxModuleLikedStatusSuccess';
  likeCount: SocialMetric;
  liked: Scalars['Boolean']['output'];
  moduleThreadId: Scalars['ID']['output'];
};

export type SeekMaxModulePersonalisationEventError = {
  message: Scalars['String']['output'];
};

export type SeekMaxModulePersonalisationEventErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type SeekMaxModulePersonalisationEventFailure = {
  __typename?: 'SeekMaxModulePersonalisationEventFailure';
  errors: Array<SeekMaxModulePersonalisationEventError>;
};

export type SeekMaxModulePersonalisationEventInput = {
  countryCode: Scalars['CountryCodeIso2']['input'];
  moduleId: Scalars['ID']['input'];
  session: Scalars['UUID']['input'];
};

export type SeekMaxModulePersonalisationEventPayload =
  | SeekMaxModulePersonalisationEventFailure
  | SeekMaxModulePersonalisationEventSuccess;

export type SeekMaxModulePersonalisationEventSuccess = {
  __typename?: 'SeekMaxModulePersonalisationEventSuccess';
  moduleId: Scalars['ID']['output'];
};

export type SeekMaxModuleProgressError = {
  message: Scalars['String']['output'];
};

export type SeekMaxModuleProgressErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type SeekMaxModuleProgressFailure = {
  __typename?: 'SeekMaxModuleProgressFailure';
  errors: Array<SeekMaxModuleProgressError>;
};

export type SeekMaxModuleProgressInput = {
  countryCode: Scalars['CountryCodeIso2']['input'];
  id: Scalars['ID']['input'];
  resumeFromSeconds: Scalars['Float']['input'];
  videoId: Scalars['ID']['input'];
};

export type SeekMaxModuleProgressPayload =
  | SeekMaxModuleProgressFailure
  | SeekMaxModuleProgressSuccess;

export type SeekMaxModuleProgressSuccess = {
  __typename?: 'SeekMaxModuleProgressSuccess';
  id: Scalars['ID']['output'];
  videoId: Scalars['ID']['output'];
};

/** SeekMaxModuleResults has been deprecated. Please use SeekMaxModuleConnectionResults instead. */
export type SeekMaxModuleResults = {
  __typename?: 'SeekMaxModuleResults';
  lastCursor: Scalars['Int']['output'];
  modules: Array<SeekMaxModule>;
  resultCount: Scalars['Int']['output'];
};

export type SeekMaxModuleSearchContent = ISeekMaxSearchContent & {
  __typename?: 'SeekMaxModuleSearchContent';
  author: Scalars['String']['output'];
  exactSocialData: ExactModuleSocialData;
  id: Scalars['ID']['output'];
  previewImageSrc?: Maybe<Scalars['String']['output']>;
  thumbnailImageSrc?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  totalDuration: Duration;
  videoCount: Scalars['Int']['output'];
};

/** SeekMaxModuleSearchResults has been deprecated. Please use SeekMaxModuleConnectionResults instead. */
export type SeekMaxModuleSearchResults = {
  __typename?: 'SeekMaxModuleSearchResults';
  lastCursor: Scalars['Int']['output'];
  modules: Array<SeekMaxSearchModule>;
  resultCount: Scalars['Int']['output'];
};

export type SeekMaxModulesInProgressResults = {
  __typename?: 'SeekMaxModulesInProgressResults';
  modules: Array<SeekMaxModule>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
  resultCount: Scalars['Int']['output'];
};

export type SeekMaxNextVideoProgress = {
  __typename?: 'SeekMaxNextVideoProgress';
  durationLeft?: Maybe<DurationLeft>;
  id: Scalars['ID']['output'];
  percentageCompleted: Scalars['Float']['output'];
  previewImageSrc?: Maybe<Scalars['String']['output']>;
  resumeFromSeconds: Scalars['Float']['output'];
  state: SeekMaxVideoState;
  thumbnailImageSrc?: Maybe<Scalars['String']['output']>;
};

export type SeekMaxPageInfo = {
  __typename?: 'SeekMaxPageInfo';
  endCursor?: Maybe<Scalars['Int']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPrevPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['Int']['output']>;
};

export type SeekMaxPoll = {
  __typename?: 'SeekMaxPoll';
  endDate: SeekDateTime;
  ended: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  maxVotes: Scalars['Int']['output'];
  options: Array<SeekMaxPollOption>;
  voteCount: SocialMetric;
};

export type SeekMaxPollOption = {
  __typename?: 'SeekMaxPollOption';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  percentage: Scalars['Int']['output'];
  personalisedDetails?: Maybe<PersonalisedSeekMaxPollOption>;
  title: Scalars['String']['output'];
};

export type SeekMaxProfile = {
  __typename?: 'SeekMaxProfile';
  groups: Array<ISeekMaxKolGroup>;
  learningProfileHashCode: Scalars['Int']['output'];
  learningProfileId: Scalars['ID']['output'];
};

export type SeekMaxProfileGroupsArgs = {
  countryCode?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
};

export type SeekMaxPublicUser = {
  __typename?: 'SeekMaxPublicUser';
  firstName: Scalars['String']['output'];
  groups: Array<ISeekMaxKolGroup>;
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  learningProfileHashCode: Scalars['Int']['output'];
};

export type SeekMaxReply = {
  __typename?: 'SeekMaxReply';
  author: SeekMaxPublicUser;
  content: Scalars['String']['output'];
  creationDate: SeekDateTime;
  id: Scalars['ID']['output'];
};

export type SeekMaxReplyResults = {
  __typename?: 'SeekMaxReplyResults';
  lastCursor: Scalars['Int']['output'];
  replies: Array<SeekMaxReply>;
  resultCount: Scalars['Int']['output'];
};

export type SeekMaxSearchContentEdge = {
  __typename?: 'SeekMaxSearchContentEdge';
  cursor: Scalars['Int']['output'];
  node: ISeekMaxSearchContent;
};

export type SeekMaxSearchModule = {
  __typename?: 'SeekMaxSearchModule';
  author: Scalars['String']['output'];
  exactSocialData: ExactModuleSocialData;
  id: Scalars['ID']['output'];
  previewImageSrc?: Maybe<Scalars['String']['output']>;
  thumbnailImageSrc?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  totalDuration: Duration;
  /** @deprecated Client side will generate this localised text by using '${videoCount} Episodes' */
  totalEpisodes?: Maybe<Episodes>;
  videoCount: Scalars['Int']['output'];
};

export type SeekMaxSearchModuleTotalEpisodesArgs = {
  locale: Scalars['Locale']['input'];
};

export enum SeekMaxSkillLevel {
  Basic = 'BASIC',
  Expert = 'EXPERT',
  Intermediate = 'INTERMEDIATE',
  Unknown = 'UNKNOWN',
}

export type SeekMaxTags = {
  __typename?: 'SeekMaxTags';
  color: Scalars['String']['output'];
  tag: Scalars['String']['output'];
};

export type SeekMaxThread = {
  __typename?: 'SeekMaxThread';
  attachments?: Maybe<Array<SeekMaxImageAttachment>>;
  author: SeekMaxPublicUser;
  /** @deprecated Use categoryV3 instead - https://myseek.atlassian.net/browse/CNC-3672 */
  category: SeekMaxCategory;
  categoryGroup?: Maybe<ISeekMaxCategoryGroup>;
  /** @deprecated Use categoryGroup instead - https://myseek.atlassian.net/browse/CNC-4488 */
  categoryV3?: Maybe<SeekMaxCategory>;
  comments: Array<SeekMaxComment>;
  creationDate: SeekDateTime;
  description: Scalars['String']['output'];
  exactSocialData: ExactThreadSocialData;
  hasUnreadPosts?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isPinned: Scalars['Boolean']['output'];
  personalisedDetails?: Maybe<PersonalisedSeekMaxThread>;
  /** @deprecated use pollV3 instead - https://myseek.atlassian.net/browse/CNC-3602 */
  poll?: Maybe<SeekMaxPoll>;
  pollV3?: Maybe<SeekMaxPoll>;
  shareLink: Scalars['String']['output'];
};

export type SeekMaxThreadCategoryArgs = {
  locale: Scalars['Locale']['input'];
};

export type SeekMaxThreadCategoryGroupArgs = {
  zone: Scalars['Zone']['input'];
};

export type SeekMaxThreadCategoryV3Args = {
  zone: Scalars['Zone']['input'];
};

export type SeekMaxThreadPollV3Args = {
  zone: Scalars['Zone']['input'];
};

export type SeekMaxThreadShareLinkArgs = {
  languageCode: Scalars['LanguageCodeIso']['input'];
  platform: Platform;
  zone: Scalars['Zone']['input'];
};

export type SeekMaxThreadConnectionResults = {
  __typename?: 'SeekMaxThreadConnectionResults';
  edges: Array<SeekMaxThreadEdge>;
  pageInfo: SeekMaxPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SeekMaxThreadEdge = {
  __typename?: 'SeekMaxThreadEdge';
  cursor: Scalars['Int']['output'];
  node: SeekMaxThread;
};

export type SeekMaxThreadLikedStatusFailure = {
  __typename?: 'SeekMaxThreadLikedStatusFailure';
  errors: Array<SeekMaxLikedStatusError>;
};

export type SeekMaxThreadLikedStatusSuccess = {
  __typename?: 'SeekMaxThreadLikedStatusSuccess';
  id: Scalars['ID']['output'];
  likeCount: SocialMetric;
  liked: Scalars['Boolean']['output'];
};

export type SeekMaxThreadPollInput = {
  optionId: Scalars['ID']['input'];
  pollId: Scalars['ID']['input'];
  voted: Scalars['Boolean']['input'];
};

export type SeekMaxThreadPollStatusError = {
  message: Scalars['String']['output'];
};

export type SeekMaxThreadPollStatusErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type SeekMaxThreadPollStatusFailure = {
  __typename?: 'SeekMaxThreadPollStatusFailure';
  errors: Array<SeekMaxThreadPollStatusError>;
};

export type SeekMaxThreadPollStatusSuccess = {
  __typename?: 'SeekMaxThreadPollStatusSuccess';
  optionId: Scalars['ID']['output'];
  pollId: Scalars['ID']['output'];
  voted: Scalars['Boolean']['output'];
};

/** SeekMaxThreadResults has been deprecated. Please use SeekMaxThreadConnectionResults instead. */
export type SeekMaxThreadResults = {
  __typename?: 'SeekMaxThreadResults';
  lastCursor: Scalars['Int']['output'];
  resultCount: Scalars['Int']['output'];
  threads: Array<SeekMaxThread>;
};

export type SeekMaxThreadSearchContent = ISeekMaxSearchContent & {
  __typename?: 'SeekMaxThreadSearchContent';
  attachments?: Maybe<Array<SeekMaxImageAttachment>>;
  author: SeekMaxPublicUser;
  /** @deprecated Use categoryV3 instead - https://myseek.atlassian.net/browse/CNC-3672 */
  category: SeekMaxCategory;
  categoryGroup?: Maybe<ISeekMaxCategoryGroup>;
  /** @deprecated Use categoryGroup instead - https://myseek.atlassian.net/browse/CNC-4488 */
  categoryV3?: Maybe<SeekMaxCategory>;
  comments: Array<SeekMaxComment>;
  creationDate: SeekDateTime;
  description: Scalars['String']['output'];
  exactSocialData: ExactThreadSocialData;
  id: Scalars['ID']['output'];
  isPinned: Scalars['Boolean']['output'];
};

export type SeekMaxThreadSearchContentCategoryArgs = {
  countryCode: Scalars['CountryCodeIso2']['input'];
};

export type SeekMaxThreadSearchContentCategoryGroupArgs = {
  zone: Scalars['Zone']['input'];
};

export type SeekMaxThreadSearchContentCategoryV3Args = {
  zone: Scalars['Zone']['input'];
};

/** SeekMaxThreadSearchResults has been deprecated. Please use SeekMaxThreadConnectionResults instead. */
export type SeekMaxThreadSearchResults = {
  __typename?: 'SeekMaxThreadSearchResults';
  isLastPage: Scalars['Boolean']['output'];
  lastCursor: Scalars['Int']['output'];
  threads: Array<SeekMaxThread>;
};

export type SeekMaxUnreadThreadsCountResponse = {
  __typename?: 'SeekMaxUnreadThreadsCountResponse';
  count: Scalars['Int']['output'];
};

export type SeekMaxVideo = {
  __typename?: 'SeekMaxVideo';
  description?: Maybe<Scalars['String']['output']>;
  drmEnabled: Scalars['Boolean']['output'];
  duration: Duration;
  id: Scalars['ID']['output'];
  personalisedDetails?: Maybe<PersonalisedSeekMaxVideo>;
  previewImageSrc: Scalars['String']['output'];
  /** @deprecated Client side will retrieve the video url from the BC playback API - https://myseek.atlassian.net/browse/CNC-3509 */
  src: Scalars['String']['output'];
  thumbnailImageSrc: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type SeekMaxVideoProgress = {
  __typename?: 'SeekMaxVideoProgress';
  durationLeft?: Maybe<DurationLeft>;
  percentageCompleted: Scalars['Float']['output'];
  resumeFromSeconds: Scalars['Float']['output'];
  state: SeekMaxVideoState;
};

export enum SeekMaxVideoState {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
}

export type SeekMaxVideoViewedEventError = {
  message: Scalars['String']['output'];
};

export type SeekMaxVideoViewedEventErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type SeekMaxVideoViewedEventFailure = {
  __typename?: 'SeekMaxVideoViewedEventFailure';
  errors: Array<SeekMaxVideoViewedEventError>;
};

export type SeekMaxVideoViewedEventInput = {
  locale: Scalars['Locale']['input'];
  moduleId: Scalars['ID']['input'];
  session: Scalars['UUID']['input'];
  videoId: Scalars['ID']['input'];
};

export type SeekMaxVideoViewedEventPayload =
  | SeekMaxVideoViewedEventFailure
  | SeekMaxVideoViewedEventSuccess;

export type SeekMaxVideoViewedEventSuccess = {
  __typename?: 'SeekMaxVideoViewedEventSuccess';
  moduleId: Scalars['ID']['output'];
  videoId: Scalars['ID']['output'];
};

export type SeekMaxVideoViewedPersonalisationEventError = {
  message: Scalars['String']['output'];
};

export type SeekMaxVideoViewedPersonalisationEventErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type SeekMaxVideoViewedPersonalisationEventFailure = {
  __typename?: 'SeekMaxVideoViewedPersonalisationEventFailure';
  errors: Array<SeekMaxVideoViewedPersonalisationEventError>;
};

export type SeekMaxVideoViewedPersonalisationEventInput = {
  moduleId: Scalars['ID']['input'];
  session: Scalars['UUID']['input'];
  videoId: Scalars['ID']['input'];
  zone: Scalars['Zone']['input'];
};

export type SeekMaxVideoViewedPersonalisationEventPayload =
  | SeekMaxVideoViewedPersonalisationEventFailure
  | SeekMaxVideoViewedPersonalisationEventSuccess;

export type SeekMaxVideoViewedPersonalisationEventSuccess = {
  __typename?: 'SeekMaxVideoViewedPersonalisationEventSuccess';
  moduleId: Scalars['ID']['output'];
  videoId: Scalars['ID']['output'];
};

export type SelectionCriteriaInput = {
  uri: Scalars['String']['input'];
  writtenText?: InputMaybe<Scalars['String']['input']>;
};

export type SendLoginCallbackEventFailure = {
  __typename?: 'SendLoginCallbackEventFailure';
  error: I18nAccountError;
};

export type SendLoginCallbackEventPayload =
  | SendLoginCallbackEventFailure
  | SendLoginCallbackEventSuccess;

export type SendLoginCallbackEventSuccess = {
  __typename?: 'SendLoginCallbackEventSuccess';
  loginType: Scalars['String']['output'];
};

export type SendSeekMaxModuleClickedEventError = {
  message: Scalars['String']['output'];
};

export type SendSeekMaxModuleClickedEventErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type SendSeekMaxModuleClickedEventFailure = {
  __typename?: 'SendSeekMaxModuleClickedEventFailure';
  errors: Array<SendSeekMaxModuleClickedEventError>;
};

export type SendSeekMaxModuleClickedEventInput = {
  countryCode: Scalars['CountryCodeIso2']['input'];
  moduleId: Scalars['ID']['input'];
  session: Scalars['UUID']['input'];
};

export type SendSeekMaxModuleClickedEventPayload =
  | SendSeekMaxModuleClickedEventFailure
  | SendSeekMaxModuleClickedEventSuccess;

export type SendSeekMaxModuleClickedEventSuccess = {
  __typename?: 'SendSeekMaxModuleClickedEventSuccess';
  moduleId: Scalars['ID']['output'];
};

export type SetAppliedJobNotesError = {
  message: Scalars['String']['output'];
};

export type SetAppliedJobNotesErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type SetAppliedJobNotesFailure = {
  __typename?: 'SetAppliedJobNotesFailure';
  errors: Array<SetAppliedJobNotesError>;
};

export type SetAppliedJobNotesInput = {
  id: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type SetAppliedJobNotesPayload =
  | SetAppliedJobNotesFailure
  | SetAppliedJobNotesSuccess;

export type SetAppliedJobNotesSuccess = {
  __typename?: 'SetAppliedJobNotesSuccess';
  id?: Maybe<Scalars['String']['output']>;
};

export type SetAppliedJobNotesValidationError = SetAppliedJobNotesError & {
  __typename?: 'SetAppliedJobNotesValidationError';
  message: Scalars['String']['output'];
};

export type SetAppliedJobNotesValidationErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type SetDefaultResumeInput = {
  id: Scalars['UUID']['input'];
};

export type SetDefaultResumePayload = {
  __typename?: 'SetDefaultResumePayload';
  ref?: Maybe<Scalars['UUID']['output']>;
  viewer?: Maybe<Candidate>;
};

export type SetSavedJobNotesError = {
  message: Scalars['String']['output'];
};

export type SetSavedJobNotesErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type SetSavedJobNotesFailure = {
  __typename?: 'SetSavedJobNotesFailure';
  errors: Array<SetSavedJobNotesError>;
};

export type SetSavedJobNotesInput = {
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type SetSavedJobNotesPayload =
  | SetSavedJobNotesFailure
  | SetSavedJobNotesSuccess;

export type SetSavedJobNotesSuccess = {
  __typename?: 'SetSavedJobNotesSuccess';
  id: Scalars['ID']['output'];
};

export type SetSavedJobNotesValidationError = SetSavedJobNotesError & {
  __typename?: 'SetSavedJobNotesValidationError';
  message: Scalars['String']['output'];
};

export type SetSavedJobNotesValidationErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type SetSeekMaxCategoryGroupFollowedStatusError = {
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type SetSeekMaxCategoryGroupFollowedStatusErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type SetSeekMaxCategoryGroupFollowedStatusErrorTitleArgs = {
  locale: Scalars['Locale']['input'];
};

export type SetSeekMaxCategoryGroupFollowedStatusFailure = {
  __typename?: 'SetSeekMaxCategoryGroupFollowedStatusFailure';
  errors: Array<SetSeekMaxCategoryGroupFollowedStatusError>;
};

export type SetSeekMaxCategoryGroupFollowedStatusPayload =
  | SetSeekMaxCategoryGroupFollowedStatusFailure
  | SetSeekMaxCategoryGroupFollowedStatusSuccess;

export type SetSeekMaxCategoryGroupFollowedStatusSuccess = {
  __typename?: 'SetSeekMaxCategoryGroupFollowedStatusSuccess';
  categoryId: Scalars['ID']['output'];
  followed: Scalars['Boolean']['output'];
  groupId: Scalars['ID']['output'];
};

export type SetSeekMaxCommentLikedStatusPayload =
  | SeekMaxCommentLikedStatusFailure
  | SeekMaxCommentLikedStatusSuccess;

export type SetSeekMaxModuleLikedStatusPayload =
  | SeekMaxModuleLikedStatusFailure
  | SeekMaxModuleLikedStatusSuccess;

export type SetSeekMaxPreferredCategoriesError = {
  message: Scalars['String']['output'];
};

export type SetSeekMaxPreferredCategoriesErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type SetSeekMaxPreferredCategoriesFailure = {
  __typename?: 'SetSeekMaxPreferredCategoriesFailure';
  errors: Array<SetSeekMaxPreferredCategoriesError>;
};

export type SetSeekMaxPreferredCategoriesInput = {
  categories: Array<SeekMaxCategory>;
};

export type SetSeekMaxPreferredCategoriesPayload =
  | SetSeekMaxPreferredCategoriesFailure
  | SetSeekMaxPreferredCategoriesSuccess;

export type SetSeekMaxPreferredCategoriesSuccess = {
  __typename?: 'SetSeekMaxPreferredCategoriesSuccess';
  categories: Array<SeekMaxCategory>;
};

export type SetSeekMaxThreadLikedStatusPayload =
  | SeekMaxThreadLikedStatusFailure
  | SeekMaxThreadLikedStatusSuccess;

export type SetSeekMaxThreadPollStatusPayload =
  | SeekMaxThreadPollStatusFailure
  | SeekMaxThreadPollStatusSuccess;

export type ShareableProfile = {
  __typename?: 'ShareableProfile';
  active: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  languageProficiencies: Array<LanguageProficiency>;
  licences: Array<Licence>;
  personalDetails: PersonalDetails;
  qualifications: Array<Qualification>;
  roles: Array<Role>;
  skills: Array<Skill>;
  summary?: Maybe<Scalars['String']['output']>;
  verifiedIdentity?: Maybe<VerifiedIdentity>;
};

export type ShareableProfileSettings = {
  __typename?: 'ShareableProfileSettings';
  active?: Maybe<Scalars['Boolean']['output']>;
  displayUrl?: Maybe<Scalars['String']['output']>;
  privacy?: Maybe<Privacy>;
  profileSlug?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

export type SingleChoiceQuestion = Question & {
  __typename?: 'SingleChoiceQuestion';
  id: Scalars['String']['output'];
  lastAnswer?: Maybe<LastAnswer>;
  options: Array<QuestionOption>;
  text: Scalars['String']['output'];
};

export type Skill = {
  __typename?: 'Skill';
  keyword: OntologyStructuredData;
};

export type SkillInput = {
  keyword: OntologyStructuredDataInput;
};

export type SocialMetric = {
  __typename?: 'SocialMetric';
  count: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum SourceExperience {
  Apply = 'APPLY',
  Jas = 'JAS',
  Profile = 'PROFILE',
}

export type SourcrRecruiter = {
  __typename?: 'SourcrRecruiter';
  agencyImageUrl?: Maybe<Scalars['String']['output']>;
  agencyName?: Maybe<Scalars['String']['output']>;
  avatarImageUrl?: Maybe<Scalars['String']['output']>;
  contactUrl: Scalars['String']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  overallRating: Scalars['Float']['output'];
  reviewCount: Scalars['Int']['output'];
};

export enum SpecReqProfileField {
  LanguageProficiency = 'LanguageProficiency',
  LicencesCertifications = 'LicencesCertifications',
  PreferredLocation = 'PreferredLocation',
  PreferredWorkTypes = 'PreferredWorkTypes',
  RightToWork = 'RightToWork',
  Skills = 'Skills',
}

export type SubClassification = {
  __typename?: 'SubClassification';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type SubClassificationInput = {
  id: Scalars['Int']['input'];
};

export type Subject = {
  __typename?: 'Subject';
  /** The Auth0 user id of the subject, eg 'auth0|12eb841a02c489d1'. */
  id: Scalars['String']['output'];
  /**
   * The associated jobseeker id, derived from the "http://seek/claims/user_id" claim.
   * This is optional, as the user in Auth0 may not be associated with a jobseeker record.
   */
  userId?: Maybe<Scalars['String']['output']>;
};

export type SubmitApplicationError = {
  message: Scalars['String']['output'];
};

export type SubmitApplicationErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type SubmitApplicationFailure = {
  __typename?: 'SubmitApplicationFailure';
  errors: Array<SubmitApplicationError>;
};

export type SubmitApplicationInput = {
  correlationId: Scalars['ID']['input'];
  coverLetter?: InputMaybe<CoverLetterInput>;
  jobId: Scalars['ID']['input'];
  mostRecentRole?: InputMaybe<MostRecentRoleInput>;
  personalDetails?: InputMaybe<SubmitApplicationPersonalDetailsInput>;
  profilePrivacyLevel?: InputMaybe<Scalars['String']['input']>;
  questionnaireAnswers?: InputMaybe<Array<QuestionnaireAnswerInput>>;
  resume?: InputMaybe<ResumeInput>;
  selectionCriteria?: InputMaybe<SelectionCriteriaInput>;
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type SubmitApplicationPayload =
  | SubmitApplicationFailure
  | SubmitApplicationSuccess;

export type SubmitApplicationPersonalDetailsInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type SubmitApplicationSuccess = {
  __typename?: 'SubmitApplicationSuccess';
  applicationId: Scalars['String']['output'];
  /** @deprecated Use applicationId instead */
  viewer?: Maybe<Candidate>;
};

export type SubmitApplicationValidationError = SubmitApplicationError & {
  __typename?: 'SubmitApplicationValidationError';
  message: Scalars['String']['output'];
};

export type SubmitApplicationValidationErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type SubscribeApacSavedEmailAlertError = {
  message: Scalars['String']['output'];
};

export type SubscribeApacSavedEmailAlertErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type SubscribeApacSavedEmailAlertFailedError =
  SubscribeApacSavedEmailAlertError & {
    __typename?: 'SubscribeApacSavedEmailAlertFailedError';
    message: Scalars['String']['output'];
  };

export type SubscribeApacSavedEmailAlertFailedErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type SubscribeApacSavedEmailAlertFailure = {
  __typename?: 'SubscribeApacSavedEmailAlertFailure';
  errors?: Maybe<Array<SubscribeApacSavedEmailAlertError>>;
};

export type SubscribeApacSavedEmailAlertInput = {
  id: Scalars['ID']['input'];
  subscribe: Scalars['Boolean']['input'];
};

export type SubscribeApacSavedEmailAlertPayload =
  | SubscribeApacSavedEmailAlertFailure
  | SubscribeApacSavedEmailAlertSuccess;

export type SubscribeApacSavedEmailAlertSuccess = {
  __typename?: 'SubscribeApacSavedEmailAlertSuccess';
  savedSearch: ApacSavedSearch;
  subscribed: Scalars['Boolean']['output'];
};

export type SubscribeMobileNoti = {
  appVersion: Scalars['String']['input'];
  deviceToken: Scalars['String']['input'];
  platform: MobilePlatformNoti;
  zone: Scalars['Zone']['input'];
};

export type SubscribeMobileNotiPayload =
  | MobileNotiFailure
  | SubscribeMobileNotiSuccess;

export type SubscribeMobileNotiSuccess = {
  __typename?: 'SubscribeMobileNotiSuccess';
  deviceToken: Scalars['String']['output'];
  platformName: Scalars['String']['output'];
};

export enum SuggestionGroupType {
  International = 'International',
  Recent = 'Recent',
  Remote = 'Remote',
  Suggestions = 'Suggestions',
}

export type SuggestionHighlight = {
  __typename?: 'SuggestionHighlight';
  end: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

export type TestTagsInput = {
  testRecord?: InputMaybe<Scalars['Boolean']['input']>;
  testScope?: InputMaybe<Scalars['String']['input']>;
};

export type TopApplicantBadge = {
  __typename?: 'TopApplicantBadge';
  description: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type TopApplicantBadgeDescriptionArgs = {
  locale?: Scalars['Locale']['input'];
  zone?: InputMaybe<Scalars['Zone']['input']>;
};

export type TopApplicantBadgeLabelArgs = {
  locale?: Scalars['Locale']['input'];
};

export type TrackJobApplicationStartedInput = {
  applicationCorrelationId: Scalars['ID']['input'];
  isProfileApply?: InputMaybe<Scalars['Boolean']['input']>;
  jobId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
};

export type TrackJobApplicationStartedPayload = {
  __typename?: 'TrackJobApplicationStartedPayload';
  eventId?: Maybe<Scalars['ID']['output']>;
};

export type TrackJobDetailsViewedError = {
  message: Scalars['String']['output'];
};

export type TrackJobDetailsViewedErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type TrackJobDetailsViewedFailure = {
  __typename?: 'TrackJobDetailsViewedFailure';
  error: TrackJobDetailsViewedError;
};

export type TrackJobDetailsViewedInput = {
  candidateId?: InputMaybe<Scalars['Int']['input']>;
  jobDetailsViewedCorrelationId?: InputMaybe<Scalars['String']['input']>;
  jobId: Scalars['Int']['input'];
  jobseekerSessionId: Scalars['String']['input'];
  omnitureTracking?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<TestTagsInput>;
};

export type TrackJobDetailsViewedPayload =
  | TrackJobDetailsViewedFailure
  | TrackJobDetailsViewedSuccess;

export type TrackJobDetailsViewedSuccess = {
  __typename?: 'TrackJobDetailsViewedSuccess';
  eventId?: Maybe<Scalars['String']['output']>;
};

export type TrackSearchResultSelectedError = {
  message: Scalars['String']['output'];
};

export type TrackSearchResultSelectedErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type TrackSearchResultSelectedFailure = {
  __typename?: 'TrackSearchResultSelectedFailure';
  error: TrackSearchResultSelectedError;
};

export type TrackSearchResultSelectedInput = {
  adDisplayType: Scalars['String']['input'];
  jobId: Scalars['Int']['input'];
  jobseekerSessionId: Scalars['String']['input'];
  tags?: InputMaybe<TestTagsInput>;
  userQueryId?: InputMaybe<Scalars['String']['input']>;
};

export type TrackSearchResultSelectedPayload =
  | TrackSearchResultSelectedFailure
  | TrackSearchResultSelectedSuccess;

export type TrackSearchResultSelectedSuccess = {
  __typename?: 'TrackSearchResultSelectedSuccess';
  eventId?: Maybe<Scalars['String']['output']>;
};

export type TrackingInfo = {
  __typename?: 'TrackingInfo';
  events?: Maybe<Array<KeyValue>>;
};

export type TrackingInfoInput = {
  events?: InputMaybe<Array<KeyValueInput>>;
};

export enum UnconfirmedContextType {
  Application = 'Application',
  PrimaryResume = 'PrimaryResume',
}

export type UnconfirmedData = UnconfirmedDataCompleted | UnconfirmedDataPending;

export type UnconfirmedDataCompleted = {
  __typename?: 'UnconfirmedDataCompleted';
  completed: Scalars['Boolean']['output'];
  qualifications: Array<Qualification>;
  roles: Array<Role>;
};

export type UnconfirmedDataPending = {
  __typename?: 'UnconfirmedDataPending';
  completed: Scalars['Boolean']['output'];
};

export enum UnconfirmedDataType {
  CareerHistory = 'CareerHistory',
  Education = 'Education',
}

export type UnknownCreateShareableProfileSettingsError =
  CreateShareableProfileSettingsError & {
    __typename?: 'UnknownCreateShareableProfileSettingsError';
    message: Scalars['String']['output'];
  };

export type UnknownCreateShareableProfileSettingsErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type UnknownReportProfileError = ReportProfileError & {
  __typename?: 'UnknownReportProfileError';
  message: Scalars['String']['output'];
};

export type UnknownReportProfileErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type UnknownUpdateCurrentLocation2Error = UpdateCurrentLocation2Error & {
  __typename?: 'UnknownUpdateCurrentLocation2Error';
  message: Scalars['String']['output'];
};

export type UnknownUpdateCurrentLocation2ErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type UnknownUpdatePreferredLocations2Error =
  UpdatePreferredLocations2Error & {
    __typename?: 'UnknownUpdatePreferredLocations2Error';
    message: Scalars['String']['output'];
  };

export type UnknownUpdatePreferredLocations2ErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type UnknownUpdateShareableProfileSettingsError =
  UpdateShareableProfileSettingsError & {
    __typename?: 'UnknownUpdateShareableProfileSettingsError';
    message: Scalars['String']['output'];
  };

export type UnknownUpdateShareableProfileSettingsErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type UnknownUpdateShareableProfileViewsError =
  UpdateShareableProfileViewsError & {
    __typename?: 'UnknownUpdateShareableProfileViewsError';
    message: Scalars['String']['output'];
  };

export type UnknownUpdateShareableProfileViewsErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type UnsubscribeApacSavedSearchByCodeError = {
  __typename?: 'UnsubscribeApacSavedSearchByCodeError';
  message: Scalars['String']['output'];
};

export type UnsubscribeApacSavedSearchByCodeErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type UnsubscribeApacSavedSearchByCodeFailure = {
  __typename?: 'UnsubscribeApacSavedSearchByCodeFailure';
  error: UnsubscribeApacSavedSearchByCodeError;
};

export type UnsubscribeApacSavedSearchByCodeInput = {
  code: Scalars['String']['input'];
};

export type UnsubscribeApacSavedSearchByCodePayload =
  | UnsubscribeApacSavedSearchByCodeFailure
  | UnsubscribeApacSavedSearchByCodeSuccess;

export type UnsubscribeApacSavedSearchByCodeSuccess = {
  __typename?: 'UnsubscribeApacSavedSearchByCodeSuccess';
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type UnsubscribeMobileNoti = {
  deviceToken: Scalars['String']['input'];
  zone: Scalars['Zone']['input'];
};

export type UnsubscribeMobileNotiPayload =
  | MobileNotiFailure
  | UnsubscribeMobileNotiSuccess;

export type UnsubscribeMobileNotiSuccess = {
  __typename?: 'UnsubscribeMobileNotiSuccess';
  deviceToken: Scalars['String']['output'];
};

export type UpdateApproachabilityInput = {
  approachable: Scalars['Boolean']['input'];
};

export type UpdateApproachabilityPayload = {
  __typename?: 'UpdateApproachabilityPayload';
  /** @deprecated Please use viewer.approachability */
  ref?: Maybe<Scalars['UUID']['output']>;
  viewer?: Maybe<Candidate>;
};

export type UpdateAvailabilityInput = {
  id: Scalars['Int']['input'];
};

export type UpdateCareerObjectivesInput = {
  personalStatement: Scalars['String']['input'];
};

export type UpdateCareerObjectivesPayload = {
  __typename?: 'UpdateCareerObjectivesPayload';
  /** @deprecated Please use `viewer.careerObjectives.personalStatement` instead */
  personalStatement: Scalars['String']['output'];
  viewer?: Maybe<Candidate>;
};

export type UpdateClassificationInput = {
  classification: ClassificationInput;
};

export type UpdateCurrentLocation2Error = {
  message: Scalars['String']['output'];
};

export type UpdateCurrentLocation2ErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type UpdateCurrentLocation2Failure = {
  __typename?: 'UpdateCurrentLocation2Failure';
  error: UpdateCurrentLocation2Error;
};

export type UpdateCurrentLocation2Input = {
  currentLocation: CurrentLocation2Input;
};

export type UpdateCurrentLocation2Payload =
  | UpdateCurrentLocation2Failure
  | UpdateCurrentLocation2Success;

export type UpdateCurrentLocation2Success = {
  __typename?: 'UpdateCurrentLocation2Success';
  viewer?: Maybe<Candidate>;
};

export type UpdateLanguageProficiencyInput = {
  id: Scalars['UUID']['input'];
  name: OntologyStructuredDataInput;
};

export type UpdateLanguageProficiencyPayload = {
  __typename?: 'UpdateLanguageProficiencyPayload';
  viewer?: Maybe<Candidate>;
};

export type UpdateLicenceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<MonthYearInput>;
  id: Scalars['UUID']['input'];
  issueDate?: InputMaybe<MonthYearInput>;
  issuingOrganisation?: InputMaybe<Scalars['String']['input']>;
  name: OntologyStructuredDataInput;
  noExpiryDate: Scalars['Boolean']['input'];
};

export type UpdateLicencePayload = {
  __typename?: 'UpdateLicencePayload';
  viewer?: Maybe<Candidate>;
};

export type UpdateNoticePeriodInput = {
  weeks: Scalars['Int']['input'];
};

export type UpdateNoticePeriodPayload = {
  __typename?: 'UpdateNoticePeriodPayload';
  /** @deprecated Use viewer.noticePeriod */
  ref?: Maybe<Scalars['UUID']['output']>;
  viewer?: Maybe<Candidate>;
};

export type UpdateNotificationCtpPreferenceError =
  NotificationCtpPreferenceError & {
    __typename?: 'UpdateNotificationCtpPreferenceError';
    errorCode?: Maybe<Scalars['Int']['output']>;
    message: Scalars['String']['output'];
  };

export type UpdateNotificationCtpPreferenceErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type UpdateNotificationCtpPreferenceFailure = {
  __typename?: 'UpdateNotificationCtpPreferenceFailure';
  error: NotificationCtpPreferenceError;
};

export type UpdateNotificationCtpSubscriptionWithTokenPayload =
  | UpdateNotificationCtpPreferenceFailure
  | UpdateNotificationCtpSubscriptionWithTokenSuccess;

export type UpdateNotificationCtpSubscriptionWithTokenSuccess = {
  __typename?: 'UpdateNotificationCtpSubscriptionWithTokenSuccess';
  product: NotificationCtpProduct;
  subscribed: Scalars['Boolean']['output'];
  subscriptionChannel: NotificationCtpChannel;
};

export type UpdatePersonalDetailsInput = {
  countryCallingCode?: InputMaybe<CountryCallingCodeInput>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  /** *Note*: Nationality will be phased out as part of unification. Currently kept to support functionalies in SEEK Asia platforms. */
  nationality?: InputMaybe<NationalityInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePersonalDetailsPayload = {
  __typename?: 'UpdatePersonalDetailsPayload';
  /** @deprecated use viewer.personalDetails instead */
  personalDetails: PersonalDetails;
  viewer?: Maybe<Candidate>;
};

export type UpdatePreferredClassificationPayload = {
  __typename?: 'UpdatePreferredClassificationPayload';
  /** @deprecated Use viewer.preferredClassification */
  ref?: Maybe<Scalars['UUID']['output']>;
  viewer?: Maybe<Candidate>;
};

export type UpdatePreferredLocations2Error = {
  message: Scalars['String']['output'];
};

export type UpdatePreferredLocations2ErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type UpdatePreferredLocations2Failure = {
  __typename?: 'UpdatePreferredLocations2Failure';
  error: UpdatePreferredLocations2Error;
};

export type UpdatePreferredLocations2Input = {
  preferredLocations: Array<PreferredLocation2Input>;
};

export type UpdatePreferredLocations2Payload =
  | UpdatePreferredLocations2Failure
  | UpdatePreferredLocations2Success;

export type UpdatePreferredLocations2Success = {
  __typename?: 'UpdatePreferredLocations2Success';
  viewer?: Maybe<Candidate>;
};

export type UpdatePreferredWorkTypesInput = {
  ids: Array<Scalars['Int']['input']>;
};

export type UpdateProfileVisibilityInput = {
  level: VisibilityLevel;
};

export type UpdateProfileVisibilityPayload = {
  __typename?: 'UpdateProfileVisibilityPayload';
  /** @deprecated Use viewer.profileVisibility */
  ref?: Maybe<Scalars['String']['output']>;
  viewer?: Maybe<Candidate>;
};

export type UpdateQualificationInput = {
  completed: Scalars['Boolean']['input'];
  completionYear?: InputMaybe<Scalars['Int']['input']>;
  expectedCompletionDate?: InputMaybe<MonthYearInput>;
  highlights?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
  institute: OntologyStructuredDataInput;
  level2?: InputMaybe<Scalars['String']['input']>;
  name: OntologyStructuredDataInput;
};

export type UpdateQualificationPayload = {
  __typename?: 'UpdateQualificationPayload';
  /**
   * qualification does not have translation support due to it being deprecated
   * @deprecated Use `viewer.qualification`
   */
  qualification?: Maybe<Qualification>;
  viewer?: Maybe<Candidate>;
};

export type UpdateRightToWorkInput = {
  /** Country associated with the right to work */
  countryCode: Scalars['CountryCodeIso2']['input'];
  /** Unique ID that identifies a specific right to work */
  id: Scalars['UUID']['input'];
  /** Candidate's right to work for a specific country */
  type2?: InputMaybe<RightToWorkType2Input>;
};

export type UpdateRightsToWorkInput = {
  /** List of rights to work to update */
  rightsToWork: Array<UpdateRightToWorkInput>;
};

export type UpdateRightsToWorkPayload = {
  __typename?: 'UpdateRightsToWorkPayload';
  viewer?: Maybe<Candidate>;
};

export type UpdateRoleInput = {
  achievements?: InputMaybe<Scalars['String']['input']>;
  company: OntologyStructuredDataInput;
  from: MonthYearInput;
  /** Required for JobsDB only */
  function?: InputMaybe<RoleFunctionInput>;
  id: Scalars['UUID']['input'];
  /** Required for JobsDB only */
  industry?: InputMaybe<RoleIndustryInput>;
  seniority?: InputMaybe<OntologyStructuredDataInput>;
  title: OntologyStructuredDataInput;
  to?: InputMaybe<MonthYearInput>;
};

export type UpdateRolePayload = {
  __typename?: 'UpdateRolePayload';
  /** @deprecated Use viewer.roles */
  role?: Maybe<Role>;
  viewer?: Maybe<Candidate>;
};

export type UpdateSalaryPreferencesInput = {
  /** List of salary preferences to update */
  salaryPreferences: Array<SalaryPreference2Input>;
};

export type UpdateSalaryPreferencesPayload = {
  __typename?: 'UpdateSalaryPreferencesPayload';
  viewer?: Maybe<Candidate>;
};

export type UpdateSeekMaxMyThreadsLastViewedError = {
  message: Scalars['String']['output'];
};

export type UpdateSeekMaxMyThreadsLastViewedErrorMessageArgs = {
  locale: Scalars['Locale']['input'];
};

export type UpdateSeekMaxMyThreadsLastViewedFailure = {
  __typename?: 'UpdateSeekMaxMyThreadsLastViewedFailure';
  errors: Array<UpdateSeekMaxMyThreadsLastViewedError>;
};

export type UpdateSeekMaxMyThreadsLastViewedInput = {
  zone: Scalars['Zone']['input'];
};

export type UpdateSeekMaxMyThreadsLastViewedPayload =
  | UpdateSeekMaxMyThreadsLastViewedFailure
  | UpdateSeekMaxMyThreadsLastViewedSuccess;

export type UpdateSeekMaxMyThreadsLastViewedSuccess = {
  __typename?: 'UpdateSeekMaxMyThreadsLastViewedSuccess';
  success: Scalars['Boolean']['output'];
};

export type UpdateShareableProfileSettingsError = {
  message: Scalars['String']['output'];
};

export type UpdateShareableProfileSettingsErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type UpdateShareableProfileSettingsFailure = {
  __typename?: 'UpdateShareableProfileSettingsFailure';
  error: UpdateShareableProfileSettingsError;
};

export type UpdateShareableProfileSettingsInput = {
  active: Scalars['Boolean']['input'];
  privacy?: InputMaybe<PrivacyInput>;
};

export type UpdateShareableProfileSettingsPayload =
  | UpdateShareableProfileSettingsFailure
  | UpdateShareableProfileSettingsSuccess;

export type UpdateShareableProfileSettingsSuccess = {
  __typename?: 'UpdateShareableProfileSettingsSuccess';
  viewer?: Maybe<Candidate>;
};

export type UpdateShareableProfileViewsError = {
  message: Scalars['String']['output'];
};

export type UpdateShareableProfileViewsErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type UpdateShareableProfileViewsFailure = {
  __typename?: 'UpdateShareableProfileViewsFailure';
  error: UpdateShareableProfileViewsError;
};

export type UpdateShareableProfileViewsInput = {
  profileSlug: Scalars['String']['input'];
};

export type UpdateShareableProfileViewsPayload =
  | UpdateShareableProfileViewsFailure
  | UpdateShareableProfileViewsSuccess;

export type UpdateShareableProfileViewsSuccess = {
  __typename?: 'UpdateShareableProfileViewsSuccess';
  ref: Scalars['UUID']['output'];
};

export type UpdateSkillsInput = {
  skills: Array<SkillInput>;
};

export type UpdateSkillsPayload = {
  __typename?: 'UpdateSkillsPayload';
  /** @deprecated Use `viewer.skills` instead */
  skills?: Maybe<Array<Skill>>;
  viewer?: Maybe<Candidate>;
};

export type UpdateVerifiedLicenceInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

export type UpdateVerifiedLicencePayload = {
  __typename?: 'UpdateVerifiedLicencePayload';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
};

export type UpdateVerifiedQualificationInput = {
  highlights?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['UUID']['input'];
};

export type UpdateVerifiedQualificationPayload = {
  __typename?: 'UpdateVerifiedQualificationPayload';
  viewer?: Maybe<Candidate>;
};

export type UpdateWorkTypesPayload = {
  __typename?: 'UpdateWorkTypesPayload';
  /** @deprecated Use viewer.workTypes */
  ref?: Maybe<Scalars['UUID']['output']>;
  viewer?: Maybe<Candidate>;
};

export type UpdateYearsOfExperienceInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  newToWorkforce: Scalars['Boolean']['input'];
};

export type UpdateYearsOfExperiencePayload = {
  __typename?: 'UpdateYearsOfExperiencePayload';
  viewer?: Maybe<Candidate>;
};

export type Upvotes = {
  __typename?: 'Upvotes';
  count: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  userIds?: Maybe<Array<Scalars['String']['output']>>;
};

export type UpvotesDescriptionArgs = {
  locale?: Scalars['Locale']['input'];
};

export type UserCookie = {
  __typename?: 'UserCookie';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type VerifiableCredential = {
  __typename?: 'VerifiableCredential';
  label: Scalars['String']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type VerifiableCredentialCategory = {
  __typename?: 'VerifiableCredentialCategory';
  credentials?: Maybe<Array<BaseVerifiableCredential>>;
  label: Scalars['String']['output'];
  pendingCount?: Maybe<Scalars['Int']['output']>;
};

export type VerifiableCredentialGroup = {
  __typename?: 'VerifiableCredentialGroup';
  credentials: Array<VerifiableCredential>;
  label: Scalars['String']['output'];
};

export type VerifiableCredentialNudge = {
  __typename?: 'VerifiableCredentialNudge';
  credential: VerifiableCredential;
};

export type VerifiableCredentialNudge2 = {
  __typename?: 'VerifiableCredentialNudge2';
  credential: BaseVerifiableCredential;
};

export type VerificationNudge = {
  __typename?: 'VerificationNudge';
  ctaLabel: Scalars['String']['output'];
  ctaUrl: Scalars['String']['output'];
  description: Scalars['String']['output'];
  rank: Scalars['Int']['output'];
  uri: Scalars['String']['output'];
  verificationType: Array<Scalars['String']['output']>;
};

export type Verifications = {
  __typename?: 'Verifications';
  nudge?: Maybe<VerificationsNudge>;
  pending?: Maybe<Array<PendingCredentialGroup>>;
  verifiable: Array<VerifiableCredentialGroup>;
  verified: Array<VerifiedCredentialGroup>;
};

export type VerificationsNudge =
  | VerifiableCredentialNudge
  | VerificationsOpenDetailsNudge;

export type VerificationsNudge2 =
  | VerifiableCredentialNudge2
  | VerificationsOpenDetailsNudge;

export type VerificationsOpenDetailsNudge = {
  __typename?: 'VerificationsOpenDetailsNudge';
  ref: Scalars['UUID']['output'];
};

export enum VerificationsTestVariant {
  Identity = 'IDENTITY',
  Opendetails = 'OPENDETAILS',
  Verifiedworkemail = 'VERIFIEDWORKEMAIL',
}

export type VerifiedCredential = {
  __typename?: 'VerifiedCredential';
  credentialInfo: Array<CredentialInfo>;
  label: Scalars['String']['output'];
};

export type VerifiedCredential2 = {
  __typename?: 'VerifiedCredential2';
  credentialInfo: Array<CredentialInfo>;
  /** String to indicate the type of credential used for frontend tracking, e.g. identity, workplace */
  credentialType: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type VerifiedCredentialCategory = {
  __typename?: 'VerifiedCredentialCategory';
  credentials?: Maybe<Array<VerifiedCredential2>>;
  label: Scalars['String']['output'];
};

export type VerifiedCredentialGroup = {
  __typename?: 'VerifiedCredentialGroup';
  credentials: Array<VerifiedCredential>;
  label: Scalars['String']['output'];
};

export type VerifiedIdentity = {
  __typename?: 'VerifiedIdentity';
  isMatchingProfileName?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated use name */
  label?: Maybe<Scalars['String']['output']>;
  manageVerificationUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  verificationUrl?: Maybe<Scalars['String']['output']>;
};

export type VideoProduct = {
  __typename?: 'VideoProduct';
  position: VideoProductPosition;
  url: Scalars['String']['output'];
};

export enum VideoProductPosition {
  Bottom = 'BOTTOM',
  Top = 'TOP',
}

export enum VirusScanStatus {
  HasVirus = 'HasVirus',
  Ok = 'OK',
  Pending = 'Pending',
  Unknown = 'Unknown',
}

export enum VisibilityLevel {
  Hidden = 'Hidden',
  Limited = 'Limited',
  Standard = 'Standard',
}

export type VisitorContextInput = {
  /** visitorId from client */
  key: Scalars['UUID']['input'];
};

export type Website = {
  __typename?: 'Website';
  displayName: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type WorkArrangementOption = {
  __typename?: 'WorkArrangementOption';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type WorkArrangementOptionLabelArgs = {
  locale: Scalars['Locale']['input'];
};

export type WorkType = {
  __typename?: 'WorkType';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type WorkTypeTaxonomyOption = {
  __typename?: 'WorkTypeTaxonomyOption';
  /** Human readable string used when the user is selecting a work type option. */
  description: Scalars['String']['output'];
  /** Unique ID used to identify a specific work type option */
  id: Scalars['Int']['output'];
};

export type WorkplaceVerifiableCredential = BaseVerifiableCredential & {
  __typename?: 'WorkplaceVerifiableCredential';
  label: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type WrongPasswordError = I18nAccountError & {
  __typename?: 'WrongPasswordError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  reason: Scalars['String']['output'];
};

export type WrongPasswordErrorMessageArgs = {
  locale?: InputMaybe<Scalars['Locale']['input']>;
};

export type Year = {
  __typename?: 'Year';
  year: Scalars['Int']['output'];
};

export type YearsOfExperience = {
  __typename?: 'YearsOfExperience';
  /** Whether or not the candidate is new to the workforce */
  newToWorkforce: Scalars['Boolean']['output'];
  /** The years of experience option chosen by the candidate */
  option?: Maybe<YearsOfExperienceOption>;
};

export type YearsOfExperienceOption = {
  __typename?: 'YearsOfExperienceOption';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type RecommendedGlobalJobFragmentFragment = {
  __typename?: 'RecommendedGlobalJob';
  id: string;
  isSaved: boolean;
};

export type RecommendedGlobalJobTagsFragmentFragment = {
  __typename?: 'RecommendedGlobalJob';
  id: string;
  isNew: boolean;
  tags?: Array<{
    __typename?: 'JobTag';
    type: string;
    label?: string | null;
  }> | null;
};

export type ViewerFragment = {
  __typename?: 'Query';
  viewer?: { __typename?: 'Candidate'; id: number } | null;
};

export type CreateApacSavedSearchMutationVariables = Exact<{
  input: CreateApacSavedSearchInput;
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
  locale?: InputMaybe<Scalars['Locale']['input']>;
  cluster?: InputMaybe<Scalars['SmarterSearchCluster']['input']>;
}>;

export type CreateApacSavedSearchMutation = {
  __typename?: 'Mutation';
  createApacSavedSearch:
    | {
        __typename?: 'CreateApacSavedSearchFailure';
        error:
          | {
              __typename?: 'CreateApacSavedSearchDuplicateError';
              message: string;
            }
          | {
              __typename?: 'CreateApacSavedSearchErrorMaxExceeded';
              message: string;
            }
          | {
              __typename?: 'CreateApacSavedSearchInvalidLocationError';
              message: string;
            };
      }
    | {
        __typename?: 'CreateApacSavedSearchSuccess';
        savedSearch: {
          __typename?: 'ApacSavedSearch';
          id: string;
          name: string;
          countLabel: string;
          query: {
            __typename?: 'SavedSearchApacQuery';
            searchQueryString?: any | null;
            parameters: Array<{
              __typename?: 'SavedSearchParameters';
              type: string;
              value: Array<string>;
            }>;
          };
        };
      };
};

export type CreateUnregisteredApacSavedSearchWithChecksumMutationVariables =
  Exact<{
    input: CreateUnregisteredApacSavedSearchWithChecksumInput;
    locale?: InputMaybe<Scalars['Locale']['input']>;
  }>;

export type CreateUnregisteredApacSavedSearchWithChecksumMutation = {
  __typename?: 'Mutation';
  createUnregisteredApacSavedSearchWithChecksum:
    | {
        __typename?: 'CreateUnregisteredApacSavedSearchWithChecksumFailure';
        error:
          | {
              __typename?: 'CreateUnregisteredApacSavedSearchDuplicateError';
              message: string;
            }
          | {
              __typename?: 'CreateUnregisteredApacSavedSearchErrorMaxExceeded';
              message: string;
            }
          | {
              __typename?: 'CreateUnregisteredApacSavedSearchInvalidChecksumError';
              message: string;
            }
          | {
              __typename?: 'CreateUnregisteredApacSavedSearchInvalidEmailError';
              message: string;
            }
          | {
              __typename?: 'CreateUnregisteredApacSavedSearchInvalidLocationError';
              message: string;
            };
      }
    | {
        __typename?: 'CreateUnregisteredApacSavedSearchWithChecksumSuccess';
        id: string;
      };
};

export type CreateSavedJobMutationVariables = Exact<{
  input: CreateSavedJobInput;
  locale?: InputMaybe<Scalars['Locale']['input']>;
}>;

export type CreateSavedJobMutation = {
  __typename?: 'Mutation';
  createSavedJob2:
    | {
        __typename: 'CreateSavedJobFailure';
        errors: Array<{
          __typename: 'CreateSavedJobValidationError';
          message: string;
        }>;
      }
    | { __typename: 'CreateSavedJobSuccess'; id: string };
};

export type DeleteSavedJobMutationVariables = Exact<{
  input: DeleteSavedJobInput;
  locale?: InputMaybe<Scalars['Locale']['input']>;
}>;

export type DeleteSavedJobMutation = {
  __typename?: 'Mutation';
  deleteSavedJob2:
    | {
        __typename: 'DeleteSavedJobFailure';
        errors: Array<{
          __typename: 'DeleteSavedJobValidationError';
          message: string;
        }>;
      }
    | { __typename: 'DeleteSavedJobSuccess'; id: string };
};

export type CreateReportedJobMutationVariables = Exact<{
  report: ReportJobInput;
}>;

export type CreateReportedJobMutation = {
  __typename?: 'Mutation';
  reportJob: { __typename?: 'ReportJobPayload'; success: boolean };
};

export type UpdateSkillsMutationVariables = Exact<{
  input: UpdateSkillsInput;
}>;

export type UpdateSkillsMutation = {
  __typename?: 'Mutation';
  updateSkills?: {
    __typename?: 'UpdateSkillsPayload';
    viewer?: {
      __typename?: 'Candidate';
      skills2?: Array<{
        __typename?: 'Skill';
        keyword: {
          __typename?: 'OntologyStructuredData';
          text: string;
          ontologyId?: string | null;
        };
      }> | null;
    } | null;
  } | null;
};

export type TrackJobDetailsViewedMutationVariables = Exact<{
  input: TrackJobDetailsViewedInput;
  locale?: InputMaybe<Scalars['Locale']['input']>;
}>;

export type TrackJobDetailsViewedMutation = {
  __typename?: 'Mutation';
  trackJobDetailsViewed:
    | { __typename: 'TrackJobDetailsViewedFailure'; error: never }
    | { __typename: 'TrackJobDetailsViewedSuccess'; eventId?: string | null };
};

export type TrackSearchResultSelectedMutationVariables = Exact<{
  input: TrackSearchResultSelectedInput;
  locale?: InputMaybe<Scalars['Locale']['input']>;
}>;

export type TrackSearchResultSelectedMutation = {
  __typename?: 'Mutation';
  trackSearchResultSelected:
    | { __typename: 'TrackSearchResultSelectedFailure'; error: never }
    | {
        __typename: 'TrackSearchResultSelectedSuccess';
        eventId?: string | null;
      };
};

export type GetSavedSearchesQueryVariables = Exact<{
  cluster?: InputMaybe<Scalars['SmarterSearchCluster']['input']>;
  languageCode?: InputMaybe<Scalars['LanguageCodeIso']['input']>;
}>;

export type GetSavedSearchesQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Candidate';
    id: number;
    apacSavedSearches?: Array<{
      __typename?: 'ApacSavedSearch';
      id: string;
      name: string;
      countLabel: string;
      query: {
        __typename?: 'SavedSearchApacQuery';
        searchQueryString?: any | null;
        parameters: Array<{
          __typename?: 'SavedSearchParameters';
          type: string;
          value: Array<string>;
        }>;
      };
    }> | null;
  } | null;
};

export type GetBannerQueryVariables = Exact<{
  placement: BannerPlacement;
  country: Scalars['String']['input'];
  visitorId?: InputMaybe<Scalars['UUID']['input']>;
  locale: Scalars['Locale']['input'];
  zone?: InputMaybe<Scalars['Zone']['input']>;
  loggedIn?: InputMaybe<Scalars['String']['input']>;
  candidateId?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  classification?: InputMaybe<Scalars['String']['input']>;
  subclassification?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['String']['input']>;
  workType?: InputMaybe<Scalars['String']['input']>;
  salaryType?: InputMaybe<Scalars['String']['input']>;
  salaryRange?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetBannerQuery = {
  __typename?: 'Query';
  banner?: {
    __typename?: 'Banner';
    id?: string | null;
    analytics?: any | null;
    template?: {
      __typename?: 'BannerTemplate';
      id?: string | null;
      image?: {
        __typename?: 'BannerImage';
        url?: string | null;
        urlDark?: string | null;
      } | null;
      link?: {
        __typename?: 'BannerLink';
        text?: string | null;
        url?: string | null;
        style?: {
          __typename?: 'BannerStyle';
          color?: string | null;
          colorDark?: string | null;
        } | null;
      } | null;
      heading?: {
        __typename?: 'BannerHeading';
        text?: string | null;
        style?: {
          __typename?: 'BannerStyle';
          color?: string | null;
          colorDark?: string | null;
        } | null;
      } | null;
      style?: {
        __typename?: 'BannerStyle';
        color?: string | null;
        colorDark?: string | null;
      } | null;
      items: Array<{
        __typename?: 'BannerItems';
        id?: string | null;
        text?: string | null;
        href?: string | null;
      }>;
    } | null;
  } | null;
};

export type GetCandidateDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCandidateDetailsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Candidate';
    id: number;
    emailAddress: any;
    trackingId: string;
    personalDetails: {
      __typename?: 'PersonalDetails';
      firstName?: string | null;
    };
    identity: {
      __typename?: 'Identity';
      actor?: { __typename?: 'Actor'; id: string } | null;
    };
  } | null;
};

export type JobFragment = {
  __typename?: 'JobDetails';
  companySearchUrl?: string | null;
  job: {
    __typename?: 'Job';
    sourceZone?: string | null;
    id: string;
    title: string;
    phoneNumber?: string | null;
    isExpired: boolean;
    isLinkOut: boolean;
    isVerified: boolean;
    abstract: string;
    content: string;
    status: string;
    shareLink?: string | null;
    tracking?: {
      __typename?: 'JobTracking';
      adProductType?: string | null;
      hasRoleRequirements?: boolean | null;
      isPrivateAdvertiser?: boolean | null;
      workTypeIds?: string | null;
      postedTime?: string | null;
      classificationInfo?: {
        __typename?: 'JobTrackingClassificationInfo';
        classificationId?: string | null;
        classification?: string | null;
        subClassificationId?: string | null;
        subClassification?: string | null;
      } | null;
      locationInfo?: {
        __typename?: 'JobTrackingLocationInfo';
        area?: string | null;
        location?: string | null;
        locationIds?: Array<string> | null;
      } | null;
    } | null;
    expiresAt: { __typename?: 'SeekDateTime'; dateTimeUtc: any };
    contactMatches?: Array<{
      __typename?: 'ContactMatches';
      type: string;
      value: string;
    }> | null;
    listedAt?: {
      __typename?: 'SeekDateTime';
      label: string;
      dateTimeUtc: any;
    } | null;
    salary?: {
      __typename?: 'JobSalary';
      currencyLabel?: string | null;
      label: string;
    } | null;
    workTypes: { __typename?: 'JobWorkTypes'; label: string };
    advertiser: {
      __typename?: 'Advertiser';
      id: string;
      name: string;
      isVerified?: boolean | null;
      registrationDate?: {
        __typename?: 'SeekDateTime';
        dateTimeUtc: any;
      } | null;
    };
    location: { __typename?: 'LocationInfo'; label: string };
    classifications: Array<{
      __typename?: 'ClassificationInfo';
      label: string;
    }>;
    products?: {
      __typename?: 'JobProducts';
      bullets?: Array<string> | null;
      branding?: {
        __typename?: 'JobProductBranding';
        id: string;
        cover?: { __typename?: 'JobProductBrandingImage'; url: string } | null;
        thumbnailCover?: {
          __typename?: 'JobProductBrandingImage';
          url: string;
        } | null;
        logo: { __typename?: 'JobProductBrandingImage'; url: string };
      } | null;
      questionnaire?: {
        __typename?: 'JobQuestionnaire';
        questions: Array<string>;
      } | null;
      video?: {
        __typename?: 'VideoProduct';
        url: string;
        position: VideoProductPosition;
      } | null;
    } | null;
  };
  companyProfile?: {
    __typename?: 'CompanyProfile';
    id: string;
    name: string;
    companyNameSlug: string;
    shouldDisplayReviews: boolean;
    branding?: { __typename?: 'Branding'; logo?: string | null } | null;
    overview?: {
      __typename?: 'Overview';
      industry?: string | null;
      description?: {
        __typename?: 'Description';
        paragraphs: Array<string>;
      } | null;
      size?: { __typename?: 'CompanySize'; description: string } | null;
      website?: { __typename?: 'Website'; url: string } | null;
    } | null;
    reviewsSummary?: {
      __typename?: 'ReviewsSummary';
      overallRating: {
        __typename?: 'OverallRating';
        value: number;
        numberOfReviews: { __typename?: 'NumberOfReviews'; value: number };
      };
    } | null;
    perksAndBenefits: Array<{ __typename?: 'PerkAndBenefit'; title: string }>;
  } | null;
  companyTags: Array<{ __typename?: 'CompanyTag'; key: string; value: string }>;
  restrictedApplication: {
    __typename?: 'JobDetailsRestrictedApplication';
    label?: string | null;
  };
  sourcr?: {
    __typename?: 'JobDetailsSourcr';
    image: string;
    imageMobile: string;
    link: string;
  } | null;
};

export type JobPersonalisedFragment = {
  __typename?: 'JobDetails';
  personalised?: {
    __typename?: 'PersonalisedJobDetails';
    isSaved?: boolean | null;
    appliedDateTime?: {
      __typename?: 'SeekDateTime';
      shortAbsoluteLabel: string;
    } | null;
    topApplicantBadge?: {
      __typename?: 'TopApplicantBadge';
      label: string;
      description: string;
    } | null;
    salaryMatch?:
      | { __typename?: 'JobProfileMissingSalaryPreference'; label: string }
      | {
          __typename?: 'JobProfileSalaryMatch';
          label: string;
          salaryPreference: {
            __typename?: 'SalaryPreference2';
            id: any;
            description?: string | null;
            currencyCode: any;
            amount?: any | null;
            salaryType: SalaryType2;
            country: {
              __typename?: 'CountryInformation';
              countryCode: any;
              name: string;
            };
          };
        }
      | { __typename?: 'JobProfileSalaryNoMatch'; label: string }
      | null;
  } | null;
};

export type JobDetailsQueryVariables = Exact<{
  jobId: Scalars['ID']['input'];
  jobDetailsViewedCorrelationId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
  zone: Scalars['Zone']['input'];
  locale: Scalars['Locale']['input'];
  languageCode: Scalars['LanguageCodeIso']['input'];
  countryCode: Scalars['CountryCodeIso2']['input'];
  timezone: Scalars['Timezone']['input'];
}>;

export type JobDetailsQuery = {
  __typename?: 'Query';
  jobDetails?: {
    __typename?: 'JobDetails';
    companySearchUrl?: string | null;
    learningInsights?: {
      __typename?: 'LearningInsights';
      analytics?: any | null;
      content: string;
    } | null;
    gfjInfo?: {
      __typename?: 'GFJInfo';
      location?: {
        __typename?: 'GFJLocation';
        countryCode?: string | null;
        country?: string | null;
        suburb?: string | null;
        region?: string | null;
        state?: string | null;
        postcode?: string | null;
      } | null;
      workTypes?: {
        __typename?: 'GFJWorkTypes';
        label?: Array<string> | null;
      } | null;
    } | null;
    job: {
      __typename?: 'Job';
      sourceZone?: string | null;
      id: string;
      title: string;
      phoneNumber?: string | null;
      isExpired: boolean;
      isLinkOut: boolean;
      isVerified: boolean;
      abstract: string;
      content: string;
      status: string;
      shareLink?: string | null;
      tracking?: {
        __typename?: 'JobTracking';
        adProductType?: string | null;
        hasRoleRequirements?: boolean | null;
        isPrivateAdvertiser?: boolean | null;
        workTypeIds?: string | null;
        postedTime?: string | null;
        classificationInfo?: {
          __typename?: 'JobTrackingClassificationInfo';
          classificationId?: string | null;
          classification?: string | null;
          subClassificationId?: string | null;
          subClassification?: string | null;
        } | null;
        locationInfo?: {
          __typename?: 'JobTrackingLocationInfo';
          area?: string | null;
          location?: string | null;
          locationIds?: Array<string> | null;
        } | null;
      } | null;
      expiresAt: { __typename?: 'SeekDateTime'; dateTimeUtc: any };
      contactMatches?: Array<{
        __typename?: 'ContactMatches';
        type: string;
        value: string;
      }> | null;
      listedAt?: {
        __typename?: 'SeekDateTime';
        label: string;
        dateTimeUtc: any;
      } | null;
      salary?: {
        __typename?: 'JobSalary';
        currencyLabel?: string | null;
        label: string;
      } | null;
      workTypes: { __typename?: 'JobWorkTypes'; label: string };
      advertiser: {
        __typename?: 'Advertiser';
        id: string;
        name: string;
        isVerified?: boolean | null;
        registrationDate?: {
          __typename?: 'SeekDateTime';
          dateTimeUtc: any;
        } | null;
      };
      location: { __typename?: 'LocationInfo'; label: string };
      classifications: Array<{
        __typename?: 'ClassificationInfo';
        label: string;
      }>;
      products?: {
        __typename?: 'JobProducts';
        bullets?: Array<string> | null;
        branding?: {
          __typename?: 'JobProductBranding';
          id: string;
          cover?: {
            __typename?: 'JobProductBrandingImage';
            url: string;
          } | null;
          thumbnailCover?: {
            __typename?: 'JobProductBrandingImage';
            url: string;
          } | null;
          logo: { __typename?: 'JobProductBrandingImage'; url: string };
        } | null;
        questionnaire?: {
          __typename?: 'JobQuestionnaire';
          questions: Array<string>;
        } | null;
        video?: {
          __typename?: 'VideoProduct';
          url: string;
          position: VideoProductPosition;
        } | null;
      } | null;
    };
    companyProfile?: {
      __typename?: 'CompanyProfile';
      id: string;
      name: string;
      companyNameSlug: string;
      shouldDisplayReviews: boolean;
      branding?: { __typename?: 'Branding'; logo?: string | null } | null;
      overview?: {
        __typename?: 'Overview';
        industry?: string | null;
        description?: {
          __typename?: 'Description';
          paragraphs: Array<string>;
        } | null;
        size?: { __typename?: 'CompanySize'; description: string } | null;
        website?: { __typename?: 'Website'; url: string } | null;
      } | null;
      reviewsSummary?: {
        __typename?: 'ReviewsSummary';
        overallRating: {
          __typename?: 'OverallRating';
          value: number;
          numberOfReviews: { __typename?: 'NumberOfReviews'; value: number };
        };
      } | null;
      perksAndBenefits: Array<{ __typename?: 'PerkAndBenefit'; title: string }>;
    } | null;
    companyTags: Array<{
      __typename?: 'CompanyTag';
      key: string;
      value: string;
    }>;
    restrictedApplication: {
      __typename?: 'JobDetailsRestrictedApplication';
      label?: string | null;
    };
    sourcr?: {
      __typename?: 'JobDetailsSourcr';
      image: string;
      imageMobile: string;
      link: string;
    } | null;
  } | null;
};

export type JobDetailsPersonalisedQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  tracking?: InputMaybe<JobDetailsTrackingInput>;
  locale: Scalars['Locale']['input'];
  zone: Scalars['Zone']['input'];
  languageCode: Scalars['LanguageCodeIso']['input'];
  timezone: Scalars['Timezone']['input'];
}>;

export type JobDetailsPersonalisedQuery = {
  __typename?: 'Query';
  jobDetails?: {
    __typename?: 'JobDetails';
    personalised?: {
      __typename?: 'PersonalisedJobDetails';
      isSaved?: boolean | null;
      appliedDateTime?: {
        __typename?: 'SeekDateTime';
        shortAbsoluteLabel: string;
      } | null;
      topApplicantBadge?: {
        __typename?: 'TopApplicantBadge';
        label: string;
        description: string;
      } | null;
      salaryMatch?:
        | { __typename?: 'JobProfileMissingSalaryPreference'; label: string }
        | {
            __typename?: 'JobProfileSalaryMatch';
            label: string;
            salaryPreference: {
              __typename?: 'SalaryPreference2';
              id: any;
              description?: string | null;
              currencyCode: any;
              amount?: any | null;
              salaryType: SalaryType2;
              country: {
                __typename?: 'CountryInformation';
                countryCode: any;
                name: string;
              };
            };
          }
        | { __typename?: 'JobProfileSalaryNoMatch'; label: string }
        | null;
    } | null;
  } | null;
};

export type GetMatchedQualitiesQueryVariables = Exact<{
  jobDetailsId: Scalars['ID']['input'];
  tracking?: InputMaybe<JobDetailsTrackingInput>;
  locale: Scalars['Locale']['input'];
}>;

export type GetMatchedQualitiesQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Candidate';
    skills2?: Array<{
      __typename?: 'Skill';
      keyword: {
        __typename?: 'OntologyStructuredData';
        ontologyId?: string | null;
        text: string;
      };
    }> | null;
    resumes: Array<{ __typename?: 'Resume'; id: any }>;
  } | null;
  jobDetails?: {
    __typename?: 'JobDetails';
    personalised?: {
      __typename?: 'PersonalisedJobDetails';
      matchedSkills?: {
        __typename?: 'MatchedSkills';
        matched?: Array<{
          __typename?: 'Quality';
          ontologyId: string;
          displayLabel: string;
        } | null> | null;
        inferred?: Array<{
          __typename?: 'Quality';
          ontologyId: string;
          displayLabel: string;
        } | null> | null;
        unmatched?: Array<{
          __typename?: 'Quality';
          ontologyId: string;
          displayLabel: string;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type QualityFragment = {
  __typename?: 'Quality';
  ontologyId: string;
  displayLabel: string;
};

export type JobDetailsWithPersonalisedQueryVariables = Exact<{
  jobId: Scalars['ID']['input'];
  jobDetailsViewedCorrelationId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
  zone: Scalars['Zone']['input'];
  locale: Scalars['Locale']['input'];
  languageCode: Scalars['LanguageCodeIso']['input'];
  countryCode: Scalars['CountryCodeIso2']['input'];
  timezone: Scalars['Timezone']['input'];
}>;

export type JobDetailsWithPersonalisedQuery = {
  __typename?: 'Query';
  jobDetails?: {
    __typename?: 'JobDetails';
    companySearchUrl?: string | null;
    job: {
      __typename?: 'Job';
      sourceZone?: string | null;
      id: string;
      title: string;
      phoneNumber?: string | null;
      isExpired: boolean;
      isLinkOut: boolean;
      isVerified: boolean;
      abstract: string;
      content: string;
      status: string;
      shareLink?: string | null;
      tracking?: {
        __typename?: 'JobTracking';
        adProductType?: string | null;
        hasRoleRequirements?: boolean | null;
        isPrivateAdvertiser?: boolean | null;
        workTypeIds?: string | null;
        postedTime?: string | null;
        classificationInfo?: {
          __typename?: 'JobTrackingClassificationInfo';
          classificationId?: string | null;
          classification?: string | null;
          subClassificationId?: string | null;
          subClassification?: string | null;
        } | null;
        locationInfo?: {
          __typename?: 'JobTrackingLocationInfo';
          area?: string | null;
          location?: string | null;
          locationIds?: Array<string> | null;
        } | null;
      } | null;
      expiresAt: { __typename?: 'SeekDateTime'; dateTimeUtc: any };
      contactMatches?: Array<{
        __typename?: 'ContactMatches';
        type: string;
        value: string;
      }> | null;
      listedAt?: {
        __typename?: 'SeekDateTime';
        label: string;
        dateTimeUtc: any;
      } | null;
      salary?: {
        __typename?: 'JobSalary';
        currencyLabel?: string | null;
        label: string;
      } | null;
      workTypes: { __typename?: 'JobWorkTypes'; label: string };
      advertiser: {
        __typename?: 'Advertiser';
        id: string;
        name: string;
        isVerified?: boolean | null;
        registrationDate?: {
          __typename?: 'SeekDateTime';
          dateTimeUtc: any;
        } | null;
      };
      location: { __typename?: 'LocationInfo'; label: string };
      classifications: Array<{
        __typename?: 'ClassificationInfo';
        label: string;
      }>;
      products?: {
        __typename?: 'JobProducts';
        bullets?: Array<string> | null;
        branding?: {
          __typename?: 'JobProductBranding';
          id: string;
          cover?: {
            __typename?: 'JobProductBrandingImage';
            url: string;
          } | null;
          thumbnailCover?: {
            __typename?: 'JobProductBrandingImage';
            url: string;
          } | null;
          logo: { __typename?: 'JobProductBrandingImage'; url: string };
        } | null;
        questionnaire?: {
          __typename?: 'JobQuestionnaire';
          questions: Array<string>;
        } | null;
        video?: {
          __typename?: 'VideoProduct';
          url: string;
          position: VideoProductPosition;
        } | null;
      } | null;
    };
    companyProfile?: {
      __typename?: 'CompanyProfile';
      id: string;
      name: string;
      companyNameSlug: string;
      shouldDisplayReviews: boolean;
      branding?: { __typename?: 'Branding'; logo?: string | null } | null;
      overview?: {
        __typename?: 'Overview';
        industry?: string | null;
        description?: {
          __typename?: 'Description';
          paragraphs: Array<string>;
        } | null;
        size?: { __typename?: 'CompanySize'; description: string } | null;
        website?: { __typename?: 'Website'; url: string } | null;
      } | null;
      reviewsSummary?: {
        __typename?: 'ReviewsSummary';
        overallRating: {
          __typename?: 'OverallRating';
          value: number;
          numberOfReviews: { __typename?: 'NumberOfReviews'; value: number };
        };
      } | null;
      perksAndBenefits: Array<{ __typename?: 'PerkAndBenefit'; title: string }>;
    } | null;
    companyTags: Array<{
      __typename?: 'CompanyTag';
      key: string;
      value: string;
    }>;
    restrictedApplication: {
      __typename?: 'JobDetailsRestrictedApplication';
      label?: string | null;
    };
    sourcr?: {
      __typename?: 'JobDetailsSourcr';
      image: string;
      imageMobile: string;
      link: string;
    } | null;
    personalised?: {
      __typename?: 'PersonalisedJobDetails';
      isSaved?: boolean | null;
      appliedDateTime?: {
        __typename?: 'SeekDateTime';
        shortAbsoluteLabel: string;
      } | null;
      topApplicantBadge?: {
        __typename?: 'TopApplicantBadge';
        label: string;
        description: string;
      } | null;
      salaryMatch?:
        | { __typename?: 'JobProfileMissingSalaryPreference'; label: string }
        | {
            __typename?: 'JobProfileSalaryMatch';
            label: string;
            salaryPreference: {
              __typename?: 'SalaryPreference2';
              id: any;
              description?: string | null;
              currencyCode: any;
              amount?: any | null;
              salaryType: SalaryType2;
              country: {
                __typename?: 'CountryInformation';
                countryCode: any;
                name: string;
              };
            };
          }
        | { __typename?: 'JobProfileSalaryNoMatch'; label: string }
        | null;
    } | null;
  } | null;
};

export type SearchLocationsSuggestQueryVariables = Exact<{
  query: Scalars['String']['input'];
  count: Scalars['Int']['input'];
  recentLocation: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['Locale']['input']>;
  country?: InputMaybe<Scalars['CountryCodeIso2']['input']>;
  visitorId?: InputMaybe<Scalars['UUID']['input']>;
  isRemoteEnabled?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SearchLocationsSuggestQuery = {
  __typename?: 'Query';
  searchLocationsSuggest: {
    __typename: 'SearchLocationSuggestions';
    suggestions: Array<
      | {
          __typename: 'LocationSuggestion';
          text: string;
          highlights: Array<{
            __typename?: 'SuggestionHighlight';
            start: number;
            end: number;
          }>;
        }
      | {
          __typename: 'LocationSuggestionGroup';
          label: string;
          suggestions: Array<{
            __typename?: 'LocationSuggestion';
            text: string;
            highlights: Array<{
              __typename?: 'SuggestionHighlight';
              start: number;
              end: number;
            }>;
          }>;
        }
    >;
  };
};

export type GetUserCookieQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserCookieQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Candidate';
    id: number;
    userCookie?: {
      __typename?: 'UserCookie';
      key: string;
      value: string;
    } | null;
  } | null;
};

export type GetHomeRecommendationsQueryVariables = Exact<{
  zone: Scalars['Zone']['input'];
  visitorId: Scalars['UUID']['input'];
  timezone: Scalars['Timezone']['input'];
  cluster?: InputMaybe<Scalars['SmarterSearchCluster']['input']>;
  locale: Scalars['Locale']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetHomeRecommendationsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Candidate';
    emailAddress: any;
    trackingId: string;
    id: number;
    personalDetails: {
      __typename?: 'PersonalDetails';
      firstName?: string | null;
    };
    recommendations?: {
      __typename?: 'Recommendations';
      recommendedGlobalJobs?: {
        __typename?: 'RecommendedGlobalJobs';
        solMetadata?: any | null;
        totalCount?: number | null;
        globalJobs: Array<{
          __typename?: 'RecommendedGlobalJob';
          id: string;
          isNew: boolean;
          isSaved: boolean;
          solMetadata: any;
          job: {
            __typename?: 'Job';
            id: string;
            title: string;
            advertiser: { __typename?: 'Advertiser'; name: string };
            salary?: {
              __typename?: 'JobSalary';
              currencyLabel?: string | null;
              label: string;
            } | null;
            location: { __typename?: 'LocationInfo'; label: string };
            listedAt?: { __typename?: 'SeekDateTime'; label: string } | null;
            products?: {
              __typename?: 'JobProducts';
              branding?: {
                __typename?: 'JobProductBranding';
                logo: { __typename?: 'JobProductBrandingImage'; url: string };
              } | null;
            } | null;
          };
          tags?: Array<{
            __typename?: 'JobTag';
            label?: string | null;
            type: string;
          }> | null;
        }>;
      } | null;
    } | null;
  } | null;
};

export type GetLoggedOutRecommendationsQueryVariables = Exact<{
  jobId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  zone: Scalars['Zone']['input'];
  visitorId: Scalars['UUID']['input'];
  timezone?: InputMaybe<Scalars['Timezone']['input']>;
  locale?: InputMaybe<Scalars['Locale']['input']>;
}>;

export type GetLoggedOutRecommendationsQuery = {
  __typename?: 'Query';
  loggedOutRecommendedJobs?: {
    __typename?: 'LoggedOutRecommendedGlobalJobs';
    solMetadata?: any | null;
    globalJobs?: Array<{
      __typename?: 'LoggedOutRecommendedGlobalJob';
      solMetadata: any;
      job?: {
        __typename?: 'Job';
        id: string;
        title: string;
        advertiser: { __typename?: 'Advertiser'; name: string };
        location: { __typename?: 'LocationInfo'; label: string };
        listedAt?: { __typename?: 'SeekDateTime'; shortLabel: string } | null;
        products?: {
          __typename?: 'JobProducts';
          branding?: {
            __typename?: 'JobProductBranding';
            logo: { __typename?: 'JobProductBrandingImage'; url: string };
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetSalaryNudgeQueryVariables = Exact<{
  zone: Scalars['String']['input'];
}>;

export type GetSalaryNudgeQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Candidate';
    salaryNudge?: {
      __typename?: 'CandidateSalaryNudge';
      firstName: string;
      id: string;
      imageUrl: string;
      prompt: string;
      text: string;
      callToActions: Array<{
        __typename?: 'CandidateSalaryNudgeCallToAction';
        displayText: string;
        operation: string;
      }>;
    } | null;
  } | null;
};

export type AddSalaryNudgeFeedbackMutationVariables = Exact<{
  nudgeId: Scalars['ID']['input'];
  nudgeOption: Scalars['String']['input'];
  zone: Scalars['String']['input'];
}>;

export type AddSalaryNudgeFeedbackMutation = {
  __typename?: 'Mutation';
  addSalaryNudgeFeedback: {
    __typename?: 'CandidateSalaryNudgeFeedbackPayload';
    nudgeId: string;
  };
};

export type SearchSavedAndAppliedJobsQueryVariables = Exact<{
  jobIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type SearchSavedAndAppliedJobsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Candidate';
    searchSavedJobs: Array<{
      __typename?: 'SearchSavedJobPartial';
      jobId: string;
    }>;
    searchAppliedJobs: Array<{
      __typename?: 'SearchAppliedJobPartial';
      jobId: string;
    }>;
  } | null;
};

export type GetHomeSavedJobsQueryVariables = Exact<{
  timezone: Scalars['Timezone']['input'];
  locale: Scalars['Locale']['input'];
  zone: Scalars['Zone']['input'];
}>;

export type GetHomeSavedJobsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Candidate';
    id: number;
    savedJobs: {
      __typename?: 'SavedJobConnection';
      total: number;
      edges: Array<{
        __typename?: 'SavedJobEdge';
        node: {
          __typename?: 'SavedJob';
          id: string;
          isActive: boolean;
          job: {
            __typename?: 'Job';
            id: string;
            title: string;
            advertiser: { __typename?: 'Advertiser'; name: string };
            salary?: {
              __typename?: 'JobSalary';
              currencyLabel?: string | null;
              label: string;
            } | null;
            location: { __typename?: 'LocationInfo'; label: string };
            listedAt?: { __typename?: 'SeekDateTime'; label: string } | null;
            products?: {
              __typename?: 'JobProducts';
              branding?: {
                __typename?: 'JobProductBranding';
                logo: { __typename?: 'JobProductBrandingImage'; url: string };
              } | null;
            } | null;
          };
        };
      }>;
    };
  } | null;
};

export type GetSkillsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSkillsQuery = {
  __typename?: 'Query';
  viewer?: {
    __typename?: 'Candidate';
    _id: string;
    skills2?: Array<{
      __typename?: 'Skill';
      keyword: {
        __typename?: 'OntologyStructuredData';
        text: string;
        ontologyId?: string | null;
      };
    }> | null;
  } | null;
};

export const RecommendedGlobalJobFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecommendedGlobalJobFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecommendedGlobalJob' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecommendedGlobalJobFragmentFragment, unknown>;
export const RecommendedGlobalJobTagsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecommendedGlobalJobTagsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecommendedGlobalJob' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isNew' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'locale' },
                      value: { kind: 'StringValue', value: '', block: false },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecommendedGlobalJobTagsFragmentFragment, unknown>;
export const ViewerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Viewer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Query' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ViewerFragment, unknown>;
export const JobFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'job' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sourceZone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tracking' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'adProductType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'classificationInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'classificationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'classification' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'subClassificationId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'subClassification',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasRoleRequirements' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isPrivateAdvertiser' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'area' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locationIds' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workTypeIds' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postedTime' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isExpired' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'expiresAt' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateTimeUtc' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isLinkOut' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactMatches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abstract' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'platform' },
                      value: { kind: 'EnumValue', value: 'WEB' },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listedAt' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'context' },
                            value: { kind: 'EnumValue', value: 'JOB_POSTED' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'length' },
                            value: { kind: 'EnumValue', value: 'SHORT' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'timezone' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'timezone' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateTimeUtc' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyLabel' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'zone' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'zone' },
                            },
                          },
                        ],
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareLink' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'platform' },
                      value: { kind: 'EnumValue', value: 'WEB' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'zone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'zone' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'locale' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'advertiser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isVerified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registrationDate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateTimeUtc' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'type' },
                            value: { kind: 'EnumValue', value: 'LONG' },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'classifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'languageCode' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'languageCode' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'branding' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cover' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'thumbnailCover' },
                              name: { kind: 'Name', value: 'cover' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'isThumbnail' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bullets' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionnaire' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'questions' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'video' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'position' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zone' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zone' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyNameSlug' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldDisplayReviews' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'branding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'overview' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paragraphs' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'size' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'website' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviewsSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'overallRating' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'numberOfReviews' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'perksAndBenefits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companySearchUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zone' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zone' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'languageCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'languageCode' },
                },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyTags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'key' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'languageCode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'languageCode' },
                      },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restrictedApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'countryCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'countryCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'locale' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourcr' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobFragment, unknown>;
export const JobPersonalisedFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'jobPersonalised' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'personalised' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appliedDateTime' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortAbsoluteLabel' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'timezone' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'timezone' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'topApplicantBadge' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'zone' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'zone' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salaryMatch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'JobProfileMissingSalaryPreference',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'JobProfileSalaryMatch',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'salaryPreference' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'languageCode' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'languageCode',
                                    },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'country' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'countryCode',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'currencyCode',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'amount' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salaryType' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'JobProfileSalaryNoMatch',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobPersonalisedFragment, unknown>;
export const QualityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'quality' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Quality' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'ontologyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayLabel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locale' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'locale' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QualityFragment, unknown>;
export const CreateApacSavedSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateApacSavedSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateApacSavedSearchInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'languageCode' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LanguageCodeIso' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Locale' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cluster' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SmarterSearchCluster' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createApacSavedSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'CreateApacSavedSearchSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'savedSearch' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'languageCode' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'languageCode',
                                    },
                                  },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'countLabel' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'cluster' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'cluster' },
                                  },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'query' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'languageCode' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'languageCode',
                                    },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'searchQueryString',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parameters' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'value',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'CreateApacSavedSearchFailure',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'error' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateApacSavedSearchMutation,
  CreateApacSavedSearchMutationVariables
>;
export const CreateUnregisteredApacSavedSearchWithChecksumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'CreateUnregisteredApacSavedSearchWithChecksum',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CreateUnregisteredApacSavedSearchWithChecksumInput',
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Locale' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'createUnregisteredApacSavedSearchWithChecksum',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value:
                        'CreateUnregisteredApacSavedSearchWithChecksumSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value:
                        'CreateUnregisteredApacSavedSearchWithChecksumFailure',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'error' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateUnregisteredApacSavedSearchWithChecksumMutation,
  CreateUnregisteredApacSavedSearchWithChecksumMutationVariables
>;
export const CreateSavedJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSavedJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSavedJobInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Locale' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSavedJob2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateSavedJobSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CreateSavedJobFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSavedJobMutation,
  CreateSavedJobMutationVariables
>;
export const DeleteSavedJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSavedJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteSavedJobInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Locale' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteSavedJob2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DeleteSavedJobSuccess' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'DeleteSavedJobFailure' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteSavedJobMutation,
  DeleteSavedJobMutationVariables
>;
export const CreateReportedJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateReportedJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'report' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReportJobInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'report' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateReportedJobMutation,
  CreateReportedJobMutationVariables
>;
export const UpdateSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateSkillsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSkills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'viewer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skills2' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'keyword' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ontologyId' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSkillsMutation,
  UpdateSkillsMutationVariables
>;
export const TrackJobDetailsViewedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TrackJobDetailsViewed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TrackJobDetailsViewedInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Locale' } },
          defaultValue: { kind: 'StringValue', value: 'en-AU', block: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trackJobDetailsViewed' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'TrackJobDetailsViewedSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eventId' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'TrackJobDetailsViewedFailure',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'error' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TrackJobDetailsViewedMutation,
  TrackJobDetailsViewedMutationVariables
>;
export const TrackSearchResultSelectedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TrackSearchResultSelected' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TrackSearchResultSelectedInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Locale' } },
          defaultValue: { kind: 'StringValue', value: 'en-AU', block: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trackSearchResultSelected' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'TrackSearchResultSelectedSuccess',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'eventId' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: {
                      kind: 'Name',
                      value: 'TrackSearchResultSelectedFailure',
                    },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'error' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'message' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TrackSearchResultSelectedMutation,
  TrackSearchResultSelectedMutationVariables
>;
export const GetSavedSearchesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSavedSearches' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cluster' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SmarterSearchCluster' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'languageCode' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LanguageCodeIso' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'apacSavedSearches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'languageCode' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'languageCode' },
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countLabel' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'cluster' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'cluster' },
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'query' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'languageCode' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'languageCode' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'searchQueryString',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parameters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSavedSearchesQuery,
  GetSavedSearchesQueryVariables
>;
export const GetBannerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBanner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'placement' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'BannerPlacement' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'country' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'visitorId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Locale' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Zone' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'loggedIn' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'candidateId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'roleId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'keywords' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'classification' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subclassification' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workType' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'salaryType' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'salaryRange' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'banner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'placement' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'placement' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'country' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'visitorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'visitorId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locale' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'locale' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zone' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zone' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'loggedIn' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'loggedIn' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'candidateId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'candidateId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'roleId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'roleId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'keywords' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'keywords' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'classification' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'classification' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subclassification' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subclassification' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'salaryType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'salaryType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'salaryRange' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'salaryRange' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'analytics' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'template' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'urlDark' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'text' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'style' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'color' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'colorDark' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'heading' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'text' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'style' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'color' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'colorDark' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'style' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'color' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'colorDark' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'text' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'href' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBannerQuery, GetBannerQueryVariables>;
export const GetCandidateDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCandidateDetails' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'personalDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailAddress' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'identity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'actor' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCandidateDetailsQuery,
  GetCandidateDetailsQueryVariables
>;
export const JobDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobDetailsViewedCorrelationId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sessionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Zone' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Locale' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'languageCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LanguageCodeIso' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'countryCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CountryCodeIso2' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Timezone' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tracking' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channel' },
                      value: {
                        kind: 'StringValue',
                        value: 'WEB',
                        block: false,
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'jobDetailsViewedCorrelationId',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'jobDetailsViewedCorrelationId',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sessionId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sessionId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'job' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningInsights' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'platform' },
                      value: { kind: 'EnumValue', value: 'WEB' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'zone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'zone' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'locale' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'analytics' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gfjInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'countryCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'suburb' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'region' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'postcode' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workTypes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'job' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sourceZone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tracking' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'adProductType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'classificationInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'classificationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'classification' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'subClassificationId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'subClassification',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasRoleRequirements' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isPrivateAdvertiser' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'area' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locationIds' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workTypeIds' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postedTime' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isExpired' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'expiresAt' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateTimeUtc' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isLinkOut' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactMatches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abstract' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'platform' },
                      value: { kind: 'EnumValue', value: 'WEB' },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listedAt' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'context' },
                            value: { kind: 'EnumValue', value: 'JOB_POSTED' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'length' },
                            value: { kind: 'EnumValue', value: 'SHORT' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'timezone' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'timezone' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateTimeUtc' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyLabel' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'zone' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'zone' },
                            },
                          },
                        ],
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareLink' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'platform' },
                      value: { kind: 'EnumValue', value: 'WEB' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'zone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'zone' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'locale' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'advertiser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isVerified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registrationDate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateTimeUtc' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'type' },
                            value: { kind: 'EnumValue', value: 'LONG' },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'classifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'languageCode' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'languageCode' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'branding' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cover' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'thumbnailCover' },
                              name: { kind: 'Name', value: 'cover' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'isThumbnail' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bullets' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionnaire' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'questions' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'video' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'position' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zone' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zone' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyNameSlug' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldDisplayReviews' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'branding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'overview' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paragraphs' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'size' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'website' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviewsSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'overallRating' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'numberOfReviews' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'perksAndBenefits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companySearchUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zone' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zone' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'languageCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'languageCode' },
                },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyTags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'key' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'languageCode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'languageCode' },
                      },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restrictedApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'countryCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'countryCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'locale' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourcr' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobDetailsQuery, JobDetailsQueryVariables>;
export const JobDetailsPersonalisedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobDetailsPersonalised' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tracking' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'JobDetailsTrackingInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Locale' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Zone' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'languageCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LanguageCodeIso' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Timezone' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tracking' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tracking' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'jobPersonalised' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'jobPersonalised' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'personalised' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appliedDateTime' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortAbsoluteLabel' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'timezone' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'timezone' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'topApplicantBadge' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'zone' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'zone' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salaryMatch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'JobProfileMissingSalaryPreference',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'JobProfileSalaryMatch',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'salaryPreference' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'languageCode' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'languageCode',
                                    },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'country' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'countryCode',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'currencyCode',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'amount' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salaryType' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'JobProfileSalaryNoMatch',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobDetailsPersonalisedQuery,
  JobDetailsPersonalisedQueryVariables
>;
export const GetMatchedQualitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMatchedQualities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobDetailsId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tracking' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'JobDetailsTrackingInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Locale' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skills2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keyword' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ontologyId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'text' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resumes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobDetailsId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tracking' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tracking' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'personalised' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'matchedSkills' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'matched' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'quality' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inferred' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'quality' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'unmatched' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'quality' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'quality' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Quality' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'ontologyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'displayLabel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locale' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'locale' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMatchedQualitiesQuery,
  GetMatchedQualitiesQueryVariables
>;
export const JobDetailsWithPersonalisedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobDetailsWithPersonalised' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobDetailsViewedCorrelationId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sessionId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Zone' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Locale' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'languageCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'LanguageCodeIso' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'countryCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CountryCodeIso2' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Timezone' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tracking' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'channel' },
                      value: {
                        kind: 'StringValue',
                        value: 'WEB',
                        block: false,
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'jobDetailsViewedCorrelationId',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'jobDetailsViewedCorrelationId',
                        },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sessionId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sessionId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'job' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'jobPersonalised' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'job' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'job' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'sourceZone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tracking' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'adProductType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'classificationInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'classificationId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'classification' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'subClassificationId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'subClassification',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasRoleRequirements' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isPrivateAdvertiser' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locationInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'area' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locationIds' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workTypeIds' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postedTime' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isExpired' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'expiresAt' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateTimeUtc' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isLinkOut' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactMatches' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'abstract' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'platform' },
                      value: { kind: 'EnumValue', value: 'WEB' },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listedAt' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'context' },
                            value: { kind: 'EnumValue', value: 'JOB_POSTED' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'length' },
                            value: { kind: 'EnumValue', value: 'SHORT' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'timezone' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'timezone' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateTimeUtc' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyLabel' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'zone' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'zone' },
                            },
                          },
                        ],
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareLink' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'platform' },
                      value: { kind: 'EnumValue', value: 'WEB' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'zone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'zone' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'locale' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workTypes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'advertiser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isVerified' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registrationDate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateTimeUtc' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'type' },
                            value: { kind: 'EnumValue', value: 'LONG' },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'classifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'languageCode' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'languageCode' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'branding' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cover' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'thumbnailCover' },
                              name: { kind: 'Name', value: 'cover' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'isThumbnail' },
                                  value: { kind: 'BooleanValue', value: true },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bullets' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionnaire' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'questions' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'video' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'position' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zone' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zone' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyNameSlug' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shouldDisplayReviews' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'branding' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'overview' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paragraphs' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'industry' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'size' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'website' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviewsSummary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'overallRating' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'numberOfReviews' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'perksAndBenefits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companySearchUrl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zone' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zone' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'languageCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'languageCode' },
                },
              },
            ],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyTags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'key' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'languageCode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'languageCode' },
                      },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restrictedApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'countryCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'countryCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'locale' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locale' },
                      },
                    },
                  ],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sourcr' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMobile' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'jobPersonalised' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobDetails' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'personalised' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isSaved' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appliedDateTime' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortAbsoluteLabel' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'timezone' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'timezone' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'topApplicantBadge' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'label' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'zone' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'zone' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salaryMatch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'JobProfileMissingSalaryPreference',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'JobProfileSalaryMatch',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'salaryPreference' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'languageCode' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'languageCode',
                                    },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'country' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'countryCode',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'currencyCode',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'amount' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'salaryType' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'JobProfileSalaryNoMatch',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locale' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locale' },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobDetailsWithPersonalisedQuery,
  JobDetailsWithPersonalisedQueryVariables
>;
export const SearchLocationsSuggestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchLocationsSuggest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'query' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'count' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recentLocation' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Locale' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'country' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'CountryCodeIso2' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'visitorId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isRemoteEnabled' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchLocationsSuggest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'query' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'count' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'count' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recentLocation' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'recentLocation' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locale' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'locale' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'country' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'visitorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'visitorId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isRemoteEnabled' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'isRemoteEnabled' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'suggestions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'LocationSuggestion' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'text' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'highlights' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'LocationSuggestionGroup',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'label' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'suggestions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'highlights' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'start',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'end' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchLocationsSuggestQuery,
  SearchLocationsSuggestQueryVariables
>;
export const GetUserCookieDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserCookie' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userCookie' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserCookieQuery, GetUserCookieQueryVariables>;
export const GetHomeRecommendationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHomeRecommendations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Zone' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'visitorId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Timezone' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cluster' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SmarterSearchCluster' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Locale' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'personalDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailAddress' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'trackingId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommendations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recommendedGlobalJobs' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'includeSavedJobs' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'limit' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'limit' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'source' },
                            value: { kind: 'EnumValue', value: 'HOME' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'zone' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'zone' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'visitorId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'visitorId' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'platform' },
                            value: { kind: 'EnumValue', value: 'WEB' },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'cluster' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'cluster' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'globalJobs' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isNew' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isSaved' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'solMetadata',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'job' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'advertiser',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'locale',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'locale',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'salary',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'currencyLabel',
                                                },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'zone',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'zone',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'label',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'location',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'label',
                                                },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'locale',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'locale',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'listedAt',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'label',
                                                },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'context',
                                                    },
                                                    value: {
                                                      kind: 'EnumValue',
                                                      value: 'JOB_POSTED',
                                                    },
                                                  },
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'length',
                                                    },
                                                    value: {
                                                      kind: 'EnumValue',
                                                      value: 'SHORT',
                                                    },
                                                  },
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'timezone',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'timezone',
                                                      },
                                                    },
                                                  },
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'locale',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'locale',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'products',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'branding',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'logo',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'url',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tags' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'label',
                                          },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: {
                                                kind: 'Name',
                                                value: 'locale',
                                              },
                                              value: {
                                                kind: 'Variable',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'locale',
                                                },
                                              },
                                            },
                                          ],
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'solMetadata' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCount' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHomeRecommendationsQuery,
  GetHomeRecommendationsQueryVariables
>;
export const GetLoggedOutRecommendationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLoggedOutRecommendations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Zone' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'visitorId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'Timezone' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Locale' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'loggedOutRecommendedJobs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'source' },
                value: { kind: 'EnumValue', value: 'LOGGED_OUT_HOME' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zone' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zone' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'visitorId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'visitorId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'platform' },
                value: { kind: 'EnumValue', value: 'WEB' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'globalJobs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'solMetadata' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'job' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'advertiser' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'locale' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'locale',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'location' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'locale' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'locale',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'listedAt' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'shortLabel' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: {
                                          kind: 'Name',
                                          value: 'timezone',
                                        },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'timezone',
                                          },
                                        },
                                      },
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'locale' },
                                        value: {
                                          kind: 'Variable',
                                          name: {
                                            kind: 'Name',
                                            value: 'locale',
                                          },
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'products' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'branding' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'logo' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'url',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'solMetadata' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLoggedOutRecommendationsQuery,
  GetLoggedOutRecommendationsQueryVariables
>;
export const GetSalaryNudgeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSalaryNudge' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zone' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salaryNudge' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'zone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'zone' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'callToActions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayText' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'operation' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'prompt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSalaryNudgeQuery, GetSalaryNudgeQueryVariables>;
export const AddSalaryNudgeFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddSalaryNudgeFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nudgeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nudgeOption' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zone' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addSalaryNudgeFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nudgeId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nudgeId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nudgeOption' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nudgeOption' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zone' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zone' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'nudgeId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddSalaryNudgeFeedbackMutation,
  AddSalaryNudgeFeedbackMutationVariables
>;
export const SearchSavedAndAppliedJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchSavedAndAppliedJobs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchSavedJobs' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'jobIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'jobIds' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchAppliedJobs' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'jobIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'jobIds' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SearchSavedAndAppliedJobsQuery,
  SearchSavedAndAppliedJobsQueryVariables
>;
export const GetHomeSavedJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHomeSavedJobs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Timezone' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locale' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Locale' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zone' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Zone' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'savedJobs' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '3' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isActive' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'job' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'advertiser',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'locale',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'locale',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'salary',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'currencyLabel',
                                                },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'zone',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'zone',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'label',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'location',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'label',
                                                },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'locale',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'locale',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'listedAt',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'label',
                                                },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'context',
                                                    },
                                                    value: {
                                                      kind: 'EnumValue',
                                                      value: 'JOB_POSTED',
                                                    },
                                                  },
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'length',
                                                    },
                                                    value: {
                                                      kind: 'EnumValue',
                                                      value: 'SHORT',
                                                    },
                                                  },
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'timezone',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'timezone',
                                                      },
                                                    },
                                                  },
                                                  {
                                                    kind: 'Argument',
                                                    name: {
                                                      kind: 'Name',
                                                      value: 'locale',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'locale',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'products',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'branding',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'logo',
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'url',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHomeSavedJobsQuery,
  GetHomeSavedJobsQueryVariables
>;
export const GetSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSkills' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skills2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keyword' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'text' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ontologyId' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSkillsQuery, GetSkillsQueryVariables>;
