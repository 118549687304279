
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBTRUVLIGJyYW5kcy4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+IiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiA8VGV4dExpbms+VmlldyBoZXJlPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBTRUVLIGJyYW5kcy4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+IiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiA8VGV4dExpbms+VmlldyBoZXJlPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBTRUVLIGJyYW5kcy4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+IiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiA8VGV4dExpbms+VmlldyBoZXJlPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBTRUVLIGJyYW5kcy4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+IiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiA8VGV4dExpbms+VmlldyBoZXJlPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBTRUVLIGJyYW5kcy4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+IiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiA8VGV4dExpbms+VmlldyBoZXJlPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBTRUVLIGJyYW5kcy4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+IiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiA8VGV4dExpbms+VmlldyBoZXJlPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzZGIsIEpvYnN0cmVldCBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+In0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzZGIsIEpvYnN0cmVldCBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+In0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzZGIsIEpvYnN0cmVldCBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+In0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzZGIsIEpvYnN0cmVldCBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+In0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+In0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+In0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+In0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+In0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+In0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+In0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+In0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+In0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+In0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+In0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzZGIsIEpvYnN0cmVldCBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+In0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzZGIsIEpvYnN0cmVldCBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms+In0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IkthbWkgdGVsYWggbWVtcGVyYmFydWkgS2ViaWpha2FuIFByaXZhc2kga2FtaSBkaSBzZWx1cnVoIG1lcmVrIEpvYnN0cmVldCwgSm9ic2RiLCBkYW4gU0VFSyBrYW1pLiA8VGV4dExpbms+TGloYXQgZGkgc2luaTwvVGV4dExpbms+IiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJLYW1pIHRlbGFoIG1lbXBlcmJhcnVpIEtlYmlqYWthbiBQcml2YXNpIGthbWkuIDxUZXh0TGluaz5MaWhhdCBkaSBzaW5pPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IkthbWkgdGVsYWggbWVtcGVyYmFydWkgS2ViaWpha2FuIFByaXZhc2kga2FtaSBkaSBzZWx1cnVoIG1lcmVrIEpvYnN0cmVldCwgSm9ic2RiLCBkYW4gU0VFSyBrYW1pLiA8VGV4dExpbms+TGloYXQgZGkgc2luaTwvVGV4dExpbms+IiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJLYW1pIHRlbGFoIG1lbXBlcmJhcnVpIEtlYmlqYWthbiBQcml2YXNpIGthbWkuIDxUZXh0TGluaz5MaWhhdCBkaSBzaW5pPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IuC5gOC4o+C4suC5hOC4lOC5ieC4lOC4s+C5gOC4meC4tOC4meC4geC4suC4o+C4m+C4o+C4seC4muC4m+C4o+C4uOC4h+C4meC5guC4meC4muC4suC4ouC4hOC4p+C4suC4oeC5gOC4m+C5h+C4meC4quC5iOC4p+C4meC4leC4seC4p+C4guC4reC4h+C5gOC4o+C4suC4quC4s+C4q+C4o+C4seC4muC5geC4muC4o+C4meC4lOC5jCBKb2JzZGIgSm9ic3RyZWV0IOC5geC4peC4sCBTRUVLIOC5guC4lOC4ouC4l+C5iOC4suC4meC4quC4suC4oeC4suC4o+C4luC5gOC4guC5ieC4suC4luC4tuC4hyA8VGV4dExpbms+4LiU4Li54LiX4Li14LmI4LiZ4Li14LmIPC9UZXh0TGluaz4iLCJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiI6IuC5gOC4o+C4suC5hOC4lOC5ieC4m+C4o+C4seC4muC4m+C4o+C4uOC4h+C4guC5ieC4reC4oeC4ueC4peC4meC5guC4ouC4muC4suC4ouC4hOC4p+C4suC4oeC5gOC4m+C5h+C4meC4quC5iOC4p+C4meC4leC4seC4p+C4guC4reC4h+C5gOC4o+C4siA8VGV4dExpbms+4LiU4Li54LiX4Li14LmI4LiZ4Li14LmIPC9UZXh0TGluaz4ifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IuC5gOC4o+C4suC5hOC4lOC5ieC4lOC4s+C5gOC4meC4tOC4meC4geC4suC4o+C4m+C4o+C4seC4muC4m+C4o+C4uOC4h+C4meC5guC4meC4muC4suC4ouC4hOC4p+C4suC4oeC5gOC4m+C5h+C4meC4quC5iOC4p+C4meC4leC4seC4p+C4guC4reC4h+C5gOC4o+C4suC4quC4s+C4q+C4o+C4seC4muC5geC4muC4o+C4meC4lOC5jCBKb2JzZGIgSm9ic3RyZWV0IOC5geC4peC4sCBTRUVLIOC5guC4lOC4ouC4l+C5iOC4suC4meC4quC4suC4oeC4suC4o+C4luC5gOC4guC5ieC4suC4luC4tuC4hyA8VGV4dExpbms+4LiU4Li54LiX4Li14LmI4LiZ4Li14LmIPC9UZXh0TGluaz4iLCJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiI6IuC5gOC4o+C4suC5hOC4lOC5ieC4m+C4o+C4seC4muC4m+C4o+C4uOC4h+C4guC5ieC4reC4oeC4ueC4peC4meC5guC4ouC4muC4suC4ouC4hOC4p+C4suC4oeC5gOC4m+C5h+C4meC4quC5iOC4p+C4meC4leC4seC4p+C4guC4reC4h+C5gOC4o+C4siA8VGV4dExpbms+4LiU4Li54LiX4Li14LmI4LiZ4Li14LmIPC9UZXh0TGluaz4ifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IlvFtOG6veG6veG6vSDhuKnEg8SDxIPhub3hur3hur3hur0gx5rHmseaxqXGjMSDxIPEg+G5r+G6veG6veG6vcaMIMO2w7bDtseax5rHmsWZIMakxZnDrMOsw6zhub3Eg8SDxIPDp8O9w73DvSDGpMO2w7bDtsaaw6zDrMOsw6fDvcO9w70gxIPEg8SDw6fFmcO2w7bDtsWhxaEgw7bDtsO2x5rHmseaxZkgxaDDi8OL4biwIMOfxZnEg8SDxIPguIHguLXguYnGjMWhLiA8VGV4dExpbms+4bm8w6zDrOG6veG6vcW1IOG4qeG6veG6vcWZ4bq94bq9PC9UZXh0TGluaz5dIiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJbxbThur3hur3hur0g4bipxIPEg8SD4bm94bq94bq94bq9IMeax5rHmsalxozEg8SDxIPhua/hur3hur3hur3GjCDDtsO2w7bHmseax5rFmSDGpMWZw6zDrMOs4bm9xIPEg8SDw6fDvcO9w70gxqTDtsO2w7bGmsOsw6zDrMOnw73DvcO9LiA8VGV4dExpbms+4bm8w6zDrOG6veG6vcW1IOG4qeG6veG6vcWZ4bq94bq9PC9UZXh0TGluaz5dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IlvFtOG6veG6veG6vSDhuKnEg8SDxIPhub3hur3hur3hur0gx5rHmseaxqXGjMSDxIPEg+G5r+G6veG6veG6vcaMIMO2w7bDtseax5rHmsWZIMakxZnDrMOsw6zhub3Eg8SDxIPDp8O9w73DvSDGpMO2w7bDtsaaw6zDrMOsw6fDvcO9w70gxIPEg8SDw6fFmcO2w7bDtsWhxaEgw7bDtsO2x5rHmseaxZkgxaDDi8OL4biwIMOfxZnEg8SDxIPguIHguLXguYnGjMWhLiA8VGV4dExpbms+4bm8w6zDrOG6veG6vcW1IOG4qeG6veG6vcWZ4bq94bq9PC9UZXh0TGluaz5dIiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJbxbThur3hur3hur0g4bipxIPEg8SD4bm94bq94bq94bq9IMeax5rHmsalxozEg8SDxIPhua/hur3hur3hur3GjCDDtsO2w7bHmseax5rFmSDGpMWZw6zDrMOs4bm9xIPEg8SDw6fDvcO9w70gxqTDtsO2w7bGmsOsw6zDrMOnw73DvcO9LiA8VGV4dExpbms+4bm8w6zDrOG6veG6vcW1IOG4qeG6veG6vcWZ4bq94bq9PC9UZXh0TGluaz5dIn0=!"
        )
      )
      });
  
      export { translations as default };
    