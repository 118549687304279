import type { JobViewType } from '@seek/seek-jobs-analytics';
import { useTranslations } from '@vocab/react';
import { useCallback, useMemo } from 'react';

import { useSignedInDashboardContext } from 'src/components/HomePage/Dashboard/SignedInDashboard/SignedInDashboardContextProvider';
import { useAppConfig } from 'src/config/appConfig';
import type {
  JobDetailsPersonalisedQuery,
  SearchSavedAndAppliedJobsQuery,
} from 'src/graphql/graphql';
import { useCurrentPage } from 'src/hooks/useCurrentPage/useCurrentPage';
import { getJobStatusFlags } from 'src/hooks/useSaveJob/utils/jobStatus';
import useSignInRedirect from 'src/hooks/useSaveJob/utils/useSignInRedirect';
import { useSolHash } from 'src/hooks/useSolHash';
import {
  isBrowserAnalyticsFacade,
  useAnalyticsFacade,
} from 'src/modules/AnalyticsFacade';
import { useSelector } from 'src/store/react';
import {
  selectAuthenticated,
  selectLocation,
  selectSelectedJobId,
  selectSessionId,
} from 'src/store/selectors';
import { updateA11yAnnouncement } from 'src/utils/a11y/announce';
import { getViewJobOriginRef } from 'src/utils/eventCapture/eventCaptureUtils';
import { getParam } from 'src/utils/urlParams';

import translations from './.vocab';
import useSaveJobMutation from './useSaveJobMutation';

export interface UseToggleSaveJobProps {
  jobId: string;
  view: 'jobDetails' | 'serp' | 'recommendationsList';
  linkPosition: 'job details' | 'listing';
  jobViewType?: JobViewType;
  jobTracking?: string;
  solMetadataReference?: string;
  savedJobsData?: SearchSavedAndAppliedJobsQuery | JobDetailsPersonalisedQuery;
  forceSave?: boolean;
}

const isSearchSavedAndAppliedJobsData = (
  data:
    | SearchSavedAndAppliedJobsQuery
    | JobDetailsPersonalisedQuery
    | undefined,
): data is SearchSavedAndAppliedJobsQuery =>
  Boolean(data && data.hasOwnProperty('viewer'));

export const useToggleSavedJob = ({
  forceSave,
  jobId,
  jobTracking,
  linkPosition,
  jobViewType,
  savedJobsData,
  solMetadataReference,
  view,
}: UseToggleSaveJobProps) => {
  const { t } = useTranslations(translations);
  const analyticsFacade = useAnalyticsFacade();
  const isAuthenticated = useSelector(selectAuthenticated);
  const sessionId = useSelector(selectSessionId);
  const currentLocation = useSelector(selectLocation);
  const { zone, locale } = useAppConfig();
  const selectedJobId = useSelector(selectSelectedJobId);
  const currentPage = useCurrentPage();
  const jobViewTypeValue = currentPage === 'home' ? 'drawer' : jobViewType;
  const { selectedJob } = useSignedInDashboardContext();

  const postSignInLocation = {
    ...currentLocation,
    query: {
      ...(currentLocation.query || {}),
      savejob: jobId,
      jobId: selectedJobId,
    },
  };
  const redirectToSignIn = useSignInRedirect(postSignInLocation)[1];
  const [, solMetadata] = useSolHash({
    id: jobId,
    isLinkOut: false,
    solMetadataReference,
  });

  const { isApplied, isSaved } = useMemo(() => {
    const isSearchSavedAndAppliedType =
      isSearchSavedAndAppliedJobsData(savedJobsData);

    if (isSearchSavedAndAppliedType) {
      return getJobStatusFlags({
        jobId,
        savedAndAppliedJobs: savedJobsData,
      });
    }

    return {
      isApplied: Boolean(
        savedJobsData?.jobDetails?.personalised?.appliedDateTime
          ?.shortAbsoluteLabel,
      ),
      isSaved: Boolean(savedJobsData?.jobDetails?.personalised?.isSaved),
    };
  }, [jobId, savedJobsData]);

  const { create: createSavedJob, delete: deleteSavedJob } = useSaveJobMutation(
    jobId,
    {
      create: {
        onCompleted: (data) => {
          if (data.createSavedJob2.__typename === 'CreateSavedJobSuccess') {
            updateA11yAnnouncement(t('Job saved'));
          } else {
            updateA11yAnnouncement(data.createSavedJob2.errors[0].message);
          }
        },
      },
      delete: {
        onCompleted: (data) => {
          if (data.deleteSavedJob2.__typename === 'DeleteSavedJobSuccess') {
            updateA11yAnnouncement(t('Job unsaved'));
          } else {
            updateA11yAnnouncement(data.deleteSavedJob2.errors[0].message);
          }
        },
      },
    },
  );

  const { jobListingPosition, jobViewOriginQuery } = useMemo(() => {
    if (selectedJob) {
      return {
        jobListingPosition: selectedJob.analytics.position,
        jobViewOriginQuery: selectedJob.analytics.origin,
      };
    }
    const pos = getParam('pos');
    const ref = getParam('ref');
    return {
      jobListingPosition: pos ? Number(pos) : undefined,
      jobViewOriginQuery: ref || undefined,
    };
  }, [selectedJob]);

  // this function is used by the save button on the job details page and job card
  const toggleSavedJob = useCallback(async () => {
    const viewJobOriginRef = getViewJobOriginRef();
    if (!isAuthenticated) {
      analyticsFacade.saveJobClicked({
        jobId,
        linkPosition,
        jobViewOriginQuery: viewJobOriginRef,
      });
      redirectToSignIn();
      return;
    }

    if (isSaved && !forceSave) {
      await deleteSavedJob({
        variables: {
          input: {
            id: `${jobId}`,
            eventCaptureData: {
              channel: 'web',
              view,
              serpTracking: jobTracking,
              eventCaptureSessionId: sessionId ?? '',
            },
          },
          locale,
        },
      });

      if (isBrowserAnalyticsFacade(analyticsFacade)) {
        analyticsFacade.saveJobRemoved(
          { solMetadata },
          {
            jobId,
            linkPosition,
            jobListingPosition,
            jobViewOriginQuery,
            jobViewType: jobViewTypeValue,
          },
        );
      }
    } else {
      await createSavedJob({
        variables: {
          input: {
            id: `${jobId}`,
            zone,
            eventCaptureData: {
              channel: 'web',
              view,
              serpTracking: jobTracking,
              eventCaptureSessionId: sessionId ?? '',
            },
          },
          locale,
        },
      });

      if (isBrowserAnalyticsFacade(analyticsFacade)) {
        analyticsFacade.saveJobCreated(
          { solMetadata },
          {
            jobId,
            linkPosition,
            jobListingPosition,
            jobViewOriginQuery,
            jobViewType: jobViewTypeValue,
          },
        );
      }
    }
  }, [
    isAuthenticated,
    isSaved,
    forceSave,
    analyticsFacade,
    jobId,
    linkPosition,
    redirectToSignIn,
    deleteSavedJob,
    view,
    jobTracking,
    sessionId,
    locale,
    solMetadata,
    jobViewTypeValue,
    createSavedJob,
    zone,
    jobListingPosition,
    jobViewOriginQuery,
  ]);

  return { toggleSavedJob, isApplied, isSaved };
};
