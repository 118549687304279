import { useTranslations } from '@vocab/react';
import { Box } from 'braid-design-system';
import filter from 'lodash/filter';
import size from 'lodash/size';
import type { ComponentProps } from 'react';

import translations from './.vocab';

export interface CopyProps {
  count: number;
  total: number;
  display?: ComponentProps<typeof Box>['display'];
}
const AllTypesCopy = ({ count, total, display }: CopyProps) => {
  const { t } = useTranslations(translations);
  return (
    <Box
      display={display}
      component="span"
      data-automation={`allTypes-${count}-${total}`}
    >
      {count === total ? t('All types (total)') : t('All types', { count })}
    </Box>
  );
};
const AllWorkTypesCopy = ({ count, total, display }: CopyProps) => {
  const { t } = useTranslations(translations);
  return (
    <Box
      display={display}
      component="span"
      data-automation={`allWorkTypes-${count}-${total}`}
    >
      {count === total
        ? t('All work types (total)')
        : t('All work types', { count })}
    </Box>
  );
};

interface WorkType {
  label: string;
}

interface WorkTypeSummaryProps {
  workTypes: WorkType[];
}

const WorkTypeSummary = ({ workTypes }: WorkTypeSummaryProps) => {
  const selectedWorkTypes = filter(workTypes, 'isActive');
  const total = size(workTypes);
  const count = size(selectedWorkTypes);

  if (count === 1) {
    const label = selectedWorkTypes.shift()?.label.split('/')[0];
    return (
      <Box component="span" data-automation={label}>
        {label}
      </Box>
    );
  }
  return (
    <>
      <AllTypesCopy
        count={count}
        total={total}
        display={{ mobile: 'inline', tablet: 'none' }}
      />
      <AllWorkTypesCopy
        count={count}
        total={total}
        display={{ mobile: 'none', tablet: 'inline' }}
      />
    </>
  );
};

export default WorkTypeSummary;
