// @ts-expect-error: non-ts file
import { provideHooks } from 'redial';
import loadable from 'sku/@loadable/component';

import { BehavioralCuesFiltersProvider } from 'src/components/SearchResultPage/BehaviouralCuesFiltersContext/BehaviouralCuesFiltersContext';
import { setPageTitle } from 'src/store/location';
import { getRecentSearches } from 'src/store/recentSearches';
import { expandSearchFilters } from 'src/store/search';
import type { RedialLocals } from 'src/types/RedialLocals';

export const HomePage = loadable(
  /* #__LOADABLE__ */ () =>
    import(
      /* webpackChunkName: "HomePage" */ 'src/components/HomePage/HomePage'
    ),
);

const hooks = {
  fetch: ({ routeEnter, dispatch, cookies, country }: RedialLocals) => {
    dispatch(setPageTitle(''));
    dispatch(expandSearchFilters(false));
    return routeEnter
      ? dispatch(getRecentSearches({ cookies, country }))
      : null;
  },
  pageLoad: ({ analyticsFacade, routeEnter }: RedialLocals) => {
    if (routeEnter) {
      analyticsFacade.homePageLoaded({
        // Note: 'dashboard' is no longer part of the redux store.
        // Now that the 3 sections of Dashboard are all indepdently loaded, this
        // event perhaps need to be re-factored / re-thought
        // const { dashboard } = store.getState() as ChaliceStore;
        flightId: undefined,
      });
    }
    return null;
  },
};

const Home = () => (
  <BehavioralCuesFiltersProvider>
    <HomePage />
  </BehavioralCuesFiltersProvider>
);

export default provideHooks(hooks)(Home);
