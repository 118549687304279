
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IlRoaXMgam9iIGlzIG5vIGxvbmdlciBhZHZlcnRpc2VkIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlNlYXJjaCBmb3IgYW5vdGhlciBqb2IifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6Ikxvd29uZ2FuIGtlcmphIGluaSB0aWRhayBsYWdpIGRpaWtsYW5rYW4iLCJKb2JzIHJlbWFpbiBvbiB7YnJhbmR9IGZvciAzMCBkYXlzLCB1bmxlc3MgdGhlIGFkdmVydGlzZXIgcmVtb3ZlcyB0aGVtIHNvb25lci4iOiJMb3dvbmdhbiB0ZXRhcCB0ZXJzZWRpYSBkaSB7YnJhbmR9IHNlbGFtYSAzMCBoYXJpLCBrZWN1YWxpIHBlbmdpa2xhbiBtZW5naGFwdXNueWEgbGViaWggYXdhbC4iLCJTZWFyY2ggZm9yIGFub3RoZXIgam9iIjoiQ2FyaSBsb3dvbmdhbiBrZXJqYSBsYWluIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6Ikxvd29uZ2FuIGtlcmphIGluaSB0aWRhayBsYWdpIGRpaWtsYW5rYW4iLCJKb2JzIHJlbWFpbiBvbiB7YnJhbmR9IGZvciAzMCBkYXlzLCB1bmxlc3MgdGhlIGFkdmVydGlzZXIgcmVtb3ZlcyB0aGVtIHNvb25lci4iOiJMb3dvbmdhbiB0ZXRhcCB0ZXJzZWRpYSBkaSB7YnJhbmR9IHNlbGFtYSAzMCBoYXJpLCBrZWN1YWxpIHBlbmdpa2xhbiBtZW5naGFwdXNueWEgbGViaWggYXdhbC4iLCJTZWFyY2ggZm9yIGFub3RoZXIgam9iIjoiQ2FyaSBsb3dvbmdhbiBrZXJqYSBsYWluIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IuC4h+C4suC4meC4meC4teC5ieC5hOC4oeC5iOC5hOC4lOC5ieC5guC4huC4qeC4k+C4suC4reC4teC4geC4leC5iOC4reC5hOC4m+C5geC4peC5ieC4pyIsIkpvYnMgcmVtYWluIG9uIHticmFuZH0gZm9yIDMwIGRheXMsIHVubGVzcyB0aGUgYWR2ZXJ0aXNlciByZW1vdmVzIHRoZW0gc29vbmVyLiI6IuC4h+C4suC4meC4iOC4sOC4hOC4h+C4reC4ouC4ueC5iOC5g+C4mSB7YnJhbmR9IOC5gOC4m+C5h+C4meC5gOC4p+C4peC4siAzMCDguKfguLHguJkg4LmA4Lin4LmJ4LiZ4LmB4LiV4LmI4Lic4Li54LmJ4LmC4LiG4Lip4LiT4Liy4LiH4Liy4LiZ4LiI4Liw4LiZ4Liz4LiH4Liy4LiZ4LiZ4Lix4LmJ4LiZ4Lit4Lit4LiB4LmE4Lib4LiB4LmI4Lit4LiZIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IuC4hOC5ieC4meC4q+C4suC4h+C4suC4meC4reC4t+C5iOC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6IuC4h+C4suC4meC4meC4teC5ieC5hOC4oeC5iOC5hOC4lOC5ieC5guC4huC4qeC4k+C4suC4reC4teC4geC4leC5iOC4reC5hOC4m+C5geC4peC5ieC4pyIsIkpvYnMgcmVtYWluIG9uIHticmFuZH0gZm9yIDMwIGRheXMsIHVubGVzcyB0aGUgYWR2ZXJ0aXNlciByZW1vdmVzIHRoZW0gc29vbmVyLiI6IuC4h+C4suC4meC4iOC4sOC4hOC4h+C4reC4ouC4ueC5iOC5g+C4mSB7YnJhbmR9IOC5gOC4m+C5h+C4meC5gOC4p+C4peC4siAzMCDguKfguLHguJkg4LmA4Lin4LmJ4LiZ4LmB4LiV4LmI4Lic4Li54LmJ4LmC4LiG4Lip4LiT4Liy4LiH4Liy4LiZ4LiI4Liw4LiZ4Liz4LiH4Liy4LiZ4LiZ4Lix4LmJ4LiZ4Lit4Lit4LiB4LmE4Lib4LiB4LmI4Lit4LiZIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IuC4hOC5ieC4meC4q+C4suC4h+C4suC4meC4reC4t+C5iOC4mSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6Ilvhua7huKnDrMOsw6zFoSDEtcO2w7bDtsOfIMOsw6zDrMWhIOC4geC4teC5icO2w7bDtiDGmsO2w7bDtuC4geC4teC5icSj4bq94bq94bq9xZkgxIPEg8SDxozhub3hur3hur3hur3FmeG5r8Osw6zDrMWh4bq94bq94bq9xoxdIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiW8S0w7bDtsO2w5/FoSDFmeG6veG6veG6vW3MgsSDxIPEg8Osw6zDrOC4geC4teC5iSDDtsO2w7bguIHguLXguYkge2JyYW5kfSDGksO2w7bDtsWZIDMwIMaMxIPEg8SDw73DvcO9xaEsIMeax5rHmuC4geC4teC5icaa4bq94bq94bq9xaHFoSDhua/huKnhur3hur3hur0gxIPEg8SDxozhub3hur3hur3hur3FmeG5r8Osw6zDrMWh4bq94bq94bq9xZkgxZnhur3hur3hur1tzILDtsO2w7bhub3hur3hur3hur3FoSDhua/huKnhur3hur3hur1tzIIgxaHDtsO2w7bDtsO2w7bguIHguLXguYnhur3hur3hur3FmS5dIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlvFoOG6veG6veG6vcSDxIPEg8WZw6fhuKkgxpLDtsO2w7bFmSDEg8SDxIPguIHguLXguYnDtsO2w7bhua/huKnhur3hur3hur3FmSDEtcO2w7bDtsOfXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYWR2ZXJ0aXNlZCI6Ilvhua7huKnDrMOsw6zFoSDEtcO2w7bDtsOfIMOsw6zDrMWhIOC4geC4teC5icO2w7bDtiDGmsO2w7bDtuC4geC4teC5icSj4bq94bq94bq9xZkgxIPEg8SDxozhub3hur3hur3hur3FmeG5r8Osw6zDrMWh4bq94bq94bq9xoxdIiwiSm9icyByZW1haW4gb24ge2JyYW5kfSBmb3IgMzAgZGF5cywgdW5sZXNzIHRoZSBhZHZlcnRpc2VyIHJlbW92ZXMgdGhlbSBzb29uZXIuIjoiW8S0w7bDtsO2w5/FoSDFmeG6veG6veG6vW3MgsSDxIPEg8Osw6zDrOC4geC4teC5iSDDtsO2w7bguIHguLXguYkge2JyYW5kfSDGksO2w7bDtsWZIDMwIMaMxIPEg8SDw73DvcO9xaEsIMeax5rHmuC4geC4teC5icaa4bq94bq94bq9xaHFoSDhua/huKnhur3hur3hur0gxIPEg8SDxozhub3hur3hur3hur3FmeG5r8Osw6zDrMWh4bq94bq94bq9xZkgxZnhur3hur3hur1tzILDtsO2w7bhub3hur3hur3hur3FoSDhua/huKnhur3hur3hur1tzIIgxaHDtsO2w7bDtsO2w7bguIHguLXguYnhur3hur3hur3FmS5dIiwiU2VhcmNoIGZvciBhbm90aGVyIGpvYiI6IlvFoOG6veG6veG6vcSDxIPEg8WZw6fhuKkgxpLDtsO2w7bFmSDEg8SDxIPguIHguLXguYnDtsO2w7bhua/huKnhur3hur3hur3FmSDEtcO2w7bDtsOfXSJ9!"
        )
      )
      });
  
      export { translations as default };
    