
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD5CeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0sIHlvdTo8L1RleHQ+PExpc3Q+PFRleHRMaXN0PmNvbnNlbnQgdG8gdGhlIHVzZSBvZiBjb29raWVzIGFzIG91dGxpbmVkIHdpdGhpbiBvdXIgUHJpdmFjeSBQb2xpY3k7IGFuZDwvVGV4dExpc3Q+PFRleHRMaXN0PmNvbmZpcm0gdGhhdCB5b3XigJlyZSBhdCBsZWFzdCAyMCB5ZWFycyBvbGQgb3IgaGF2ZSBwYXJlbnRhbCBjb25zZW50IGZvciBKb2JzZGIgYW5kIGFmZmlsaWF0ZXMgdG8gcHJvY2VzcyB5b3VyIHBlcnNvbmFsIGRhdGE8L1RleHRMaXN0PjwvTGlzdD4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD5CeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0sIHlvdTo8L1RleHQ+PExpc3Q+PFRleHRMaXN0PmNvbnNlbnQgdG8gdGhlIHVzZSBvZiBjb29raWVzIGFzIG91dGxpbmVkIHdpdGhpbiBvdXIgUHJpdmFjeSBQb2xpY3k7IGFuZDwvVGV4dExpc3Q+PFRleHRMaXN0PmNvbmZpcm0gdGhhdCB5b3XigJlyZSBhdCBsZWFzdCAyMCB5ZWFycyBvbGQgb3IgaGF2ZSBwYXJlbnRhbCBjb25zZW50IGZvciBKb2JzZGIgYW5kIGFmZmlsaWF0ZXMgdG8gcHJvY2VzcyB5b3VyIHBlcnNvbmFsIGRhdGE8L1RleHRMaXN0PjwvTGlzdD4ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD5CeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0sIHlvdSBjb25zZW50IHRvIHRoZSB1c2Ugb2YgY29va2llcyBhcyBvdXRsaW5lZCB3aXRoaW4gb3VyIFByaXZhY3kgUG9saWN5LjwvVGV4dD4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD5CeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0sIHlvdSBjb25zZW50IHRvIHRoZSB1c2Ugb2YgY29va2llcyBhcyBvdXRsaW5lZCB3aXRoaW4gb3VyIFByaXZhY3kgUG9saWN5LjwvVGV4dD4ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD5CeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0sIHlvdTo8L1RleHQ+PExpc3Q+PFRleHRMaXN0PmNvbnNlbnQgdG8gdGhlIHVzZSBvZiBjb29raWVzIGFzIG91dGxpbmVkIHdpdGhpbiBvdXIgUHJpdmFjeSBQb2xpY3k7IGFuZDwvVGV4dExpc3Q+PFRleHRMaXN0PmNvbmZpcm0gdGhhdCB5b3XigJlyZSBhdCBsZWFzdCAyMCB5ZWFycyBvbGQgb3IgaGF2ZSBwYXJlbnRhbCBjb25zZW50IGZvciBKb2JzZGIgYW5kIGFmZmlsaWF0ZXMgdG8gcHJvY2VzcyB5b3VyIHBlcnNvbmFsIGRhdGE8L1RleHRMaXN0PjwvTGlzdD4ifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD5CeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0sIHlvdTo8L1RleHQ+PExpc3Q+PFRleHRMaXN0PmNvbnNlbnQgdG8gdGhlIHVzZSBvZiBjb29raWVzIGFzIG91dGxpbmVkIHdpdGhpbiBvdXIgUHJpdmFjeSBQb2xpY3k7IGFuZDwvVGV4dExpc3Q+PFRleHRMaXN0PmNvbmZpcm0gdGhhdCB5b3XigJlyZSBhdCBsZWFzdCAyMCB5ZWFycyBvbGQgb3IgaGF2ZSBwYXJlbnRhbCBjb25zZW50IGZvciBKb2JzZGIgYW5kIGFmZmlsaWF0ZXMgdG8gcHJvY2VzcyB5b3VyIHBlcnNvbmFsIGRhdGE8L1RleHRMaXN0PjwvTGlzdD4ifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ+In0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD7guKvguLLguIHguJfguYjguLLguJnguJTguLPguYDguJnguLTguJnguIHguLLguKPguYPguIrguYnguYHguJ7guKXguJXguJ/guK3guKPguYzguKHguILguK3guIfguYDguKPguLIg4LiW4Li34Lit4Lin4LmI4Liy4LiX4LmI4Liy4LiZ4LmE4LiU4LmJ4LiV4LiB4Lil4LiH4LiU4Lix4LiH4LiV4LmI4Lit4LmE4Lib4LiZ4Li14LmJPC9UZXh0PjxMaXN0PjxUZXh0TGlzdD7guKLguLTguJnguKLguK3guKHguYPguKvguYnguYPguIrguYnguITguLjguIHguIHguLXguYnguJXguLLguKHguJfguLXguYjguIHguLPguKvguJnguJTguYTguKfguYnguYPguJnguJnguYLguKLguJrguLLguKLguITguKfguLLguKHguYDguJvguYfguJnguKrguYjguKfguJnguJXguLHguKfguILguK3guIfguYDguKPguLI8L1RleHRMaXN0PjxUZXh0TGlzdD7guKLguLfguJnguKLguLHguJnguKfguYjguLLguJfguYjguLLguJnguKHguLXguK3guLLguKLguLjguJXguLHguYnguIfguYHguJXguYggMjAg4Lib4Li14LiC4Li24LmJ4LiZ4LmE4LibIOC4q+C4o+C4t+C4reC4l+C5iOC4suC4meC5hOC4lOC5ieC4o+C4seC4muC4hOC4p+C4suC4oeC4ouC4tOC4meC4ouC4reC4oeC4iOC4suC4geC4nOC4ueC5ieC4m+C4geC4hOC4o+C4reC4hyDguYDguJ7guLfguYjguK3guKLguLTguJnguKLguK3guKHguYPguKvguYkgSm9ic2RiIOC5geC4peC4sOC4muC4o+C4tOC4qeC4seC4l+C5g+C4meC5gOC4hOC4o+C4t+C4reC4m+C4o+C4sOC4oeC4p+C4peC4nOC4peC4guC5ieC4reC4oeC4ueC4peC4quC5iOC4p+C4meC4muC4uOC4hOC4hOC4peC4guC4reC4h+C4l+C5iOC4suC4mTwvVGV4dExpc3Q+PC9MaXN0PiJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD7guKvguLLguIHguJfguYjguLLguJnguJTguLPguYDguJnguLTguJnguIHguLLguKPguYPguIrguYnguYHguJ7guKXguJXguJ/guK3guKPguYzguKHguILguK3guIfguYDguKPguLIg4LiW4Li34Lit4Lin4LmI4Liy4LiX4LmI4Liy4LiZ4LmE4LiU4LmJ4LiV4LiB4Lil4LiH4LiU4Lix4LiH4LiV4LmI4Lit4LmE4Lib4LiZ4Li14LmJPC9UZXh0PjxMaXN0PjxUZXh0TGlzdD7guKLguLTguJnguKLguK3guKHguYPguKvguYnguYPguIrguYnguITguLjguIHguIHguLXguYnguJXguLLguKHguJfguLXguYjguIHguLPguKvguJnguJTguYTguKfguYnguYPguJnguJnguYLguKLguJrguLLguKLguITguKfguLLguKHguYDguJvguYfguJnguKrguYjguKfguJnguJXguLHguKfguILguK3guIfguYDguKPguLI8L1RleHRMaXN0PjxUZXh0TGlzdD7guKLguLfguJnguKLguLHguJnguKfguYjguLLguJfguYjguLLguJnguKHguLXguK3guLLguKLguLjguJXguLHguYnguIfguYHguJXguYggMjAg4Lib4Li14LiC4Li24LmJ4LiZ4LmE4LibIOC4q+C4o+C4t+C4reC4l+C5iOC4suC4meC5hOC4lOC5ieC4o+C4seC4muC4hOC4p+C4suC4oeC4ouC4tOC4meC4ouC4reC4oeC4iOC4suC4geC4nOC4ueC5ieC4m+C4geC4hOC4o+C4reC4hyDguYDguJ7guLfguYjguK3guKLguLTguJnguKLguK3guKHguYPguKvguYkgSm9ic2RiIOC5geC4peC4sOC4muC4o+C4tOC4qeC4seC4l+C5g+C4meC5gOC4hOC4o+C4t+C4reC4m+C4o+C4sOC4oeC4p+C4peC4nOC4peC4guC5ieC4reC4oeC4ueC4peC4quC5iOC4p+C4meC4muC4uOC4hOC4hOC4peC4guC4reC4h+C4l+C5iOC4suC4mTwvVGV4dExpc3Q+PC9MaXN0PiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiJbPFRleHQ+PC9UZXh0Pl0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiJbPFRleHQ+PC9UZXh0Pl0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    