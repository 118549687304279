
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiSGlkZSByZWZpbmVtZW50cyIsIlJlZmluZSB5b3VyIHNlYXJjaCI6IlJlZmluZSB5b3VyIHNlYXJjaCIsIlNob3cge2ZpZWxkfSByZWZpbmVtZW50cy4iOiJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIiwicmVmaW5lIGJ5IHtmaWVsZH0iOiJyZWZpbmUgYnkge2ZpZWxkfSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiU2VtYnVueWlrYW4gcGVueWFyaW5nYW4iLCJSZWZpbmUgeW91ciBzZWFyY2giOiJGaWx0ZXIgcGVuY2FyaWFuIGthbXUiLCJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIjoiVGFtcGlsa2FuIGZpbHRlciB7ZmllbGR9LiIsInJlZmluZSBieSB7ZmllbGR9IjoiZmlsdGVyIGJlcmRhc2Fya2FuIHtmaWVsZH0ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiU2VtYnVueWlrYW4gcGVueWFyaW5nYW4iLCJSZWZpbmUgeW91ciBzZWFyY2giOiJGaWx0ZXIgcGVuY2FyaWFuIGthbXUiLCJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIjoiVGFtcGlsa2FuIGZpbHRlciB7ZmllbGR9LiIsInJlZmluZSBieSB7ZmllbGR9IjoiZmlsdGVyIGJlcmRhc2Fya2FuIHtmaWVsZH0ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoi4LiL4LmI4Lit4LiZ4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LmB4Lia4Lia4Lil4Liw4LmA4Lit4Li14Lii4LiUIiwiUmVmaW5lIHlvdXIgc2VhcmNoIjoi4LiE4LmJ4LiZ4Lir4Liy4LmC4LiU4Lii4LmD4LiK4LmJ4LiV4Lix4Lin4LiB4Lij4Lit4LiHIiwiU2hvdyB7ZmllbGR9IHJlZmluZW1lbnRzLiI6IuC5geC4quC4lOC4hyB7ZmllbGR9IOC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4l+C4teC5iOC4peC4sOC5gOC4reC4teC4ouC4lOC4guC4tuC5ieC4mSIsInJlZmluZSBieSB7ZmllbGR9Ijoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LmC4LiU4LiiIHtmaWVsZH0ifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoi4LiL4LmI4Lit4LiZ4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LmB4Lia4Lia4Lil4Liw4LmA4Lit4Li14Lii4LiUIiwiUmVmaW5lIHlvdXIgc2VhcmNoIjoi4LiE4LmJ4LiZ4Lir4Liy4LmC4LiU4Lii4LmD4LiK4LmJ4LiV4Lix4Lin4LiB4Lij4Lit4LiHIiwiU2hvdyB7ZmllbGR9IHJlZmluZW1lbnRzLiI6IuC5geC4quC4lOC4hyB7ZmllbGR9IOC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4l+C4teC5iOC4peC4sOC5gOC4reC4teC4ouC4lOC4guC4tuC5ieC4mSIsInJlZmluZSBieSB7ZmllbGR9Ijoi4LiE4Lix4LiU4LiB4Lij4Lit4LiH4LmC4LiU4LiiIHtmaWVsZH0ifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiW+G4qMOsw6zDrMaM4bq94bq94bq9IMWZ4bq94bq94bq9xpLDrMOsw6zguIHguLXguYnhur3hur3hur1tzILhur3hur3hur3guIHguLXguYnhua/FoV0iLCJSZWZpbmUgeW91ciBzZWFyY2giOiJbxZjhur3hur3GksOsw6zguIHguLXguYnhur3hur0gw73DvcO2w7bHmseaxZkgxaHhur3hur3Eg8SDxZnDp+G4qV0iLCJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIjoiW8Wg4bipw7bDtsO2xbUge2ZpZWxkfSDFmeG6veG6veG6vcaSw6zDrMOs4LiB4Li14LmJ4bq94bq94bq9bcyC4bq94bq94bq94LiB4Li14LmJ4bmvxaEuXSIsInJlZmluZSBieSB7ZmllbGR9IjoiW8WZ4bq94bq9xpLDrMOs4LiB4Li14LmJ4bq94bq9IMOfw73DvSB7ZmllbGR9XSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIaWRlIHJlZmluZW1lbnRzIjoiW+G4qMOsw6zDrMaM4bq94bq94bq9IMWZ4bq94bq94bq9xpLDrMOsw6zguIHguLXguYnhur3hur3hur1tzILhur3hur3hur3guIHguLXguYnhua/FoV0iLCJSZWZpbmUgeW91ciBzZWFyY2giOiJbxZjhur3hur3GksOsw6zguIHguLXguYnhur3hur0gw73DvcO2w7bHmseaxZkgxaHhur3hur3Eg8SDxZnDp+G4qV0iLCJTaG93IHtmaWVsZH0gcmVmaW5lbWVudHMuIjoiW8Wg4bipw7bDtsO2xbUge2ZpZWxkfSDFmeG6veG6veG6vcaSw6zDrMOs4LiB4Li14LmJ4bq94bq94bq9bcyC4bq94bq94bq94LiB4Li14LmJ4bmvxaEuXSIsInJlZmluZSBieSB7ZmllbGR9IjoiW8WZ4bq94bq9xpLDrMOs4LiB4Li14LmJ4bq94bq9IMOfw73DvSB7ZmllbGR9XSJ9!"
        )
      )
      });
  
      export { translations as default };
    