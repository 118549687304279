import gql from 'graphql-tag';

export const GET_SAVED_SEARCHES = gql`
  query GetSavedSearches(
    $cluster: SmarterSearchCluster
    $languageCode: LanguageCodeIso
  ) {
    viewer {
      id
      apacSavedSearches {
        id
        name(languageCode: $languageCode)
        countLabel(cluster: $cluster)
        query(languageCode: $languageCode) {
          searchQueryString
          parameters {
            type
            value
          }
        }
      }
    }
  }
`;
