import {
  ApacCandidateHeader,
  ApacCandidateHeaderFooterLinkProvider,
} from '@seek/apac-candidate-header-footer';

import { useHeaderFooterLinkProvider } from 'src/hooks';
import { useHeaderProps } from 'src/hooks/useHeaderFooterProps';

const Header = () => {
  const headerLink = useHeaderFooterLinkProvider();
  const {
    authenticationStatus,
    brand,
    country,
    hostname,
    isEnableDivider,
    isUnified,
    language,
    languageSwitcher,
    onLanguageSwitch,
    returnUrlForPostAuth,
    userProfile,
  } = useHeaderProps();

  return (
    <ApacCandidateHeaderFooterLinkProvider value={headerLink}>
      <ApacCandidateHeader
        activeGlobalTab="jobs"
        activePrimaryTab="search"
        authenticationStatus={authenticationStatus}
        brand={brand}
        country={country}
        divider={isEnableDivider}
        hostname={hostname}
        isUnified={isUnified}
        language={language}
        onLanguageSwitch={onLanguageSwitch}
        languageSwitcher={languageSwitcher}
        returnUrlForPostAuth={returnUrlForPostAuth}
        userProfile={userProfile}
        widthMode="large"
      />
    </ApacCandidateHeaderFooterLinkProvider>
  );
};

export default Header;
