import type {
  SearchParams,
  SearchResultJob,
  SearchResultJobV5,
  SearchResultLocation,
  SearchResultLocationV5,
} from '@seek/chalice-types';
import assign from 'lodash/assign';
import omit from 'lodash/omit';
// @ts-expect-error non-ts file
import { toWords } from 'spelled-number';

import { isJobExternal } from 'src/modules/qualified-location';
import type {
  JobViewModel,
  JobViewModelClassification,
  JobViewModelItemPartial,
  JobViewModelLocation,
  JobViewModelLocationPartial,
  JobViewModelV5,
} from 'src/types/JobViewModel';
import type { Country } from 'src/types/globals';

const MINUTE = 60000; // 60 * 1000
const MINUTES = MINUTE;
const HOUR = 60 * MINUTES;
const HOURS = HOUR;
const DAY = 24 * HOURS;
const DAYS = DAY;

const minutes = (amount: number) => amount * MINUTES;

const hours = (amount: number) => amount * HOURS;

const days = (amount: number) => amount * DAYS;

// Picks the right mix of params from the current page (query)
// and the current job.
const pickSearchParams = (
  job: SearchResultJob | SearchResultJobV5,
  query: SearchParams,
) => {
  const result = omit(query, 'page'); // Always refine the to first page

  // Use the job's company name and id over the query, because
  // our follow / nofollow logic depends on these.
  if (
    query.companyname &&
    job.companyName &&
    job.companyProfileStructuredDataId
  ) {
    result.companyname = job.companyName;
    result.companyid = `${job.companyProfileStructuredDataId}`;
  }

  return result;
};

const createLocationObject = (
  job: SearchResultJob,
  query: SearchParams,
  locationField: keyof JobViewModelLocation,
) => {
  const name = job[locationField] || '';
  const result: JobViewModelLocationPartial = {
    name,
    title: '',
    params: {
      where: '',
    },
  };
  const locationWhereValueField = locationField.concat(
    'WhereValue',
  ) as keyof Pick<
    SearchResultJob,
    'areaWhereValue' | 'suburbWhereValue' | 'locationWhereValue'
  >;

  if (job[locationWhereValueField]) {
    const parentLocation =
      locationField === 'area' ? ` in ${job.location}` : '';
    const whereid = job[`${locationField}Id`];
    result.title = `Limit results to ${name}${parentLocation}`;
    result.params = assign(
      pickSearchParams(job, query),
      {
        where: job[locationWhereValueField] || '',
      },
      whereid && { whereid },
    );
  }

  return result;
};

const getLocation = (
  job: SearchResultJob,
  query: SearchParams,
): JobViewModelLocation | null => {
  if (job.suburb && job.suburb !== 'none') {
    return {
      suburb: createLocationObject(job, query, 'suburb'),
    };
  }

  if (!job.location) {
    return null;
  }

  const locationObj = createLocationObject(job, query, 'location');

  if (!job.area) {
    return {
      location: locationObj,
    };
  }

  return {
    location: locationObj,
    area: createLocationObject(job, query, 'area'),
  };
};

const getJobLocation = (job: SearchResultJob | SearchResultJobV5) => {
  if ('jobLocation' in job) {
    // This is a V4 job
    return job.jobLocation;
  } else if ('locations' in job) {
    // V5 supports an array of locations but we only need to worry about the first one for now
    return job.locations[0];
  }
};

const getUnifiedLocation = (
  job: SearchResultJob | SearchResultJobV5,
  query: SearchParams,
) => {
  const jobLocation = getJobLocation(job);

  if (!jobLocation || !jobLocation.seoHierarchy) {
    return null;
  }

  // temp use before the seoHierarchy.label exist
  const getNameFromConcatenatedLabel = (index: number) => {
    const locations = jobLocation.label.split(', ');
    const seoLength = jobLocation.seoHierarchy.length;
    const isLengthMatch = locations.length === seoLength;

    if (!isLengthMatch && index === seoLength - 1) {
      return locations
        .filter((part: string, i: number) => i >= seoLength - 1)
        .join(', ');
    }
    return locations[index];
  };

  const unifiedLocationObj = jobLocation.seoHierarchy
    .map((item, index) => {
      const name = item.label ?? getNameFromConcatenatedLabel(index);
      return name
        ? {
            name,
            title: `Limit results to ${name}`,
            params: assign(pickSearchParams(job, query), {
              where: item.contextualName,
            }),
          }
        : null;
    })
    .filter((item) => item !== null);

  return unifiedLocationObj as JobViewModelItemPartial[];
};

const getUnifiedDateDescription = (
  job: SearchResultJob | SearchResultJobV5,
) => {
  const listingDateDisplay = job.listingDateDisplay;

  if (!listingDateDisplay) {
    return null;
  }

  if (listingDateDisplay === 'just now') {
    return 'Listed just now';
  }

  const timeUnit = listingDateDisplay.split(' ')[0].replace(/[0-9]/g, '');
  const number = Number(listingDateDisplay.replace(/[^0-9]/g, ''));

  if (timeUnit === 'm') {
    return `Listed ${convertToWords(number, 'minute')} ago`;
  }
  if (timeUnit === 'h') {
    return `Listed ${convertToWords(number, 'hour')} ago`;
  }
  if (timeUnit === 'd') {
    return `Listed ${convertToWords(number, 'day')} ago`;
  }
  return `Listed more than ${convertToWords(number, 'day')} ago`;
};

const getClassification = (
  job: SearchResultJob | SearchResultJobV5,
  query: SearchParams,
  location?: SearchResultLocation | SearchResultLocationV5,
): JobViewModelClassification => {
  let classification;
  let subClassification;
  if ('classifications' in job) {
    // This is a V5 job

    // V5 supports an array of classification hierarchies but for now we only need to worry about the first one
    const classificationHierarchy = job.classifications[0];
    classification = classificationHierarchy.classification;
    subClassification = classificationHierarchy.subclassification;
  } else {
    classification = job.classification;
    subClassification = job.subClassification;
  }

  const matchedWhere =
    !location ||
    location.description === 'All Australia' ||
    location.description === 'All New Zealand'
      ? ''
      : location.description;
  const result: Partial<JobViewModelClassification> = {};

  if (classification) {
    // should always exist, but it's safer to guard anyway
    result.classification = {
      name: classification.description || '',
      title: `Limit results to ${classification.description}`,
      params: assign(omit(pickSearchParams(job, query), 'subclassification'), {
        where: matchedWhere,
        classification: classification.id,
      }),
    };
  }

  if (subClassification && subClassification.id) {
    // it is null for graduate jobs (don't ask me why)
    result.subClassification = {
      name: subClassification.description || '',
      title: `Limit results to ${subClassification.description} in ${classification.description}`,
      params: assign(pickSearchParams(job, query), {
        where: matchedWhere,
        classification: classification.id,
        subclassification: subClassification.id,
      }),
    };
  }

  return result as JobViewModelClassification;
};

const getIsJobExternal = (
  job: SearchResultJob | SearchResultJobV5,
  candidateCountryCode: Country,
) => {
  const jobLocation = getJobLocation(job);

  return isJobExternal(candidateCountryCode, jobLocation?.countryCode);
};

const diffFromNow = (dateStr: string, nowStr?: string) => {
  const date = new Date(dateStr);
  const now = nowStr ? new Date(nowStr) : new Date();

  return now.getTime() - date.getTime();
};

// Converts:
//     5, 'minute'
// to:
//     'five minutes'
const convertToWords = (number: number, word: string) => {
  const numberAsWords = toWords(number);

  return `${numberAsWords} ${word}${number === 1 ? '' : 's'}`;
};

const getListingDate = (
  job: SearchResultJob | SearchResultJobV5,
  now?: string,
) => {
  const diff = diffFromNow(job.listingDate, now);
  let number;

  if (diff < minutes(5.5)) {
    number = 5;

    return {
      shortDescription: `${number}m ago`,
      longDescription: `Listed ${convertToWords(number, 'minute')} ago`,
    };
  }

  if (diff < minutes(59.5)) {
    number = Math.round(diff / MINUTE);

    return {
      shortDescription: `${number}m ago`,
      longDescription: `Listed ${convertToWords(number, 'minute')} ago`,
    };
  }

  if (diff < hours(23.5)) {
    number = Math.round(diff / HOUR);

    return {
      shortDescription: `${number}h ago`,
      longDescription: `Listed ${convertToWords(number, 'hour')} ago`,
    };
  }

  if (diff < days(30.5)) {
    number = Math.round(diff / DAY);

    return {
      shortDescription: `${number}d ago`,
      longDescription: `Listed ${convertToWords(number, 'day')} ago`,
    };
  }

  number = 30;

  return {
    shortDescription: `${number}d+ ago`,
    longDescription: `Listed more than ${convertToWords(number, 'day')} ago`,
  };
};

const getIsFresh = (job: SearchResultJob | SearchResultJobV5, now?: string) =>
  diffFromNow(job.listingDate, now) < hours(23.5);

const getBulletPoints = (job: SearchResultJob | SearchResultJobV5) => {
  if (job.bulletPoints && job.bulletPoints.length > 0) {
    return job.bulletPoints;
  }
};

const getWorkType = (job: SearchResultJobV5) =>
  // V5 supports an array of workTypes but for now we only need to worry about the first one
  job.workTypes[0];

const getJobId = (job: SearchResultJobV5) =>
  // V5 returns id as a string instead of a number like in V4
  // Keeping the id consistent as a number for now, but we can consider refactoring once V4 is deprecated
  parseInt(job.id, 10);

export interface JobViewModelParams {
  job: SearchResultJob | SearchResultJobV5;
  query: SearchParams;
  location?: SearchResultLocation | SearchResultLocationV5;
  now?: string;
}
const jobViewModel = (
  data: JobViewModelParams,
  candidateCountryCode: Country,
  isV5Search: boolean = false,
) => {
  const job = data.job || {};
  const query = data.query || {};
  const location = data.location;
  const now = data.now; // used in tests to mock the current time
  const solMetadataString = job.solMetadata
    ? JSON.stringify(job.solMetadata)
    : job.solMetadata;

  if (isV5Search) {
    const v5Job = job as SearchResultJobV5;
    const viewModel: JobViewModelV5 = {
      advertiser: v5Job.advertiser,
      branding: v5Job.branding,
      bulletPoints: getBulletPoints(v5Job),
      classification: getClassification(v5Job, query, location),
      currencyLabel: v5Job.currencyLabel,
      id: getJobId(v5Job),
      isFeatured: v5Job.isFeatured,
      isFresh: getIsFresh(v5Job, now),
      title: v5Job.title,
      teaser: v5Job.teaser,
      salary: v5Job.salaryLabel,
      srcLogo: v5Job.branding?.serpLogoUrl,
      solMetadataString,
      unifiedLocation: getUnifiedLocation(v5Job, query),
      unifiedListingDate: v5Job.listingDateDisplay,
      unifiedListingDateDescription: getUnifiedDateDescription(v5Job),
      workType: getWorkType(v5Job),
      isJobExternal: getIsJobExternal(v5Job, candidateCountryCode),
    };

    if (!v5Job.isFeatured) {
      const listingDate = getListingDate(v5Job, now);

      viewModel.listingDate = listingDate.shortDescription;
      viewModel.listingDateDescription = listingDate.longDescription;
    }

    return viewModel;
  }

  const v4Job = job as SearchResultJob;
  const viewModel: JobViewModel = {
    advertiser: v4Job.advertiser,
    branding: v4Job.branding,
    bulletPoints: getBulletPoints(v4Job),
    classification: getClassification(v4Job, query, location),
    currencyLabel: v4Job.currencyLabel,
    id: v4Job.id,
    isPremium: v4Job.isPremium,
    isStandOut: !v4Job.isPremium && v4Job.isStandOut,
    isFresh: getIsFresh(v4Job, now),
    title: v4Job.title,
    logo: v4Job.logo,
    location: getLocation(v4Job, query),
    locationMatch: v4Job.locationMatch,
    teaser: v4Job.teaser,
    salary: v4Job.salary,
    srcLogo: v4Job.branding?.assets?.logo?.strategies?.serpLogo,
    solMetadataString,
    joraClickTrackingUrl: v4Job.joraClickTrackingUrl,
    joraImpressionTrackingUrl: v4Job.joraImpressionTrackingUrl,
    unifiedLocation: getUnifiedLocation(v4Job, query),
    unifiedListingDate: v4Job.listingDateDisplay,
    unifiedListingDateDescription: getUnifiedDateDescription(v4Job),
    workType: v4Job.workType,
    isJobExternal: getIsJobExternal(v4Job, candidateCountryCode),
    isFeatured: v4Job.isPremium,
  };

  if (!v4Job.isPremium) {
    const listingDate = getListingDate(v4Job, now);

    viewModel.listingDate = listingDate.shortDescription;
    viewModel.listingDateDescription = listingDate.longDescription;
  }

  return viewModel;
};

export default jobViewModel;
