import type { SearchParams } from '@seek/chalice-types';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';

import type { Country } from 'src/types/globals';

import { shouldRequestRelatedSearches } from './convertToApiQueryHelper';

const isFalsy = (x: any) => !x;
const clean = (object: Record<PropertyKey, any>) => omitBy(object, isFalsy);

// Asia markets shouldn't have a default where value.
const defaultWhereValue: Partial<Record<Country, string>> = {
  AU: 'All Australia',
  NZ: 'All New Zealand',
};

const shouldUseDefaultWhereValue = (country: Country) =>
  Object.keys(defaultWhereValue).includes(country);

const getDefaultWhereValue = (country: Country) =>
  shouldUseDefaultWhereValue(country) ? defaultWhereValue[country] : '';

const hasLegacyLocations = ({
  nation,
  state,
  location,
  area,
  areaid,
}: Record<PropertyKey, any>) =>
  typeof nation !== 'undefined' ||
  typeof state !== 'undefined' ||
  typeof location !== 'undefined' ||
  typeof area !== 'undefined' ||
  typeof areaid !== 'undefined';

const includeSeoAndRelatedSearchesData = ({
  searchParams,
  isV5Search,
}: {
  searchParams: SearchParams;
  isV5Search?: boolean;
}): SearchParams => {
  const include = `${searchParams.include || ''}seodata${
    isV5Search && shouldRequestRelatedSearches(searchParams)
      ? ',relatedsearches'
      : ''
  }${isV5Search ? ',joracrosslink,gptTargeting' : ''}`;

  return { ...searchParams, include };
};

const atCompany = (searchParams: SearchParams): SearchParams => {
  if (!searchParams.companysearch) {
    return searchParams;
  }
  const { keywords } = searchParams;

  return {
    ...omit(searchParams, ['keywords', 'companysearch']),
    companyname: keywords,
  };
};

const getMappedParams = (
  { advertiserid, advertisergroup, companyid }: SearchParams,
  isV5Search: boolean = false,
) => {
  if (!isV5Search) {
    // Jobsearch V4 previously supported hirerid/hirergroup/companyprofilestructureddataid (equivalent to advertiserid/advertisergroup/companyid) but they are now deprecated in V5
    return {
      hirerid: advertiserid,
      hirergroup: advertisergroup,
      companyprofilestructureddataid: companyid,
    };
  }

  return { advertiserid, advertisergroup, companyid };
};

export default ({
  country,
  searchQuery,
  isV5Search,
}: {
  country: Country;
  searchQuery: SearchParams;
  isV5Search?: boolean;
}): SearchParams => {
  /*
  Setting seekSelectAllPages to true means always return promoted jobs for every page.
  Historically it's been added to the search API on purpose not to show
  promoted adds for subsequent "pages" on mobile apps because they have an infinite scroll.
  It's been defaulted to false to support old apps so they dont start showing them.
  Means for chalice it should be explicitly set to true, because we don't have any
  logic around that or infinite scroll.
  If we do infinite scroll one day, we might want to remove it

  The default behaviour in Jobsearch V5 is to include premium (now called featured) jobs so the seekSelectAllPages param is no longer required
  If we don't want to include featured ads, we should instead add "nofeatured" to the includes param (e.g. &include=nofeatured)
  */
  const defaultParams = {
    where: !hasLegacyLocations(searchQuery)
      ? getDefaultWhereValue(country)
      : '',
    page: '1',
    ...(!isV5Search ? { seekSelectAllPages: true } : {}),
  };

  const expandedQuery = { ...defaultParams, ...searchQuery };

  const { advertiserid, advertisergroup, companyid, ...standardParams } =
    expandedQuery;

  const mappedParams = getMappedParams(
    { advertiserid, advertisergroup, companyid },
    isV5Search,
  );

  return clean(
    includeSeoAndRelatedSearchesData({
      searchParams: atCompany({
        ...standardParams,
        ...mappedParams,
      }),
      isV5Search,
    }),
  );
};
