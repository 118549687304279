import type {
  SearchResultLocation,
  SearchResultLocationV5,
} from '@seek/chalice-types';

const LocationTypes = {
  SUBURB: 'Suburb',
};

const isSuburb = (
  location: SearchResultLocation | SearchResultLocationV5 | undefined,
): boolean => {
  if (location === undefined) {
    return false;
  }
  return location.type === LocationTypes.SUBURB;
};

export default isSuburb;
