
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiU2VsYW1hdCBkYXRhbmcgZGkgVVJMIGJhcnUga2FtaSAtIHtvbGRVcmx9IHNla2FyYW5nIG1lbmphZGkgPEJvbGQ+e25ld1VybH08L0JvbGQ+LiBNQVNVSyBzZWthcmFuZyB1bnR1ayBwZW5nYWxhbWFuIHlhbmcgc2FtYSBoZWJhdG55YS4gPFRleHRMaW5rPlBlbGFqYXJpIGxlYmloIGxhbmp1dDwvVGV4dExpbms+LiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiU2VsYW1hdCBkYXRhbmcgZGkgVVJMIGJhcnUga2FtaSAtIHtvbGRVcmx9IHNla2FyYW5nIG1lbmphZGkgPEJvbGQ+e25ld1VybH08L0JvbGQ+LiBNQVNVSyBzZWthcmFuZyB1bnR1ayBwZW5nYWxhbWFuIHlhbmcgc2FtYSBoZWJhdG55YS4gPFRleHRMaW5rPlBlbGFqYXJpIGxlYmloIGxhbmp1dDwvVGV4dExpbms+LiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiV2VsY29tZSB0byBvdXIgbmV3IFVSTCDigJMge29sZFVybH0gaXMgbm93IDxCb2xkPntuZXdVcmx9PC9Cb2xkPi4gU0lHTiBJTiBub3cgZm9yIHRoZSBzYW1lIGdyZWF0IGV4cGVyaWVuY2UuIDxUZXh0TGluaz5MZWFybiBtb3JlPC9UZXh0TGluaz4uIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiW8W04bq94bq94bq9xprDp8O2w7bDtm3MguG6veG6veG6vSDhua/DtsO2w7Ygw7bDtsO2x5rHmseaxZkg4LiB4Li14LmJ4bq94bq94bq9xbUgx5nFmMWBIOKAkyB7b2xkVXJsfSDDrMOsw6zFoSDguIHguLXguYnDtsO2w7bFtSA8Qm9sZD57bmV3VXJsfTwvQm9sZD4uIMWgw4/HpsORIMOPw5Eg4LiB4Li14LmJw7bDtsO2xbUgxpLDtsO2w7bFmSDhua/huKnhur3hur3hur0gxaHEg8SDxINtzILhur3hur3hur0gxKPFmeG6veG6veG6vcSDxIPEg+G5ryDhur3hur3hur3qrZXGpeG6veG6veG6vcWZw6zDrMOs4bq94bq94bq94LiB4Li14LmJw6fhur3hur3hur0uIDxUZXh0TGluaz7FgeG6veG6vcSDxIPFmeC4geC4teC5iSBtzILDtsO2xZnhur3hur08L1RleHRMaW5rPi5dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGFuZ2VzIGluIHBsYXRmb3JtIjoiW8W04bq94bq94bq9xprDp8O2w7bDtm3MguG6veG6veG6vSDhua/DtsO2w7Ygw7bDtsO2x5rHmseaxZkg4LiB4Li14LmJ4bq94bq94bq9xbUgx5nFmMWBIOKAkyB7b2xkVXJsfSDDrMOsw6zFoSDguIHguLXguYnDtsO2w7bFtSA8Qm9sZD57bmV3VXJsfTwvQm9sZD4uIMWgw4/HpsORIMOPw5Eg4LiB4Li14LmJw7bDtsO2xbUgxpLDtsO2w7bFmSDhua/huKnhur3hur3hur0gxaHEg8SDxINtzILhur3hur3hur0gxKPFmeG6veG6veG6vcSDxIPEg+G5ryDhur3hur3hur3qrZXGpeG6veG6veG6vcWZw6zDrMOs4bq94bq94bq94LiB4Li14LmJw6fhur3hur3hur0uIDxUZXh0TGluaz7FgeG6veG6vcSDxIPFmeC4geC4teC5iSBtzILDtsO2xZnhur3hur08L1RleHRMaW5rPi5dIn0=!"
        )
      )
      });
  
      export { translations as default };
    