
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlNhbGFyeSB0eXBlIiwiU2FsYXJ5IHR5cGUsIGxhYmVsIjoiU2FsYXJ5IHR5cGUsIHtsYWJlbH0ifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlRpcGUgZ2FqaSIsIlNhbGFyeSB0eXBlLCBsYWJlbCI6IlRpcGUgZ2FqaSwge2xhYmVsfSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlRpcGUgZ2FqaSIsIlNhbGFyeSB0eXBlLCBsYWJlbCI6IlRpcGUgZ2FqaSwge2xhYmVsfSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IuC4m+C4o+C4sOC5gOC4oOC4l+C5gOC4h+C4tOC4meC5gOC4lOC4t+C4reC4mSIsIlNhbGFyeSB0eXBlLCBsYWJlbCI6IuC4m+C4o+C4sOC5gOC4oOC4l+C5gOC4h+C4tOC4meC5gOC4lOC4t+C4reC4mSB7bGFiZWx9In0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IuC4m+C4o+C4sOC5gOC4oOC4l+C5gOC4h+C4tOC4meC5gOC4lOC4t+C4reC4mSIsIlNhbGFyeSB0eXBlLCBsYWJlbCI6IuC4m+C4o+C4sOC5gOC4oOC4l+C5gOC4h+C4tOC4meC5gOC4lOC4t+C4reC4mSB7bGFiZWx9In0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlvFoMSDxIPGmsSDxIPFmcO9w70g4bmvw73Dvcal4bq94bq9XSIsIlNhbGFyeSB0eXBlLCBsYWJlbCI6IlvFoMSDxIPEg8aaxIPEg8SDxZnDvcO9w70g4bmvw73DvcO9xqXhur3hur3hur0sIHtsYWJlbH1dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYWxhcnkgdHlwZSI6IlvFoMSDxIPGmsSDxIPFmcO9w70g4bmvw73Dvcal4bq94bq9XSIsIlNhbGFyeSB0eXBlLCBsYWJlbCI6IlvFoMSDxIPEg8aaxIPEg8SDxZnDvcO9w70g4bmvw73DvcO9xqXhur3hur3hur0sIHtsYWJlbH1dIn0=!"
        )
      )
      });
  
      export { translations as default };
    