import { useTranslations } from '@vocab/react';
import { Stack, Strong, Text, TextLink } from 'braid-design-system';

import { useAppConfig, useTimedBanner } from 'src/config/appConfig';
import { isTimedComponentValid } from 'src/utils/timedComponent/timedComponentValidator';

import { GlobalNotificationBannerSkeleton } from '../GlobalNotificationBannerSkeleton/GlobalNotificationBannerSkeleton';

import translations from './.vocab';

export const LaunchBanner = () => {
  const { t } = useTranslations(translations);
  const { language } = useAppConfig();
  const launchBannerConfig = useTimedBanner('launchBanner');
  const showLaunchBanner = isTimedComponentValid(
    launchBannerConfig?.displayDates,
  );

  if (!launchBannerConfig || !showLaunchBanner) {
    return null;
  }

  const bannerUrl =
    language === 'id' && launchBannerConfig.bannerUrl.id
      ? launchBannerConfig.bannerUrl.id
      : launchBannerConfig.bannerUrl.en;

  return (
    <GlobalNotificationBannerSkeleton
      dataAutomation="launchBanner"
      tone="info"
      localStorageKey="lB"
    >
      <Stack space="small">
        <Text size="small">
          {t('Changes in platform', {
            oldUrl: launchBannerConfig.oldUrl,
            newUrl: launchBannerConfig.newUrl,
            TextLink: (children) => (
              <TextLink href={bannerUrl}>{children}</TextLink>
            ),
            Bold: (children) => <Strong>{children}</Strong>,
          })}
        </Text>
      </Stack>
    </GlobalNotificationBannerSkeleton>
  );
};
