import {
  type HeaderFooterLinkType,
  mapLinkActionHelper,
  type ApacCandidateHeaderFooterLinkProvider,
} from '@seek/apac-candidate-header-footer';
import type {
  LinkClickedProps,
  NavigationPressedProps,
  PageName,
} from '@seek/seek-jobs-analytics';
import type { ComponentProps } from 'react';

import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';

import { useCurrentPage } from '../useCurrentPage/useCurrentPage';

type LinkProviderValueType = ComponentProps<
  typeof ApacCandidateHeaderFooterLinkProvider
>['value'];

type SendLinkEventContext = Parameters<
  LinkProviderValueType['sendLinkEvent']
>['0']['linkContext'];

const isSendLinkEventContext = (
  context: unknown,
): context is SendLinkEventContext =>
  (context as SendLinkEventContext)?.openingOrClosing !== undefined;

interface MapLinkContextToTrackingContextProps {
  currentPage: PageName;
  linkContext: SendLinkEventContext;
}
export const mapLinkContextToTrackingContext = ({
  currentPage,
  linkContext,
}: MapLinkContextToTrackingContextProps):
  | (LinkClickedProps['linkContext'] | NavigationPressedProps) & {
      eventName?: string;
    } => ({
  eventName: 'navigation_pressed',
  currentPage,
  linkPosition: linkContext?.isExpanded
    ? 'expanded menu'
    : linkContext.position,
  linkText: linkContext?.source || '',
  ...(isSendLinkEventContext(linkContext) && linkContext.openingOrClosing
    ? mapLinkActionHelper(linkContext.openingOrClosing, {
        key: 'linkAction',
        opening: 'expand',
        closing: 'collapse',
      })
    : {}),
});

export const useHeaderFooterLinkContextMapper = () => {
  const currentPage = useCurrentPage();
  return (linkContextHeaderFooter: HeaderFooterLinkType) => ({
    allowPropagation: true,
    linkContext: mapLinkContextToTrackingContext({
      linkContext: linkContextHeaderFooter,
      currentPage,
    }),
    rel: linkContextHeaderFooter.isNoFollow ? 'nofollow' : undefined,
  });
};

export const useHeaderFooterLinkProvider = (): LinkProviderValueType => {
  const currentPage = useCurrentPage();
  const analyticsFacade = useAnalyticsFacade();
  return {
    sendLinkEvent: ({ linkContext }) => {
      const trackingContext = mapLinkContextToTrackingContext({
        currentPage,
        linkContext,
      });
      analyticsFacade.navigationPressed(trackingContext);
    },
  };
};
