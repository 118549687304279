import { CookieConsentBanner } from './CookieConsentBanner/CookieConsentBanner';
import { LaunchBanner } from './LaunchBanner/LaunchBanner';
import { PrivacyPolicyBanner } from './PrivacyPolicyBanner/PrivacyPolicyBanner';

export const GlobalNotificationBanner = () => (
  <>
    <CookieConsentBanner />
    <PrivacyPolicyBanner />
    <LaunchBanner />
  </>
);
