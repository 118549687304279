import type { LogsEvent } from '@datadog/browser-logs';

const errorIgnoreListPartial = [
  'pinterest',
  'gtag',
  'doubleclick',
  'tealium',
  'trackjs',
  'google-analytics',
  'hotjar',
  'snapchat',
  'googleads',
  'googlesyndication',
];

const errorIgnoreListExact = ['console error:', ''];

export const beforeSend = (log: LogsEvent) => {
  if (log?.error?.stack && !log.error.stack.includes(window.location.host)) {
    return false;
  }

  for (const partialErrorToIgnore of errorIgnoreListPartial) {
    if (log.message.toLowerCase().includes(partialErrorToIgnore)) {
      return false;
    }
  }

  for (const exactErrorToIgnore of errorIgnoreListExact) {
    if (log.message === exactErrorToIgnore) {
      return false;
    }
  }

  return true;
};
